<template>
    <div>
        <el-container>

            <el-header class="my-page-header">
                <el-row>
                    <common-page-title-label title="头像管理"/>
                    <common-add-button title="添加头像" @click="onAddAvatarClick"/>
                </el-row>
            </el-header>

            <!-- 列表 -->
            <el-main width="80%">

                <el-row :gutter="20">
                    <el-col class="avatar-col-item" :span="2" v-for="(item, index) in tableData"
                            :key="item.name" @click="onEditItemClick(index)">
                        <div>
                            <img class="avatar-image" :src="item.path" alt=""/>
                        </div>
                    </el-col>
                </el-row>

            </el-main>

            <!-- 分页插件 -->
            <el-footer>
                <el-pagination
                    :page-size="pageSize"
                    :page-count="total"
                    :current-page="currentPage"
                    layout="prev, pager, next"
                    @current-change="onPageChange">

                </el-pagination>
            </el-footer>

        </el-container>

        <avatar-edit-dialog-v2 v-model:visible="this.addDialogVisible" mode="add"/>

        <avatar-edit-dialog-v2 v-model:visible="this.editDialogVisible" mode="edit" ref="editDialog"
                               :avatar-id="this.selectAvatarId"/>

    </div>
</template>

<script>

import AdminAvatarEditDialog from "@/views/dubbing/avatar/AvatarEditDialog";
import {getAvatarList} from "@/api/anchor";
import {deleteAvatar} from "@/api/avatar";
import {ElMessage, ElMessageBox} from 'element-plus';
import {Delete} from "@element-plus/icons-vue"
import AnchorSexLabel from "@/views/dubbing/components/AnchorSexLabel";
import CommonPageTitleLabel from "@/views/common/CommonPageTitleLabel";
import CommonAddButton from "@/views/common/CommonAddButton";
import AvatarEditDialogV2 from "@/views/dubbing/avatar/AvatarEditDialogV2";
import {DateUtils} from "@/utils/DateUtils";


export default {
    name: "AdminAvatarPage",
    components: {
        AvatarEditDialogV2,
        CommonAddButton,
        CommonPageTitleLabel,
        AnchorSexLabel,
        AdminAvatarEditDialog,
        Delete
    },
    mounted() {
        this.keyRandom = DateUtils.currentTimeInMillis();
        this.requestGetAvatar(1)
    },
    data() {
        return {
            keyRandom: 0,
            showEditDialog: false,
            showDeleteIndex: -1,
            tableLoading: false,
            currentPage: 1,
            pageSize: 120,
            total: 0,
            tableData: [],
            addDialogVisible: false,
            editDialogVisible: false,
            selectAvatarId: null,
        }
    },
    methods: {

        // ===========================================
        //      添加头像
        // ===========================================

        onAddAvatarClick() {
            // this.showEditDialog = true;
            this.addDialogVisible = true;
        },

        onAddAvatarResult(avatarVo) {
            this.tableData.push(avatarVo);
        },

        onEditItemClick(index) {
            let avatarVo = this.tableData[index];
            this.selectAvatarId = avatarVo.id;
            this.$refs.editDialog.setSex(avatarVo.sex);
            this.$refs.editDialog.setAvatarUrl(avatarVo.path);
            this.editDialogVisible = true;
        },

        onDeleteAvatarClick(index) {
            ElMessageBox.confirm("确定要删除该头像吗", "提示", {
                confirmButtonText: "删除",
                cancelButtonText: "取消",
                type: 'warning'
            }).then(() => {
                // 确定删除
                this.handleDeleteAvatar(index);
            }).catch(error => {
                // 取消删除
                console.log("取消删除")
            })
        },

        handleDeleteAvatar(index) {
            let avatarVo = this.tableData[index];
            let path = avatarVo.path;
            deleteAvatar(path).then(res => {
                if (res.code === 200) {
                    ElMessage.success("删除成功")
                } else {
                    ElMessage.error("删除失败: " + res.msg);
                }
            }).catch(error => {
                ElMessage.error("删除失败: " + error.toString());
            })
        },

        // ===========================================
        //      页码改变
        // ===========================================

        onPageChange(newPage) {
            this.currentPage = newPage;
            this.requestGetAvatar(newPage);
        },

        requestGetAvatar(newPage) {
            getAvatarList(newPage, this.pageSize).then(res => {
                let pageData = res.data;
                this.total = pageData.total;
                this.tableData = pageData.list;
            }).catch(error => {
                console.error("getAvatarList with error: " + error.toString());
                ElMessage.error("获取头像列表失败")
            })
        },
    }
}
</script>

<style scoped>

.avatar-col-item {
    margin-top: 10px;
    cursor: pointer;
}

.avatar-image {
    width: 100%;
    display: block;
}

.delete-btn {
    position: absolute;
    bottom: 0;
    width: 100%;
    text-align: center;
    /*height: 20px;*/
    padding: 0;
    background-color: #ff3b30;
    opacity: 0.7;
    color: white;
}

</style>