import {Apis} from "@/api/Apis";

export const TradeApis = {

    getTradeOrderList(page, pageSize) {
        let req = {
            page: page,
            pageSize: pageSize
        }
        return Apis.get("/admin/trade/order/list", req)
    },

    searchTradeOrderList(type, content, page, pageSize) {
        let req = {
            type: type,
            content: content,
            page: page,
            pageSize: pageSize
        }
        return Apis.post("/admin/trade/order/search", req)
    },
}