const VoiceOptions = {
    voiceSourceList: [
        {
            label: '阿里云',
            value: 1
        },
        {
            label: '微软',
            value: 2
        }
    ],
    sexOptions: [
        {
            label: '男',
            value: 1
        },
        {
            label: '女',
            value: 2
        },
        {
            label: '童声',
            value: 3
        }
    ],
    aiLevelOptions: [
        {
            label: '普通AI',
            value: 1
        },
        {
            label: '高级AI',
            value: 2
        },
        {
            label: '情绪AI',
            value: 3
        }
    ],
    sampleRateOptions: [
        {
            label: "8k",
            value: 8000
        },
        {
            label: "16k",
            value: 16000
        },
        {
            label: "24k",
            value: 24000
        },
        {
            label: "48k",
            value: 48000
        }
    ],

    makeSampleRateOptions(sampleRateMax){
        let options = Array.from(this.sampleRateOptions);
        if (sampleRateMax === 48000) {
            return options;
        } else if (sampleRateMax === 24000) {
            return options.splice(0, 3);
        } else if (sampleRateMax === 16000) {
            return options.splice(0, 2);
        } else if (sampleRateMax === 8000) {
            return options.splice(0, 1);
        }
        return options;
    },
    voiceTypeList: [
        {
            label: '标准男声',
            value: '标准男声'
        },
        {
            label: '标准女声',
            value: '标准女声'
        },
        {
            label: '温柔女声',
            value: '温柔女声'
        },
        {
            label: '严厉女声',
            value: '严厉女声'
        },
        {
            label: '亲和女声',
            value: '亲和女声'
        },
        {
            label: '甜美女声',
            value: '甜美女声'
        },
        {
            label: '自然女声',
            value: '自然女声'
        },
        {
            label: '微软-常规',
            value: '微软-常规'
        },
        {
            label: '微软-使用 SSML 提供多种风格',
            value: '微软-使用 SSML 提供多种风格'
        },
    ],
    sceneOptions: [
        {
            label: '通用场景',
            value: '通用场景'
        },
        {
            label: '客服场景',
            value: '客服场景'
        },
        {
            label: '童声场景',
            value: '童声场景'
        },
        {
            label: '英文场景',
            value: '英文场景'
        },
        {
            label: '方言场景',
            value: '方言场景'
        },
        {
            label: '多语种场景',
            value: '多语种场景'
        },
        {
            label: '直播场景',
            value: '直播场景'
        },
        {
            label: '方言场景',
            value: '方言场景'
        },
    ],
    qualityOptions: [
        {
            label:'lite版',
            value:'lite版'
        },
        {
            label:'标准版',
            value:'标准版'
        },
        {
            label: '微软-常规',
            value: '微软-常规'
        }
    ],

};

export default VoiceOptions