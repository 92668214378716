<template>
    <!-- 编辑发音人弹框 -->
    <!-- name, voice, type, scene, language, quality,  -->
    <el-dialog width="40%" v-model="internalVisible" title="设置角色" >
        <el-form ref="voiceFormEl" :model="voiceForm" label-width="100px">

            <el-form-item label="声音名称" prop="name">
                <el-input v-model="voiceForm.name" disabled/>
            </el-form-item>

            <el-form-item label="支持风格:" prop="styles">
                <el-checkbox-group v-model="voiceForm.roles">
                    <el-checkbox v-for="item in roleOptionList" :key="item.id" :label="item.id">
                        {{ item.name }}
                    </el-checkbox>
                </el-checkbox-group>
            </el-form-item>

        </el-form>

        <template #footer>
                <span class="dialog-footer">
                    <el-button @click="dismiss">取消</el-button>
                    <el-button type="primary" @click="onSubmitClick">确定</el-button>
                </span>
        </template>
    </el-dialog>
</template>

<!-- 微软发音人 支持风格 编辑 -->
<script>
import bus from "@/utils/bus";
import {DubbingApis} from "@/api/dubbing";
import {ElMessage} from "element-plus";
import BasicDialog from "@/views/base/BasicDialog";
import {DubbingRoleApis} from "@/api/dubbing-role-apis";

export default {
    name: "VoiceRoleEditDialog",
    mixins: [BasicDialog],
    data() {
        return {
            roleOptionList: [], // 可选择风格列表
            voiceForm: {
                id: null,
                name: null,
                roles:[], // 已选择的风格
            },
        }
    },
    mounted() {
        this.getRoleOptions();
    },

    methods: {

        setupRole(voiceItemData) {
           this.voiceForm.id = voiceItemData.id;
           this.voiceForm.name = voiceItemData.name;
            if (voiceItemData.roles !== null) {
                for (let i = 0; i < voiceItemData.roles.length; i++) {
                    let item = voiceItemData.roles[i];
                    this.voiceForm.roles.push(item.id)
                }
            }
        },

        reset() {
            this.$refs.voiceFormEl.resetFields();
            this.$refs.voiceFormEl.clearValidate();
        },

        onDismiss() {
            this.reset()
        },

        getRoleOptions() {
            DubbingRoleApis.getAll().then(res => {
                this.roleOptionList = res.data;
            })
        },

        onSubmitClick() {
            // $refs 获取组件
            this.$refs.voiceFormEl.validate( (valid) => {
                if (valid) {
                    this.modifyStyles()
                }
            })
        },

        modifyStyles() {
            let req = {
                voiceId: this.voiceForm.id,
                roleIds: this.voiceForm.roles
            }

            DubbingApis.editVoiceRoles(req).then(res => {
                ElMessage.success("设置成功");
            })
        }
    }
}
</script>

<style scoped>

</style>