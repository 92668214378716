<template>
    <el-dialog v-model="internalVisible" width="360px"
               :title="dialogTitle"
               :before-close="beforeClose"
               @closed="reset">

        <el-form ref="avatarForm" :model="avatarForm" label-width="80px">

            <el-form-item v-if="!this.isAddMode" label="头像id:">
                <el-input v-model="this.internalAvatarId" disabled/>
            </el-form-item>

            <el-form-item label="声音性别" prop="sex">
                <el-select v-model="avatarForm.sex" placeholder="选择声音性别:" style="width: 240px">
                    <el-option v-for="item in sexOptions"
                               :key="item.value"
                               :label="item.label"
                               :value="item.value"/>
                </el-select>
            </el-form-item>

            <el-form-item label="选择头像:" prop="avatar">
                <el-upload ref="uploader" class="anchor-avatar-uploader"
                           action="#" drag
                           accept="image/png,image/jpeg"
                           :limit="1"
                           :auto-upload="false"
                           :show-file-list="false"
                           :on-exceed="_onExceed"
                           :on-remove="_onRemove"
                           :on-change="_onFileChange"
                           :before-upload="beforeUpload"
                           :http-request="onUpload">
                    <!--suppress HtmlUnknownTarget -->
                    <div class="anchor-avatar-uploader-holder">
                        <img v-if="selectedAvatarFileUrl"
                             :src="selectedAvatarFileUrl"
                             class="avatar-img">
                        <span class="holder-text">点击选择头像或拖拽到此</span>
                    </div>
                    <!--<el-icon v-else class="plus-icon">-->
                    <!--    <plus/>-->
                    <!--</el-icon>-->
                </el-upload>
            </el-form-item>

        </el-form>

        <template #footer>
                <span class="dialog-footer">
                    <el-button @click="onCancelClick">取消</el-button>
                    <el-button type="primary" @click="onSubmitClick">确定</el-button>
                </span>
        </template>
    </el-dialog>
</template>

<script>
import BasicDialog from "@/views/base/BasicDialog";
import voiceOptions from "@/views/dubbing/VoiceConstants";
import {ElMessage, genFileId} from "element-plus";
import {AnchorAvatarApis} from "@/api/AnchorAvatarApis";
import {Plus} from "@element-plus/icons-vue";

export default {
    name: "AvatarEditDialogV2",
    components: {
        Plus
    },
    mixins:[BasicDialog],
    props:{
       mode: {
           type: String,
           default: 'add' // add, update
       },
       avatarId: {
           type: Number,
           default: null
       }
    },
    emits: ["result"],
    computed: {
        isAddMode() {
            return "add" === this.mode;
        },
        dialogTitle() {
            return this.isAddMode ? "添加头像" : "编辑头像"
        },
        internalAvatarId: {
            get() {
                return this.avatarId;
            },
            set(val) {

            }
        }
    },
    data() {
        return {
            sexOptions: voiceOptions.sexOptions,
            selectedAvatarFileUrl: null, // 当前选择的头像文件链接，用于预览
            selectedAvatarFile: null, // 当前选择的头像文件，用于上传,
            avatarForm: {
                sex: 1
            }
        }
    },
    methods: {

        setSex(sex){
            this.avatarForm.sex = sex;
        },

        setAvatarUrl(path) {
            this.selectedAvatarFileUrl = path;
        },

        reset() {
            // 清空已选择的文件
            this.$refs.uploader.clearFiles();
            // 清除表单
            this.$refs['avatarForm'].resetFields();
            //清除表单内所有规则检测提示
            this.$refs['avatarForm'].clearValidate();

            let obj1 = this.$data;
            let obj2 = this.$options.data.call(this);
            for (let attrName in obj1) {
                if (attrName !== 'rules') {
                    // noinspection JSUnfilteredForInLoop
                    obj1[attrName] = obj2[attrName]
                }
            }
        },

        beforeClose() {
            this.dismiss();
        },
        onCancelClick() {
            this.dismiss();
        },

        onSubmitClick() {
            if (!this.isAddMode && this.selectedAvatarFile === null) {
                this.updateProperties();
            } else {
                this.$refs.uploader.submit()
            }
        },

        notifyAddSuccess(data) {
            this.$emit('result', data);
            this.dismiss();
        },

        // ===========================================
        //      file change
        // ===========================================

        _onExceed(files) {
            this.$refs.uploader.clearFiles();
            const file = files[0];
            file.uid = genFileId();
            this.$refs.uploader.handleStart(file);
        },

        _onRemove() {
            this.selectedAvatarFile = null;
            this.selectedAvatarFileUrl = null;
        },

        // 文件状态改变时的钩子，添加文件、上传成功和上传失败时都会被调用
        _onFileChange(file) {
            // console.log("onChange: " + JSON.stringify(file));
            this.selectedAvatarFile = file;
            // 获取上传图片的本地URL，用于上传前的本地预览
            let URL = null;
            if (window.createObjectURL !== undefined) {
                // basic
                URL = window.createObjectURL(file.raw);
            } else if (window.URL !== undefined) {
                // mozilla(firefox)
                URL = window.URL.createObjectURL(file.raw);
            } else if (window.webkitURL !== undefined) {
                // webkit or chrome
                URL = window.webkitURL.createObjectURL(file.raw);
            }
            this.selectedAvatarFileUrl = URL;
        },

        // avatar-uploader
        beforeUpload(file) {
            if (!this.isAddMode && file === null && this.selectedAvatarFileUrl != null) {
                return true;
            }
            // console.log("beforeUpload file: " + JSON.stringify(file));
            const isJPG = file.type === 'image/jpeg' || file.type === 'image/png';
            const isLt2M = file.size / 1024 / 1024 < 2;

            if (!isJPG) {
                ElMessage.error('Avatar picture must be JPG/PNG format!')
            }
            if (!isLt2M) {
                ElMessage.error('Avatar picture size can not exceed 2MB!')
            }
            return isJPG && isLt2M
        },

        onUpload(param) {
            if (this.isAddMode) {
                this.addAvatar(param);
            } else {
                this.updateAvatar(param);
            }
        },

        // ===========================================
        //      upload
        // ===========================================

        addAvatar(param) {
            let file = param.file;
            let sex = this.avatarForm.sex;

            let form = new FormData();
            form.append("file", file);
            form.append("sex", sex);

            AnchorAvatarApis.addAnchorAvatarV2(form).then(res => {
                if (res.code === 200) {
                    // 添加成功
                    this.notifyAddSuccess()
                } else {
                    // 添加失败
                }
            })
        },

        // ===========================================
        //      update
        // ===========================================

        updateAvatar(param) {
            let file = param.file;

            let form = new FormData();
            form.append("file", file);
            form.append("avatarId", this.avatarId);
            form.append("sex", this.avatarForm.sex);

            AnchorAvatarApis.updateAvatarV2(form).then(res => {
                if (res.code === 200) {
                    this.notifyAddSuccess()
                }
            })
        },

        updateProperties() {
            let form = new FormData();
            form.append("avatarId", this.avatarId);
            form.append("sex", this.avatarForm.sex);

            AnchorAvatarApis.updateSex(form).then(res => {
                if (res.code === 200) {
                    this.notifyAddSuccess()
                }
            })
        }
    }
}
</script>

<style lang="scss" scoped>

.anchor-avatar-uploader {
    width: 100%;
    //height: 90px;
    //border: 1px dashed #d9d9d9;
    //border-radius: 10px;
    cursor: pointer;
    overflow: hidden;
    line-height: 0;

    :deep(.el-upload-dragger) {
        padding: 0;
    }

}

.anchor-avatar-uploader-holder {
    display: flex;
    align-items: center;
    width: 100%;
    height: 90px;
    justify-content: center;

    .avatar-img {
        width: 64px;
        height: 64px;
        border: 1px dashed #d9d9d9;
        border-radius: 32px;
    }

    .holder-text {
        margin-left: 10px;
        font-size: 12px;
    }
}

//.plus-icon {
//    font-size: 20px;
//    color: #8c939d;
//    width: 90px;
//    height: 90px;
//    text-align: center;
//
//    margin-top: 0;
//}

</style>