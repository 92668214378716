<template>
    <div class="login-container">
        <el-form class="login-form" :model="loginForm">

            <div class="title-container">
                <h3 class="title">用户登录</h3>
            </div>

            <el-form-item prop="username">
                <el-input :prefix-icon="Avatar"
                          v-model="loginForm.username"
                          placeholder="用户名"
                          type="text"
                          autocomplete="on"/>
            </el-form-item>

            <el-form-item prop="password">
                <el-input :prefix-icon="Lock"
                          type="password"
                          v-model="loginForm.password"
                          placeholder="请输入密码" show-password
                          @keyup.enter="login">
                </el-input>
            </el-form-item>

            <div class="login-btn__wrapper" v-loading="loading">
                <el-button type="primary" class="login-btn"
                           @click="login">
                    登录
                </el-button>
            </div>

        </el-form>
    </div>
</template>

<script>

import {login} from "@/api/user-apis";
// 一个用户图像+密码锁图标
import {Avatar, Lock} from "@element-plus/icons-vue";
import {AccountManager} from "@/data/AccountManager";

export default {
    name: "AdminLogin",
    setup() {
        return {
            Avatar,
            Lock
        }
    },
    data() {
        return {
            loginForm: {
                username: '',
                password: ''
            },
            loading: false,
        }
    },
    methods: {
        login() {
            // console.log(process.env.VUE_APP_API_HOST + ",,," + process.env.VUE_APP_MODE);
            const params = this.loginForm;
            this.loading = true;
            login(params).then(res => {
                this.loading = false;
                let userinfo = res.data;
                this.$store.commit('user/SET_TOKEN', res.data.token);
                this.$store.commit('user/SET_USERNAME', res.data.username);
                this.$store.commit('user/SET_AVATAR', res.data.avatar);

                AccountManager.setLoginSuccess(userinfo);

                const path = this.$route.query.redirect;
                const target = (path === '/' || path === undefined) ? '/' : path;
                this.$router.replace({path: target})

            }).catch(error => {
                this.loading = false;
                console.log("login error: " + error)
                alert(error.message)
            })
        }
    }
}
</script>


<style lang="scss" scoped>
//$bg: #2d3a4b;
//$dark_gray: #889aa4;
//$light_gray: #eee;

.login-container {
    background: #2d3a4b;
    height: 100%;
    width: 100%;
    overflow: hidden;
    //background: url("~@/assets/login_bg.jpg") no-repeat;

    .login-form {
        position: relative;
        width: 350px;
        margin: 160px auto;
        overflow: hidden;
        //background-color: white;

        :deep(.el-input) {
            height: 44px;
            font-size: 14px;
        }
    }

    .title-container {
        .title {
            color: white;
            font-size: 22px;
            font-weight: bold;
            margin: 0 auto 25px auto;
        }
    }

    .login-btn__wrapper {
        width: 100%;

        .login-btn {
            width: 100%;
            height: 44px;
        }
    }
}
</style>