<template>
    <el-drawer v-model="internalVisible" title="AI样音--编辑" >
        <el-form v-model="sampleForm" label-width="80px" label-position="right">
            <el-form-item label="风格">
                <el-select v-model="sampleForm.sceneId">
                    <el-option v-for="item in sceneList" :key="item.id" :label="item.name" :value="item.id"/>
                </el-select>
            </el-form-item>
            <el-form-item label="名称">
                <el-input v-model="sampleForm.name" placeholder="输入样音名称"/>
            </el-form-item>
            <el-form-item label="简介">
                <el-input v-model="sampleForm.comment" placeholder="输入样音简介"/>
            </el-form-item>
        </el-form>
        <div style="width: 100%; margin-top:20px; display: flex;align-items: center;">
            <el-button style="margin-left: auto;" @click.stop="dismiss">取消</el-button>
            <el-button class="ml-16" @click.stop="_onCommit" type="primary">确定</el-button>
        </div>
    </el-drawer>
</template>

<script>
import BasicDialog from "@/views/base/BasicDialog";
import {AnchorApis} from "@/api/anchor";
import {ToastUtil} from "@/utils/toast-util";

export default {
    name: "AnchorSampleUpdateDrawer",
    mixins: [BasicDialog],
    data() {
        return {
            sceneList: [],
            sampleForm: {
                id: null,
                name: null,
                comment: null,
                sceneId: null,

            }
        }
    },

    methods: {

        _loadSceneList() {
            if (this.sceneList.length > 0) {
                return;
            }
            AnchorApis.sampleScenes().then(res => {
                this.sceneList = res.data;
            })
        },

        init(sample) {
            this.sampleForm.id = sample.id;
            this.sampleForm.name = sample.name;
            this.sampleForm.comment = sample.comment;
            this.sampleForm.sceneId = sample.sceneId;
        },

        onShow() {
            this._loadSceneList();
        },

        _onCommit() {
            let req = {
                id: this.sampleForm.id,
                name: this.sampleForm.name,
                comment: this.sampleForm.comment,
                sceneId: this.sampleForm.sceneId
            }
            AnchorApis.updateSample(req).then(res => {
                ToastUtil.toast("修改成功");
                this.dismiss();
            })
        }
    }

}
</script>

<style lang="scss" scoped>

</style>