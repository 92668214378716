import axios from "axios";
import {ElMessage} from "element-plus";

export const OssService = {
    // 为了代码提示
    _ossPolicy() {
        return {
            accessKeyId: '',
            policy: '',
            dir: '',
            filename: '',
            objectKey: '',
            callback: '',
            signature: ''
        }
    },
    uploadFile(file, ossPolicy, onProgress= null, successHandler, failHandler = null) {
        // let objectKey = ossPolicy.dir + ossPolicy.filename; // 使用服务端生成的文件名
        let objectKey = ossPolicy.objectKey;
        let form = new FormData();
        //注意formData里append添加的键的大小写
        form.append('key', objectKey); //存储在oss的文件路径
        form.append('OSSAccessKeyId', ossPolicy.accessKeyId);
        form.append('policy', ossPolicy.policy); //policy
        form.append('Signature', ossPolicy.signature); //签名
        form.append('callback', ossPolicy.callback); //回调
        form.append('success_action_status', "200"); //成功后返回的操作码
        //如果是base64文件，那么直接把base64字符串转成blob对象进行上传就可以了
        form.append("file", file);
        return axios.post(ossPolicy.host, form, {
            headers: {
                'Content-Type': 'multipart/form-data;',
            },
            //获取上传进度
            onUploadProgress: function (progressEvent) {
                if (onProgress) {
                    //实时获取上传进度
                    let progress = 0;
                    if (progressEvent.total > 0) {
                        progress = progressEvent.loaded / progressEvent.total * 100;
                    }
                    progress = parseInt(progress + "");
                    onProgress(progress);
                }
            }
        }).then(res => {
            console.log(res);
            if (res.status === 200) {
                let callback = res.data
                if (callback.code === 200) {
                    // 上传成功
                    successHandler(callback);
                } else {
                    // 服务端回调处理失败
                    if (failHandler && !failHandler(callback.code, callback.message)) {
                        ElMessage.error("应用服务器回调失败：" + callback.message)
                    }
                }
            } else {
                // 上传到oss失败
                if (failHandler && !failHandler(res.status, res.statusText)) {
                    ElMessage.error("上传到oss失败：" + res.statusText)
                }
            }
        })
    }
}