<template>
    <!-- eslint-disable-next-line vue/no-mutating-props -->
    <el-dialog width="500px"
               v-model="internalVisible"
               :title="dialogTitle"
               @closed="reset">
        <div class="avatar-picker-container">
            <div>
                <el-row :gutter="20">
                    <el-col class="avatar-col-item"
                            :span="4"
                            v-for="(item, index) in tableData"
                            :key="item.name">
                        <!--@mouseenter="showDeleteIndex = index" @mouseleave="showDeleteIndex = -1"-->
                        <div style="position: relative;" @click="onItemClick(index)">
                            <!--suppress HtmlUnknownTarget -->
                            <img class="avatar-image" :src="item.path" :alt="item.name">
                            <div class="selected-overlay" v-if="index === selectedIndex"></div>
                            <el-icon class="selected-check" :size="36" v-if="index === selectedIndex">
                                <check/>
                            </el-icon>
                        </div>
                    </el-col>
                </el-row>
            </div>
            <!-- 分页插件 -->
            <el-pagination
                :page-size="pageSize"
                :page-count="total"
                :current-page="page"
                layout="prev, pager, next"
                @current-change="onPageChange">
            </el-pagination>
        </div>

        <template #footer>
                <span class="dialog-footer">
                    <el-button @click="dismiss">取消</el-button>
                    <el-button type="primary" @click="onSubmitClick">确定</el-button>
                </span>
        </template>
    </el-dialog>
</template>

<script>
import {getAvatarList} from "@/api/anchor";
import {ElMessage} from "element-plus";
import {Check} from "@element-plus/icons-vue"
import BasicDialog from "@/views/base/BasicDialog";

export default {
    name: "AvatarPickerDialog",
    mixins: [BasicDialog],
    components: {
        Check
    },
    // 定义抛出事件名
    emits: ["dismiss", "onResult"],
    data() {
        return {
            dialogTitle: "选择头像",
            page: 1,
            pageSize: 30,
            total: 0,
            tableData: [],
            selectedIndex: -1
        }

    },
    mounted() {
        this.requestGetAvatar(this.page);
    },
    methods: {
        reset() {
        },
        // dismiss() {
        //     // 关闭状态需要父组件控制
        //     // this.$emit('dismiss');
        // },
        // beforeClose() {
        //     this.dismiss();
        // },
        // onCancelClick() {
        //     this.dismiss();
        // },

        onSubmitClick() {
            console.log("onSubmitClick");
            if (this.selectedIndex >= 0 && this.selectedIndex < this.tableData.length) {
                this.$emit('onResult', this.tableData[this.selectedIndex]);
                this.dismiss();
            } else {
                ElMessage.error("请选择一个头像")
            }
        },

        onItemClick(index) {
            console.log("selected item: " + index);
            this.selectedIndex = index
        },

        // ===========================================
        //      api
        // ===========================================

        onPageChange(newPage) {
            this.page = newPage;
            this.requestGetAvatar(newPage);
        },

        requestGetAvatar(newPage) {
            getAvatarList(newPage, this.pageSize).then(res => {
                let pageData = res.data;
                this.total = pageData.total;
                this.tableData = pageData.list;
            }).catch(error => {
                console.error("getAvatarList with error: " + error.toString());
                ElMessage.error("获取头像列表失败")
            })
        }
    }
}
</script>

<style lang="scss" scoped>

:deep(.el-dialog__body) {
    padding: 0;
}

.avatar-picker-container {
    display: flex;
    flex-direction: column;
}

.avatar-col-item {
    padding-bottom: 10px;
}

.avatar-image {
    width: 100%;
    /*margin-left: 15%;*/
    /*margin-right: 15%;*/
    display: block;
}

.selected-overlay {
    position: absolute;
    top: 0;
    bottom: 0;
    width: 100%;
    border-width: 2px;
    border-color: white;
    border-radius: 50%;
    text-align: center;
    /*height: 20px;*/
    padding: 0;
    background-color: black;
    opacity: 0.3;
}

.selected-check {
    position: absolute;
    color: white;
    margin: auto;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
}

</style>