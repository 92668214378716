<template>
    <el-container>
        <el-header>
            <common-page-title-label title="AI样音"/>
            <common-add-button title="添加样音"/>
        </el-header>

        <el-main style="height: 100%;">
            <el-table :data="sampleList" border>
                <el-table-column label="ID" prop="id" width="60" align="center"/>
                <el-table-column label="头像" prop="anchorAvatar" width="80" align="center">
                    <template #default="scope">
                        <el-avatar :size="40" :src="scope.row.anchorAvatar"/>
                    </template>
                </el-table-column>
                <el-table-column label="场景" prop="sceneName" width="120" align="center"/>
                <el-table-column label="名称" prop="name" width="220" align="center"/>
                <el-table-column label="简介" prop="comment" width="220" align="center"/>
                <el-table-column label="主播" prop="anchorName" width="120" align="center"/>
                <el-table-column label="风格" prop="templateName" width="120" align="center"/>
                <!--<el-table-column label="热门" prop="hot" width="60" align="center"/>-->
                <el-table-column label="热门" width="60" align="center">
                    <template #default="scope">
                        <anchor-sample-hot-pop :id="scope.row.id"
                                               :hot="scope.row.hot"
                                               :hot-text="scope.row.hotText"/>
                    </template>
                </el-table-column>
                <el-table-column label="排序" prop="sort" width="60" align="center">
                    <template #default="scope">
                        <anchor-sample-sort-pop :id="scope.row.id" :sort="scope.row.sort"/>
                    </template>
                </el-table-column>
                <el-table-column label="试听" prop="filepath" align="center">
                    <template #default="scope">
                        <audio preload="none" :src="scope.row.templateAudio" controls style="height: 40px;"/>
                    </template>
                </el-table-column>
                <el-table-column label="操作" fixed="right" width="260">
                    <template #default="scope">
                        <el-row>
                            <el-button size="small" @click="_onEditClick(scope.row)">编辑</el-button>
                            <el-button size="small" type="danger">删除</el-button>
                        </el-row>
                    </template>
                </el-table-column>
            </el-table>
        </el-main>

        <el-pagination layout="prev, pager, next"
                       v-model="page"
                       :page-count="total"
                       :page-size="pageSize" @current-change="_onPageChange"></el-pagination>

        <anchor-sample-update-drawer ref="updateDrawer" v-model:visible="updateDrawerVisible"/>

    </el-container>
</template>

<script>
import CommonPageTitleLabel from "@/views/common/CommonPageTitleLabel";
import CommonAddButton from "@/views/common/CommonAddButton";
import {AnchorApis} from "@/api/anchor";
import AnchorSampleHotPop from "@/views/dubbing/anchor/AnchorSampleHotPop";
import AnchorSampleSortPop from "@/views/dubbing/anchor/AnchorSampleSortPop";
import AnchorSampleUpdateDrawer from "@/views/dubbing/anchor/AnchorSampleUpdateDrawer";
export default {
    name: "AnchorSamplesPage",
    components: {
        AnchorSampleUpdateDrawer,
        AnchorSampleSortPop, AnchorSampleHotPop, CommonAddButton, CommonPageTitleLabel},
    data() {
        return {
            /*
            {
                id: 0,
                name: "",
                comment: "",
                sceneId: ,
                sceneName:,
                anchorId:,
                anchorName:,
                anchorAvatar:,
                templateId:,
                templateName:,
                hot: ,
                hotText:,
                sort: 0,
            }
             */
            sceneId: null,
            page: 1,
            pageSize: 20,
            total: 1,
            sampleList: [],
            tableLoading: false,
            updateDrawerVisible: false,
        }
    },

    mounted() {
        this._searchSamples();
    },

    methods: {

        _searchSamples() {
            AnchorApis.searchAnchorSample(this.sceneId, this.page, this.pageSize).then(res => {
                let pageData = res.data;
                this.total = pageData.total;
                this.sampleList = pageData.list;
            })
        },

        _onPageChange(newPage) {
            this.page = newPage;
            this._searchSamples();
        },

        _onEditClick(itemData) {
            this.$refs.updateDrawer.init(itemData);
            this.updateDrawerVisible = true;
        }
    }
}
</script>

<style lang="scss" scoped>

</style>