import {Apis} from "@/api/Apis";

/**
 * 设置api接口
 */
export const SettingApis = {

    // ===========================================
    //      Tts 新手指引
    // ===========================================

    /**
     * 获取配音新手作品文案
     */
    getTtsGuideContent: function () {
        return Apis.get('/admin/tts/guide/content');
    },

    /**
     * 设置配音新手作品文案
     * @param req
     */
    setTtsGuideContent: function (req) {
        return Apis.post('/admin/tts/guide/update', req);
    }

}