<template>
    <el-dialog v-model="internalVisible" width="30%" title="编辑">
        <el-form ref="editingElForm"
                 :model="editingForm" :rules="editingRules"
                 label-width="80px">

            <el-form-item label="ID" prop="id">
                <el-input v-model="editingForm.styleId" disabled/>
            </el-form-item>

            <el-form-item label="名称" prop="name">
                <el-input v-model="editingForm.name" placeholder="请输入风格名称"/>
            </el-form-item>

            <el-form-item label="别名" prop="alias">
                <el-input v-model="editingForm.alias" placeholder="请输入风格别名，仅支持大小写字母、数字、连接符-_"/>
            </el-form-item>

            <el-form-item label="微软" prop="value">
                <el-input v-model="editingForm.value" placeholder="请输入微软风格的属性值，例如assistant, 其他平台为null"/>
            </el-form-item>

            <el-form-item label="阿里云" prop="valueAli">
                <el-input v-model="editingForm.valueAli" placeholder="请输入阿里云情绪的属性值，例如angry, 其他平台为null"/>
            </el-form-item>

            <el-form-item label="说明" prop="description">
                <el-input v-model="editingForm.description" placeholder="输入风格说明，可为空" type="textarea" :rows="2"/>
            </el-form-item>

            <el-form-item label="排序" prop="sort">
                <el-input-number v-model="editingForm.sort" :min="0" :max="100"/>
            </el-form-item>
        </el-form>

        <template #footer>
                <span>
                    <el-button primary @click="dismiss">取消</el-button>
                    <el-button type="primary" @click="_onEditingSubmit">提交</el-button>
                </span>
        </template>
    </el-dialog>
</template>

<script>
import BasicDialog from "@/views/base/BasicDialog";
import {DubbingApis} from "@/api/dubbing";
import {ElMessage} from "element-plus";

export default {
    name: "EmotionEditDialog",
    mixins: [BasicDialog],
    data() {
        return {
            editingForm: {
                styleId: null, // 风格id
                name: '', // 风格名称
                alias: '', // 风格别名
                value: null, // 微软风格属性值
                valueAli: null, // 阿里云情绪值
                description: null, // 说明
                sort: 0 // 排序因子
            },
            editingRules: {
                name: [
                    {required: true, message: '风格名称不能为空', trigger: 'blur'},
                    {min: 2, max: 7, message: '仅支持2～7位中文字汉字', trigger: 'blur'}
                ],
                alias: [
                    {required: true, message: '风格别名不能为空', trigger: 'blur'},
                    {pattern: /^[a-zA-Z0-9-_]{1,50}$/, message: '别名仅支持大小写字母、数字、连接符-_组合', trigger: 'blur'}
                ]
            },
        }
    },

    methods: {

        setup(itemData) {
            this.editingForm.styleId = itemData.id;
            this.editingForm.name = itemData.name;
            this.editingForm.alias = itemData.alias;
            this.editingForm.value = itemData.value;
            this.editingForm.valueAli = itemData.valueAli;
            this.editingForm.description = itemData.description;
            this.editingForm.sort = itemData.sort;
            this.editingDialogVisible = true
        },

        _onEditingSubmit() {
            this.$refs.editingElForm.validate(valid => {
                if (valid) {
                    this._submitEditing();
                }
            })
        },

        _submitEditing() {
            let req = {
                styleId: this.editingForm.styleId,
                name: this.editingForm.name,
                alias: this.editingForm.alias,
                value: this.editingForm.value,
                valueAli: this.editingForm.valueAli,
                description: this.editingForm.description,
                sort: this.editingForm.sort
            }
            DubbingApis.updateStyle(req).then(res => {
                ElMessage.success("修改成功");
                this.dismiss();
            }).catch(error => {
                ElMessage.error("修改失败: " + error.toString());
            })
        },
    }
}
</script>

<style scoped>

</style>