<template>
    <div class="avatar-player"
         :style="customStyle"
         @click.stop=""
         @mouseenter="this.controlVisible = true"
         @mouseleave="this.controlVisible = false">

        <el-progress class="avatar-player__progress" type="circle"
                     :width="this.internalAvatarSize"
                     :percentage="this.progress"
                     :show-text="false"
                     :stroke-width="2"
                     status="success"/>

        <div class="avatar-player__control">
            <svg-icon class="avatar-player__control__btn"
                      ref="playButtonRef"
                      icon-class="avatar-play"
                      v-if="isIdle"
                      @click="onPlayClick"/>

            <svg-icon class="avatar-player__control__btn"
                      ref="stopButtonRef"
                      icon-class="avatar-pause"
                      v-if="isPlaying || isLoading"
                      @click="onStopClick"/>
        </div>

        <audio ref="audioRef"
               @canplay="onCanPlay"
               @playing="onPlaying"
               @timeupdate="onTimeUpdate"
               @ended="onPlayEnded"/>
    </div>
</template>

<script>
import StringUtils from "@/utils/stringUtils";
import SvgIcon from "@/components/SvgIcon";
import {ElMessage} from "element-plus";

export default {
    name: "QxCirclePlayer",
    components: {SvgIcon},
    props: ['audioPath', 'avatar', 'size'],
    data() {
        return {
            state_default: 0,
            state_playing: 1,
            state_loading: 2,
            state: 0,
            progress: 0, // 0--100
            duration: 0,
            playedSeconds: 0,
            controlVisible: false,
        }
    },

    computed: {

        internalVisible() {
            return this.controlVisible || !this.isIdle;
        },

        internalAvatarSize() {
            if (this.size) {
                return this.size;
            }
            return 36;
        },

        customStyle() {
            return `--avatar-wrap-size:${this.internalAvatarSize}px;
            --avatar-img-size:${this.internalAvatarSize - 4}px;
            --control-btn-size: ${this.internalAvatarSize / 2}px;
            --control-btn-ox:${this.internalAvatarSize / 4 - 2}px`
        },

        audio() {
            return this.$refs.audioRef;
        },

        hasAudioPath() {
            return StringUtils.isNotBlank(this.audioPath);
        },

        isPlaying() {
            return this.state === this.state_playing;
        },

        isLoading() {
            return this.state === this.state_loading;
        },

        isIdle() {
            return this.state === this.state_default;
        }

    },

    methods: {

        startPlay() {
            if (this.audioPath) {
                this.audio.src = this.audioPath;
                this.audio.play().then(()=> {
                    this.state = this.state_loading;
                }).catch(error => {
                    console.log(error);
                    ElMessage.error("播放失败：" + error.toString())
                })
            }
        },


        stop() {
            if (!this.hasAudioPath) {
                return;
            }
            this.state = this.state_default;
            this.audio.pause();
            this.audio.currentTime = 0;
            this.progress = 0;
        },

        onPlayClick() {
            if (!this.hasAudioPath) {
                return;
            }
            this.startPlay();
        },

        onStopClick() {
            this.stop();
        },

        onCanPlay() {
            this.duration = this.audio.duration;
        },

        onPlaying() {
            this.state = this.state_playing;
        },

        onTimeUpdate() {
            this.playedSeconds = this.audio.currentTime
            if (this.duration > 0) {
                this.progress = this.playedSeconds / this.duration * 100;
            } else {
                this.progress = 0
            }
        },

        // onPlayError() {
        //     ElMessage.error("播放失败")
        //     this.state = this.state_default;
        // },

        onPlayEnded() {
            this.state = this.state_default;
            this.progress = 0;
        }
    }


}
</script>

<style lang="scss" scoped>
//@use "src/styles/global" as *;

//$test-size: var(--avatar-size);

.avatar-player {
    width: var(--avatar-wrap-size);
    height: var(--avatar-wrap-size);
    position: relative;
}

.avatar-player__progress {
    position: absolute;
    left: 0;
    right: 0;

    --el-fill-color-light: white
}

.avatar-player__img {
    width: var(--avatar-img-size);
    height: var(--avatar-img-size);
    position: absolute;
    left: 2px;
    top: 2px;
}


.avatar-player__control {
    width: var(--avatar-img-size);
    height: var(--avatar-img-size);
    background-color: white;
    border-radius: 16px;
    position: absolute;
    left: 2px;
    top: 2px;
    //display: inline-block;

    &:hover {
        cursor: pointer;
        background-color: $color-accent-10;
    }
}

.avatar-player__control__btn {
    position: absolute;
    left: var(--control-btn-ox);
    top: var(--control-btn-ox);
    color: $color-accent;
    width: var(--control-btn-size);
    height: var(--control-btn-size);
    //margin: auto;
    //transform: translateY(40%);
}

</style>