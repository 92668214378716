<template>
    <el-container>
        <el-header>
            <el-page-header content="编辑主播" @back="onBack"/>
        </el-header>

        <el-main>
            <el-form :model="anchorForm" :rules="anchorRules"
                     label-width="100px" style="width: 50%; margin-left: 20px">

                <!-- 不支持修改发音人：因为一旦修改发音人，该主播的所有模版都将失效 -->
                <el-form-item label="主播声音:" prop="voiceId">
                    <el-input v-model="anchorForm.voice" placeholder="请选择发音人" disabled/>
                </el-form-item>

                <el-form-item label="主播名称:" prop="name">
                    <el-input v-model="anchorForm.name" placeholder="主播名称" autocomplete="off"/>
                </el-form-item>

                <el-form-item label="主播别名:" prop="alias">
                    <el-input v-model="anchorForm.alias" placeholder="主播别名，仅支持大小写字母、数字、连接符-组合" autocomplete="off"/>
                </el-form-item>

                <el-form-item label="主播简介:" prop="description">
                    <el-input v-model="anchorForm.description" placeholder="主播简介" autocomplete="off"/>
                </el-form-item>

                <el-form-item label="主播头像:" prop="avatarId">
                    <div class="avatar-uploader" @click="avatarPickerVisible = true">
                        <!--suppress HtmlUnknownTarget -->
                        <img v-if="selectedAvatarData" :src="selectedAvatarData.path" class="avatar-img" alt="头像">
                        <el-icon v-else class="plus-icon">
                            <plus/>
                        </el-icon>
                    </div>
                </el-form-item>

                <el-form-item label="AI等级:" prop="aiLevel">
                    <el-select v-model="anchorForm.aiLevel" placeholder="选择AI等级">
                        <el-option v-for="item in aiLevelOptions" :label="item.label" :key="item.value"
                                   :value="item.value"/>
                    </el-select>
                </el-form-item>

                <el-form-item label="支持语言:" prop="languages">
                    <el-checkbox-group v-model="anchorForm.languages">
                        <el-checkbox v-for="item in languageList" :key="item.id" :label="item.id">
                            {{ item.name }}
                        </el-checkbox>
                    </el-checkbox-group>
                </el-form-item>

                <el-form-item label="场景领域:" prop="categories">
                    <el-checkbox-group v-model="anchorForm.categories">
                        <el-checkbox v-for="item in categoryList" :key="item.id" :label="item.id">
                            {{ item.name }}
                        </el-checkbox>
                    </el-checkbox-group>
                </el-form-item>

            </el-form>

            <div style="width: 50%;text-align: right">
                    <span>
                        <el-button type="primary" round @click="onSubmitClick">提交</el-button>
                        <el-button round @click="onBack">退出</el-button>
                    </span>
            </div>

            <!-- 头像选择框 -->
            <avatar-picker-dialog v-model:visible="avatarPickerVisible"
                                  @onResult="onAvatarPick"/>

        </el-main>
    </el-container>
</template>

<script>
import VoiceOptions from "@/views/dubbing/VoiceConstants";
import AvatarPickerDialog from "@/views/dubbing/anchor/AvatarPickerDialog";
import {ElMessage, ElMessageBox} from "element-plus";
import {DubbingApis} from "@/api/dubbing";
import {Plus} from "@element-plus/icons-vue"
import StringUtils from "@/utils/stringUtils";

export default {
    name: "AnchorEditPage",
    components: {
        AvatarPickerDialog,
        Plus
    },
    data() {
        return {
            editingAnchor: null,
            aiLevelOptions: VoiceOptions.aiLevelOptions, // AI等级
            voiceSourceList: VoiceOptions.voiceSourceList, // 声音来源
            categoryList: [], // 主播分类列表
            languageList: [], // 语种列表

            avatarPickerVisible: false, // 选择头像对话框可见性
            selectedAvatarData: {
                id: null,
                path: '',
            },

            anchorForm: {
                id: null, // 主播id
                name: '', // 主播名称
                alias: '', // 主播别名
                // voiceSource: 1, // 声音来源
                // voiceId: '', // 声音id
                avatarId: '', // 头像id
                description: null, // 简介
                aiLevel: 1, // AI等级
                // speechRate: 1.0, // 语速
                // pitchRate: 0, // 语调
                // volume: 50, // 音量
                categories: [], // 已选择的分类id数组
                languages: [], // 已选择的语种id数组
                // sort: 0, // 排序
                // recommend: 0, // 热门推荐标记
                // content: null,
            },
            anchorRules: {
                name: [
                    {required: true, message: "主播名称不能为空", trigger: 'blur'},
                    {pattern: /^[a-zA-Z0-9\u4e00-\u9fa5_]{1,20}$/, message: "主播名称只支持汉字、大小写字母、数字、下划线", trigger: 'blur'}
                ],
                alias: [
                    {required: true, message: '别名不能为空', trigger: 'blur'},
                    {pattern: /^[a-zA-Z0-9-]{1,50}$/, message: '别名仅支持大小写字母、数字、连接符-组合', trigger: 'blur'}
                ],
                description: [
                    {required: true, message: "主播简介不能为空", trigger: 'blur'}
                ],
                // voiceSource: [
                //     {required: true, message: "声音来源不能为空", trigger: 'blur'}
                // ],
                voiceId: [
                    {required: true, message: "发音人不能为空", trigger: 'blur'}
                ],
                avatarId: [
                    {required: true, message: "头像不能为空", trigger: 'blur'}
                ],
                categories: [
                    {type: 'array', required: true, message: '至少选择一个分类', trigger: 'change'}
                ],
                languages: [
                    {type: 'array', required: true, message: '至少选择一个语种', trigger: 'change'}
                ],
                // content: [
                //     {required: true, message: "发音人不能为空", trigger: 'blur'}
                // ]
            }
        }
    },

    created() {

    },

    mounted() {
        let json = this.$route.params.anchor;
        if (json !== undefined && StringUtils.isNotBlank(json)) {
            this.editingAnchor = JSON.parse(json);
            console.log(this.editingAnchor)
            this.initForm(this.editingAnchor)
        }

        this.getCategoryListIfNeed();
        this.getLanguageListIfNeed();
    },

    methods: {
        /**
         * 初始化 表单
         * @param data 待编辑的主播信息
         */
        initForm(data) {
            this.anchorForm.id = data.id;
            this.anchorForm.name = data.name;
            this.anchorForm.alias = data.alias;
            this.anchorForm.description = data.description;
            this.anchorForm.voice = data.voice;

            this.anchorForm.aiLevel = data.aiLevel;
            // 头像
            this.anchorForm.avatarId = data.avatarId;
            this.selectedAvatarData.id = data.avatarId;
            this.selectedAvatarData.path = data.avatar;
            // 场景
            for (let i = 0; i < data.categories.length; i++) {
                let item = data.categories[i];
                this.anchorForm.categories.push(item.id)
            }
            // 语言
            for (let i = 0; i < data.languages.length; i++) {
                let lan = data.languages[i];
                this.anchorForm.languages.push(lan.id)
            }
            console.log(this.anchorForm)
        },

        onBack() {
            this.$router.back();
        },

        // ===========================================
        //      选择头像
        // ===========================================

        onAvatarPick(data) {
            this.selectedAvatarData = data;
            this.anchorForm.avatarId = data.id
        },

        // ===========================================
        //      选择场景/领域标签
        // ===========================================

        /// 获取主播分类列表
        getCategoryListIfNeed() {
            let self = this;
            DubbingApis.getCategoryList().then(res => {
                if (res.code === 200) {
                    self.categoryList = res.data;
                } else {
                    ElMessage.error("无法获取分类列表：" + res.msg);
                }
            }).catch(error => {
                ElMessage.error(error.toString())
            })
        },

        getLanguageListIfNeed() {
            if (this.languageList.length > 0) {
                return
            }
            DubbingApis.getLanguageList().then(res => {

                if (res.code === 200) {
                    this.languageList = res.data;
                } else {
                    console.log("无法获取语种列表：" + res.message)
                }

            }).catch(error => {
                console.log("无法获取语种列表: " + error.toString())
            })
        },

        // ===========================================
        //      提交编辑
        // ===========================================

        onSubmitClick() {
            let req = {
                anchorId: this.anchorForm.id,
                name: this.anchorForm.name,
                alias: this.anchorForm.alias,
                description: this.anchorForm.description,
                avatarId: this.anchorForm.avatarId,
                aiLevel: this.anchorForm.aiLevel,
                categoryIds: this.anchorForm.categories,
                languageIds: this.anchorForm.languages
            }
            DubbingApis.updateAnchor(req).then(res => {
                if (res.code === 200) {
                    ElMessageBox.alert("主播信息已更新", "修改成功", {
                        confirmButtonText: "退出",
                        callback: () => {
                            this.onBack();
                        }
                    })
                } else {
                    ElMessage.error("更新失败：" + res.message);
                }
            }).catch(error => {
                ElMessage.error("更新失败：" + error.toString());
            })
        }

    }
}
</script>

<style scoped>

</style>