<template>
    <el-dialog v-model="internalVisible" width="400px" title="修改信息" @closed="_clearUploader">
        <el-form ref="updateForm" :model="updateForm" label-width="90px" label-position="right" :rules="updateRules">
            <el-form-item label="版本ID">
                <el-input v-model="updateForm.id" disabled/>
            </el-form-item>
            <el-form-item label="渠道名">
                <el-input v-model="updateForm.channel" disabled/>
            </el-form-item>
            <el-form-item label="App版本">
                <el-input v-model="updateForm.version" placeholder="App版本，如1.2.3"/>
            </el-form-item>
            <el-form-item label="App版本号">
                <el-input-number v-model="updateForm.build" placeholder="App版本号，如99"/>
            </el-form-item>
            <el-form-item label="最低版本">
                <el-input v-model="updateForm.requireVersion" placeholder="最低版本，如1.2.3"/>
            </el-form-item>
            <el-form-item label="最低版本号">
                <el-input-number v-model="updateForm.requireBuild" placeholder="最低版本号，如99"/>
            </el-form-item>
            <el-form-item label="静默更新">
                <el-switch
                    v-model="updateForm.silent"
                    active-color="#13ce66">
                </el-switch>
            </el-form-item>
            <el-form-item label="更新内容">
                <el-input type="textarea" :rows="5" v-model="updateForm.details" placeholder="输入更新内容"/>
            </el-form-item>
        </el-form>

        <template #footer>
            <span style="flex: 1"/>
            <el-button @click="dismiss">取消</el-button>
            <el-button type="primary" @click="_onCommit">提交</el-button>
        </template>
    </el-dialog>
</template>

<script>
import BasicDialog from "@/views/base/BasicDialog";
import {AppVersionConstants} from "@/views/setting/AppVersionConstants";
import {AppVersionApis} from "@/api/app-version-apis";
import {ToastUtil} from "@/utils/toast-util";

export default {
    name: "AppVersionEditDialog",
    mixins: [BasicDialog],
    emits: ["success"],
    data() {
        return {
            appList: AppVersionConstants.AppList,
            updateForm: {
                id: null,
                channel: null,
                build: 1,
                version: "1.0.0",
                requireBuild: 1,
                requireVersion: "1.0.0",
                details: "",
                silent: true,
            },
            updateRules: {
                details: [
                    {required: true, message: "更新内容不能为空", trigger: 'blur'},
                ]
            },
        }
    },
    methods: {

        init(versionData) {
            this.updateForm.id = versionData.id;
            this.updateForm.channel = versionData.channel;
            this.updateForm.version = versionData.version;
            this.updateForm.build = versionData.build;
            this.updateForm.requireVersion = versionData.requireVersion;
            this.updateForm.requireBuild = versionData.requireBuild;
            this.updateForm.silent = versionData.silent;
            this.updateForm.details = versionData.details;
        },

        _clearUploader() {
            // 清除表单
            this.$refs.updateForm.resetFields();
            //清除表单内所有规则检测提示
            this.$refs.updateForm.clearValidate();
        },

        _onCommit() {
            let req = {
                id: this.updateForm.id,
                channel: this.updateForm.channel,
                build: this.updateForm.build,
                version: this.updateForm.version,
                requireBuild: this.updateForm.requireBuild,
                requireVersion: this.updateForm.requireVersion,
                details: this.updateForm.details,
                silent: this.updateForm.silent
            }
            AppVersionApis.updateVersionInfo(req).then(res => {
                ToastUtil.toast("修改成功");
                this.$emit("success");
                this.dismiss();
            })
        }
    }
}
</script>

<style scoped>

</style>