<template>
    <el-dialog v-model="internalVisible" width="400px" title="添加活动">
        <el-form v-model="addForm" label-width="90px">
            <el-form-item label="名称">
                <el-input v-model="addForm.name" placeholder="输入活动名称"/>
            </el-form-item>
            <el-form-item label="链接">
                <el-input v-model="addForm.link" placeholder="输入跳转链接，可为空"/>
            </el-form-item>
            <el-form-item label="开始时间">
                <el-date-picker v-model="addForm.beginTime" placeholder="选择开始时间" type="datetime"/>
            </el-form-item>
            <el-form-item label="结束时间">
                <el-date-picker v-model="addForm.endTime" placeholder="选择结束时间" type="datetime"/>
            </el-form-item>
            <el-form-item label="位置ID" >
                <el-input v-model="addForm.positionId"/>
            </el-form-item>
            <el-form-item label="位置名称">
                <el-input v-model="addForm.positionName"/>
            </el-form-item>
        </el-form>

        <template #footer>
            <el-row>
                <el-button style="margin-left: auto;" @click.stop="dismiss">取消</el-button>
                <el-button type="primary" @click.stop="_onCommitClick" v-loading="apiLoading" >确定</el-button>
            </el-row>
        </template>
    </el-dialog>
</template>

<script>
import BasicDialog from "@/views/base/BasicDialog";
import {DateUtils} from "@/utils/DateUtils";
import {WebActivityApis} from "@/api/web-activity-apis";
import {ToastUtil} from "@/utils/toast-util";

export default {
    name: "ActivityBannerDialogAdd",
    mixins: [BasicDialog],
    emits:['success'],
    data() {
        return {
            addForm: {
                name: null,
                positionId: 1,
                positionName: "页面顶部",
                beginTime: DateUtils.beginOfDate(new Date()),
                endTime: DateUtils.beginOfDate(DateUtils.dayAfter(31)),
                link: null,
            },
            apiLoading: false,
        }
    },
    methods: {

        _onCommitClick() {
            let req = {
                name: this.addForm.name,
                positionId: this.addForm.positionId,
                positionName: this.addForm.positionName,
                beginTimeMillis: this.addForm.beginTime.getTime(),
                endTimeMillis: this.addForm.endTime.getTime(),
                link: this.addForm.link
            }
            this.apiLoading = true;
            WebActivityApis.addActivity(req).then(res => {
                this.apiLoading = false;
                ToastUtil.toast("添加成功")
                this.$emit("success")
                this.dismiss()
            }).catch(error => {
                this.apiLoading = false;
            })
        }
    }
}
</script>

<style scoped>

</style>