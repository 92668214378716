import {Apis} from "@/api/Apis";

export const AppVersionApis = {

    /**
     * 查询app版本
     * @param page
     * @param clientId
     * @param appId
     */
    searchVersions(page, clientId, appId, channel) {
        let body = {
            page: page,
            clientId: clientId,
            appId: appId,
            channel: channel
        }
        return Apis.post("/admin/app/version/search", body);
    },

    /**
     * 获取Apk上传配置
     * @param filename
     */
    getApkUploadPolicy(filename) {
        let params = {
            filename: filename
        }
        return Apis.get("/admin/app/version/upload/policy", params)
    },

    /**
     * 发布新版本
     * @param req
     */
    addVersion(req) {
        return Apis.post("/admin/app/version/add", req);
    },

    /**
     * 修改审核状态
     * @param versionId 版本记录id
     * @param review true表示审核中，false表示已上架
     */
    updateReview(versionId, review) {
        let body = {
            versionId: versionId,
            review: review
        }
        return Apis.post("/admin/app/version/update/review", body);
    },

    /**
     * 修改静默更新状态
     * @param versionId 版本记录id
     * @param silent true表示静默更新，false表示非静默
     */
    updateSilent(versionId, silent) {
        let params = {
            id: versionId,
            silent: silent
        }
        return Apis.get("/admin/app/version/update/silent", params);
    },

    /**
     * 修改版本信息
     */
    updateVersionInfo(req) {
        return Apis.post("/admin/app/version/update/info", req)
    },

    /**
     * 上传apk文件
     * @param versionId
     * @param file
     * @param progressCallback 进度
     */
    updateVersionFile(versionId, file, progressCallback = null) {
        let params = {
            file: file,
            id: versionId
        }
        return Apis.uploadWithForm("/admin/app/version/update/file", params, progressCallback)
    },

    updateVersionFilepath(versionId, filepath) {
        let params = {
            id: versionId,
            filepath: filepath,
        }
        return Apis.get("/admin/app/version/update/filepath", params)
    },

    deleteVersion(versionId) {
        let params = {
            id: versionId
        }
        return Apis.get("/admin/app/version/delete", params);
    }
}