<template>
    <span v-if="status === 0" class="status_label_normal">正常</span>
    <span v-if="status === 1" class="status_label_disabled">已禁用</span>
    <span v-if="status === 2" class="status_label_deleted">已删除</span>
</template>

<script>
export default {
    name: "CommonStatusLabel",
    props: {
        status: {
            type: Number,
            default: 0
        }
    }
}
</script>

<style scoped lang="scss">
@import "src/styles/global";

.status_label_normal {
//font-weight: bold;
    color: $systemBlue;
}

.status_label_disabled {
//font-weight: bold;
    color: $systemRed;
}

.status_label_deleted {
//font-weight: bold;
    color: $systemRed;
}

</style>