<template>
    <el-dialog v-model="showing" width="25%" title="指定发音" >

        <!--<span style="color: #ff3b30">{{ selectedWord }}</span>-->
        <el-input v-model="input" placeholder="请输入汉语拼音">
            <template #prepend>
                <el-button>{{ selectedWord }}</el-button>
            </template>
            <template #append>
                <el-select v-model="tone" style="width: 110px">
                    <el-option v-for="item in options"
                               :key="item.value"
                               :value="item.value"
                               :label="item.name"/>
                </el-select>
            </template>
        </el-input>

        <template #footer>
                <span class="dialog-footer">
                    <el-button @click="dismiss">取消</el-button>
                    <el-button type="primary" @click="onSubmitClick">确定</el-button>
                </span>
        </template>
    </el-dialog>
</template>

<script>

import vuePinyin from "@/utils/vue-pinyin";
import StringUtils from "@/utils/stringUtils";
import bus from "@/utils/bus";

export default {
    name: "PhonemeInputDialog",
    props: {
        visible: {
            type: Boolean,
            default: false
        }
    },
    emits: ['dismiss', 'result'],
    computed: {
      showing() {
          return this.visible;
      },
    },
    data() {
        return {
            selectedWord: '', // 要设置读音的汉字
            input: null, // 要设置的拼音
            tone: '', // 声调
            options: [
                {
                    name: '第1声',
                    value: '1'
                },
                {
                    name: '第2声',
                    value: '2'
                },
                {
                    name: '第3声',
                    value: '3'
                },
                {
                    name: '第4声',
                    value: '4'
                },
                {
                    name: '轻声',
                    value: '5'
                },
            ]
        }
    },
    mounted() {
        bus.on('selectedWord', (word) => {
            this.selectedWord = word;
            if (StringUtils.isNotBlank(this.selectedWord)) {
                this.input = vuePinyin.chineseToPinYin(this.selectedWord);
            }
        })
    },
    methods: {
        dismiss() {
            this.$emit('dismiss');
        },
        beforeClose() {
            this.dismiss();
        },
        reset() {
            this.input = ''
            this.tone = ''
        },
        onSubmitClick() {
            let phoneme = this.input + this.tone
            this.$emit('result', phoneme)
            this.dismiss()
        }
    }
}
</script>

<style scoped>

</style>