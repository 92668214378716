<template>
    <!-- 编辑发音人弹框 -->
    <!-- name, voice, type, scene, language, quality,  -->
    <el-dialog width="30%" v-model="isVisible" title="编辑发音人" @closed="reset">
        <el-form ref="voiceFormEl" :model="voiceForm" :rules="rules" label-width="80px">

            <el-form-item label="声音平台" >
                <el-select v-model="voiceForm.source" placeholder="选择声音平台" disabled/>
            </el-form-item>

            <el-form-item label="声音名称" prop="name">
                <el-input
                    v-model="voiceForm.name"
                    placeholder="发音人名称，例如小云"
                    autocomplete="off"/>
            </el-form-item>

            <el-form-item label="声音参数" prop="voice">
                <el-input v-model="voiceForm.voice" placeholder="发音人参数值，例如xiaoyun" autocomplete="off"/>
            </el-form-item>

            <el-form-item label="声音性别" prop="sex">
                <el-select v-model="voiceForm.sex" placeholder="请选择声音性别">
                    <el-option v-for="item in sexOptions"  :key="item.value"  :value="item.value"  :label="item.label"/>
                </el-select>
            </el-form-item>

            <el-form-item label="AI等级" prop="level">
                <el-select v-model="voiceForm.aiLevel" placeholder="请选择AI等级">
                    <el-option v-for="item in aiLevelOptions" :key="item.value" :value="item.value" :label="item.label"/>
                </el-select>
            </el-form-item>

            <el-form-item label="采样率" prop="sampleRate">
                <el-select v-model="voiceForm.sampleRate" placeholder="请选择支持采样率">
                    <el-option v-for="item in sampleRateOptions" :key="item.value" :value="item.value" :label="item.label"/>
                </el-select>
            </el-form-item>

            <el-form-item label="声音性别" prop="sex">
                <el-select v-model="voiceForm.sex" placeholder="选择声音性别">
                    <el-option v-for="item in sexOptions" :key="item.value" :label="item.label" :value="item.value"/>
                </el-select>
            </el-form-item>

            <el-form-item label="适用语言" prop="language">
                <el-input v-model="voiceForm.language" placeholder="请输入适用语言"/>
            </el-form-item>

            <el-form-item label="声音类型" >
                <el-input v-model="voiceForm.type" placeholder="输入声音类型，可为空，例如标准男声"/>
            </el-form-item>

            <el-form-item label="适用场景">
                <el-input v-model="voiceForm.scene" placeholder="输入适用场景，可为空，例如适用客服聊天场景"/>
            </el-form-item>

            <el-form-item label="声音品质">
                <el-input v-model="voiceForm.quality" placeholder="输入声音的品质，可为空，例如lite版，标准版"/>
            </el-form-item>

        </el-form>

        <template #footer>
                <span class="dialog-footer">
                    <el-button @click="dismiss">取消</el-button>
                    <el-button type="primary" @click="onSubmitClick">确定</el-button>
                </span>
        </template>
    </el-dialog>
</template>

<script>
import voiceOptions from "@/views/dubbing/VoiceConstants";
import bus from "@/utils/bus";
import {ElMessage} from "element-plus"
import {DubbingApis} from "@/api/dubbing";

export default {
        name: "VoiceEditDialog",
        props: {
            visible: {
                type: Boolean,
                default: false
            }
        },
        // 定义抛出事件名
        emits: ["dismiss", 'visible'],
        computed: {
            isVisible: {
                get() {
                    return this.visible;
                },
                set(v) {
                    this.$emit('visible', v);
                }
            }
        },
        data() {
            return {
                editVoiceData: null,
                // typeOptions: voiceOptions.voiceTypeList,
                // sceneOptions: voiceOptions.sceneOptions,
                // qualityOptions: voiceOptions.qualityOptions,
                // 性别选项
                sexOptions: voiceOptions.sexOptions,
                // AI等级选项
                aiLevelOptions: voiceOptions.aiLevelOptions,
                // 采样率选项
                sampleRateOptions: voiceOptions.sampleRateOptions,
                voiceForm: {
                    id: null,       // 声音id
                    source: null,   // 声音来源平台
                    name: null,     // 声音名称
                    voice: null,    // 发音人
                    sex: 1,         // 声音性别
                    aiLevel: 1,     // ai等级
                    sampleRate: null, // 采样率
                    language: null, // 支持语言
                    type: null,     // 声音类型
                    scene: null,    // 声音场景
                    quality: null,  // 声音品质
                },
                rules: {
                    name: [
                        { required: true, message: '声音名称不能为空', trigger: 'blur' },
                        { min: 2, max: 5, message: '名称长度为2-5', trigger: 'blur' }
                    ],
                    voice: [
                        { required: true, message: '声音参数不能为空', trigger: 'blur' }
                    ],
                    type: [
                        { required: true, message: '声音类型不能为空', trigger: 'blur' }
                    ],
                    sex: [
                        { required: true, message: '声音性别不能为空', trigger: 'blur' }
                    ],
                    scene: [
                        { required: true, message: '适用场景不能为空', trigger: 'blur' }
                    ],
                    language: [
                        { required: true, message: '支持语种不能为空', trigger: 'blur' }
                    ],
                    quality: [
                        { required: true, message: '声音品质不能为空', trigger: 'blur' }
                    ],
                    aiLevel: [
                        { required: true, message: 'AI等级不能为空', trigger: 'blur' }
                    ],
                    sampleRate: [
                        {required: true, message: '采样率不能为空', trigger: 'blur'}
                    ]
                }
            }
        },
        mounted() {
            let self = this;
            bus.on('editVoiceData', (data) => {
                self.editVoiceData = data;
                self.voiceForm.id = data.id;
                self.voiceForm.source = data.source;
                self.voiceForm.name = data.name;
                self.voiceForm.voice = data.voice;
                self.voiceForm.sex = data.sex;
                self.voiceForm.aiLevel = data.aiLevel;
                self.voiceForm.sampleRate = data.sampleRate;
                self.voiceForm.language = data.language;
                self.voiceForm.type = data.type;
                self.voiceForm.scene = data.scene;
                self.voiceForm.quality = data.quality;
            });
        },
        methods: {
            reset() {
                this.$refs.voiceFormEl.resetFields();
                this.$refs.voiceFormEl.clearValidate();
            },
            dismiss() {
                this.$emit('dismiss');
                this.reset()
            },
            onSubmitClick() {
                // $refs 获取组件
                this.$refs.voiceFormEl.validate( (valid) => {
                    if (valid) {
                        this.modifyVoice()
                    }
                })
            },
            // 提交修改数据
            modifyVoice() {
                // let jsonParam = JSON.stringify(this.voiceForm);
                // console.log("voiceForm: " + jsonParam);
                let req = {
                    id: this.voiceForm.id,
                    source: this.voiceForm.source,
                    name: this.voiceForm.name,
                    voice: this.voiceForm.voice,
                    sex: this.voiceForm.sex,
                    aiLevel: this.voiceForm.aiLevel,
                    sampleRate: this.voiceForm.sampleRate,
                    language: this.voiceForm.language,
                    type: this.voiceForm.type,
                    scene: this.voiceForm.scene,
                    quality: this.voiceForm.quality
                }
                DubbingApis.editVoice(req).then( res => {
                    if (res.code === 200) {
                        this.dismiss();
                        ElMessage.success('修改成功')
                    } else {
                        ElMessage.error('修改失败: ' + res.message)
                    }
                })
            }
        }
    }
</script>

<style scoped>

</style>