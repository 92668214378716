import {Apis} from "@/api/Apis";

export const SmsApis = {

    getSmsTemplateList() {
        return Apis.get("/admin/sms/template/list")
    },

    getSmsLogList(page, pageSize) {
        let param = {
            page: page,
            pageSize: pageSize
        }
        return Apis.get("/admin/sms/log/list", param);
    },

    /**
     * 群发短信
     */
    sendGroupSms(req) {
        return Apis.post("/admin/sms/groupSend", req)
    }
}