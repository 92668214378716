<template>
    <el-container>
        <el-header>
            <el-button>添加管理员</el-button>
        </el-header>

        <el-main style="width: 40%">
            <el-form :model="registerForm" label-width="80px" :rules="rules">
                <el-form-item label="用户名:" prop="username">
                    <el-input v-model="registerForm.username" placeholder="请输入用户名"/>
                </el-form-item>
                <el-form-item label="密码:" prop="password">
                    <el-input v-model="registerForm.password" type="password" placeholder="请输入密码"/>
                </el-form-item>
                <el-form-item label="昵称:">
                    <el-input v-model="registerForm.nickname" placeholder="请输入昵称"/>
                </el-form-item>
                <!--<el-form-item label="手机号:">-->
                <!--    <el-input v-model="registerForm.nickname" placeholder="请输入昵称"/>-->
                <!--</el-form-item>-->

                <el-form-item>
                    <el-button type="primary" @click="onAddAdministrator">注册</el-button>
                    <el-button>取消</el-button>
                </el-form-item>
            </el-form>
        </el-main>
    </el-container>

</template>

<!-- 添加管理员页面 -->
<script>
import {addAdministrator} from "@/api/user-apis";
import {ElMessage} from "element-plus";

export default {
    name: "AdminAddPage",
    data() {
        return {
            registerForm: {
                username: '',
                password: '',
                nickname: null,
                phone: ''
            },
            rules: {
                username: [
                    {required: true, message: '用户名不能为空', trigger: 'blur'},
                    {min: 4, max: 20, message: '必须由4～20位大小写字母、数字、特殊字符_@组成，且不能以特殊字符开头', trigger: 'change'}
                ],
                password: [
                    {required: true, message: '密码不能为空', trigger: 'blur'},
                    {min: 6, max: 16, message: '必须由6～16位大小写字母、数字、特殊符_@!组成', trigger: 'change'}
                ]
            }
        }
    },
    methods: {
        back() {
            this.$router.back();
        },
        onAddAdministrator() {
            let param = {
                username: this.registerForm.username,
                password: this.registerForm.password,
                nickname: this.registerForm.nickname
            }
            addAdministrator(param).then(res => {
                if (res.code === 200) {
                    ElMessage.success("添加成功");
                    this.back();
                } else {
                    ElMessage.error("添加失败: " + res.message);
                }
            }).catch(error => {
                ElMessage.error("网络异常：" + error.toString())
            })
        }
    }
}
</script>

<style scoped>

</style>