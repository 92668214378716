<template>
    <el-container>
        <el-header class="my-page-header">
            <el-button type="primary" @click="_onRefreshClick">刷新</el-button>
            <el-button type="primary" @click="_onAddClick">添加</el-button>
        </el-header>

        <el-main style="width: 100%;margin-top: 10px;">
            <el-table :data="this.templateList" border >
                <el-table-column label="ID" width="50" prop="id"/>
                <el-table-column label="标签" width="64">
                    <template #default="scope">
                        <img v-if="scope.row.icon" :src="scope.row.icon" style="width: 38px;height: 38px;"/>
                        <div v-else style="width: 38px;height:38px;text-align: center;line-height: 38px;border-radius: 5px;"
                             :style="this._createLabelStyle(scope.row.label)">
                            {{ scope.row.label.value }}
                        </div>
                    </template>
                </el-table-column>
                <el-table-column label="名称" width="140" prop="title"/>
                <el-table-column label="描述" width="180" prop="subtitle"/>
                <el-table-column label="提示" width="280" prop="prompt"/>
                <el-table-column label="样例" width="150" prop="demos"/>
                <el-table-column label="输入提示" width="150" prop="placeholder"/>
                <el-table-column label="输入限制" width="60" prop="inputMax"/>
                <el-table-column label="首页标记" width="68" prop="homeType">
                    <template #default="scope">
                        <!-- 必须设置:active-value和:inactive-value，否则初始化时会触发change事件 -->
                        <el-switch v-model="scope.row.homeType"
                                   :active-value="1" :inactive-value="0"
                                   @change="_onItemHomeTypeChange($event,scope.row)"/>
                    </template>
                </el-table-column>
                <el-table-column label="使用次数" width="60" prop="count"/>
                <el-table-column label="排序" prop="sort" align="center" width="60" style="cursor: pointer;">
                    <template #default="scope">
                        <qx-quick-edit-pop :text="scope.row.sort + ''" :row="scope.$index" input-type="number" @done-action="_onSortEdit"/>
                    </template>
                </el-table-column>
                <el-table-column label="操作">
                    <template #default="scope">
                        <el-button size="small" type="primary" @click="_onEditClick(scope.row)">编辑</el-button>
                        <el-button size="small" type="primary" @click="_onEditIconClick(scope.row)">图标</el-button>
                        <el-button v-if="scope.row.status === 0" size="small" type="danger" @click="_onForbidTemplate(scope.row)">禁用</el-button>
                        <el-button v-else size="small" type="success" @click="_onResumeTemplate(scope.row)">恢复</el-button>
                    </template>
                </el-table-column>
            </el-table>
        </el-main>
        <!-- 分页插件 -->
        <el-footer>
            <el-pagination
                :current-page="page"
                :page-size="pageSize"
                :page-count="totalPage"
                layout="prev, pager, next"
                @current-change="_onPageChange">
            </el-pagination>
        </el-footer>

        <gpt-template-add-dialog ref="addDialog" v-model:visible="addDialogVisible"/>

        <gpt-template-icon-dialog ref="iconDialog" v-model:visible="iconDialogVisible"/>

    </el-container>
</template>

<script>
import GptTemplateAddDialog from "@/views/gpt/GptTemplateAddDialog";
import {GptApis} from "@/api/gpt-apis";
import QxQuickEditPop from "@/components/QxQuickEditPop";
import GptTemplateIconDialog from "@/views/gpt/GptTemplateIconDialog";
import {ElMessage} from "element-plus";

export default {
    name: "GptTemplateManagerPage",
    components: {GptTemplateIconDialog, QxQuickEditPop, GptTemplateAddDialog},
    data() {
        return {
            templateList: [],
            templateItem: {
                id: Number,
                code: String,
                title: String,
                subtitle: String,
                prompt: "",
                placeholder: '',
                inputMax: 500,
                demos:"",
                sort: 0,
                count: 0,
                homeType: 0,
                icon: null,
                label: {
                    id: 1,
                    textColor: "",
                    bgColor: ""
                },
            },
            page: 1,
            pageSize: 20,
            totalPage: 1,
            addDialogVisible: false,
            iconDialogVisible: false,
        }
    },
    mounted() {
        this._loadTemplate();
    },
    methods: {

        _createLabelStyle(labelData) {
            return {
                color: labelData.textColor,
                backgroundColor: labelData.bgColor
            }
        },

        _loadTemplate() {
            let req = {
                page: this.page,
                pageSize: this.pageSize
            }
            GptApis.searchTemplate(req).then(res => {
                let pageData = res.data;
                this.totalPage = pageData.total;
                this.templateList = pageData.list;
            })
        },

        _onPageChange(newPage) {
            this.page = newPage;
            this._loadTemplate();
        },

        _onRefreshClick() {
            this._loadTemplate();
        },
         _onAddClick() {
            this.$refs.addDialog.initAdding();
            this.addDialogVisible = true;
         },

        _onItemHomeTypeChange(newType, itemData) {
            let templateId = itemData.id;
            let homeType = newType ? 1 : 0;
            GptApis.updateTemplateHomeType(templateId, homeType).then(res => {
                if (newType) {
                    ElMessage.success("已添加到App首页显示")
                } else {
                    ElMessage.success("已移除从App首页显示")
                }
                // itemData.homeType = homeType;
            })
        },

        _onEditClick(itemData) {
            this.$refs.addDialog.initEditing(itemData);
            this.addDialogVisible = true;
        },

        _onSortEdit(text, row) {
            let newSort = parseInt(text);
            let itemData = this.templateList[row];
            let req = {
                templateId: itemData.id,
                sort: newSort
            }
            GptApis.updateTemplateSort(req).then(() => {
                this.templateList[row].sort = newSort;
            })
        },

        _onEditIconClick(itemData) {
            this.$refs.iconDialog.init(itemData.id, itemData.icon);
            this.iconDialogVisible = true;
        },

        _onForbidTemplate(itemData) {
            GptApis.updateTemplateStatus(itemData.id, 1).then(res => {
                itemData.status = 1;
                ElMessage.success("模版已禁用")
            })
        },

        _onResumeTemplate(itemData) {
            GptApis.updateTemplateStatus(itemData.id, 0).then(res => {
                itemData.status = 0;
                ElMessage.success("模版已恢复")
            })
        }
    }
}
</script>

<style scoped>

</style>