<template>
    <!--suppress HtmlUnknownTarget -->
    <audio ref="audio"
           :src="audioData"
           @canplay="onCanPlay"
           @timeupdate="onAudioTimeUpdate"
           @ended="onPlayEnd">
        您的浏览器不支持 audio 标签。
    </audio>

    <div class="my-controls">
        <!-- 播放、加载中 按钮 -->
        <el-button v-if="isAudioDefault"
                   class="control-btn" circle
                   @click="onPlayClick" >
            <el-icon :size="36"><video-play/></el-icon>
        </el-button>

        <!-- 加载中 -->
        <el-button v-if="isLoading" class="control-btn" plain circle>
            <el-icon :size="36" class="is-loading"><loading/></el-icon>
        </el-button>

        <!-- 暂停按钮 -->
        <el-button v-if="isPlaying" class="control-btn" plain circle @click="pause">
            <el-icon :size="36"><video-pause/></el-icon>
        </el-button>

        <!-- 已播放时间 -->
        <span style="margin-left: 10px" :key="playedKey">{{ makeTimeDesc(playedSeconds, 0) }}</span>

        <!-- 播放进度条 -->
        <el-slider style="flex: fit-content;margin-left: 15px;margin-right: 15px"
                   v-model="progress"
                   :min="0"
                   :max="100"
                   :show-tooltip="false"
                   @change="onSeeking"
                   @mousedown="onSeekingStart"
                   @mouseup="onSeekingEnd"/>

        <!-- 总时长 -->
        <span style="margin-right: 10px">{{ makeTimeDesc(audioDuration, 1) }}</span>
    </div>

    <!--<el-row class="my-controls" justify="center">-->


    <!--</el-row>-->
</template>

<!-- 添加主播页面的试听组件 -->
<script>

import {Loading, VideoPause, VideoPlay} from "@element-plus/icons-vue";
import StringUtils from "@/utils/stringUtils";
import {ElMessage} from "element-plus";
import SvgIcon from "@/components/SvgIcon";

const PlayerState = {
    DEFAULT: 0,
    PLAYING: 1,
    LOADING: 2,
};


export default {
    name: "AuditionPlayer",
    components: {
        SvgIcon,
        VideoPlay, VideoPause, Loading
    },
    props: {
        audioData: {
            type: String,
            default: ''
        }
    },
    emits: ["play"],
    data() {
        return {
            playedSeconds: 0, // 已播放时间(秒)
            audioDuration: 0, // 音频时长(秒)
            progress: 0, // 播放进度
            auditionState: PlayerState.DEFAULT, // 播放状态：0默认状态，1->播放中，2->加载中
            isSeeking: false,
            playedKey: 0,
        }
    },
    computed: {
        audio() {
            return this.$refs.audio
        },
        isAudioDefault() {
            return this.auditionState === PlayerState.DEFAULT;
        },
        isPlaying() {
            return this.auditionState === PlayerState.PLAYING;
        },
        isLoading() {
            return this.auditionState === PlayerState.LOADING;
        },
    },
    methods: {

        makeTimeDesc(time, id) {
            if (isNaN(time)) {
                time = 0
            }
            // 分钟
            const minute = parseInt("" + time / 60);
            var minuteStr = minute.toString();
            if (minute < 10) {
                minuteStr = "0" + minuteStr;
            }
            // 秒
            var second = parseInt("" + time % 60);
            var secondStr = Math.round(second);
            if (second < 10) {
                secondStr = "0" + secondStr;
            }
            return `${minuteStr}:${secondStr}`;
        },

        startPlay() {
            if (StringUtils.isNotBlank(this.audioData)) {
                this.auditionState = PlayerState.PLAYING;
                this.audio.play();
            } else {
                this.auditionState = PlayerState.DEFAULT;
                ElMessage.warning("音频地址为空")
            }
        },

        pause() {
            if (this.isPlaying) {
                this.audio.pause();
                this.auditionState = PlayerState.DEFAULT;
            }
        },

        stopPlay() {
            if (this.isPlaying) {
                this.$refs.audio.pause();
                this.audio.currentTime = 0;
                this.playedSeconds = 0;
                this.auditionState = PlayerState.DEFAULT;
            }
        },

        showLoading() {
            this.auditionState = PlayerState.LOADING;
        },

        hideLoading() {
            this.auditionState = PlayerState.DEFAULT;
        },

        // ===========================================
        //      listener
        // ===========================================

        onPlayClick() {
            if (this.isLoading) {
                return;
            }
            this.$emit('play', (res) => {
                console.log("res: " + res)
            })
        },

        onCanPlay() {
            this.audioDuration = this.audio.duration;
        },

        onAudioTimeUpdate() {
            if (this.isSeeking) {
                return;
            }
            this.playedSeconds = this.audio.currentTime
            if (this.audioDuration > 0) {
                this.progress = this.playedSeconds / this.audioDuration * 100;
            } else {
                this.progress = 0
            }
        },

        onPlayEnd() {
            this.playedSeconds = 0;
            this.auditionState = PlayerState.DEFAULT;
        },

        onSeekingStart() {
            this.isSeeking = true;
        },

        onSeeking(val) {
            let time = this.audioDuration * val / 100;
            this.audio.currentTime = time
            this.playedSeconds = time
            this.isSeeking = false
        },

        onSeekingEnd() {
            // this.audio.currentTime = this.playedSeconds;
            // this.isSeeking = false;
        },
    }
}
</script>

<style lang="scss" scoped>

.my-controls {
    background-color: #eeeeee;
    height: 40px;
    line-height: 40px;
    border-radius: 20px;

    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .control-btn {
        width: 32px;
        height: 32px;
        color: $systemBlue;
        padding: 0;
        margin-left: 5px
    }
}

</style>