<template>
    <el-menu class="nav-bar" mode="horizontal">

        <!-- sidebar抽屉按钮 class="navbar"  -->
        <div class="sidebar-switch" @click="switchSidebar">
            <el-icon v-if="open" :size="24">
                <fold/>
            </el-icon>
            <el-icon v-else :size="24">
                <expand/>
            </el-icon>
        </div>

        <bread-crumb/>

        <el-dropdown class="avatar-container" @command="onCommandHandler">
            <span class="el-dropdown-link" style="font-weight: bold;color: #333333">
              {{ username }}
              <el-icon class="el-icon--right">
                <caret-bottom/>
              </el-icon>
            </span>
            <template #dropdown>
                <el-dropdown-menu>
                    <el-dropdown-item command="signOut">退出登录</el-dropdown-item>
                </el-dropdown-menu>
            </template>
        </el-dropdown>
    </el-menu>
</template>

<script>
import {CaretBottom, Expand, Fold} from "@element-plus/icons-vue"
import BreadCrumb from "@/components/BreadCrumb";
import {AccountManager} from "@/data/AccountManager";

export default {
    name: "AdminNavBar",
    components: {
        BreadCrumb,
        Fold,
        Expand,
        CaretBottom
    },
    props: {
        open: {
            type: Boolean,
            default: true
        }
    },
    data() {
        return {
        }
    },
    computed: {
        username() {
            return AccountManager.username();
        }
    },
    mounted() {
        console.log(AccountManager.username());
    },

    methods: {

        switchSidebar() {
            this.$emit('switchSidebar')
        },

        onCommandHandler(command) {
            if (command === 'signOut') {
                this.onSignOut();
            }
        },

        /**
         * 退出登录
         */
        onSignOut() {
            // this.$store.dispatch('user/signOut').then(() => {
            //     location.reload();
            // })
            AccountManager.setLoginOut();
            location.reload();
        }
    }
}
</script>

<style lang="scss" scoped>

.nav-bar {
    height: 100%;
    overflow: hidden;
    position: relative;
    background: #fff;
    margin-left: 0;
    margin-right: 0;
    /*box-shadow: 0 1px 4px rgba(0,21,41,.08);*/
    /*-webkit-box-shadow: 0 1px 4px rgba(0,21,41,0.08);*/

    .el-header {
        padding-left: 0;
        padding-right: 0;
    }

    .el-icon {
        padding-top: 12px;
    }

    .sidebar-switch {
        padding-right: 10px;
        line-height: 50px;
        float: left;
        cursor: pointer;
    }

    .el-breadcrumb {
        float: left;
        height: 100%;
        line-height: 50px;
    }

    .breadcrumb-container {
        float: left;
    }

    .avatar-container {
        position: absolute;
        right: 35px;

        .el-icon--right {
            top: 2px;
        }
    }
}
</style>