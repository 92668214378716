<template>
    <el-container>
        <!--<el-header class="my-page-header">-->
        <!--    <common-page-title-label title="标签管理"/>-->
        <!--</el-header>-->

        <el-main>
            <el-tabs v-model="activeName">
                <el-tab-pane label="场景/领域" name="scene">
                    <category-tab-page/>
                </el-tab-pane>
                <el-tab-pane label="风格/情绪" name="style">
                    <style-tab-page/>
                </el-tab-pane>
                <el-tab-pane label="语种/语言" name="language">
                    <!-- 通过修改selected属性，可以触发 beforeUpdate 和 updated方法， -->
                    <language-tab-page :selected="activeName === 'language'"/>
                </el-tab-pane>
                <el-tab-pane label="角色" name="role">
                    <voice-role-manager/>
                </el-tab-pane>
            </el-tabs>

        </el-main>
    </el-container>
</template>

<!-- 配音属性管理：场景、风格、语种 -->
<script>
import CategoryTabPage from "@/views/dubbing/tags/CategoryTabPage";
import StyleTabPage from "@/views/dubbing/style/StyleTabPage";
import LanguageTabPage from "@/views/dubbing/tags/LanguageTabPage";
import CommonPageTitleLabel from "@/views/common/CommonPageTitleLabel";
import VoiceRoleManager from "@/views/dubbing/role/VoiceRoleManager";
export default {
    name: "LabelsManagerPage",
    components: {VoiceRoleManager, CommonPageTitleLabel, LanguageTabPage, StyleTabPage, CategoryTabPage},
    data() {
        return {
            activeName: 'scene',
        }
    },
    mounted() {
        let tab = this.$route.query.tab;
        if (tab) {
            this.activeName = tab + "";
        }
    },
    watch: {
        activeName: {
            immediate: true,
            handler(newValue, oldValue) {
                if (newValue !== oldValue) {
                    this.$router.replace({
                        query: {
                            tab: newValue
                        }
                    })
                }
            }
        }
    }
}
</script>

<style scoped>

</style>