import StringUtils from "@/utils/stringUtils";

const TOKEN_KEY = "token";

export const UserDefaults = {

    getToken() {
        return this.getString(TOKEN_KEY, null);
    },

    setToken(token) {
        this.setString(TOKEN_KEY, token);
    },

    removeToken() {
        this.removeItem(TOKEN_KEY);
    },


    // ===========================================
    //      Local Storage
    // ===========================================
    isLocalStorageEnable() {
        return window.Storage && window.localStorage && window.localStorage instanceof Storage;
    },

    removeItem(key) {
        localStorage.removeItem(key);
    },

    setString(key, val) {
        localStorage.setItem(key, val);
    },

    getString(key, nullElse) {
        const val = localStorage.getItem(key);
        if (StringUtils.isBlank(val)) {
            return nullElse;
        }
        return val;
    },

    setNumber(key, number) {
        localStorage.setItem(key, number);
    },

    getNumber(key, nullElse) {
        const val = localStorage.getItem(key);
        if (StringUtils.isBlank(val)) {
            return nullElse;
        }
        return Number(val);
    },

    getFloat(key, nullElse) {
        const val = localStorage.getItem(key);
        if (StringUtils.isBlank(val)) {
            return nullElse;
        }
        return Number.parseFloat(val);
    },

    getInt(key, nullElse) {
        const val = localStorage.getItem(key);
        if (StringUtils.isBlank(val)) {
            return nullElse;
        }
        return Number.parseInt(val);
    },

    setObject(key, obj) {
        if (obj === null || obj === undefined) {
            this.removeItem(key);
            return;
        }
        let json = JSON.stringify(obj);
        localStorage.setItem(key, json);
    },

    getObject(key, nullElse, receiver) {
        let json = localStorage.getItem(key);
        if (StringUtils.isBlank(json)) {
            return nullElse;
        }
        if (receiver === null || receiver === undefined) {
            return JSON.parse(json);
        }
        return JSON.parse(json, receiver);
    }
}