<template>
    <div>
        <el-header>
            <el-page-header :content="anchorName" @back="onBack"/>
        </el-header>

        <el-table border v-model="tableLoading" :data="tableData">

            <el-table-column label="ID" prop="id" align="center" width="80"/>

            <el-table-column label="名称" prop="title" align="center" width="100px"/>

            <el-table-column label="风格" prop="styleValue" align="center" width="100px"/>

            <el-table-column label="语调" prop="pitchRate" align="center" width="60px"/>

            <el-table-column label="语速" prop="speechRate" align="center" width="60px">
                <template #default="scope">
                    <span>{{scope.row.speechRate.toFixed(2)}}</span>
                </template>
            </el-table-column>

            <el-table-column label="音量" prop="volume" align="center" width="60px"/>

            <el-table-column label="采样率" prop="sampleRate" align="center" width="80px"/>

            <el-table-column label="使用次数" prop="orderCount" align="center" width="100px"/>

            <el-table-column label="状态" prop="status" align="center" width="80px">
                <template #default="scope">
                    <common-status-label :status="scope.row.status"/>
                </template>
            </el-table-column>


            <!--<el-table-column label="试听地址" prop="filepath" align="center"/>-->
            <el-table-column label="试听" prop="filepath" align="center">
                <template #default="scope">
                    <audio :src="scope.row.filepath" controls style="height: 40px"/>
                </template>
            </el-table-column>

            <el-table-column label="操作">
                <template #default="scope">
                    <el-button size="small" @click="onItemEditClick(scope.$index, scope.row)">
                        编辑
                    </el-button>
                    <el-button v-if="scope.row.status === 1"
                               size="small" type="success"
                               @click="onItemResumeClick(scope.$index, scope.row)">
                        恢复
                    </el-button>

                    <el-button v-if="scope.row.status === 0"
                               size="small" type="danger"
                               @click="onItemDisableClick(scope.$index, scope.row)">
                        禁用
                    </el-button>
                    <el-button size="small" type="danger"
                               @click="onItemDeleteClick(scope.$index, scope.row)">
                        删除
                    </el-button>
                    <el-button size="small" type="success" @click="_onItemSampleClick(scope.$index, scope.row)">设为样音</el-button>
                </template>
            </el-table-column>

        </el-table>

        <el-button type="primary" style="margin-top: 30px; margin-left: 20px;" @click="onAddTemplate">添加模版</el-button>

        <anchor-sample-set-drawer ref="sampleSetDialog" v-model:visible="sampleSetVisible"/>
    </div>
</template>

<!-- 主播的风格模版列表 -->
<script>
import {DubbingApis} from "@/api/dubbing";
import {ElMessage} from "element-plus";
import CommonStatusLabel from "@/views/common/CommonStatusLabel";
import StringUtils from "@/utils/stringUtils";
import {QxAlertDialog} from "@/components/QxAlertDialog";
import AnchorSampleSetDrawer from "@/views/dubbing/anchor/AnchorSampleSetDrawer";

export default {
    name: "AnchorTemplatePage",
    components: {AnchorSampleSetDrawer, CommonStatusLabel},
    data() {
        return {
            anchorId: null,
            anchorName: '风格模版',
            tableLoading: false,
            tableData: [], // 模版列表
            sampleSetVisible: false,
        }
    },

    created() {
        // 方式一：针对params传参的接收方式
        // let argJson = this.$route.params.anchor;
        // console.log(argJson)
        // if (StringUtils.isNotBlank(argJson)) {
        //     let anchorData = JSON.parse(argJson + "");
        //     this.anchorId = anchorData.id;
        //     this.anchorName = anchorData.name;
        // }
    },

    activated() {
        console.log("AnchorTemplatePage.activated()")
        // 方式二：针对query传参的接收
        this.anchorId = this.$route.query.id;
        this.anchorName = this.$route.query.name;
        this.getTemplateList();
    },

    deactivated() {
        console.log("AnchorTemplatePage.deactivated()")
    },

    methods: {

        onBack() {
            this.$router.back()
        },

        getTemplateList() {
            if (this.anchorId === null || this.anchorId === undefined) {
                return
            }
            DubbingApis.getTemplatesByAnchorId(this.anchorId).then(res => {
                if (res.code === 200) {
                    this.tableData = res.data;
                } else {
                    ElMessage.error("获取模版列表失败：" + res.message);
                }
            }).catch(error => {
                ElMessage.error("获取模版列表失败：" + error.toString());
            })
        },

        // ===========================================
        //      Actions
        // ===========================================

        /**
         * 添加模版
         */
        onAddTemplate(){
            if (this.anchorId === null || this.anchorId === undefined) {
                alert("主播id为空，无法添加模版")
                console.log("主播id为空，无法添加模版")
                return
            }
            // console.log("anchorId: " + this.anchorId)
            this.$router.push({
                path: '/dubbing/anchor/template/add',
                query: {
                    id: this.anchorId
                }
            })
            // this.$router.push({
            //     path: `/dubbing/anchor/template/add/${this.anchorId}`,
            // })
        },

        onItemEditClick(index, itemData) {
            // let jsonStr = JSON.stringify(itemData);
            // this.$router.push({
            //     name: 'AnchorTemplateEditPage',
            //     params: {
            //         data: jsonStr
            //     }
            // })

            this.$router.push({
                path: "/dubbing/anchor/template/edit",
                query: {
                    id: itemData.id
                }
            })
        },

        onItemDisableClick(index, itemData) {

        },

        onItemResumeClick(index, itemData) {

        },

        onItemDeleteClick(index, itemData) {
            QxAlertDialog.warn("删除模版", "删除后无法撤销，请确认后再继续操作", "确定", () => {
                let templateId = itemData.id;
                DubbingApis.deleteTemplate(templateId).then(res => {
                    if (res.code === 200) {
                        ElMessage.success(res.message);
                    } else {
                        ElMessage.error(res.message);
                    }
                })
            }, "取消", null)
        },

        _onItemSampleClick(index, itemData) {
            this.$refs.sampleSetDialog.init(itemData);
            this.sampleSetVisible = true;
        }

    }
}
</script>

<style scoped>

</style>