export const AudioUtils = {

    parseAudioDuration(file, callback) {
        let url = null;
        if (window.createObjectURL !== undefined) {
            // basic
            url = window.createObjectURL(file.raw);
        } else if (window.URL !== undefined) {
            // mozilla(firefox)
            url = window.URL.createObjectURL(file.raw);
        } else if (window.webkitURL !== undefined) {
            // webkit or chrome
            url = window.webkitURL.createObjectURL(file.raw);
        }
        let audio = new Audio(url);
        audio.addEventListener("loadedmetadata", (_event) => {
            let duration = Math.round(audio.duration);
            callback(duration);
        });
    }

}