<template>

    <div>

        <el-table v-loading="tableLoading" border :data="tableData">
            <el-table-column label="ID" prop="id" align="center" width="60px"/>
            <el-table-column label="名称" prop="name" align="center" width="150px"/>
            <el-table-column label="排序(降序)" prop="sort" align="center" width="130px"/>
            <el-table-column label="状态" prop="status" align="center" width="100px">
                <template #default="scope">
                    <common-status-label :status="scope.row.status"/>
                </template>
            </el-table-column>
            <el-table-column label="操作" align="left">
                <template #default="itemScope">
                    <el-button size="small" type="primary" @click="onEditClick(itemScope.$index, itemScope.row)">编辑</el-button>
                    <el-button size="small" type="danger" @click="onDeleteClick(itemScope.$index, itemScope.row)">删除</el-button>
                </template>
            </el-table-column>
        </el-table>

        <el-row>
            <common-add-button title="添加场景" @click="onAddClick" style="margin-top: 20px"/>
            <common-add-button title="清除缓存" @click="_onClearCacheClick" style="margin-top: 20px"/>
        </el-row>

        <!-- 编辑框 -->
        <el-dialog v-model="editingDialogVisible" width="30%" title="编辑">
            <el-form :model="editingForm" label-width="70px" :rules="editingRules">

                <el-form-item label="ID" prop="id">
                    <el-input v-model="editingForm.categoryId" disabled/>
                </el-form-item>

                <el-form-item label="名称" prop="name" >
                    <el-input v-model="editingForm.name" placeholder="请输入场景/领域名称"/>
                </el-form-item>

                <el-form-item label="排序" prop="sort">
                    <el-input-number v-model="editingForm.sort" :min="0" :max="100"/>
                </el-form-item>
            </el-form>

            <template #footer>
                <span>
                    <el-button primary @click="editingDialogVisible = false">取消</el-button>
                    <el-button type="primary" @click="onEditingSubmit">提交</el-button>
                </span>
            </template>
        </el-dialog>


        <!-- 添加框 -->
        <el-dialog v-model="addingDialogVisible" width="30%" title="添加场景/领域">
            <el-form ref="addingElForm" :model="addingForm" label-width="70px" :rules="addingRules">

                <el-form-item label="名称" prop="name" >
                    <el-input v-model="addingForm.name" placeholder="请输入场景/领域名称"/>
                </el-form-item>

                <el-form-item label="排序" prop="sort">
                    <el-input-number v-model="addingForm.sort" :min="0" :max="100"/>
                </el-form-item>
            </el-form>

            <template #footer>
                <span>
                    <el-button primary @click="addingDialogVisible = false">取消</el-button>
                    <el-button type="primary" @click="onAddingSubmit">提交</el-button>
                </span>
            </template>
        </el-dialog>

    </div>

</template>

<script>
import {DubbingApis} from "@/api/dubbing";
import {ElMessage, ElMessageBox} from "element-plus";
import CommonStatusLabel from "@/views/common/CommonStatusLabel";
import CommonAddButton from "@/views/common/CommonAddButton";

export default {
    name: "CategoryTabPage",
    components: {CommonAddButton, CommonStatusLabel},
    data() {
        return {
            tableLoading: false,
            tableData: [],
            // editing
            editingDialogVisible: false,
            editingForm: {
                categoryId: null,
                name: '',
                sort: 0
            },
            editingRules: {
                name: [
                    {required: true, message: '名称不能为空'},
                    {min: 2, max: 7, message: '仅支持2～7位中文字汉字'}
                ],
                sort: [
                    {required: true, message: '排序因子不能为空'}
                ]
            },
            // adding
            addingDialogVisible: false,
            addingForm: {
                name: '',
                sort: 0
            },
            addingRules: {
                name: [
                    {required: true, message: '名称不能为空'},
                    {min: 2, max: 7, message: '仅支持2～7位中文字汉字'}
                ],
                sort: [
                    {required: true, message: '排序因子不能为空'}
                ]
            }
        }
    },
    mounted() {
        this.loadCategoryData();
    },
    methods: {

        loadCategoryData() {
            DubbingApis.getCategoryList().then(res => {
                if (res.code === 200) {
                    this.tableData = res.data;
                }
            })
        },

        // ===========================================
        //      编辑
        // ===========================================

        onEditClick(index, itemData) {
            this.editingForm.categoryId = itemData.id;
            this.editingForm.name = itemData.name;
            this.editingForm.sort = itemData.sort;
            this.editingDialogVisible = true;
        },

        onEditingSubmit() {
            let param = {
                categoryId: this.editingForm.categoryId,
                name: this.editingForm.name,
                sort: this.editingForm.sort
            }
            DubbingApis.updateCategory(param).then(res => {
                if (res.code === 200) {
                    // 更新成功
                    ElMessage.success("更新成功");
                    this.editingDialogVisible = false;
                    // 刷新列表
                    this.loadCategoryData();
                } else {
                    ElMessage.error("更新失败: " + res.message);
                }
            }).catch(error => {
                ElMessage.error("更新失败: " + error.toString());
            })

        },

        // ===========================================
        //      添加标签
        // ===========================================

        onAddClick() {
            this.addingDialogVisible = true;
        },

        onAddingSubmit() {
            this.$refs['addingElForm'].validate(valid => {
                if (!valid) { return }

                let param = {
                    name: this.addingForm.name,
                    sort: this.addingForm.sort
                }
                DubbingApis.addCategory(param).then(res =>{
                    if (res.code === 200) {
                        // 添加成功
                        ElMessage.success("添加成功");
                        this.addingDialogVisible = false;
                        // 刷新列表
                        this.loadCategoryData();
                    } else {
                        ElMessage.error("添加失败: " + res.message);
                    }
                }).catch(error => {
                    ElMessage.error("添加失败: " + error.toString());
                })
            })
        },

        _onClearCacheClick() {
            DubbingApis.deleteCategoryCache().then(() => {
                ElMessage.success("已清除缓存")
            })
        },

        // ===========================================
        //      删除
        // ===========================================

        onDeleteClick(index, itemData) {
            ElMessageBox.confirm("删除后无法恢复，且相关记录都会被删除，请确认后再执行此操作", "警告", {
                confirmButtonText: '删除',
                cancelButtonText: '取消',
                type: 'error'
            }).then(() => {
                // 确认删除
                this.ensureDelete(itemData.id)
            }).catch(() => {
                // 取消
            })
        },

        ensureDelete(categoryId) {
            let param = {
                categoryId: categoryId
            }
            DubbingApis.deleteCategory(param).then(res => {
                if (res.code === 200) {
                    // 删除成功
                    this.loadCategoryData();
                } else {
                    ElMessage.error("删除失败: " + res.message);
                }
            }).catch(error => {
                ElMessage.error("删除失败: " + error.toString());
            })
        }
    }
}
</script>

<style scoped>

</style>