<template>
    <span v-if="sex === 1">男声</span>
    <span v-else-if="sex === 2">女声</span>
    <span v-else-if="sex === 3">童声</span>
    <span v-else>未知</span>
</template>

<script>
export default {
    name: "AnchorSexLabel",
    props: {
      sex: {
          type: Number,
          default: 0
      }
    },
    data() {
        return {

        }
    }
}
</script>

<style scoped>

</style>