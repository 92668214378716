<template>
  <router-view/>
</template>

<script>
import HelloWorld from './components/HelloWorld.vue'

export default {
  name: 'App',
  components: {
    HelloWorld
  },
  mounted() {
  }
}
</script>

<style lang="scss">
#app {
  width: 100%;
  height: 100vh;
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: left;
  color: #2c3e50;

  .el-dialog {
    border-radius: 10px;
  }

  .el-drawer__header {
    padding: 16px;
    margin-bottom: 0;
  }

  .el-drawer__body {
    padding: 0;
  }

  // ==========================================
  //      element-plus
  // ==========================================
  //--el-color-primary:#{$color-accent};
  //--el-color-primary-dark-2:#{$color-accent-dark-2};
  //--el-color-primary-light-3:#{$color-accent-light-70};
  //--el-border-color-hover:#{colorWithAlpha($color-accent, 0.5)};
}

nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}
</style>
