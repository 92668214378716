<template>
    <!-- eslint-disable-next-line vue/no-mutating-props -->
    <el-dialog width="30%" title="上传背景音乐" v-model="visible" :before-close="dismiss" @close="dismiss">

        <el-form ref="uploadElForm" :model="uploadForm" label-width="80px" :rules="uploadRules">

            <el-form-item label="名称:" prop="title">
                <el-input v-model="uploadForm.title" placeholder="请输入背景音乐名称"/>
            </el-form-item>

            <el-form-item label="时长(秒):">
                <el-input v-model="uploadForm.duration" type="number" disabled>
                    <template #append>
                        <span>秒</span>
                    </template>
                </el-input>
            </el-form-item>

            <el-form-item label="选择文件:">
                <el-upload ref="uploader"
                           accept=".wav"
                           action="#"
                           :file-list="fileList"
                           :limit="1"
                           :auto-upload="false"
                           :on-change="onChange"
                           :on-remove="onRemove"
                           :before-upload="beforeUpload"
                           :http-request="onUploadToOss">

                    <template #trigger>
                        <el-button type="primary">选择文件</el-button>
                    </template>

                    <span style="margin-left: 15px; font-size: 10px">仅支持上传wmv格式，且不超过10M</span>
                </el-upload>
            </el-form-item>

        </el-form>

        <template #footer>
            <el-button @click="dismiss">取消</el-button>
            <el-button type="primary" @click="onSubmitClick" :loading="loading">上传</el-button>
        </template>
    </el-dialog>
</template>

<!-- 添加背景音乐 -->
<script>
import {ElMessage} from "element-plus";
import {DubbingApis} from "@/api/dubbing";
import axios from "axios";

export default {
    name: "BgmUploadDialog",
    props: {
        visible: {
            type: Boolean,
            default: false
        }
    },
    computed: {
        dialogVisible() {
            return this.visible;
        }
    },
    emits: ['dismiss', 'callback'],
    data() {
        return {
            loading: false,
            fileList: [], // 用于在重置时清空上次选择结果
            uploadForm: {
                title: '',
                duration: 0
            },
            uploadRules: {
                title: [
                    {required: true, message: "名称不能为空", trigger: 'blur'},
                    {pattern: /^[A-Za-z0-9\u4e00-\u9fa5-_]+$/, message: "名称只能由汉字、字母、数字组成，且长度不超过50", trigger: 'blur'}
                ]
            }
        }
    },
    methods: {
        dismiss() {
            this.reset();
            this.$emit('dismiss');
        },
        reset() {
            // 清除已选择文件
            this.$refs.uploader.clearFiles();
            // 清除表单
            this.$refs.uploadElForm.resetFields();
            // 清除校验结果
            this.$refs.uploadElForm.clearValidate();

            this.fileList = [];
            this.uploadForm.title = '';
            this.uploadForm.duration = 0;
        },

        parseAudioDuration(file) {
            // let url = URL.createObjectURL(file);
            var URL = null;
            if (window.createObjectURL !== undefined) {
                // basic
                URL = window.createObjectURL(file.raw);
            } else if (window.URL !== undefined) {
                // mozilla(firefox)
                URL = window.URL.createObjectURL(file.raw);
            } else if (window.webkitURL !== undefined) {
                // webkit or chrome
                URL = window.webkitURL.createObjectURL(file.raw);
            }

            let audio = new Audio(URL);
            audio.addEventListener('loadedmetadata', (_event) => {
                this.uploadForm.duration = Math.round(audio.duration);
                console.log("duration: " + audio.duration)
            })
        },

        onSubmitClick() {
            // 校验表单
            this.$refs.uploadElForm.validate((valid) => {
                if (valid) {
                    // 触发上传任务
                    this.$refs.uploader.submit();
                }
            })
        },

        // ===========================================
        //      Upload
        // ===========================================

        onChange(file, fileList) {
            if (file === undefined) {
                this.uploadForm.duration = 0
            } else {
                this.parseAudioDuration(file);
            }
        },

        onRemove(file) {
            this.uploadForm.duration = 0;
        },

        beforeUpload(file) {
            // 限制上传大小为50M
            const sizeLimit = file.size / 1024 / 1024 < 50;

            if (!sizeLimit) {
                ElMessage.error('背景音乐文件大小不能超过 50MB!')
            }
            return sizeLimit
        },

        onUploadToOss(options) {
            let file = options.file;
            let filename = file.name;

            this.uploadDirectly(file, this.uploadForm.title, this.uploadForm.duration)

            // let req = {
            //     name: this.uploadForm.title,
            //     duration: this.uploadForm.duration,
            //     filename: filename
            // }
            // DubbingApis.getBgmUploadPolicy(req).then(res => {
            //     if (res.code === 200) {
            //         console.log(res.data);
            //         this.uploadToOss(file, res.data);
            //     } else {
            //         ElMessage.error("获取oss配置失败：" + res.message);
            //     }
            // }).catch(error => {
            //     ElMessage.error("操作失败：" + error.toString())
            // })
        },

        uploadDirectly(file, bgmName, duration) {
            let form = {
                file: file,
                name: bgmName,
                duration: duration
            }
            this.loading = true
            DubbingApis.uploadBgm(form).then(res => {
                this.loading = false;
                this.onUploadSuccess();
            }).catch(error => {
                this.loading = false;
                console.error("upload failed: " + error.toString())
            })
        },

        onUploadSuccess() {
            ElMessage.success("上传成功");
            this.dismiss();
        },

        notifyUploadSuccess(data) {

        },

        uploadToOss(file, ossPolicy) {
            let form = new FormData();
            //注意formData里append添加的键的大小写
            form.append('key', ossPolicy.dir + ossPolicy.filename); //存储在oss的文件路径
            form.append('OSSAccessKeyId', ossPolicy.accessKeyId); //accessKeyId
            form.append('policy', ossPolicy.policy); //policy
            form.append('Signature', ossPolicy.signature); //签名
            form.append('callback', ossPolicy.callback); //回调
            form.append('success_action_status', 200); //成功后返回的操作码
            // 自定义参数：需要注意必须以x:开头，且只能使用小写字母和下划线_
            // form.append('x:token', Store.getters.token);
            // form.append('x:gender', this.avatarForm.sex);
            //如果是base64文件，那么直接把base64字符串转成blob对象进行上传就可以了
            form.append("file", file);

            axios.post(ossPolicy.host, form, {
                'Content-Type': 'multipart/form-data'
            }).then(response => {
                // 注意，这里的response是阿里云返回的数据，而应用服务器返回的数据则是在 response.data中
                // response: {status:200, statusText: '成功', data: {code: 200, msg: '成功', data: {...}}}
                if (response.status === 200) {
                    if (response.data.code === 200) {
                        // 上传成功 + 回调成功
                        this.notifyUploadSuccess(response.data.data);
                    } else {
                        ElMessage.error("应用服务器回调失败：" + response.data.message)
                    }
                } else {
                    ElMessage.error("上传到oss发生错误：" + response.statusText);
                }
            })
        }
    },
}
</script>

<style scoped>

</style>