<!-- 用户列表 -->
<template>
    <el-container>
        <el-header class="my-page-header">
            <el-row>
                <common-page-title-label title="用户列表"/>
                <el-input v-model="searchUserId" style="width: 280px;" placeholder="输入搜索内容">
                    <template #prepend>
                        <el-select v-model="selectedType" placeholder="请选择" style="width: 100px;">
                            <el-option label="用户ID" value="1"></el-option>
                            <el-option label="用户名" value="2"></el-option>
                            <el-option label="手机号" value="3"></el-option>
                        </el-select>
                    </template>
                </el-input>
                <el-button type="primary" class="ml-16" @click="_onSearchClick">搜索</el-button>
                <el-button type="primary" @click="_onSearchClearClick">清空</el-button>
            </el-row>
        </el-header>

        <el-main style="height: 100%">
            <el-table
                v-loading="tableLoading"
                style="width: 100%;" border
                :data="tableData">

                <el-table-column label="ID" prop="id" width="80" align="center" fixed/>
                <el-table-column label="用户id" prop="userId" width="180" align="center" fixed/>
                <el-table-column label="用户名" prop="username" width="180" align="center"/>
                <el-table-column label="昵称" prop="nickname" width="180" align="center"/>
                <el-table-column label="手机号" prop="phone" width="120" align="center"/>
                <el-table-column label="注册时间" prop="createTime" width="180" align="center"/>
                <el-table-column label="上次登录" prop="lastLoginTime" width="180" align="center"/>
                <el-table-column label="客户端" prop="lastLoginClient" width="100" align="center"/>
                <el-table-column label="登录IP" prop="lastLoginIp" width="120" align="center"/>
                <el-table-column label="用户状态" prop="statusName" width="100" align="center">
                    <template #default="scope">
                        <span v-if="scope.row.status === 0">{{ scope.row.statusName }}</span>
                        <span v-if="scope.row.status === 1" style="color: #ff3b30">{{ scope.row.statusName }}</span>
                        <span v-if="scope.row.status === 2" style="color: #ff3b30">{{ scope.row.statusName }}</span>
                    </template>
                </el-table-column>
                <el-table-column label="操作" fixed="right" width="270">
                    <template #default="scope">
                        <el-button size="small" type="success"
                                   @click="onResetPassword(scope.$index, scope.row)">
                            重置密码
                        </el-button>
                        <el-button v-if="scope.row.status !== 0" size="small" type="success"
                                   @click="onItemResumeClick(scope.$index, scope.row)">
                            恢复
                        </el-button>
                        <el-button v-if="scope.row.status === 0" size="small" primary
                                   @click="onItemDisableClick(scope.$index, scope.row)">
                            禁用
                        </el-button>
                        <el-button v-if="scope.row.status === 0" size="small" type="danger"
                                   @click="onItemDeleteClick(scope.$index, scope.row)">
                            删除
                        </el-button>
                    </template>
                </el-table-column>
            </el-table>
        </el-main>

        <!-- 分页插件 -->
        <el-footer>
            <el-pagination
                :page-size="pageSize"
                :page-count="total"
                :current-page="currentPage"
                layout="prev, pager, next"
                @current-change="onPageChange"/>
        </el-footer>

        <reset-password-dialog ref="resetPwdDialog" v-model:visible="resetPasswordDialogVisible"/>
    </el-container>
</template>

<script>
import {deleteUser, disableUser, getUserList, resumeUser, UserApis} from "@/api/user-apis";
import {ElMessage} from "element-plus";
import CommonPageTitleLabel from "@/views/common/CommonPageTitleLabel";
import StringUtils from "@/utils/stringUtils";
import ResetPasswordDialog from "@/views/user/ResetPasswordDialog";

export default {
    name: "AdminUserListPage",
    components: {ResetPasswordDialog, CommonPageTitleLabel},
    data() {
        return {
            selectedType: "1",
            tableLoading: false,
            tableData: [],
            pageSize: 30, // 每页item数量
            currentPage: 1, // 当前页
            total: 1,
            searchUserId: null,
            resetPasswordDialogVisible: false
        }
    },
    mounted() {
        this._loadUserList();
    },
    methods: {
        onPageChange(page) {
            this.currentPage = page;
            this._loadUserList();
        },

        setTableLoading(loading) {
            this.tableLoading = loading;
        },

        tableRowClassName({row, rowIndex}) {
            let status = row.status;
            if (status !== 0) {
                return "warning-row"
            }
            return ''
        },

        _loadUserList() {
            this.setTableLoading(true);
            UserApis.listUser(this.currentPage, this.pageSize).then(res => {
                this.setTableLoading(false);
                this.total = res.data.total;
                this.tableData = res.data.list;
            }).catch((error) => {
                this.setTableLoading(false);
                ElMessage.error("网络异常: " + error.toString());
            })
        },

        // ===========================================
        //      Search
        // ===========================================

        _onSearchClick() {
            if (StringUtils.isBlank(this.searchUserId)) {
                ElMessage.warning("输入搜索内容");
                return;
            }
            let type = Number.parseInt(this.selectedType);
            let content = this.searchUserId;
            UserApis.searchUser(type, content).then(res => {
                let pageData = res.data;
                this.total = this.totalPage = Math.max(1, pageData.total);
                this.tableData = pageData.list;
            })
        },

        _onSearchClearClick() {
            this.searchUserId = null;
            this.currentPage = 1;
            this._loadUserList();
        },

        // ===========================================
        //      操作
        // ===========================================

        onResetPassword(index, itemData) {
            let username = itemData.username;
            this.$refs.resetPwdDialog.init(username);
            this.resetPasswordDialogVisible = true;
        },

        onItemDisableClick(index, cellData) {
            // console.log(cellData)
            if (cellData.status === 1) {
                ElMessage.warning("当前用户已被禁用，不要重复操作")
                return
            }
            if (cellData.status === 2) {
                ElMessage.warning("当前用户已被删除，不要重复操作")
                return
            }
            disableUser(cellData.userId).then(res => {
                if (res.code === 200) {
                    cellData.status = res.data.status;
                    cellData.statusName = res.data.statusName;
                    ElMessage.success("操作成功");
                } else {
                    ElMessage.error("操作失败: " + res.message);
                }
            }).catch(error => {
                ElMessage.error("网络异常: " + error.toString());
            })
        },

        onItemResumeClick(index, itemData) {
            resumeUser(itemData.userId).then(res => {
                if (res.code === 200) {
                    itemData.status = res.data.status;
                    itemData.statusName = res.data.statusName;
                    ElMessage.success("操作成功");
                } else {
                    ElMessage.error("操作失败: " + res.message);
                }
            }).catch(error => {
                ElMessage.error("网络异常: " + error.toString());
            })
        },

        onItemDeleteClick(index, cellData) {
            if (cellData.status === 2) {
                ElMessage.warning("当前用户已被删除，不要重复操作")
                return
            }
            deleteUser(cellData.userId).then(res => {
                if (res.code === 200) {
                    cellData.status = res.data.status;
                    cellData.statusName = res.data.statusName;
                    ElMessage.success("操作成功");
                } else {
                    ElMessage.error("操作失败: " + res.message);
                }
            }).catch(error => {
                ElMessage.error("网络异常: " + error.toString());
            })
        },
    }
}
</script>

<!--<style>-->

<!--.el-table .warning-row {-->
<!--    &#45;&#45;el-table-tr-bg-color: #ffcc0040;-->
<!--}-->

<!--</style>-->

<style lang="scss" scoped>

//.el-header {
//    --el-header-padding: 0px;
//}
//
//.el-main {
//    --el-main-padding: 0px;
//}

</style>