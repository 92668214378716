import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

// element-plus
import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'

// svg-icon
import '@/assets/icons'
import SvgIcon from "@/components/SvgIcon";
import {AccountManager} from "@/data/AccountManager";

const app = createApp(App);
app.use(router);
app.use(store);
app.use(ElementPlus);
app.component('svg-icon', SvgIcon);
app.mount('#app');

const whiteList = ['/login', '/about'] // 不重定向白名单

router.beforeEach(((to, from, next) => {
    console.log("from: " + from.fullPath + " ===> to: " + to.fullPath);

    // const token = store.getters.token;
    const token = AccountManager.token();
    if (token) {
        next();
    } else {
        if (whiteList.indexOf(to.path) !== -1) {
            next()
        } else {
            next('/login')
        }
    }

}));

router.afterEach((to) => {
    // 一定要再afterEach中判断而不是beforeEach，因为beforeEach在点击返回之后获取到的值不准确，每返回一次，会获取到延后一次的to、history
    if (window.history.state && window.history.state.forward) {
        // 或者判断 to.forward,window.history.state.forward是vue-router写入的，当返回或前进的时候才会有值
        to.meta.isBack = true;
    } else {
        to.meta.isBack = false;
    }
});