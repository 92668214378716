<template>
    <div class="tts-guide-body">
        <el-form :model="this.guideData" label-width="100px">
            <el-form-item prop="version" label="版本">
                <el-input-number v-model="guideData.version" :min="this.guideData.version" :max="999" :step="1"/>
            </el-form-item>
            <el-form-item prop="content" label="文案">
                <el-input type="textarea" v-model="guideData.content" placeholder="请粘贴新手指引文案" :rows="20"/>
            </el-form-item>
        </el-form>

        <div class="tts-guide-footer">
            <el-button type="danger" @click="_onSaveGuideContent">更新</el-button>
        </div>
    </div>
</template>

<script>
import {SettingApis} from "@/api/SettingApis";
import StringUtils from "@/utils/stringUtils";
import {ElMessage} from "element-plus";

export default {
    name: "TtsGuideSetting",
    data() {
        return {
            guideData: {
                version: 1,
                content: null,
            }
        }
    },

    mounted() {
        this._getGuideContent();
    },

    methods: {

        _getGuideContent() {
            SettingApis.getTtsGuideContent().then(res => {
                if (res.data) {
                    this.guideData = res.data;
                }
            })
        },

        _onSaveGuideContent() {
            if (StringUtils.isBlank(this.guideData.content)) {
                ElMessage.error("文案不能为空");
                return;
            }
            SettingApis.setTtsGuideContent(this.guideData).then(res => {
                ElMessage.success("设置成功");
            })
        }
    }
}
</script>

<style lang="scss" scoped>

.tts-guide-body {
    width: 1200px;
}

.tts-guide-footer {
    width: 100%;
    margin-top: 30px;
    margin-right: 20px;
    display: flex;
    justify-content: flex-end;
}

</style>