<template>
    <!-- 设置图标 -->
    <el-dialog v-model="internalVisible" width="20%" title="设置图片">
        <el-form ref="iconFormEl" :model="iconForm" label-width="80px">
            <el-form-item label="ID">
                <el-input v-model="iconForm.activityId" disabled/>
            </el-form-item>
            <el-form-item label="上传图片">
                <el-upload ref="uploader" class="style-icon-uploader"
                           action="#" drag
                           accept="image/png,image/jpeg"
                           :limit="1"
                           :auto-upload="false"
                           :show-file-list="false"
                           :on-exceed="_onExceed"
                           :on-remove="_onRemove"
                           :on-change="_onFileChange"
                           :before-upload="beforeUpload"
                           :http-request="onUpload">
                    <!--suppress HtmlUnknownTarget -->
                    <div class="style-icon-uploader-holder">
                        <img v-if="selectedFileUrl"
                             :src="selectedFileUrl" style="max-width: 120px;object-fit: contain;" alt="">
                        <span class="holder-text">点击选择图片或拖拽到此</span>
                    </div>
                </el-upload>
            </el-form-item>
        </el-form>

        <template #footer>
            <el-button primary @click="dismiss">取消</el-button>
            <el-button type="primary" @click="_onSubmitClick" v-loading="uploading">设置</el-button>
        </template>
    </el-dialog>
</template>

<script>
import BasicDialog from "@/views/base/BasicDialog";
import {ElMessage, genFileId} from "element-plus";
import {WebActivityApis} from "@/api/web-activity-apis";

export default {
    name: "ActivityBannerDialogSetImage",
    mixins: [BasicDialog],
    emits: ['success'],
    data() {
        return {
            selectedFileUrl: null, // 当前选择的头像文件链接，用于预览
            selectedFile: null, // 当前选择的头像文件，用于上传,
            iconForm: {
                activityId: null, // 风格id
            },
            uploading: false,
        }
    },

    methods: {

        init(data) {
            this.iconForm.activityId = data.id;
        },

        _onSubmitClick() {
            this.$refs.uploader.submit()
        },

        _reset() {
            this.iconForm.activityId = null;
            this.$refs.uploader.clearFiles();
        },

        // ===========================================
        //      file change
        // ===========================================

        _onExceed(files) {
            this.$refs.uploader.clearFiles();
            const file = files[0];
            file.uid = genFileId();
            this.$refs.uploader.handleStart(file);
        },

        _onRemove() {
            this.selectedFile = null;
            this.selectedFileUrl = null;
        },

        // 文件状态改变时的钩子，添加文件、上传成功和上传失败时都会被调用
        _onFileChange(file) {
            this.selectedAvatarFile = file;
            // 获取上传图片的本地URL，用于上传前的本地预览
            let URL = null;
            if (window.createObjectURL !== undefined) {
                // basic
                URL = window.createObjectURL(file.raw);
            } else if (window.URL !== undefined) {
                // mozilla(firefox)
                URL = window.URL.createObjectURL(file.raw);
            } else if (window.webkitURL !== undefined) {
                // webkit or chrome
                URL = window.webkitURL.createObjectURL(file.raw);
            }
            this.selectedFileUrl = URL;
        },

        // avatar-uploader
        beforeUpload(file) {
            const isJPG = file.type === 'image/jpeg' || file.type === 'image/png';
            // const isLt2M = file.size / 1024 / 1024 < 5;
            if (!isJPG) {
                ElMessage.error('Avatar picture must be JPG/PNG format!')
            }
            return isJPG
        },

        onUpload(param) {
            const file = param.file;
            this.uploading = true;
            WebActivityApis.setActivityImage(file, this.iconForm.activityId).then(res => {
                this.uploading = false;
                ElMessage.success("图标设置成功")
                this.dismiss();
                this.$emit('success');
            }).catch(() => {
                this.uploading = false;
            })
        },
    }
}
</script>

<style scoped>

</style>