<template>
    <el-dialog v-model="internalVisible" width="500px" title="修改文件" @closed="_clearUploader" >
        <el-form ref="updateForm" :model="updateForm" label-width="90px" label-position="right">
            <el-form-item label="版本ID">
                <el-input v-model="updateForm.id" disabled/>
            </el-form-item>
            <el-form-item label="渠道名">
                <el-input v-model="updateForm.channel" disabled/>
            </el-form-item>
            <el-form-item label="App版本">
                <el-input v-model="updateForm.version" placeholder="App版本，如1.2.3" disabled/>
            </el-form-item>
            <el-form-item label="App版本号">
                <el-input-number v-model="updateForm.build" placeholder="App版本号，如99" disabled/>
            </el-form-item>
            <el-form-item label="选择文件">
                <el-upload ref="uploader"
                           accept=".apk"
                           action="#" drag
                           :limit="1"
                           :auto-upload="false"
                           :on-change="_onChange"
                           :on-remove="_onRemove"
                           :before-upload="_beforeUpload"
                           :http-request="_onUploadToOssV2">

                    <div class="apk-uploader-holder">
                        <span v-if="selectedFilename" class="f12">
                            {{ selectedFilename }}
                        </span>
                        <span v-else class="ml-12 f10">点击选择文件或拖拽到此</span>
                    </div>

                    <span class="ml-16 f9">仅支持上传apk格式，且不超过150M</span>
                </el-upload>
            </el-form-item>

            <el-form-item label="上传进度">
                <el-progress class="upload-progress" :percentage="uploadProgress"/>
            </el-form-item>
        </el-form>

        <template #footer>
            <div class="dialog-footer" v-loading="uploading">
                <!--<el-progress v-if="uploading" class="upload-progress" :percentage="uploadPercent"/>-->
                <span style="flex: 1"/>
                <el-button @click="dismiss">取消</el-button>
                <el-button type="primary" @click="_onCommit">提交</el-button>
            </div>
        </template>
    </el-dialog>
</template>

<script>
import BasicDialog from "@/views/base/BasicDialog";
import {ElMessage, genFileId} from "element-plus";
import {AppVersionApis} from "@/api/app-version-apis";
import {ToastUtil} from "@/utils/toast-util";
import {OssService} from "@/api/oss-service";

export default {
    name: "AppVersionFileEditDialog",
    mixins: [BasicDialog],
    emits: ["success"],
    data() {
        return {
            updateForm: {
                id: null,
                channel: null,
                build: 1,
                version: "1.0.0",
            },
            selectedFilename: null,
            uploading: false,
            uploadProgress: 0,
        }
    },
    methods: {

        init(versionData) {
            this.updateForm.id = versionData.id;
            this.updateForm.channel = versionData.channel;
            this.updateForm.version = versionData.version;
            this.updateForm.build = versionData.build;
        },

        onShow() {
            // 清空已选择的文件
            this.selectedFilename = null;
            this.$nextTick(() => {
                this.$refs.uploader.clearFiles();
            })
        },

        onDismiss() {

        },

        _onCommit() {
            this.$refs.uploader.submit()
        },

        // ===========================================
        //      Upload
        // ===========================================

        _clearUploader() {
            // 清空已选择的文件
            this.selectedFilename = null;
            if (this.$refs.uploader) {
                this.$refs.uploader.clearFiles();
            }
            // 清除表单
            this.$refs.updateForm.resetFields();
            //清除表单内所有规则检测提示
            this.$refs.updateForm.clearValidate();
        },

        _onChange(file) {
            if (file) {
                this.selectedFilename = file.name;
            } else {
                this.selectedFilename = null;
            }
        },

        _onRemove(file) {
            this.selectedFilename = null;
        },

        _beforeUpload(file) {
            // 限制上传大小为200M
            const sizeLimit = file.size / 1024 / 1024 < 200;
            if (!sizeLimit) {
                ElMessage.error('Apk文件大小不能超过 200MB!')
            }
            return sizeLimit
        },

        // /**
        //  * 通过后端上传文件
        //  */
        // _onUploadToOss(options) {
        //     let file = options.file;
        //     let versionId = this.updateForm.id;
        //     this.uploading = true;
        //     AppVersionApis.updateVersionFile(versionId, file, (progress) => {
        //         this.uploadProgress = progress;
        //     }).then(res => {
        //         this.uploading = false
        //         ToastUtil.toast("上传成功");
        //         this.dismiss();
        //         this.$emit("success");
        //     }).catch(error => {
        //         this.uploading = false;
        //     })
        // },

        /**
         * 直接上传至oss
         */
        _onUploadToOssV2(options) {
            let file = options.file;
            let filename = file.name;
            AppVersionApis.getApkUploadPolicy(filename).then(res => {
                let policyData = res.data;
                this._handleUploadToOss(file, policyData);
            })
        },

        _handleUploadToOss(file, policy) {
            let objectKey = policy.objectKey;
            let self = this;
            this.uploading = true;
            OssService.uploadFile(file, policy, function (percent) {
                self.uploadProgress = percent;
            }, function (result) {
                self.uploading = false;
                self._doUpdateFilepath(objectKey);
            }, function (errCode, errMsg) {
                self.uploading = false;
                return false;
            })
        },

        _doUpdateFilepath(objectKey) {
            AppVersionApis.updateVersionFilepath(this.updateForm.id, objectKey).then(_ => {
                this.dismiss()
                ElMessage.success("修改成功");
            })
        }
    }
}
</script>

<style lang="scss" scoped>

.apk-uploader-holder {
    display: flex;
    align-items: center;
    width: 100%;
    height: 70px;
    justify-content: center;
}

.dialog-footer {
    display: flex;

    .upload-progress {
        width: 250px;
    }
}


</style>