<template>
    <el-dialog width="370px" v-model="internalVisible" title="配音会员">
        <el-form :model="this.rewardForm" label-width="80px">
            <el-form-item label="用户名称">
                <el-input v-model="rewardForm.username" disabled/>
            </el-form-item>

            <el-form-item label="会员等级">
                <el-select v-model="rewardForm.vipLevel" placeholder="选择会员等级">
                    <el-option v-for="(item,index) in vipLevelList"
                               :key="index" :label="item.label" :value="item.value"
                    />
                </el-select>
            </el-form-item>

            <el-form-item label="会员时长">
                <el-input-number v-model="rewardForm.duration" :step="1" :min="1" :max="30"/>
            </el-form-item>

            <el-form-item label="时长单位">
                <el-select v-model="rewardForm.unit" placeholder="选择单位">
                    <el-option v-for="(item,index) in unitList"
                               :key="index" :label="item.label" :value="item.value"
                    />
                </el-select>
            </el-form-item>

            <el-form-item label="备注描述">
                <el-input v-model="rewardForm.comment" type="textarea" placeholder="输入赠送备注，可为空" maxlength="100"/>
            </el-form-item>
        </el-form>

        <template #footer>
                <span class="dialog-footer">
                    <el-button @click="dismiss">取消</el-button>
                    <el-button type="primary" @click="_onSubmit">确定</el-button>
                </span>
        </template>
    </el-dialog>
</template>

<script>
import BasicDialog from "@/views/base/BasicDialog";
import {ElMessage} from "element-plus";
import {TtsVipApis} from "@/api/tts-vip-apis";

export default {
    name: "TtsVipRewardDialog",
    mixins: [BasicDialog],
    emits: ['success'],
    data() {
        return {
            rewardForm: {
                userId: null,
                username: null,
                vipLevel: 2,
                duration: 0,
                unit: 'd',
                comment: null,
            },
            vipLevelList: [
                {
                    label: '高级会员',
                    value: 2
                }
            ],
            unitList: [
                {
                    label: '天',
                    value: 'd'
                },
                {
                    label: '月',
                    value: 'm'
                }
            ]
        }
    },

    mounted() {

    },

    methods: {

        onShow() {
            if (!this.rewardForm.userId) {
                ElMessage.error("userId不能为空")
            }
        },

        setup(userId, username) {
            this.rewardForm.userId = userId;
            this.rewardForm.username = username;
            this.rewardForm.comment = null;
        },

        _onSubmit() {
            let req = {
                userId: this.rewardForm.userId,
                vipLevel: this.rewardForm.vipLevel,
                duration: this.rewardForm.duration,
                unit: this.rewardForm.unit,
                comment: this.rewardForm.comment
            }
            TtsVipApis.addVipReward(req).then(() => {
                ElMessage.success("设置奖励成功");
                this.dismiss();
                this.$emit('success')
            })
        }
    }
}
</script>

<style scoped>

</style>