import {Apis} from "@/api/Apis";
import request from "@/api/request";

export const GptApis = {

    // ===========================================
    //      template
    // ===========================================

    getCensorEnable() {
        return Apis.get("/admin/gpt/config/censorEnable")
    },

    setCensorEnable(enable) {
        let param = {
            enable: enable
        }
        return Apis.get("/admin/gpt/config/updateCensorEnable", param);
    },

    searchLogList(req) {
        return Apis.post("/admin/gpt/log/list", req)
    },

    /**
     * 添加模版标签类型
     * @param req
     */
    addLabelType(req) {
        return Apis.post("/admin/gpt/template/labelType/add", req)
    },

    /**
     * 更新标签类型
     * @param req
     */
    updateLabelType(req) {
        return Apis.post("/admin/gpt/template/labelType/update", req)
    },

    getAllLabelType() {
        return Apis.get("/admin/gpt/template/labelType/all")
    },

    // ===========================================
    //      template
    // ===========================================

    addTemplate(req) {
        return Apis.post("/admin/gpt/template/add", req)
    },

    updateTemplate(req) {
        return Apis.post("/admin/gpt/template/update", req)
    },

    updateTemplateSort(req) {
        return Apis.get("/admin/gpt/template/updateSort", req);
    },

    updateTemplateStatus(id, status) {
        let req = {
            templateId: id,
            status: status
        }
        return Apis.get("/admin/gpt/template/updateStatus", req);
    },

    updateTemplateHomeType(templateId, type) {
        let req = {
            templateId: templateId,
            homeType: type
        }
        return Apis.get("/admin/gpt/template/updateHomeType", req);
    },

    updateTemplateIcon(form) {
        return Apis.uploadWithForm("/admin/gpt/template/updateIcon", form);
    },

    removeTemplateIcon(templateId) {
        let param = {
            templateId: templateId
        }
        return Apis.get("/admin/gpt/template/deleteIcon", param)
    },

    searchTemplate(req) {
        return Apis.post("/admin/gpt/template/search", req)
    },


    // ===========================================
    //      Preset Prompt
    // ===========================================

    getAllPresetPrompts() {
        return Apis.get("/admin/gpt/presetPrompt/all")
    },

    addPresetPrompt(req) {
        return Apis.post("/admin/gpt/presetPrompt/add", req)
    },

    updatePresetPrompt(req) {
        return Apis.post("/admin/gpt/presetPrompt/update", req)
    },


}