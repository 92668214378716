<template>
    <el-dialog width="360px" title="编辑"
               v-model="internalVisible" :append-to-body="false" v-loading="this.uploading">
        <el-form :model="this.soundForm" label-width="60px">

            <el-form-item v-if="!this.addMode" label="id:">
                <el-input v-model="soundForm.id" disabled/>
            </el-form-item>

            <el-form-item label="名称:">
                <el-input v-model="soundForm.name" />
            </el-form-item>
            <el-form-item label="风格:">
                <el-select v-model="soundForm.styleId"
                           placeholder="选择风格">
                    <el-option v-for="item in internalStyleList"
                               :key="item.id"
                               :label="item.name"
                               :value="item.id"/>
                </el-select>
            </el-form-item>
            <el-form-item label="音频:">
                <audio-uploader ref="audioUploader"
                                :sound-url="this.soundForm.soundUrl"
                                @upload-handler="_onUpload"/>
            </el-form-item>
        </el-form>

        <template #footer>
                <span class="dialog-footer" v-loading="this.uploading">
                    <el-button @click="dismiss">取消</el-button>
                    <el-button type="primary" @click="_onSubmit">确定</el-button>
                </span>
        </template>
    </el-dialog>
</template>

<script>
import BasicDialog from "@/views/base/BasicDialog";
import AudioUploader from "@/views/dubbing/sound/SoundUploader";
import {DubbingApis} from "@/api/dubbing";
import {ElMessage} from "element-plus";
import StringUtils from "@/utils/stringUtils";

export default {
    name: "SoundEditDialog",
    components: {AudioUploader},
    mixins:[BasicDialog],
    props: {
        styleList: null,
        addMode: {
            type: Boolean,
            default: false
        },
    },
    computed: {
       internalStyleList() {
           if (this.styleList) {
               return this.styleList;
           } else {
               return [];
           }
       }
    },
    data() {
        return {
            soundForm: {
                id: null,
                name: null,
                styleId: null,
                soundUrl: null,
            },
            uploading: false,
        }
    },

    methods: {
        setup(soundData) {
            this.soundForm.id = soundData.id;
            this.soundForm.name = soundData.name;
            this.soundForm.styleId = soundData.style.id;
            this.soundForm.soundUrl = soundData.path;
        },

        _onSubmit() {
            if (StringUtils.isBlank(this.soundForm.name)) {
                ElMessage.error("音效名称不能为空")
                return;
            } else if (!this.soundForm.styleId) {
                ElMessage.error("请选择音效风格")
                return;
            }

            // 添加模式
            if (this.add) {
                this.$refs.audioUploader.submit();
            } else {
                // 编辑模式
                // 如果选择了新文件，则需要上传文件
                if (this.$refs.audioUploader.getSelectedFile()) {
                    this.$refs.audioUploader.submit();
                } else {
                    this._updateProperty();
                }
            }
        },

        _onUpload(file, duration) {
            if (this.addMode) {
                this._addSound(file, duration)
            } else {
                this._updateSound(file, duration);
            }
        },

        _addSound(file, duration) {
            let req = {
                name: this.soundForm.name,
                styleId: this.soundForm.styleId,
                duration: duration
            }
            this.uploading = true;
            DubbingApis.addSound(file, req).then(res => {
                this.uploading = false;
                ElMessage.success("更新成功");
                this.dismiss();
            }).catch(() => {
                this.uploading = false;
            })
        },

        /**
         * 只修改属性，不修改音频文件
         * @private
         */
        _updateProperty() {
            let req = {
                id: this.soundForm.id,
                name: this.soundForm.name,
                styleId: this.soundForm.styleId
            }
            this.uploading = true;
            DubbingApis.updateSoundInfo(req).then(res => {
                this.uploading = false;
                ElMessage.success("更新成功");
                this.dismiss();
            }).catch(() => {
                this.uploading = false;
            })
        },

        /**
         * 同时修改属性和音频文件
         * @private
         */
        _updateSound(file, duration) {
            let req = {
                id: this.soundForm.id,
                name: this.soundForm.name,
                styleId: this.soundForm.styleId,
                duration: duration
            }
            this.uploading = true;
            DubbingApis.updateSoundAll(file, req).then(res => {
                this.uploading = false;
                ElMessage.success("更新成功");
                this.dismiss();
            }).catch(() => {
                this.uploading = false;
            })
        }

    }
}
</script>

<style scoped>

</style>