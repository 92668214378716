import {Apis} from "@/api/Apis";

export const TtsVipApis = {

    addVipReward(req) {
        return Apis.post("/admin/tts/vip/addReward", req);
    },

    searchVipList(page, pageSize, userId, obtainType) {
        let body = {
            page: page,
            pageSize: pageSize,
            userId: userId,
            obtainType: obtainType
        }
        return Apis.post("/admin/tts/vip/list", body)
    },

    getProductList() {
        return Apis.get("/admin/tts/vip/product/list")
    },

    updateProduct(req) {
        return Apis.post("/admin/tts/vip/product/update", req)
    },

    enableProduct(productId) {
        let params = {
            id: productId,
            status: 0
        }
        return Apis.get("/admin/tts/vip/product/updateStatus", params)
    },

    disableProduct(productId) {
        let params = {
            id: productId,
            status: 1
        }
        return Apis.get("/admin/tts/vip/product/updateStatus", params)
    },

}