<template>
    <el-container>
        <el-header class="my-page-header">
            <el-row>
                <common-page-title-label title="交易记录"/>
                <el-input v-model="searchUserId" style="width: 280px;" placeholder="输入搜索内容">
                    <template #prepend>
                        <el-select v-model="selectedType" placeholder="请选择" style="width: 100px;">
                            <el-option label="用户ID" value="1"/>
                            <el-option label="用户名" value="2"/>
                            <el-option label="手机号" value="3"/>
                        </el-select>
                    </template>
                </el-input>
                <el-button type="primary" class="ml-16" @click="_onSearchClick">搜索</el-button>
                <el-button type="primary" @click="_onSearchClearClick">清空</el-button>
            </el-row>
        </el-header>

        <el-main style="width: 100%;margin-top: 10px;">
            <el-table :data="tradeOrderList" border >
                <el-table-column label="ID" width="80" prop="id"/>
                <el-table-column label="日期" width="160" prop="createTime"/>
                <el-table-column label="用户ID" width="180" prop="userId"/>
                <el-table-column label="商品名称" width="200" prop="productName"/>
                <el-table-column label="交易金额" width="120" prop="amount"/>
                <el-table-column label="支付方式" width="120" prop="paymentName"/>
                <el-table-column label="支付订单" width="180" prop="paymentTradeNo"/>
                <el-table-column label="客户端" width="80" prop="clientName"/>
                <el-table-column label="操作">
                    <!--<template #default="scope">-->
                    <!--    <el-button size="small" type="primary" @click="_onCheckContent(scope.row)">文案</el-button>-->
                    <!--</template>-->
                </el-table-column>
            </el-table>
        </el-main>
        <!-- 分页插件 -->
        <el-footer>
            <el-pagination
                :current-page="page"
                :page-size="pageSize"
                :page-count="totalPage"
                layout="prev, pager, next"
                @current-change="_onPageChange">
            </el-pagination>
        </el-footer>

    </el-container>
</template>

<script>
import {TradeApis} from "@/api/trade-apis";
import StringUtils from "@/utils/stringUtils";
import {ElMessage} from "element-plus";
import CommonPageTitleLabel from "@/views/common/CommonPageTitleLabel";

export default {
    name: "TradeOrderManagerPage",
    components: {CommonPageTitleLabel},
    data() {
        return {
            tradeOrderList: [],
            tradeOrderItem: {
                id: 0,
                userId: "",
                productName: "",
                amount: "",
                paymentName: "",
                paymentTradeNo: "",
                clientName: "",
                createTime: ""
            },
            searchMode: false,
            searchUserId: null,
            page: 1,
            pageSize: 30,
            totalPage: 1,
            selectedType: "1"
        }
    },

    mounted() {
        this._loadOrderList();
    },

    methods: {

        _loadOrderList() {
            TradeApis.getTradeOrderList(this.page, this.pageSize).then(res => {
                let pageData = res.data;
                this.totalPage = pageData.total;
                this.tradeOrderList = pageData.list;
            })
        },

        _onPageChange(newPage) {
            if (this.searchMode) {
                return;
            }
            this.page = newPage;
            this._loadOrderList();
        },

        _onSearchClick() {
            if (StringUtils.isBlank(this.searchUserId)) {
                ElMessage.warning("输入搜索内容");
                return;
            }
            this.searchMode = true;

            let type = Number.parseInt(this.selectedType);
            let content = this.searchUserId;
            TradeApis.searchTradeOrderList(type, content, 1, this.pageSize).then((res => {
                let pageData = res.data;
                this.totalPage = Math.max(1, pageData.total);
                this.tradeOrderList = pageData.list;
            }))
        },

        _onSearchClearClick() {
            this.searchMode = false;
            this.searchUserId = null;
            this.page = 1;
            this._loadOrderList();
        }
    }
}
</script>

<style scoped>

</style>