<template>
    <el-dialog custom-class="card-dialog" v-model="internalVisible" width="25%" title="修改密码" :before-close="dismiss" @close="dismiss">
        <el-form ref="updateForm" v-model="passwordForm" label-width="80px" label-position="top">
            <el-form-item label="用户名: ">
                <el-input v-model="passwordForm.username" disabled/>
            </el-form-item>

            <el-form-item label="新密码: ">
                <el-input v-model="passwordForm.password" type="password" show-password placeholder="请输入新密码(6-16位数字字母组合)"/>
            </el-form-item>
        </el-form>

        <template #footer>
            <span class="dialog-footer">
                <el-button type="danger" @click="_onSubmit">确定</el-button>
                <el-button style="margin-left: 20px" @click="dismiss">取消</el-button>
            </span>
        </template>
    </el-dialog>
</template>

<script>
import BasicDialog from "@/views/base/BasicDialog";
import {UserApis} from "@/api/user-apis";
import {ToastUtil} from "@/utils/toast-util";

export default {
    name: "ResetPasswordDialog",
    mixins: [BasicDialog],
    data() {
        return {
            passwordForm: {
                username: null,
                password: null
            }
        }
    },
    mounted() {

    },

    methods: {

        init(username) {
            this.passwordForm.username = username;
        },

        _onSubmit() {
            let username = this.passwordForm.username;
            let password = this.passwordForm.password;
            UserApis.resetPassword(username, password).then(res => {
                ToastUtil.toast(res.message);
                this.dismiss();
            })
        }
    }

}
</script>

<style scoped>

</style>