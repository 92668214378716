<template>
    <div>
        <el-header>
            <el-page-header content="编辑模版" @back="onBack"/>
        </el-header>

        <el-form ref="templateElForm" :model="editForm" :rules="editRules" label-width="90px"
                 style="width: 60%; margin-left: 10px">

            <el-form-item label="主播名称:">
                <el-input v-model="editForm.anchorName" disabled/>
            </el-form-item>

            <el-form-item label="模版id:">
                <el-input v-model="editForm.templateId" disabled/>
            </el-form-item>

            <el-form-item label="模版名称:" prop="title">
                <el-input v-model="editForm.title" placeholder="请输入风格模版名称"/>
            </el-form-item>

            <el-form-item label="主播风格:" prop="styleId">
                <el-select v-model="editForm.styleId" placeholder="请选择主播风格">
                    <el-option v-for="item in styleOptionList" :label="item.name" :value="item.id" :key="item.id"/>
                </el-select>
            </el-form-item>

            <el-form-item label="采样率" prop="sampleRate">
                <el-select v-model="editForm.sampleRate" placeholder="选择采样率">
                    <el-option v-for="item in sampleRateOptions"
                               :key="item.value" :value="item.value"
                               :label="item.label"/>
                </el-select>
            </el-form-item>

            <el-form-item label="语调:">
                <el-slider class="slider" v-model="editForm.pitchRate" :min="-500" :max="500" show-input/>
            </el-form-item>

            <el-form-item label="语速:">
                <el-slider class="slider" v-model="editForm.speechRate" :min="0.5" :max="2.0" :step="0.01"
                           :marks="speedLevelMarks" show-input/>
            </el-form-item>

            <el-form-item label="音量:">
                <el-slider class="slider" v-model="editForm.volume" :min="0" :max="100" show-input/>
            </el-form-item>

            <el-form-item label="模版文案：" prop="content">
                <el-input ref="contentInput"
                          v-model="editForm.content"
                          :rows="7"
                          type="textarea"
                          maxlength="500"
                          show-word-limit
                          placeholder="请输入主播试听内容"
                          @blur="onInputBlur"/>
            </el-form-item>

            <el-form-item>
                <el-button round type="primary" @click="onInsertHalt">插入停顿</el-button>
                <el-button round type="primary" @click="onInsertPhoneme">指定发音</el-button>
            </el-form-item>

            <el-form-item label="点击试听：">
                <audition-player ref="auditionPlayer" :audio-data="auditionPath" @play="onAudition"/>
            </el-form-item>

        </el-form>

        <div style="width: 50%;text-align: right">
            <span>
                <el-button type="primary" round @click="onEditClick">修改</el-button>
                <el-button round @click="onBack">取消</el-button>
            </span>
        </div>

        <!-- 插入停顿对话框 -->
        <halt-picker-dialog :dialog-visible="haltPickerVisible"
                            @dismiss="haltPickerVisible = false"
                            @result="onHaltResult"/>

        <!-- 指定发音对话框 -->
        <phoneme-input-dialog :visible="phonemeDialogVisible"
                              @dismiss="phonemeDialogVisible = false"
                              @result="onPhonemeResult"/>
    </div>
</template>

<!-- 模版编辑 -->
<script>
import AuditionPlayer from "@/views/dubbing/anchor/AuditionPlayer";
import PhonemeInputDialog from "@/views/dubbing/components/PhonemeInputDialog";
import HaltPickerDialog from "@/views/dubbing/components/HaltPickerDialog";
import AvatarPickerDialog from "@/views/dubbing/anchor/AvatarPickerDialog";
import {Loading, Plus, VideoPause, VideoPlay} from "@element-plus/icons-vue";
import {DubbingApis} from "@/api/dubbing";
import {ElMessage, ElMessageBox} from "element-plus";
import bus from "@/utils/bus";
import StringUtils from "@/utils/stringUtils";
import {SsmlUtils} from "@/utils/SsmlUtils";
import voiceOptions from "@/views/dubbing/VoiceConstants";

export default {
    name: "AnchorTemplateEditPage",
    components: {
        AuditionPlayer,
        PhonemeInputDialog,
        HaltPickerDialog,
        AvatarPickerDialog,
        Plus, VideoPlay, VideoPause, Loading,
    },
    data() {
        return {
            styleOptionList: [], // 风格列表
            // 采样率选项
            sampleRateOptions: voiceOptions.sampleRateOptions,
            speedLevelMarks: {0.5: '0.5x', 1: '1x', 1.5: '1.5x', 2.0: '2x'},  // 语速marks

            haltPickerVisible: false, // 插入停顿对话框可见性
            phonemeDialogVisible: false, // 指定发音对话框可见性
            inputSelectionStart: null, // 文案输入框当前选中范围 开始位置
            inputSelectionEnd: null, // 文案输入框当前选中范围 结束位置

            auditionPath: null,
            lastAuditionData: '', // 记录当前试听请求参数

            editForm: {
                templateId: null, // 模版id
                anchorId: null, // 主播id
                anchorName: '', // 主播名称
                title: '', // 模版名称
                styleId: 1, // 风格id
                pitchRate: 0, // 语调
                speechRate: 1.0, // 语速
                volume: 100, // 音量
                sampleRate: 16000,
                content: '', // 文案
            },
            editRules: {
                title: [
                    {required: true, message: "模版名称不能为空", trigger: 'blur'},
                    {pattern: /^[a-zA-Z0-9\u4e00-\u9fa5-_]{2,4}$/, message: "标题仅支持2～4位汉字、大小写字母、数字、-、_组成", trigger: 'blur'}
                ],
                styleId: [
                    {required: true, message: "请选择一中风格", trigger: 'blur'}
                ]
            }
        }
    },

    created() {
        this.editForm.templateId = this.$route.query.id;
    },
    mounted() {
        this.loadStyleList();
        this.loadTemplateInfo();
    },
    methods: {
        onBack() {
            this.$router.back();
        },

        loadStyleList() {
            DubbingApis.getStyleList().then(res => {
                if (res.code === 200) {
                    this.styleOptionList = res.data;
                }
            })
        },

        loadTemplateInfo() {
            DubbingApis.getTemplateInfo(this.editForm.templateId).then(res => {
                if (res.code === 200) {
                    let templateData = res.data;
                    this.sampleRateOptions = voiceOptions.makeSampleRateOptions(templateData.sampleRateMax);
                    this.editForm.templateId = templateData.id;
                    this.editForm.title = templateData.title;
                    this.editForm.styleId = templateData.styleId;
                    this.editForm.anchorName = templateData.anchorName;
                    this.editForm.anchorId = templateData.anchorId;
                    this.editForm.pitchRate = templateData.pitchRate;
                    this.editForm.speechRate = templateData.speechRate;
                    this.editForm.volume = templateData.volume;
                    this.editForm.sampleRate = templateData.sampleRate;
                    this.editForm.content = templateData.content;
                    this.auditionPath = templateData.filepath;

                } else {
                    ElMessage.error("获取模版信息失败: " + res.message);
                }
            }).catch(error => {
                ElMessage.error("无法获取模版信息：" + error.toString());
            })
        },

        // ===========================================
        //      插入停顿 指定发音
        // ===========================================

        onInputBlur(event) {
            let input = event.srcElement;
            this.inputSelectionStart = input.selectionStart;
            this.inputSelectionEnd = input.selectionEnd;
        },

        onInsertHalt() {
            this.haltPickerVisible = true;
        },

        onHaltResult(halt) {
            const startPos = this.inputSelectionStart;
            const endPos = this.inputSelectionEnd;
            if (startPos === null || endPos === undefined) {
                ElMessage.warning("请将光标移动至待插入位置")
                return
            }
            let content = this.editForm.content;
            let haltTag = SsmlUtils.makeBreakTag(halt);
            this.editForm.content = content.slice(0, startPos) + haltTag + content.slice(endPos);
        },

        onInsertPhoneme() {
            const startPos = this.inputSelectionStart;
            const endPos = this.inputSelectionEnd;
            // console.log("selection: start = " + startPos + ", end = " + endPos);
            if (startPos === null || endPos === undefined || (endPos - startPos) !== 1) {
                // ElMessage.waring("请选择")
                alert("请选择一个需要设置读音的中文汉字")
                return
            }

            let content = this.editForm.content;
            let selectedWord = content.slice(startPos, endPos)
            // console.log("selectedWord: " + selectedWord)
            bus.emit('selectedWord', selectedWord)
            this.phonemeDialogVisible = true;
        },

        onPhonemeResult(phoneme) {
            const startPos = this.inputSelectionStart;
            const endPos = this.inputSelectionEnd;
            // console.log("selection: start = " + startPos + ", end = " + endPos);
            if (startPos === null || endPos === undefined) {
                ElMessage.warning("请将光标移动至待插入位置")
                return
            }
            let content = this.editForm.content;
            let selectedWord = content.slice(startPos, endPos)
            // [#行=hang2#]
            let ssmlTag = SsmlUtils.makePhonemeTag(selectedWord, phoneme);
            this.editForm.content = content.slice(0, startPos) + ssmlTag + content.slice(endPos);
        },


        // ===========================================
        //      试听
        // ===========================================

        onAudition(callback) {
            callback(true)
            let ap = this.$refs['auditionPlayer']
            if (StringUtils.isBlank(this.editForm.anchorId)) {
                ElMessage.error("主播id为空");
                return
            }
            let content = this.editForm.content;
            if (StringUtils.isBlank(content)) {
                ElMessage.error("请输入试听文案");
                return;
            }
            let auditionParam = {
                anchorId: this.editForm.anchorId,
                styleId: this.editForm.styleId,
                pitchRate: this.editForm.pitchRate,
                speechRate: this.editForm.speechRate,
                volume: this.editForm.volume,
                sampleRate: this.editForm.sampleRate,
                content: this.editForm.content
            }

            let currentAuditionData = JSON.stringify(auditionParam);
            // 如果试听参数与上次相同，且当前试听链接不为空，则直接播放; 减少向服务器请求次数
            if (this.auditionPath !== null && this.lastAuditionData === currentAuditionData) {
                ap.startPlay();
                return
            }

            ap.showLoading();
            DubbingApis.requestAudition(auditionParam).then(res => {
                let model = res.data;
                if (model.status === 200) {
                    this.auditionPath = model.audioData;
                    this.lastAuditionData = currentAuditionData;
                    this.delayToStartPlay();
                } else {
                    ap.hideLoading();
                    ElMessage.error("试听失败: " + model.statusText);
                }
            }).catch(error => {
                ap.hideLoading();
            })
        },

        delayToStartPlay() {
            setTimeout(() => {
                this.$refs.auditionPlayer.startPlay()
            }, 1)
        },

        // ===========================================
        //      修改模版
        // ===========================================

        onEditClick() {
            this.$refs.templateElForm.validate(valid => {
                if (valid) {
                    // 添加模版
                    this.requestUpdateTemplate();
                }
            })
        },

        requestUpdateTemplate() {
            let req = {
                templateId: this.editForm.templateId,
                title: this.editForm.title,
                styleId: this.editForm.styleId,
                pitchRate: this.editForm.pitchRate,
                speechRate: this.editForm.speechRate,
                sampleRate: this.editForm.sampleRate,
                volume: this.editForm.volume,
                content: this.editForm.content
            }
            DubbingApis.updateTemplate(req).then(res => {
                if (res.code === 200) {
                    ElMessageBox.alert("模版信息已更新", "修改成功", {
                        confirmButtonText: '返回',
                        callback: () => {
                            this.onBack();
                        }
                    })
                } else {
                    ElMessage.error("更新失败：" + res.message);
                }
            }).catch(error => {
                ElMessage.error("更新失败：" + error.toString());
            })
        }
    }
}
</script>

<style lang="scss" scoped>

</style>