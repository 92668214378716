<template>
    <el-dialog v-model="internalVisible" title="文字内容" width="480px">
        <div class="content-wrapper">{{ this.taskContent }}</div>
        <template #footer="">
            <el-button type="primary" @click="dismiss">确 定</el-button>
        </template>
    </el-dialog>
</template>

<script>
import BasicDialog from "@/views/base/BasicDialog";

export default {
    name: "TtsTaskContentDialog",
    mixins: [BasicDialog],
    data() {
        return {
            taskContent: "",
        }
    },
    methods: {
        onDismiss() {
            this.taskContent = ""
        },
        setupContent(text) {
            this.taskContent = text;
        }
    }
}
</script>

<style lang="scss" scoped>

:deep(el-dialog_body) {
    padding: 0;
}

.content-wrapper {
    //width: 100%;
    min-height: 100px;
    max-height: 400px;
    background-color: #f5f5f5;
    color: #333333;
    padding: 8px;
    overflow-y: auto;
}

.dialog-footer {
    height: 44px;
    display: flex;
    align-items: center;
    justify-content: right;
    //padding: 10px 10px;
}

</style>