<template>
    <!-- 设置图标 -->
    <el-dialog v-model="internalVisible" width="20%" title="设置图标">
        <el-form ref="iconFormEl" :model="iconForm" label-width="80px">
            <el-form-item label="选择图标">
                <el-upload ref="uploader" class="style-icon-uploader"
                           action="#" drag
                           accept="image/png,image/jpeg"
                           :limit="1"
                           :auto-upload="false"
                           :show-file-list="false"
                           :on-exceed="_onExceed"
                           :on-remove="_onRemove"
                           :on-change="_onFileChange"
                           :before-upload="beforeUpload"
                           :http-request="onUpload">
                    <!--suppress HtmlUnknownTarget -->
                    <div class="style-icon-uploader-holder">
                        <img v-if="selectedAvatarFileUrl"
                             :src="selectedAvatarFileUrl" class="avatar-img">
                        <span class="holder-text">点击选择图片或拖拽到此</span>
                    </div>
                </el-upload>
            </el-form-item>
        </el-form>

        <template #footer>
            <el-button primary @click="dismiss">取消</el-button>
            <el-button type="primary" @click="_onSubmitClick" v-loading="uploading">设置</el-button>
        </template>
    </el-dialog>
</template>

<script>
import BasicDialog from "@/views/base/BasicDialog";
import {ElMessage, genFileId} from "element-plus";
import {DubbingApis} from "@/api/dubbing";

export default {
    name: "EmotionIconEditDialog",
    mixins: [BasicDialog],
    data() {
        return {
            selectedAvatarFileUrl: null, // 当前选择的头像文件链接，用于预览
            selectedAvatarFile: null, // 当前选择的头像文件，用于上传,
            iconForm: {
                styleId: null, // 风格id
            },
            uploading: false,
        }
    },

    methods: {

        setStyleId(id) {
            this.iconForm.styleId = id;
        },

        _onSubmitClick() {
            this.$refs.uploader.submit()
        },

        // ===========================================
        //      file change
        // ===========================================

        _onExceed(files) {
            this.$refs.uploader.clearFiles();
            const file = files[0];
            file.uid = genFileId();
            this.$refs.uploader.handleStart(file);
        },

        _onRemove() {
            this.selectedAvatarFile = null;
            this.selectedAvatarFileUrl = null;
        },

        // 文件状态改变时的钩子，添加文件、上传成功和上传失败时都会被调用
        _onFileChange(file) {
            // console.log("onChange: " + JSON.stringify(file));
            this.selectedAvatarFile = file;
            // 获取上传图片的本地URL，用于上传前的本地预览
            let URL = null;
            if (window.createObjectURL !== undefined) {
                // basic
                URL = window.createObjectURL(file.raw);
            } else if (window.URL !== undefined) {
                // mozilla(firefox)
                URL = window.URL.createObjectURL(file.raw);
            } else if (window.webkitURL !== undefined) {
                // webkit or chrome
                URL = window.webkitURL.createObjectURL(file.raw);
            }
            this.selectedAvatarFileUrl = URL;
        },

        // avatar-uploader
        beforeUpload(file) {
            if (!this.isAddMode && file === null && this.selectedAvatarFileUrl != null) {
                return true;
            }
            // console.log("beforeUpload file: " + JSON.stringify(file));
            const isJPG = file.type === 'image/jpeg' || file.type === 'image/png';
            const isLt2M = file.size / 1024 / 1024 < 2;

            if (!isJPG) {
                ElMessage.error('Avatar picture must be JPG/PNG format!')
            }
            if (!isLt2M) {
                ElMessage.error('Avatar picture size can not exceed 2MB!')
            }
            return isJPG && isLt2M
        },

        onUpload(param) {
            const file = param.file;
            let form = new FormData();
            form.append("file", file);
            form.append("styleId", this.iconForm.styleId);

            this.uploading = true;
            DubbingApis.updateStyleImage(form).then(res => {
                this.uploading = false;
                ElMessage.success("图标设置成功")
                this.dismiss();
            }).catch(error => {
                ElMessage.error("图标设置失败" + error.toString());
                this.uploading = false;
            })
        },
    }
}
</script>

<style lang="scss" scoped>

.style-icon-uploader {
    width: 100%;
    //height: 90px;
    //border: 1px dashed #d9d9d9;
    //border-radius: 10px;
    cursor: pointer;
    overflow: hidden;
    line-height: 0;

    :deep(.el-upload-dragger) {
        padding: 0;
    }

}

.style-icon-uploader-holder {
    display: flex;
    align-items: center;
    width: 100%;
    height: 90px;
    justify-content: center;

    .avatar-img {
        width: 64px;
        height: 64px;
        border: 1px dashed #d9d9d9;
        border-radius: 32px;
    }

    .holder-text {
        margin-left: 10px;
        font-size: 12px;
    }
}

</style>