<template>
  <div class="page-body">
    <div class="page-main-card">
      <div>
        <el-button type="primary" style="flex: 0" @click="onAddStyleClick">添加Style</el-button>
      </div>
      <el-table :data="styleList" border stripe style="margin-top: 15px;overflow: auto">
        <el-table-column label="ID" prop="id" width="80" align="center"></el-table-column>
        <el-table-column label="名称" prop="name" width="180" align="center"></el-table-column>
        <el-table-column label="Value" prop="value" width="180" align="center"></el-table-column>
        <el-table-column label="训练模型" prop="trainModel" width="120" align="center"></el-table-column>
        <el-table-column label="封面" prop="pic" width="120" align="center">
          <template #default="scope">
            <img v-if="scope.row.pic" style="width: 80px;height: 80px" :src="scope.row.pic" alt="cover">
            <span v-else>{{ scope.row.pic }}</span>
          </template>
        </el-table-column>
        <el-table-column label="修改时间" prop="updateTime" width="180" align="center"></el-table-column>
        <el-table-column label="创建时间" prop="createTime" width="180" align="center"></el-table-column>
        <el-table-column label="操作" align="center">
          <template #default="scope">
            <el-button text type="primary" @click="onItemEditClick(scope.row)">编辑</el-button>
            <el-button text type="primary" @click="onItemCoverEditClick(scope.row)">封面</el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <draw-style-edit-drawer
      ref="styleEditDrawer"
      v-model:visible="styleEditDialogVisible"
      @success="onEditSuccess">
    </draw-style-edit-drawer>
    <draw-cover-edit-drawer
      ref="coverEditDrawer"
      v-model:visible="coverDialogVisible"
      type="style"
      @success="onEditSuccess">
    </draw-cover-edit-drawer>
  </div>
</template>

<script>
  import {DrawsApi} from "@/api/draws-api";
  import CommonAddButton from "@/views/common/CommonAddButton.vue";
  import DrawStyleEditDrawer from "@/views/draws/DrawStyleEditDrawer.vue";
  import DrawCoverEditDrawer from "@/views/draws/DrawCoverEditDrawer.vue";

  export default {
    name: 'DrawStyleManager',
    components: {DrawCoverEditDrawer, DrawStyleEditDrawer, CommonAddButton},
    data() {
      return {
        styleList: [],
        styleEditDialogVisible: false,
        coverDialogVisible: false
      }
    },
    mounted() {
      this.getStyleList();
    },
    methods: {
      getStyleList() {
        DrawsApi.getStyleList().then(res => {
          this.styleList = res.data;
        })
      },
      onAddStyleClick() {
        this.$refs.styleEditDrawer.show();
      },
      onItemEditClick(itemData) {
        this.$refs.styleEditDrawer.showEditing(itemData)
      },
      onItemCoverEditClick(item) {
        const data = {
          id: item.id,
          name: item.name
        }
        this.$refs.coverEditDrawer.showEditing(data)
      },
      onEditSuccess() {
        this.getStyleList();
      }
    }
  }
</script>

<style lang="scss" scoped>
@import "@/styles/colors";

.page-body {
  width: 100%;
  height: 100%;
  background-color: $systemBackground;
  box-sizing: border-box;
  padding: 15px;

  .page-main-card {
    width: 100%;
    height: 100%;
    background-color: white;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    padding: 10px;
  }
}

</style>