<template>
    <el-dialog v-model="internalVisible" width="400px" title="群发短信">
        <el-form ref="sendForm" :model="this.sendForm" style="width: 100%;" label-width="90px" label-position="right">
            <el-form-item label="短信模版">
                <el-select v-model="sendForm.templateId">
                    <el-option v-for="item in templateList"
                               :key="item.id"
                               :value="item.id"
                               :label="item.templateName">
                    </el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="手机号码">
                <el-input v-model="sendForm.phoneNumbers" type="textarea" :rows="8"  placeholder="输入手机号码，用逗号分割"/>
            </el-form-item>
        </el-form>

        <template #footer>
            <span style="display: flex;" v-loading="sendLoading">
                <div style="flex: 1"/>
                <el-button @click="dismiss">取消</el-button>
                <el-button type="primary" @click="_onCommit">提交</el-button>
            </span>
        </template>

    </el-dialog>
</template>

<script>
import BasicDialog from "@/views/base/BasicDialog";
import {SmsApis} from "@/api/SmsApis";
import StringUtils from "@/utils/stringUtils";
import {ElMessage} from "element-plus";
import {QxAlertDialog} from "@/components/QxAlertDialog";

export default {
    name: "SmsSendDialog",
    mixins: [BasicDialog],
    data() {
        return {
            templateList: [],
            sendForm: {
                templateId: 0,
                phoneNumbers: ""
            },
            phoneInput: '',
            sendLoading: false,
        }
    },
    mounted() {
        this._getTemplateList();
    },
    methods: {
        onShow() {
            console.log("onshow")
            if (this.templateList.length === 0) {
                this._getTemplateList();
            }
        },
        _getTemplateList() {
            SmsApis.getSmsTemplateList().then(res => {
                this.templateList = res.data;
                this.sendForm.templateId = this.templateList[0].id;
            })
        },
        _onCommit() {
            if (this.sendForm.templateId < 1) {
                ElMessage.warning("请选择短信模版")
                return;
            }
            if (StringUtils.isBlank(this.sendForm.phoneNumbers)) {
                ElMessage.warning("请输入手机号码")
                return;
            }
            let phoneList = this.sendForm.phoneNumbers.split(",")
            let data = {
                templateId: this.sendForm.templateId,
                phoneList: phoneList
            }
            this.sendLoading = true;
            SmsApis.sendGroupSms(data).then(res => {
                this.sendLoading = false;
                let data = res.data;
                let message = "共" + data.total + "条，成功=" + data.successCount + ", 失败=" + data.failureCount;
                QxAlertDialog.success("发送成功", message, "好的", null);
            }).catch(_ => {
                this.sendLoading = false;
            })
        }
    }
}
</script>

<style lang="scss" scoped>

</style>