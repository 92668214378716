<template>
    <div class="tts-vip-setting-container">
        <el-header class="my-page-header">
            <el-row>
                <common-add-button title="刷新缓存" @click="_onRefreshCache"/>
            </el-row>
        </el-header>

        <el-main style="width: 100%">
            <el-table class="vip-limit-table" :data="limitList" border :row-class-name="_rowClassName">
                <el-table-column label="会员等级" width="100" prop="vipLevel">
                    <template #default="scope">
                        <span>{{_vipName(scope.row.vipLevel)}}</span>
                    </template>
                </el-table-column>

                <el-table-column label="Ai等级" width="100" prop="aiLevel">
                    <template #default="scope">
                        <span>{{_aiLevelName(scope.row.aiLevel)}}</span>
                    </template>
                </el-table-column>

                <el-table-column label="每天试听字数" width="120" prop="auditionWordsInDay"/>
                <el-table-column label="每月试听字数" width="120" prop="auditionWordsInMonth"/>
                <el-table-column label="每天合成次数" width="120" prop="taskCountInDay"/>
                <el-table-column label="单次合成字数" width="120" prop="taskWordsInOnce"/>
                <el-table-column label="每月合成字数" width="120" prop="taskWordsInMonth"/>

                <el-table-column label="操作" >
                    <template #default="scope">
                        <el-button size="small" type="primary" @click="_onEditItemClick(scope.$index)">编辑</el-button>
                    </template>
                </el-table-column>
            </el-table>
        </el-main>

        <tts-vip-limit-edit-dialog ref="editDialog" v-model:visible="editDialogVisible"/>
    </div>
</template>

<script>
    import CommonAddButton from "@/views/common/CommonAddButton";
    import {TtsApis} from "@/api/tts-apis";
    import {ElMessage} from "element-plus";
    import TtsVipLimitEditDialog from "@/views/setting/TtsVipLimitEditDialog";
    export default {
        name: "TtsVipSetting",
        components: {TtsVipLimitEditDialog, CommonAddButton},
        data() {
            return {
                limitList: [],
                editDialogVisible: false,
            }
        },
        mounted() {
            this._getLimitation();
        },
        methods: {

            _vipName(level) {
                if (level === 0) {
                    return '非会员'
                } else if (level === 1) {
                    return "普通会员"
                } else if (level === 2) {
                    return "高级会员"
                } else if (level === 3) {
                    return "年度会员"
                } else {
                    return ""
                }
            },

            _aiLevelName(level) {
                if (level === 1) {
                    return "普通主播"
                } else if (level === 2) {
                    return "高级主播"
                } else if (level === 3) {
                    return "情绪主播"
                } else {
                    return ""
                }
            },

            _rowClassName({row, index}) {
                if (row.vipLevel === 1) {
                    return 'warning-row'
                } else if (row.vipLevel === 3) {
                    return 'success-row'
                }
            },

            _getLimitation() {
                TtsApis.getVipLimitation().then(res => {
                    this.limitList = res.data;
                })
            },

            _onRefreshCache() {
                TtsApis.refreshVipLimitCache().then(res => {
                    ElMessage.success("已刷新缓存")
                })
            },

            _onEditItemClick(index) {
                let itemData = this.limitList[index];
                this.$refs.editDialog.setup(itemData);
                this.editDialogVisible = true;
            },
        }
    }
</script>

<style>
.el-table .warning-row {
    --el-table-tr-bg-color: var(--el-color-warning-light-9);
}
.el-table .success-row {
    --el-table-tr-bg-color: var(--el-color-success-light-9);
}
</style>

<style lang="scss" scoped>


.tts-vip-setting-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
}

.vip-limit-table {
    //width: 100%;
    //margin: 20px;
    //box-sizing: border-box;
}

</style>