import {Apis} from "@/api/Apis";

export const BannerApis = {

    addBanner(req) {
        return Apis.post("/admin/banner/add", req);
    },

    updateBanner(req) {
        return Apis.post("/admin/banner/update", req);
    },

    getAllBanners(clientId) {
        let query = {
            clientId: clientId
        }
        return Apis.get("/admin/banner/all", query)
    },

    updateImage(file, bannerId) {
        let form = {
            file: file,
            id: bannerId
        }
        return Apis.uploadWithForm("/admin/banner/updateImage", form);
    },

    forbidBanner(id) {
        let query = {
            id: id
        }
        return Apis.get("/admin/banner/forbidden", query)
    },

    resumeBanner(id) {
        let query = {
            id: id
        }
        return Apis.get("/admin/banner/resume", query)
    }
}