<template>
    <el-popover ref="popover" trigger="click" @show="_onShow">
        <el-input :type="this.inputType" v-model="internalText" :placeholder="this.placeholder"/>
        <div class="action-wrapper">
            <el-button size="small" @click="_dismiss">取消</el-button>
            <el-button type="primary" size="small" @click.stop="_onDone">确定</el-button>
        </div>
        <template #reference>
            <span class="ui-cursor-pointer ui-color-blue">{{this.text}}</span>
        </template>
    </el-popover>
</template>

<script>
import StringUtils from "@/utils/stringUtils";
import {ElMessage} from "element-plus";

export default {
    name: "QxQuickEditPop",
    props: {
        inputType: {
            type: String,
            default: 'text'
        },
        text: {
            type: String,
            default: null,
        },
        row: {
           type: Number,
           default: null,
        },
        placeholder: {
            type: String,
            default: '在这里输入'
        }
    },
    emits: ['done-action'],
    data() {
        return {
            internalText: null,
        }
    },

    methods: {
        _onShow() {
            this.internalText = this.text;
        },

        _dismiss() {
            this.$refs.popover.hide();
        },

        _onDone() {
            if (StringUtils.isBlank(this.internalText)) {
                ElMessage.error("内容不能为空");
                return;
            }
            this.$emit("done-action", this.internalText, this.row);
            this._dismiss();
        }
    }
}
</script>

<style scoped>

.action-wrapper {
    width: 100%;
    margin-top: 7px;
    text-align: right;
}

/*.show-text {*/
/*    cursor: pointer;*/
/*}*/

</style>