<template>
    <el-dialog width="600px" title="背景音乐试听" v-model="internalVisible" >
        <el-form ref="templateElForm" :model="auditionForm" label-width="100px">
            <!--<el-form-item label="模版Id:" prop="templateId">-->
            <!--    <el-input v-model="auditionForm.templateId" placeholder="请输入主播模版id"/>-->
            <!--</el-form-item>-->
            <el-form-item label="选择主播：" >
                <el-select v-model="auditionForm.templateId" placeholder="请选择主播模版">
                    <el-option v-for="item in anchors"
                               :key="item.templateId"
                               :value="item.templateId"
                               :label="item.name"/>
                </el-select>
            </el-form-item>

            <el-form-item label="模版文案：" prop="content">
                <el-input ref="contentInput"
                          v-model="auditionForm.content"
                          :rows="7"
                          type="textarea"
                          maxlength="500"
                          show-word-limit
                          placeholder="请输入主播试听内容"/>
            </el-form-item>
            <el-form-item label="点击试听：">
                <audition-player ref="auditionPlayer"
                                 :audio-data="auditionData"
                                 @play="onAudition"/>
            </el-form-item>
        </el-form>
        <template #footer>
                <span class="dialog-footer">
                    <el-button @click="dismiss">取消</el-button>
                </span>
        </template>
    </el-dialog>
</template>

<script>
import BasicDialog from "@/views/base/BasicDialog";
import StringUtils from "@/utils/stringUtils";
import {ElMessage} from "element-plus";
import {DubbingApis} from "@/api/dubbing";
import AuditionPlayer from "@/views/dubbing/anchor/AuditionPlayer";

export default {
    name: "BgmAuditionDialog",
    components: {AuditionPlayer},
    mixins: [BasicDialog],
    data() {
        return {
            anchors: [
                {
                    name: "阿里云-若兮",
                    templateId: 5,
                },
                {
                    name: '微软-云晓晓',
                    templateId: 22,
                }
            ],
            auditionForm: {
                bgmId: null,
                bgmName: null,
                anchorName: "若兮",
                templateId: 5,
                content: "在埃及旅游被骗的可能性有多大？一位网友表示：在埃及，我只允许自己被骗3次。" +
                    "我本以为这是一种夸张的手法，现在才知道，这竟然是一种含蓄的表达。",
            },
            auditionData: null,

        }
    },

    methods: {

        init(bgmId, bgmName) {
            this.auditionForm.bgmId = bgmId;
            this.auditionForm.bgmName = bgmName;
        },

        onCancel() {
            this.dismiss();
        },

        // ===========================================
        //      试听
        // ===========================================

        onAudition(callback) {
            callback(true)
            let ap = this.$refs['auditionPlayer']
            if (ap.isPlaying) {
                ap.stopPlay()
                return
            }
            if (StringUtils.isBlank(this.auditionForm.templateId)) {
                ElMessage.error("主播模版id为空");
                return
            }

            let content = this.auditionForm.content;
            if (StringUtils.isBlank(content)) {
                ElMessage.error("请输入试听文案");
                return;
            }

            let auditionParam = {
                bgmId: this.auditionForm.bgmId,
                templateId: this.auditionForm.templateId,
                content: this.auditionForm.content
            }

            ap.showLoading();
            DubbingApis.auditionBgm(auditionParam).then(res => {
                let model = res.data;
                if (model.status === 200) {
                    this.auditionData = model.audioData;
                    this.delayToStartPlay();
                } else {
                    ap.hideLoading();
                    ElMessage.error("试听失败: " + res.message);
                }
            }).catch(error => {
                ap.hideLoading();
            })
        },

        delayToStartPlay() {
            setTimeout(() => {
                this.$refs.auditionPlayer.startPlay()
            }, 1)
        },
    }
}
</script>

<style scoped>

</style>