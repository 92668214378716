const AppList = [
    {
        clientId: 2,
        clientName: "android",
        channel: "default",
        name: "青岫",
        identifier: "com.qxvoice.qingxiu"
    },
    {
        clientId: 3,
        clientName: "iOS",
        channel: "AppStore",
        name: "青岫",
        identifier: "com.qxvoice.AppMain"
    },
]

export const AppVersionConstants = {
  AppList,
}