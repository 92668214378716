<template>
    <el-dialog v-model="internalVisible" width="460px" :title="this.title">
        <el-form ref="sendForm" :model="this.templateForm" style="width: 100%;" label-width="80px" label-position="right">
            <el-form-item v-if="this.editing" label="ID">
                <el-input disabled v-model="this.templateForm.templateId"/>
            </el-form-item>
            <el-form-item label="名称">
                <el-input v-model="this.templateForm.title" placeholder="输入模版名称"/>
            </el-form-item>
            <el-form-item label="描述">
                <el-input type="textarea" v-model="this.templateForm.subtitle" placeholder="输入模版简介"/>
            </el-form-item>
            <el-form-item label="提示词">
                <el-input type="textarea" rows="4" v-model="this.templateForm.prompt" placeholder="输入提示词"/>
            </el-form-item>
            <el-form-item label="输入提示">
                <el-input type="textarea" rows="2" v-model="this.templateForm.placeholder" placeholder="输入框提示语"/>
            </el-form-item>
            <el-form-item label="输入限制">
                <el-input-number v-model="this.templateForm.inputMax" step="100"/>
            </el-form-item>
            <el-form-item label="样例">
                <el-input type="textarea" rows="2" v-model="this.templateForm.demos" placeholder="输入使用样例，使用英文;;进行分割"/>
            </el-form-item>
            <el-form-item label="标签样式">
                <el-select v-model="this.templateForm.labelId" placeholder="请选择">
                    <el-option
                        v-for="item in labelList"
                        :key="item.id"
                        :label="item.id"
                        :value="item.id">
                        <span style="width: 100%; padding-left: 10px;padding-right: 10px;" :style="{color:item.textColor, backgroundColor: item.bgColor}">T</span>
                    </el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="排序">
                <el-input-number v-model="this.templateForm.sort" step="1"/>
            </el-form-item>
        </el-form>

        <template #footer>
            <span style="display: flex;" v-loading="commitLoading">
                <div style="flex: 1"></div>
                <el-button @click="dismiss">取消</el-button>
                <el-button type="primary" @click="_onCommit">提交</el-button>
            </span>
        </template>

    </el-dialog>
</template>

<script>
import BasicDialog from "@/views/base/BasicDialog";
import {GptApis} from "@/api/gpt-apis";
import {ElMessage} from "element-plus";

export default {
    name: "GptTemplateAddDialog",
    mixins:[BasicDialog],
    props: {
        // editing: {
        //     type: Boolean,
        //     default: false
        // }
    },
    data() {
        return {
            editing: false,
            title: "添加模版",
            labelList: [],
            templateForm: {
                templateId: null,
                labelId: 1,
                title: "",
                subtitle: "",
                prompt: "",
                placeholder: "",
                inputMax: 500,
                demos: "",
                sort: 0,
            },
            commitLoading: false
        }
    },
    mounted() {
        this._loadLabelList();
    },
    methods: {

        initAdding() {
            this.editing = false;
            this.title = "添加模版";
        },

        initEditing(data) {
            this.editing = true;
            this.title = "编辑模版";
            this.templateForm.templateId = data.id;
            this.templateForm.labelId = data.label.id;
            this.templateForm.title = data.title;
            this.templateForm.subtitle = data.subtitle;
            this.templateForm.prompt = data.prompt;
            this.templateForm.placeholder = data.placeholder;
            this.templateForm.demos = data.demos;
            this.templateForm.sort = data.sort;
            this.templateForm.inputMax = data.inputMax;
        },

        onDismiss() {
            this._reset();
        },

        _reset() {
            this.editing = false;
            this.title = "添加模版"
            this.templateForm.templateId = null;
            this.templateForm.labelId = null;
            this.templateForm.title = "";
            this.templateForm.subtitle = "";
            this.templateForm.prompt = "";
            this.templateForm.placeholder = "";
            this.templateForm.inputMax = 500;
            this.templateForm.demos = "";
            this.templateForm.sort = 0;
        },

        _loadLabelList() {
            GptApis.getAllLabelType().then(res => {
                this.labelList = res.data;
            })
        },

        _onCommit() {
            if (this.editing) {
                this._editHandler();
            } else {
                this._addHandler();
            }
        },

        _addHandler() {
            let req = {
                labelId: this.templateForm.labelId,
                title: this.templateForm.title,
                subtitle: this.templateForm.subtitle,
                prompt: this.templateForm.prompt,
                placeholder: this.templateForm.placeholder,
                demos: this.templateForm.demos,
                sort: this.templateForm.sort,
                inputMax: this.templateForm.inputMax
            }
            this.commitLoading = true;
            GptApis.addTemplate(req).then(res => {
                ElMessage.success("添加成功")
                this.commitLoading = false;
                this.dismiss();
            }).catch(_ => {
                this.commitLoading = false;
            })
        },

        _editHandler() {
            let req = {
                templateId: this.templateForm.templateId,
                labelId: this.templateForm.labelId,
                title: this.templateForm.title,
                subtitle: this.templateForm.subtitle,
                prompt: this.templateForm.prompt,
                placeholder: this.templateForm.placeholder,
                demos: this.templateForm.demos,
                sort: this.templateForm.sort,
                inputMax: this.templateForm.inputMax
            }
            this.commitLoading = true;
            GptApis.updateTemplate(req).then(res => {
                ElMessage.success("更新成功")
                this.commitLoading = false;
                this.dismiss();
            }).catch(_ => {
                this.commitLoading = false;
            })
        }

    }
}
</script>

<style scoped>

</style>