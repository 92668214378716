import {getToken, setToken, removeToken} from "@/utils/auth";
import {signOut} from "@/api/user-apis";

const state = {
    token: getToken(),
    username: '',
    avatar: ''
};

const mutations = {
    SET_TOKEN: (state, newValue) => {
        state.token = newValue;
        setToken(newValue)
    },
    REMOVE_TOKEN: (state) => {
        removeToken();
        state.token = null
    },
    SET_USERNAME: (state, newValue) => {
        state.username = newValue
    },
    SET_AVATAR: (state, newValue) => {
        state.avatar = newValue
    }
};

const actions = {
    // 登出
    signOut({ commit, state }) {
        return new Promise((resolve, reject) => {
            signOut().then(() => {
                commit('SET_TOKEN', '')
                commit('SET_USERNAME', '')
                removeToken()
                resolve()
            }).catch(error => {
                reject(error)
            })
        })
    },
};

export default {
    // 开启了命名空间，在使用getter或者dispatch时，必须带上 'user/', 例如 'user/signOut'
    namespaced: true,
    state,
    mutations,
    actions
}