import request from "@/api/request";

export const Apis = {

    get(url, params) {
        if (params) {
            return request({
                url: url,
                method: 'get',
                params: params
            })
        } else {
            return request({
                url: url,
                method: 'get',
            })
        }
    },

    post(url, data) {
        return request({
            url: url,
            method: 'post',
            data: data
        })
    },

    postForm(url, form) {
        return request({
            url: url,
            method: 'post',
            params: form
        })
    },

    /**
     * @param url
     * @param form
     * @param progressCallback function callback(progress), progress=[0,100]
     * @returns {*}
     */
    uploadWithForm(url, form, progressCallback = null) {
        return request({
            url: url,
            method: 'post',
            data: form,
            headers: {
                'Content-Type': 'multipart/form-data'
            },
            onUploadProgress: e => {
                if (e.lengthComputable && progressCallback) {
                    //实时获取上传进度
                    let progress = 0;
                    if (e.total > 0) {
                        progress = e.loaded / e.total * 100;
                    }
                    progress = parseInt(progress + "");
                    progressCallback(progress);
                }
            }
        })
    },

    // ===========================================
    //      V2
    // ===========================================

    get2(module, path, params) {
        return this.get(module + path, params);
    },

    post2(module, path, data) {
        return this.post(module + path, data);
    },

    postForm2(module, path, form) {
        return this.postForm(module + path, form);
    },

    uploadWithForm2(module, path, form) {
        return this.uploadWithForm(module + path, form);
    }

}