<!-- 用户登录记录 -->
<template>
    <el-container>
        <el-header class="my-page-header">
            <div style="display: flex;align-items: center">
                <common-page-title-label title="登录日志"/>

                <el-input class="userid-input" v-model="userId" placeholder="查询用户ID"/>

                <el-date-picker style="max-width: 400px;height: 32px" v-model="dateRange"
                                type="daterange"
                                start-placeholder="开始日期"
                                range-separator="至"
                                end-placeholder="结束日期"
                                :default-value="dateRange"/>

                <el-button class="search-button" type="primary" @click="onSearchClick">
                    <el-icon class="el-icon--left"><icon-search/></el-icon>
                    查询
                </el-button>

                <div style="flex: 1"></div>
            </div>
        </el-header>

        <el-main style="height: 100%">
            <el-table border style="width: 100%" v-model="tableData" v-loading="tableLoading"
                      :data="tableData.slice((page - 1) * pageSize, page * pageSize)">
                <el-table-column label="ID" prop="id" width="80" align="center"/>
                <el-table-column label="用户名" prop="username" width="180" align="center"/>
                <el-table-column label="用户ID" prop="userId" width="180" align="center"/>
                <el-table-column label="登录时间" prop="loginTime" width="180" align="center"/>
                <el-table-column label="登录IP" prop="loginIp" width="180" align="center"/>
                <el-table-column label="登录方式" prop="loginMethod" width="150" align="center"/>
                <el-table-column label="终端" prop="client" width="100" align="center"/>
                <el-table-column label="User-Agent" prop="useragent"/>
            </el-table>
        </el-main>

        <el-footer>
            <el-pagination
                :page-size="pageSize"
                :pager-count="11"
                :total="total"
                :current-page="page"
                layout="prev, pager, next"
                @current-change="onPageChange"/>
        </el-footer>

    </el-container>
</template>

<script>
import {getLoginLogs} from "@/api/user-apis";
import {ElMessage} from "element-plus";
import {Search as IconSearch} from "@element-plus/icons-vue";
import CommonPageTitleLabel from "@/views/common/CommonPageTitleLabel";

export default {
    name: "UserLoginLogPage",
    components: {
        CommonPageTitleLabel,
        IconSearch
    },
    data() {
        return {
            tableLoading: false,
            tableData: [],
            total: 1,
            page: 1,
            pageSize: 20,
            userId: null,
            dateRange: [new Date(), new Date()],
        }
    },
    mounted() {
        // 默认近三天
        let preTime = new Date().getTime() - (3 * 24 * 3600 * 1000);
        this.dateRange[0] = new Date(preTime);
        this.getTableData();
    },
    methods: {

        onPageChange(newPage) {
            this.page = newPage;
            this.getTableData();
        },

        onSearchClick() {
            console.log(this.dateRange)
            this.getTableData();
        },

        getTableData() {
            let startDate = this.dateRange[0];
            let endDate = this.dateRange[1];
            let startTime = startDate.getTime();
            let endTime = endDate.getTime();

            let param = {
                userId: this.userId,
                startTime: startTime,
                endTime: endTime,
                page: this.page,
                pageSize: this.pageSize
            }
            // console.log(param);
            this.tableLoading = true;
            getLoginLogs(param).then(res => {
                this.tableLoading = false;

                if (res.code !== 200) {
                    ElMessage.error("查询失败: " + res.msg);
                    return
                }

                this.total = res.data.total;
                this.tableData = res.data.list;

            }).catch(error => {
                this.tableLoading = false;
            })
        }
    }
}
</script>

<style scoped lang="scss">

.userid-input {
    width: 180px;
    margin-left: 30px;
    margin-right: 10px;
}


.search-button {
    margin-left: 10px;
}

</style>