import {Apis} from "@/api/Apis";

const BASE_PATH = "/admin/draw"

export const DrawsApi = {

  /**
   * 获取风格列表
   */
  getStyleList() {
    return Apis.get(BASE_PATH + '/style/list')
  },

  /**
   * 添加风格
   * @param data
   */
  addStyle(data) {
    return Apis.post(BASE_PATH + '/style/add', data);
  },

  updateStyle(data) {
    return Apis.post(BASE_PATH + '/style/update', data);
  },

  deleteStyle(styleId) {
    const params = {
      id: styleId
    }
    return Apis.get(BASE_PATH + '/style/delete', params);
  },

  resumeStyle(styleId) {
    const params = {
      id: styleId
    }
    return Apis.get(BASE_PATH + '/style/resume', params);
  },

  // ============================================
  // mark - Model
  // ============================================

  getModelList() {
    return Apis.get(BASE_PATH + '/model/list')
  },

  syncAli() {
    return Apis.get(BASE_PATH + '/model/syncAli')
  },

  addModel(data) {
    return Apis.post(BASE_PATH + '/model/add', data);
  },

  updateModel(data) {
    return Apis.post(BASE_PATH + '/model/update', data);
  },

  deleteModel(modelId) {
    const params = {
      id: modelId
    }
    return Apis.get(BASE_PATH + '/model/delete', params);
  },

  resumeModel(modelId) {
    const params = {
      id: modelId
    }
    return Apis.get(BASE_PATH + '/model/resume', params);
  },

  updateCover(file, id, type) {
    const param = {
      file: file,
      id: id
    }
    let path = null;
    if (type === 'model') {
      path = '/model/cover/update'
    } else if (type === 'style') {
      path= '/style/cover/update'
    } else {
      return
    }
    return Apis.uploadWithForm(BASE_PATH + path, param);
  }

}