<template>
    <el-dialog width="30%" v-model="dialogVisible" title="添加声音" :before-close="dismiss" @close="dismiss">
        <el-form ref="voiceFormEl" :model="voiceForm" :rules="voiceRules" label-width="80px" >

            <el-form-item label="声音平台" prop="source">
                <el-select v-model="voiceForm.source" placeholder="请选择声音平台">
                    <el-option v-for="item in voiceSourceList"
                               :key="item.value"
                               :value="item.value"
                               :label="item.label"/>
                </el-select>
            </el-form-item>

            <el-form-item label="声音名称" prop="name">
                <el-input v-model="voiceForm.name" placeholder="请输入声音名称，例如晓云"/>
            </el-form-item>

            <el-form-item label="发音人" prop="voice">
                <el-input v-model="voiceForm.voice" placeholder="请输入发音人，例如Xiaoyun, zh-cn-Xiaoyun"/>
            </el-form-item>

            <el-form-item label="声音性别" prop="sex">
                <el-select v-model="voiceForm.sex" placeholder="请选择声音性别">
                    <el-option v-for="item in sexOptions"  :key="item.value"  :value="item.value"  :label="item.label"/>
                </el-select>
            </el-form-item>

            <el-form-item label="AI等级" prop="level">
                <el-select v-model="voiceForm.level" placeholder="请选择AI等级">
                    <el-option v-for="item in aiLevelOptions" :key="item.value" :value="item.value" :label="item.label"/>
                </el-select>
            </el-form-item>

            <el-form-item label="采样率" prop="sampleRate">
                <el-select v-model="voiceForm.sampleRate" placeholder="请选择最大支持采样率">
                    <el-option v-for="item in sampleRateOptions" :key="item.value" :value="item.value" :label="item.label"/>
                </el-select>
            </el-form-item>

            <el-form-item label="适用语言" prop="language">
                <el-input v-model="voiceForm.language" placeholder="请输入适用语言"/>
            </el-form-item>

            <el-form-item label="声音类型" >
                <el-input v-model="voiceForm.type" placeholder="输入声音类型，可为空，例如标准男声"/>
            </el-form-item>

            <el-form-item label="适用场景">
                <el-input v-model="voiceForm.scene" placeholder="输入适用场景，可为空，例如适用客服聊天场景"/>
            </el-form-item>

            <el-form-item label="声音品质">
                <el-input v-model="voiceForm.quality" placeholder="输入声音的品质，可为空，例如lite版，标准版"/>
            </el-form-item>

        </el-form>

        <template #footer>
            <el-button @click="dismiss">取消</el-button>
            <el-button type="primary" @click="onAddClick">添加</el-button>
        </template>
    </el-dialog>
</template>

<!-- 添加发音人：声音来源 + 声音名称 + 发音人 + 性别 + 语种 +  -->
<script>
import VoiceOptions from "@/views/dubbing/VoiceConstants";
import voiceOptions from "@/views/dubbing/VoiceConstants";
import {DubbingApis} from "@/api/dubbing";
import {ElMessage} from "element-plus";

export default {
    name: "VoiceAddDialog",
    props: {
        visible: {
            type: Boolean,
            default: false
        }
    },
    computed: {
        dialogVisible: {
            get() {
                return this.visible;
            },
            set(v) {
                this.$emit('visible', v)
            }
        }
    },
    emits: ['dismiss'],
    data() {
        return {
            // 声音平台选项
            voiceSourceList: VoiceOptions.voiceSourceList,
            // 性别选项
            sexOptions: voiceOptions.sexOptions,
            // Ai等级选项
            aiLevelOptions: voiceOptions.aiLevelOptions,
            // 采样率选项
            sampleRateOptions: voiceOptions.sampleRateOptions,
            voiceForm: {
                source: 1,          // 声音来源平台，@NoneNull Number
                name: '',           // 声音名称, @NoneNull String
                voice: '',          // 发音人, @NoneNull String
                sex: 1,             // 性别, @NoneNull Integer
                level: 1,           // 声音等级，@NoneNull Integer 1表示普通Ai，2表示高级Ai
                sampleRate: 16000,   // 采样率，@NonNull Integer 8k,16k,24k,48k
                language: '中文及中英文混合场景',       // 适用语言, @NoneNull String
                type: '',           // 声音类型，@Nullable String 主要是阿里云字段
                scene: '通用场景',          // 适用场景，@Nullable String 主要是阿里云字段
                quality: '标准版',        // 音质，@Nullable String 主要是阿里云字段

            },
            voiceRules: {
                source: [
                    {required: true, message: '声音来源不能为空', trigger: 'blur'}
                ],
                name: [
                    {required: true, message: '声音名称不能为空', trigger: 'blur'}
                ],
                voice: [
                    {required: true, message: '发音人不能为空', trigger: 'blur'}
                ],
                sex: [
                    {required: true, message: '性别不能为空', trigger: 'blur'}
                ],
                language: [
                    {required: true, message: '语言不能为空', trigger: 'blur'}
                ],
                level: [
                    {required: true, message: 'AI等级不能为空', trigger: 'blur'}
                ],
                sampleRate: [
                    {required: true, message: '采样率不能为空', trigger: 'blur'}
                ]
            }
        }
    },

    methods : {
        dismiss() {
            this.$emit('dismiss')
            this.reset();
        },

        reset() {
            this.$refs.voiceFormEl.resetFields();
            this.$refs.voiceFormEl.clearValidate();

            // reset form
            this.voiceForm.name = '';
            this.voiceForm.voice = '';
            this.voiceForm.sex = 1;
            this.voiceForm.level = 1;

            // let obj1 = this.$data;
            // let obj2 = this.$options.data.call(this);
            // for (let attrName in obj1) {
            //     if (attrName !== 'rules') {
            //         // noinspection JSUnfilteredForInLoop
            //         obj1[attrName] = obj2[attrName]
            //     }
            // }
        },

        onAddClick() {
            this.$refs.voiceFormEl.validate( valid => {
                if (valid) {
                    this.addNewVoice();
                }
            })
        },

        addNewVoice() {
            let req = {
                source: this.voiceForm.source,
                name: this.voiceForm.name,
                voice: this.voiceForm.voice,
                sex: this.voiceForm.sex,
                language: this.voiceForm.language,
                aiLevel: this.voiceForm.level,
                sampleRate: this.voiceForm.sampleRate,
                type: this.voiceForm.type,
                scene: this.voiceForm.scene,
                quality: this.voiceForm.quality
            }
            DubbingApis.addVoice(req).then(res => {
                if (res.code === 200) {
                    ElMessage.success("添加成功");
                    this.dismiss();
                } else {
                    ElMessage.error("添加失败：" + res.message);
                }
            }).catch(error => {
                ElMessage.error("添加失败：" + error.toString())
            })
        }
    }
}
</script>

<style scoped>

</style>