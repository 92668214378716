<template>
    <el-container>
        <el-header class="my-page-header">
            <el-button type="primary" @click="_onRefreshClick">刷新</el-button>
            <el-button type="primary" @click="_onAddClick">添加</el-button>
        </el-header>

        <el-main style="width: 100%;margin-top: 10px;">
            <el-table :data="this.promptList" border >
                <el-table-column label="ID" width="50" prop="id"/>
                <el-table-column label="名称" width="140" prop="name"/>
                <el-table-column label="描述" width="180" prop="code"/>
                <el-table-column label="提示词" width="280" prop="prompt"/>
                <!--<el-table-column label="使用次数" width="60" prop="count"/>-->
                <el-table-column label="操作">
                    <template #default="scope">
                        <el-button size="small" type="primary" @click="_onEditClick(scope.row)">编辑</el-button>
                    </template>
                </el-table-column>
            </el-table>
        </el-main>

        <gpt-preset-prompt-add-dialog ref="addDialog" v-model:visible="addDialogVisible"/>

    </el-container>
</template>

<script>
import {GptApis} from "@/api/gpt-apis";
import QxQuickEditPop from "@/components/QxQuickEditPop";
import GptPresetPromptAddDialog from "@/views/gpt/GptPresetPromptAddDialog";

export default {
    name: "GptPresetPromptPage",
    components: {GptPresetPromptAddDialog, QxQuickEditPop},
    data() {
        return {
            promptList: [],
            promptItem: {
                id: Number,
                code: String,
                name: String,
                prompt: "",
            },
            addDialogVisible: false,
        }
    },
    mounted() {
        this._loadTemplate();
    },
    methods: {

        _loadTemplate() {
            GptApis.getAllPresetPrompts().then(res => {
                this.promptList = res.data;
            })
        },

        _onRefreshClick() {
            this._loadTemplate();
        },

        _onAddClick() {
            this.$refs.addDialog.initAdding();
            this.addDialogVisible = true;
        },

        _onEditClick(itemData) {
            this.$refs.addDialog.initEditing(itemData);
            this.addDialogVisible = true;
        },

    }
}
</script>

<style scoped>

</style>