
export const DateUtils =  {

    currentTimeInMillis() {
        return Date.now();
    },

    currentTimeInSeconds() {
        return Number.parseInt(Date.now() / 1000 + "");
    },

    formatTimeMillis(timeMillis, fmt) {
        return this.format(new Date(timeMillis), fmt);
    },

    // 日期格式化
    format(date, fmt) {
        let ret;
        const opt = {
            "y+": date.getFullYear().toString(),        // 年
            "m+": (date.getMonth() + 1).toString(),     // 月
            "d+": date.getDate().toString(),            // 日
            "H+": date.getHours().toString(),           // 时
            "M+": date.getMinutes().toString(),         // 分
            "S+": date.getSeconds().toString()          // 秒
            // 有其他格式化字符需求可以继续添加，必须转化成字符串
        };
        for (let k in opt) {
            ret = new RegExp("(" + k + ")").exec(fmt);
            if (ret) {
                fmt = fmt.replace(ret[1], (ret[1].length === 1) ? (opt[k]) : (opt[k].padStart(ret[1].length, "0")))
            }
        }
        return fmt;
    },

    formatDuration(time) {
        // 分钟
        const minute = parseInt("" + time / 60);
        let minuteStr = minute.toString();
        if (minute < 10) {
            minuteStr = "0" + minuteStr;
        }
        // 秒
        let second = parseInt("" + time % 60);
        let secondStr = Math.round(second);
        if (second < 10) {
            secondStr = "0" + secondStr;
        }
        return `${minuteStr}:${secondStr}`;
    },

    /**
     * 今天开始前n天
     * @param count
     */
    dayBefore(count) {
        let today = new Date();
        let millis = today.getTime() - 24 * 60 * 60 * 1000 * count;
        today.setTime(millis);
        return today;
    },

    dayAfter(count) {
        let today = new Date();
        let millis = today.getTime() + 24 * 60 * 60 * 1000 * count;
        today.setTime(millis);
        return today;
    },

    beginOfDate(date) {
        const result = new Date(date);
        result.setHours(0, 0, 0, 0);
        return result;
    }

}