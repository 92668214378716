<template>
    <el-button disabled style="margin-right: 30px">{{title}}</el-button>
</template>

<script>
export default {
    name: "CommonPageTitleLabel",
    props: {
        title: {
            type: String,
            default: 'Title'
        }
    }
}
</script>

<style lang="scss" scoped>

</style>