import request from "@/api/request";
import {Apis} from "@/api/Apis";

// noinspection SpellCheckingInspection

const MODULE_API = "/admin/dubbing/anchor"

export const AnchorApis = {

    getSceneList() {
        return Apis.get("/admin/dubbing/category/list");
    },

    /**
     * 获取主播简要信息
     * @param anchorId
     * @returns {*}
     */
    getAnchorSimpleInfo(anchorId) {
        return request({
            url: '/admin/dubbing/anchor/simpleInfo',
            method: 'post',
            params: {
                anchorId: anchorId
            }
        })
    },

    /**
     * 分页查询主播列表
     * @param page 当前页码
     * @param pageSize 页大小
     */
    getAnchorList(page, pageSize) {
        return request({
            url: "/admin/dubbing/anchor/list",
            method: 'get',
            params: {
                page: page,
                pageSize: pageSize
            }
        })
    },

    /**
     * 查询主播
     *
     * @param req
     */
    searchAnchor(req) {
        return Apis.post("/admin/dubbing/anchor/search", req)
    },

    /**
     * 同步头像地址
     */
    syncAvatarPath() {
        return Apis.get("/admin/dubbing/anchor/sync_avatar_path")
    },

    /**
     * 同步所有模版的source属性
     */
    syncTemplateSource() {
        return Apis.get("/admin/dubbing/anchor/sync_source")
    },

    /**
     * 同步所有模版的StyleValue属性
     */
    syncTemplateStyleValue() {
        return Apis.get("/admin/dubbing/template/sync_style_value")
    },


    /**
     * 更新主播的排序因子
     * @param anchorId
     * @param sort
     * @returns {*}
     */
    updateAnchorSort(anchorId, sort) {
        let form = {
            anchorId: anchorId,
            sort: sort
        }
        return Apis.postForm(MODULE_API + "/updateSort", form);
    },

    /**
     * 修改主播热门/推荐标记
     * @param anchorId
     * @param recommend
     * @returns {*}
     */
    updateAnchorRecommend(anchorId, recommend) {
        let form = {
            anchorId: anchorId,
            recommend: recommend
        }
        return Apis.postForm(MODULE_API + "/updateRecommend", form);
    },

    // ===========================================
    //      ai样音
    // ===========================================

    addAnchorSample(req) {
        return Apis.post("/admin/dubbing/anchor/sample/add", req)
    },

    updateSample(req) {
        return Apis.post("/admin/dubbing/anchor/sample/update", req)
    },

    updateSampleHot(req) {
        return Apis.get("/admin/dubbing/anchor/sample/updateHot", req)
    },

    updateSampleSort(req) {
        return Apis.get("/admin/dubbing/anchor/sample/updateSort", req)
    },

    sampleScenes() {
        return Apis.get("/admin/dubbing/anchor/sample/scenes")
    },

    searchAnchorSample(sceneId = null, page, pageSize = 20) {
        let query = {
            sceneId: sceneId,
            page:page,
            pageSize: pageSize
        }
        return Apis.get("/admin/dubbing/anchor/sample/search", query)
    }
}

// ============

/**
 * 获取主播分类列表
 */
export function getAnchorCategoryList() {
    return request({
        url: "/admin/dubbing/category/list",
        method: 'get'
    })
}

/**
 * 获取主播风格列表
 */
export function getVoiceStyleList() {
    return request({
        url: "/admin/dubbing/style/list",
        method: "get"
    })
}

/**
 * 添加主播
 * @param param
 */
export function addAnchorApi(param) {
    return request({
        url: "/admin/dubbing/anchor/add",
        method: "post",
        data: param
    })
}


export function getAvatarList(page, pageSize) {
    return request({
        url: "/admin/dubbing/avatar/listAll",
        method: 'get',
        params: {
            page: page,
            pageSize: pageSize
        }
    })
}