<template>
    <el-dialog v-model="internalVisible" width="460px" :title="this.title">
        <el-form ref="sendForm" :model="this.promptForm" style="width: 100%;" label-width="80px" label-position="right">
            <el-form-item v-if="this.editing" label="ID">
                <el-input disabled v-model="this.promptForm.id"/>
            </el-form-item>
            <el-form-item label="名称">
                <el-input v-model="this.promptForm.name" placeholder="输入模版名称"/>
            </el-form-item>
            <el-form-item label="Code">
                <el-input type="textarea" v-model="this.promptForm.code" placeholder="输入模版code"/>
            </el-form-item>
            <el-form-item label="提示词">
                <el-input type="textarea" rows="4" v-model="this.promptForm.prompt" placeholder="输入提示词"/>
            </el-form-item>
        </el-form>

        <template #footer>
            <span style="display: flex;" v-loading="commitLoading">
                <div style="flex: 1"></div>
                <el-button @click="dismiss">取消</el-button>
                <el-button type="primary" @click="_onCommit">提交</el-button>
            </span>
        </template>

    </el-dialog>
</template>

<script>
import BasicDialog from "@/views/base/BasicDialog";
import {GptApis} from "@/api/gpt-apis";
import {ElMessage} from "element-plus";

export default {
    name: "GptPresetPromptAddDialog",
    mixins:[BasicDialog],
    data() {
        return {
            editing: false,
            title: "添加模版",
            promptForm: {
                id: 0,
                name: "",
                code: "",
                prompt: "",
            },
            commitLoading: false
        }
    },
    mounted() {
    },
    methods: {

        initAdding() {
            this.editing = false;
            this.title = "添加提示词";
        },

        initEditing(data) {
            this.editing = true;
            this.title = "编辑提示词";
            this.promptForm.id = data.id;
            this.promptForm.name = data.name;
            this.promptForm.code = data.code;
            this.promptForm.prompt = data.prompt;
        },

        onDismiss() {
            this._reset();
        },

        _reset() {
            this.editing = false;
            this.title = "添加提示词"
            this.promptForm.id = 0;
            this.promptForm.name = "";
            this.promptForm.code = "";
            this.promptForm.prompt = "";
        },

        _onCommit() {
            if (this.editing) {
                this._editHandler();
            } else {
                this._addHandler();
            }
        },

        _addHandler() {
            let req = {
                name: this.promptForm.name,
                code: this.promptForm.code,
                prompt: this.promptForm.prompt,
            }
            this.commitLoading = true;
            GptApis.addPresetPrompt(req).then(res => {
                ElMessage.success("添加成功")
                this.commitLoading = false;
                this.dismiss();
            }).catch(_ => {
                this.commitLoading = false;
            })
        },

        _editHandler() {
            let req = {
                id: this.promptForm.id,
                name: this.promptForm.name,
                code: this.promptForm.code,
                prompt: this.promptForm.prompt,
            }
            this.commitLoading = true;
            GptApis.updatePresetPrompt(req).then(res => {
                ElMessage.success("更新成功")
                this.commitLoading = false;
                this.dismiss();
            }).catch(_ => {
                this.commitLoading = false;
            })
        }

    }
}
</script>

<style scoped>

</style>