
export const QxPasteBoard = {

    copy(text) {
        if (window.clipboardData) {
            window.clipboardData.setData("Text", text);
        } else {
            (function () {
                document.oncopy = function (e) {
                    e.clipboardData.setData('text', text);
                    e.preventDefault();
                    document.oncopy = null;
                }
            })(text);
            document.execCommand('Copy');
        }
    }
}