<template>
    <el-container>
        <el-header class="my-page-header">
            <el-row>
                <common-page-title-label title="配音会员"/>
                <el-input v-model="searchUserId" style="width: 180px;" placeholder="输入用户ID"/>
                <el-button type="primary" class="ml-16" @click="_onSearchClick">搜索</el-button>
                <el-button type="primary" @click="_onSearchClearClick" style="margin-right: 20px;">清空</el-button>
                <el-radio-group v-model="obtainType" @change="_onObtainTypeChange" >
                    <el-radio-button label="-1">全部</el-radio-button>
                    <el-radio-button label="0">自主购买</el-radio-button>
                    <el-radio-button label="1">新人奖励</el-radio-button>
                    <el-radio-button label="2">平台赠送</el-radio-button>
                </el-radio-group>
            </el-row>
        </el-header>
        <el-main style="width: 100%;height: 100%;" >
            <el-table :data="memberList" border v-loading="this.loading">
                <el-table-column label="序号" prop="id" width="60" align="center"/>
                <el-table-column label="用户ID" prop="userId" width="170" align="center"/>
                <el-table-column label="购买时间" prop="payTime" width="160" align="center"/>
                <el-table-column label="注册时间" prop="registerTime" width="160" align="center"/>
                <el-table-column label="获得方式" prop="obtainName" width="100" align="center"/>
                <el-table-column label="商品名称" prop="productName" width="120" align="center"/>
                <el-table-column label="开始时间" prop="startTime" width="160" align="center"/>
                <el-table-column label="结束时间" prop="endTime" width="160" align="center"/>
                <el-table-column label="操作">
                    <template #default="scope">
                        <el-button size="small" type="success" @click="_onAddReward(scope.$index, scope.row)">
                            赠送会员
                        </el-button>
                    </template>
                </el-table-column>
            </el-table>
        </el-main>
        <el-footer>
            <el-pagination
                :page-size="pageSize"
                :page-count="totalPage"
                :current-page="page"
                layout="prev, pager, next"
                @current-change="_onPageChange"/>
        </el-footer>

        <tts-vip-reward-dialog ref="rewardDialog" v-model:visible="rewardDialogVisible" @success="_onRewardSuccess"/>
    </el-container>
</template>

<script>
import CommonPageTitleLabel from "@/views/common/CommonPageTitleLabel";
import {TtsVipApis} from "@/api/tts-vip-apis";
import TtsVipRewardDialog from "@/views/vip/TtsVipRewardDialog";
import {ToastUtil} from "@/utils/toast-util";
export default {
    name: "TtsVipMemberPage",
    components: {TtsVipRewardDialog, CommonPageTitleLabel},

    data() {
        return {
            searchUserId: "",
            obtainType: -1,
            memberItem: {
                id: 0,
                userId: 0,
                username: "",
                vipLevel: 0,
                startTime: "",
                endTime: "",
                obtainName: "",
                productName: "",
                registerTime: "",
                payTime: "",
            },
            loading: false,
            memberList:[],
            page: 1,
            pageSize: 20,
            totalPage: 1,
            rewardDialogVisible: false,
        }
    },

    mounted() {
        this._loadMemberList();
    },

    methods: {

        _onSearchClick() {
            if (this.searchUserId) {
                this.obtainType = -1;
                this._loadMemberList();
            } else {
                ToastUtil.toast("请输入用户id")
            }
        },

        _onSearchClearClick() {
            this.searchUserId = null;
            this.obtainType = -1;
            this._loadMemberList();
        },

        _onPageChange(newPage) {
            this.page = newPage
            this._loadMemberList();
        },

        _onObtainTypeChange(newValue) {
            this.obtainType = newValue;
            this._loadMemberList();
        },

        /**
         * 赠送会员
         * @param index
         * @param itemData
         * @private
         */
        _onAddReward(index, itemData) {
            this.$refs.rewardDialog.setup(itemData.userId, itemData.username);
            this.rewardDialogVisible = true;
        },

        _onRewardSuccess() {
            this._loadMemberList();
        },

        _loadMemberList() {
            this.loading = true;
            let obtainValue = this.obtainType >= 0 ? this.obtainType : null;

            TtsVipApis.searchVipList(this.page, this.pageSize, this.searchUserId, obtainValue).then(res => {
                this.loading = false;
                let pageData = res.data;
                this.totalPage = pageData.total;
                this.memberList = pageData.list;
            }).catch(_ => {
                this.loading = false
            })
        },
    }
}
</script>

<style scoped>

</style>