<template>
    <el-dialog v-model="internalVisible" width="375px" title="添加Banner">
        <el-form v-model="bannerForm" label-width="90px">
            <el-form-item label="Client" >
                <el-select v-model="bannerForm.clientId">
                    <el-option v-for="(item,index) in clientList"
                               :key="index"
                               :label="item.name"
                               :value="item.id"/>
                </el-select>
            </el-form-item>
            <el-form-item label="描述">
                <el-input v-model="bannerForm.comment" placeholder="输入描述简介"/>
            </el-form-item>
            <el-form-item label="链接">
                <el-input v-model="bannerForm.link" placeholder="输入跳转链接，可为空"/>
            </el-form-item>
            <el-form-item label="链接类型">
                <el-select v-model="bannerForm.linkType">
                    <el-option label="h5" :value="1"/>
                    <el-option label="local" :value="2"/>
                </el-select>
            </el-form-item>
            <el-form-item label="排序">
                <el-input-number v-model="bannerForm.sort" step="1"/>
            </el-form-item>
        </el-form>

        <template #footer>
            <el-row>
                <el-button @click.stop="dismiss">取消</el-button>
                <el-button type="primary" @click.stop="_onCommitClick" v-loading="apiLoading" >确定</el-button>
            </el-row>
        </template>
    </el-dialog>
</template>

<script>
import BasicDialog from "@/views/base/BasicDialog";
import {BannerApis} from "@/api/banner-apis";
import {ToastUtil} from "@/utils/toast-util";

export default {
    name: "BannerAddDialog",
    mixins: [BasicDialog],
    props:{
        editing: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            clientList: [
                {
                    id: 1,
                    name: "web"
                },
                {
                    id: 2,
                    name: "app"
                }
            ],
            bannerForm: {
                id: null,
                clientId: 1,
                comment: null,
                link: null,
                linkType: 1,
                sort: 99,
            },
            apiLoading: false
        }
    },
    methods: {
        init(data) {
            this.bannerForm.id = data.id;
            this.bannerForm.clientId = data.clientId;
            this.bannerForm.comment = data.comment;
            this.bannerForm.link = data.link;
            this.bannerForm.linkType = data.linkType;
            this.bannerForm.sort = data.sort;
        },
        _onCommitClick() {
            if (this.editing) {
                this._editBanner();
            } else {
                this._addBanner();
            }
        },

        _addBanner() {
            let req = {
                clientId: this.bannerForm.clientId,
                comment: this.bannerForm.comment,
                link: this.bannerForm.link,
                linkType: this.bannerForm.linkType,
                sort: this.bannerForm.sort
            }
            this.apiLoading = true;
            BannerApis.addBanner(req).then(res => {
                this.apiLoading = false;
                ToastUtil.toast("添加成功");
                this.dismiss();
            })
        },

        _editBanner() {
            let req = {
                id: this.bannerForm.id,
                clientId: this.bannerForm.clientId,
                comment: this.bannerForm.comment,
                link: this.bannerForm.link,
                linkType: this.bannerForm.linkType,
                sort: this.bannerForm.sort
            }
            this.apiLoading = true;
            BannerApis.updateBanner(req).then(res => {
                this.apiLoading = false;
                ToastUtil.toast("修改成功");
                this.dismiss();
            })
        }
    }
}
</script>

<style scoped>

</style>