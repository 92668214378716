<template :key="componentKey">
    <div>
        <el-container>
            <el-header>
                <el-page-header :content="pageTitle" @back="onBack"/>
            </el-header>

            <el-main>
                <el-form ref="anchorElForm"
                         :model="anchorForm"
                         :rules="anchorRules"
                         label-width="100px"
                         style="width: 50%; margin-left: 20px">

                    <el-row>
                        <el-form-item label="声音来源:" style="width: 240px" prop="voiceSource">
                            <el-select v-model="anchorForm.voiceSource" placeholder="选择声音来源"
                                       @change="onVoiceSourceChange">
                                <el-option v-for="item in voiceSourceList" :key="item.value" :label="item.label"
                                           :value="item.value"/>
                            </el-select>
                        </el-form-item>

                        <el-form-item label="主播声音:" prop="voiceId">
                            <el-select v-model="selectedVoice" placeholder="请选择发音人" value-key="id"
                                       @change="onVoiceSelectionChanged">
                                <!-- 当绑定值为对象是，必须使用value-key来指定对象中的哪个值，例如需要显示item.voice，则value-key=voice -->
                                <el-option v-for="item in voiceOptionList" :label="item.name" :key="item.id"
                                           :value="item"/>
                            </el-select>
                        </el-form-item>

                        <!-- 搜索 -->
                        <el-autocomplete style="margin-left: 10px" v-model="adviceContent"
                                         :fetch-suggestions="queryVoice"
                                         :trigger-on-focus="false"
                                         clearable
                                         placeholder="筛选发音人名称" @select="handleSelectedAdvice"/>
                    </el-row>

                    <el-form-item label="主播名称:" prop="name">
                        <el-input v-model="anchorForm.name" placeholder="主播名称" autocomplete="off"/>
                    </el-form-item>

                    <el-form-item label="主播别名:" prop="alias">
                        <el-input v-model="anchorForm.alias" placeholder="例如: ruoxi" autocomplete="off"/>
                    </el-form-item>

                    <el-form-item label="主播简介:" prop="description">
                        <el-input v-model="anchorForm.description" placeholder="主播简介" autocomplete="off"/>
                    </el-form-item>

                    <el-form-item label="主播头像:" prop="avatarId">
                        <div class="avatar-uploader" @click="_onAvatarClick">
                            <!--suppress HtmlUnknownTarget -->
                            <img v-if="selectedAvatarData" :src="selectedAvatarData.path" class="avatar-img" alt="头像">
                            <el-icon v-else class="plus-icon">
                                <plus/>
                            </el-icon>
                        </div>
                    </el-form-item>

                    <el-form-item label="AI等级:" prop="aiLevel">
                        <el-select v-model="anchorForm.aiLevel" placeholder="选择AI等级">
                            <el-option v-for="item in aiLevelOptions" :label="item.label" :key="item.value"
                                       :value="item.value"/>
                        </el-select>
                    </el-form-item>

                    <el-form-item label="支持语言:" prop="languages">
                        <el-checkbox-group v-model="anchorForm.languages">
                            <el-checkbox v-for="item in languageList" :key="item.id" :label="item.id">
                                {{ item.name }}
                            </el-checkbox>
                        </el-checkbox-group>
                    </el-form-item>

                    <el-form-item label="场景领域:" prop="categories">
                        <el-checkbox-group v-model="anchorForm.categories">
                            <el-checkbox v-for="item in categoryList" :key="item.id" :label="item.id">
                                {{ item.name }}
                            </el-checkbox>
                        </el-checkbox-group>
                    </el-form-item>

                    <el-form-item label="采样率" prop="sampleRate">
                        <el-select v-model="anchorForm.sampleRate" placeholder="选择采样率">
                            <el-option v-for="item in sampleRateOptions"
                                       :key="item.value" :value="item.value"
                                       :label="item.label"/>
                        </el-select>
                    </el-form-item>

                    <el-form-item label="语调:">
                        <el-slider class="slider" v-model="anchorForm.pitchRate" :min="-500" :max="500" show-input/>
                    </el-form-item>

                    <el-form-item label="语速:">
                        <el-slider class="slider" v-model="anchorForm.speechRate" :min="0.5" :max="2.0" :step="0.01"
                                   :marks="speedLevelMarks" show-input/>
                    </el-form-item>

                    <el-form-item label="音量:">
                        <el-slider class="slider" v-model="anchorForm.volume" :min="0" :max="100" show-input/>
                    </el-form-item>

                    <el-form-item label="模版文案：" prop="content">
                        <el-input ref="contentInput"
                                  v-model="anchorForm.content"
                                  :rows="7"
                                  type="textarea"
                                  placeholder="请输入试听内容"
                                  @blur="onInputBlur"/>
                    </el-form-item>

                    <el-form-item>
                        <el-button round type="primary" @click="onInsertHalt">插入停顿</el-button>
                        <el-button round type="primary" @click="onInsertPhoneme">指定发音</el-button>
                    </el-form-item>

                    <el-form-item label="点击试听：">
                        <audition-player ref="auditionPlayer" :audio-data="auditionData" @play="onAudition"/>
                    </el-form-item>

                </el-form>

                <div style="width: 50%;text-align: right">
                    <span>
                        <el-button type="primary" round @click="onAddClick">添加</el-button>
                        <el-button round>取消</el-button>
                    </span>
                </div>

                <!-- 头像选择框 -->
                <avatar-picker-dialog :visible="avatarPickerVisible"
                                      @dismiss="avatarPickerVisible = false"
                                      @onResult="onAvatarPick"/>

                <!-- 插入停顿对话框 -->
                <halt-picker-dialog :dialog-visible="haltPickerVisible"
                                    @dismiss="haltPickerVisible = false"
                                    @result="onHaltResult"/>

                <!-- 指定发音对话框 -->
                <phoneme-input-dialog :visible="phonemeDialogVisible"
                                      @dismiss="phonemeDialogVisible = false"
                                      @result="onPhonemeResult"/>
            </el-main>
        </el-container>
    </div>
</template>

<!-- 添加主播 -->
<script>
import voiceOptions from "@/views/dubbing/VoiceConstants";
import {ElMessage, ElMessageBox} from "element-plus";
import PhonemeInputDialog from "@/views/dubbing/components/PhonemeInputDialog";
import HaltPickerDialog from "@/views/dubbing/components/HaltPickerDialog";
import {Loading, Plus, VideoPause, VideoPlay} from "@element-plus/icons-vue";
import AvatarPickerDialog from "@/views/dubbing/anchor/AvatarPickerDialog";
import {getAnchorCategoryList} from "@/api/anchor";
import {DubbingApis} from "@/api/dubbing";
import AuditionPlayer from "@/views/dubbing/anchor/AuditionPlayer";
import StringUtils from "@/utils/stringUtils";
import bus from "@/utils/bus";
import {SsmlUtils} from "@/utils/SsmlUtils";

export default {
    name: "AnchorAddPage",
    components: {
        AuditionPlayer,
        PhonemeInputDialog,
        HaltPickerDialog,
        AvatarPickerDialog,
        Plus, VideoPlay, VideoPause, Loading,
    },
    data() {
        return {
            isEditing: false, // true表示编辑主播，false表示添加主播
            editingData: null, // 正在编辑的主播信息
            pageTitle: "添加主播",
            componentKey: 0,
            aiLevelOptions: voiceOptions.aiLevelOptions, // AI等级
            voiceSourceList: voiceOptions.voiceSourceList, // 声音来源
            sampleRateOptions: voiceOptions.sampleRateOptions, // 采样率
            categoryList: [], // 主播分类列表
            languageList: [], // 语种列表
            // voiceStyleList: [], // 主播风格列表
            speedLevelMarks: {0.5: '0.5x', 1: '1x', 1.5: '1.5x', 2.0: '2x'},  // 语速marks

            selectedVoiceSource: 1, // 当前选择的声音来源: 1==阿里云  2==微软
            aliyunVoiceList: [],    // 阿里云发音人列表
            microsoftVoiceList: [], // 微软发音人列表
            selectedVoice: null, // 当前选择的发音人

            avatarPickerVisible: false, // 选择头像对话框可见性
            selectedAvatarData: null,

            haltPickerVisible: false, // 插入停顿对话框可见性
            phonemeDialogVisible: false, // 指定发音对话框可见性
            inputSelectionStart: null, // 文案输入框当前选中范围 开始位置
            inputSelectionEnd: null, // 文案输入框当前选中范围 结束位置

            auditionData: null,
            lastAuditionData: '', // 记录当前试听请求参数
            adviceContent: '', // 自动搜索发音人内容
            anchorForm: {
                name: '', // 主播名称
                alias: '', // 主播别名
                voiceSource: 1, // 声音来源
                voiceId: '', // 声音id
                avatarId: '', // 头像id
                description: null, // 简介
                aiLevel: 1, // AI等级
                speechRate: 1.0, // 语速
                pitchRate: 0, // 语调
                volume: 100, // 音量
                sampleRate: 16000, // 采样率
                categories: [], // 已选择的分类id数组
                languages: [], // 已选择的语种id数组
                content: null,
                styleId: 1, // 添加主播时，为默认模版，即默认风格
            },
            anchorRules: {
                name: [
                    {required: true, message: "主播名称不能为空", trigger: 'blur'},
                    {pattern: /^[a-zA-Z0-9\u4e00-\u9fa5_]{1,20}$/, message: "主播名称只支持汉字、大小写字母、数字、下划线", trigger: 'blur'}
                ],
                alias: [
                    {required: true, message: '别名不能为空', trigger: 'blur'},
                    {pattern: /^[a-zA-Z0-9-]{1,50}$/, message: '别名仅支持大小写字母、数字、连接符-组合', trigger: 'blur'}
                ],
                description: [
                    {required: true, message: "主播简介不能为空", trigger: 'blur'}
                ],
                voiceSource: [
                    {required: true, message: "声音来源不能为空", trigger: 'blur'}
                ],
                voiceId: [
                    {required: true, message: "发音人不能为空", trigger: 'blur'}
                ],
                avatarId: [
                    {required: true, message: "头像不能为空", trigger: 'blur'}
                ],
                categories: [
                    {type: 'array', required: true, message: '至少选择一个分类', trigger: 'change'}
                ],
                languages: [
                    {type: 'array', required: true, message: '至少选择一个语种', trigger: 'change'}
                ],
                content: [
                    {required: true, message: "发音人不能为空", trigger: 'blur'}
                ]
            }
        }
    },
    computed: {
        voiceOptionList() {
            if (this.selectedVoiceSource === 2) {
                return this.microsoftVoiceList
            } else {
                return this.aliyunVoiceList
            }
        },
    },
    created() {
        // 获取页面传参
        let data = this.$route.params.data
        if (data) {
            console.log(data)
            this.isEditing = true;
            this.editingData = data;
            this.pageTitle = "编辑主播";
        } else {
            this.isEditing = false;
            this.editingData = null;
            this.pageTitle = "添加主播";
        }
    },
    mounted() {
        this.getVoiceOptionListIfNeed();
        this.getCategoryListIfNeed();
        this.getLanguageListIfNeed();
    },
    methods: {

        onBack() {
            this.$router.back();
        },

        reload() {
            this.componentKey += 1
        },

        reset() {
            this.$refs.anchorElForm.resetFields()
            this.$refs.anchorElForm.clearValidate()

            this.selectedAvatarData = null
            this.selectedVoice = null

            this.auditionData = null
            this.lastAuditionData = null
        },

        // ===========================================
        //      选择发音人
        // ===========================================

        onVoiceSourceChange(source) {
            this.selectedVoiceSource = source;
            this.getVoiceOptionListIfNeed();
            // console.log("now source: " + this.selectedVoiceSource)
        },

        getVoiceOptionListIfNeed() {
            if (this.voiceOptionList == null || this.voiceOptionList.length === 0) {
                DubbingApis.getVoiceOptionList(this.selectedVoiceSource).then(res => {
                    if (res.code === 200) {
                        // this.voiceOptionList = res.data;
                        if (this.selectedVoiceSource === 2) {
                            this.microsoftVoiceList = res.data;
                        } else {
                            this.aliyunVoiceList = res.data;
                        }
                    } else {
                        ElMessage.error("获取发音人列表失败: " + res.msg);
                    }
                }).catch(error => {
                    ElMessage.error(error.toString());
                })
            }
        },

        /**
         * 发音人选择回调
         * @param voiceData 发音人itemData
         */
        onVoiceSelectionChanged(voiceData) {
            // 根据最大采样率，生成选择列表
            this.sampleRateOptions = voiceOptions.makeSampleRateOptions(voiceData.sampleRate);
            this.anchorForm.voiceId = voiceData.id;
            this.anchorForm.sampleRate = voiceData.sampleRate;
            this.anchorForm.alias = voiceData.voice;
        },

        queryVoice(text, callback) {
            if (!text || !this.voiceOptionList) {
                return []
            }
            const adviceList = [];
            this.voiceOptionList.filter((voiceItem, index) =>{
                let bingo = StringUtils.contains(voiceItem.name, text);
                if (bingo) {
                    let adviceItem = {
                        index: index,
                        value: voiceItem.name
                    }
                    adviceList.push(adviceItem);
                }
                return bingo
            })
            callback(adviceList);
        },

        handleSelectedAdvice(adviceItem) {
            this.adviceContent = '';
            this.selectedVoice = this.voiceOptionList[adviceItem.index];
            this.onVoiceSelectionChanged(this.selectedVoice);
        },

        // ===========================================
        //      选择头像
        // ===========================================

        _onAvatarClick() {
            this.avatarPickerVisible = true;
        },

        onAvatarPick(data) {
            // console.log('onAvatarPick: ' + JSON.stringify(data));
            this.selectedAvatarData = data;
            this.anchorForm.avatarId = data.id
        },

        // ===========================================
        //      选择场景/领域标签
        // ===========================================

        /// 获取主播分类列表
        getCategoryListIfNeed() {
            let self = this;
            getAnchorCategoryList().then(res => {
                if (res.code === 200) {
                    self.categoryList = res.data;
                } else {
                    ElMessage.error("无法获取分类列表：" + res.msg);
                }
            }).catch(error => {
                ElMessage.error(error.toString())
            })
        },

        getLanguageListIfNeed() {
            if (this.languageList.length > 0) {
                return
            }
            DubbingApis.getLanguageList().then(res => {

                if (res.code === 200) {
                    this.languageList = res.data;
                } else {
                    console.log("无法获取语种列表：" + res.message)
                }

            }).catch(error => {
                console.log("无法获取语种列表: " + error.toString())
            })
        },

        // ===========================================
        //      插入停顿 指定发音
        // ===========================================

        onInputBlur(event) {
            let input = event.srcElement;
            this.inputSelectionStart = input.selectionStart;
            this.inputSelectionEnd = input.selectionEnd;
        },

        onInsertHalt() {
            this.haltPickerVisible = true;
        },

        onHaltResult(halt) {
            const startPos = this.inputSelectionStart;
            const endPos = this.inputSelectionEnd;
            console.log("selection: start = " + startPos + ", end = " + endPos);
            if (startPos === null || endPos === undefined || startPos !== endPos) {
                ElMessage.warning("请将光标移动至待插入位置")
                return
            }
            let content = this.anchorForm.content;
            let haltTag = SsmlUtils.makeBreakTag(halt);
            this.anchorForm.content = content.slice(0, startPos) + haltTag + content.slice(endPos);
        },

        onInsertPhoneme() {
            const startPos = this.inputSelectionStart;
            const endPos = this.inputSelectionEnd;
            if (startPos === null || endPos === undefined || (endPos - startPos) !== 1) {
                // ElMessage.waring("请选择")
                alert("请选择一个需要设置读音的中文汉字")
                return
            }

            let content = this.anchorForm.content;
            let selectedWord = content.slice(startPos, endPos)
            bus.emit('selectedWord', selectedWord)
            this.phonemeDialogVisible = true;
        },

        onPhonemeResult(phoneme) {
            const startPos = this.inputSelectionStart;
            const endPos = this.inputSelectionEnd;
            console.log("selection: start = " + startPos + ", end = " + endPos);
            if (startPos === null || endPos === undefined) {
                ElMessage.warning("请将光标移动至待插入位置")
                return
            }
            let content = this.anchorForm.content;
            let selectedWord = content.slice(startPos, endPos);
            let phonemeTag = SsmlUtils.makePhonemeTag(selectedWord, phoneme);
            this.anchorForm.content = content.slice(0, startPos) + phonemeTag + content.slice(endPos);
        },


        // ===========================================
        //      试听
        // ===========================================

        onAudition(callback) {
            callback(true)
            let ap = this.$refs['auditionPlayer']
            if (ap.isPlaying) {
                ap.stopPlay()
                return
            }
            let voiceId = this.anchorForm.voiceId;
            if (StringUtils.isBlank(voiceId)) {
                ElMessage.error("请选择一个发音人");
                return
            }

            let content = this.anchorForm.content;
            if (StringUtils.isBlank(content)) {
                ElMessage.error("请输入试听文案");
                return;
            }

            let auditionParam = {
                voiceId: this.anchorForm.voiceId,
                styleId: this.anchorForm.styleId,
                pitchRate: this.anchorForm.pitchRate,
                speechRate: this.anchorForm.speechRate,
                volume: this.anchorForm.volume,
                content: this.anchorForm.content,
                sampleRate: this.anchorForm.sampleRate
            }

            let currentAuditionData = JSON.stringify(auditionParam);
            // 如果试听参数与上次相同，且当前试听链接不为空，则直接播放; 减少向服务器请求次数
            if (this.auditionData !== null && this.lastAuditionData === currentAuditionData) {
                ap.startPlay();
                return
            }

            ap.showLoading();
            DubbingApis.requestAudition(auditionParam).then(res => {
                let model = res.data;
                if (model.status === 200) {
                    this.auditionData = model.audioData;
                    this.lastAuditionData = currentAuditionData;
                    this.delayToStartPlay();
                } else {
                    ap.hideLoading();
                    ElMessage.error("试听失败: " + res.message);
                }
            }).catch(error => {
                ElMessage.error("试听失败: " + error.toString());
                ap.hideLoading();
            })
        },

        delayToStartPlay() {
            setTimeout(() => {
                this.$refs.auditionPlayer.startPlay()
            }, 1)
        },

        // ===========================================
        //      添加主播
        // ===========================================

        onAddClick() {
            this.$refs.anchorElForm.validate(valid => {
                if (valid) {
                    // 添加主播
                    this.requestAddAnchor();
                }
            })
        },

        requestAddAnchor() {
            let req = {
                name: this.anchorForm.name,
                alias: this.anchorForm.alias,
                description: this.anchorForm.description,
                avatarId: this.anchorForm.avatarId,
                voiceId: this.anchorForm.voiceId,
                aiLevel: this.anchorForm.aiLevel,
                categoryIds: this.anchorForm.categories,
                languageIds: this.anchorForm.languages,
                volume: this.anchorForm.volume,
                speechRate: this.anchorForm.speechRate,
                pitchRate: this.anchorForm.pitchRate,
                content: this.anchorForm.content
            }
            DubbingApis.addAnchor(req).then(res => {
                if (res.code === 200) {
                    console.log("添加主播成功");
                    // ElMessage.success("添加主播成功");
                    ElMessageBox.confirm("添加主播成功", "提示", {
                        confirmButtonText: "继续添加",
                        cancelButtonText: "退出",
                        type: 'success'
                    }).then(() => {
                        // 继续添加
                        this.reset();
                    }).catch(() => {
                        // 退出
                        this.onBack();
                    })
                } else {
                    ElMessage.error("添加主播失败: " + res.message);
                }
            }).catch(error => {
                ElMessage.error("添加主播失败: " + error.toString());
            })
        },
    }
}
</script>

<style lang="scss">

.avatar-uploader {
    width: 90px;
    border: 1px dashed #d9d9d9;
    border-radius: 10px;
    cursor: pointer;
    overflow: hidden;
    line-height: 0;
}

.avatar-uploader:hover {
    border-color: #409eff;
}

.plus-icon {
    font-size: 20px;
    color: #8c939d;
    width: 90px;
    height: 90px;
    text-align: center;
}

.plus-icon svg {
    margin-top: 34px; /* (90px - 20px) / 2 - 1px */
}

.avatar-img {
    width: 90px;
    height: 90px;
    display: block;
}

.slider {
    padding-left: 15px;
    padding-right: 15px;
}

.el-slider {
    --el-slider-height: 4px;
    --el-slider-button-size: 12px;

    // progress
    .el-slider__bar {
        //background-image: linear-gradient(90deg, $systemRed, $systemBlue);
    }

    // track
    .el-slider__runway {
        //background-color: #8c939d; // 修改slider背景颜色
    }

    // el-slier 设置mark时，在track上的标记原点
    .el-slider__stop {
        display: none; // 隐藏小圆点，不显示
    }

    .el-slider__marks-text {
        white-space: nowrap;
        margin-top: 0;
    }
}

.audioIcon {
    width: 30px;
    height: 30px;
}

</style>