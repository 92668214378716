<template>
    <el-dialog v-model="internalVisible" title="解析结果" width="500px">
        <!--<div class="content-wrapper">{{ this.mediaJson }}</div>-->
        <div v-if="mediaType === 1" class="video-content">
            <div class="result-cell">
                <span class="title">标题</span>
                <span class="value">{{this.videoData.title}}</span>
            </div>
            <div class="result-cell">
                <span class="title">作者</span>
                <span class="value">{{videoData.author}}</span>
            </div>
            <div class="result-cell">
                <span class="title">尺寸</span>
                <span class="value">{{_videoRatio()}}</span>
            </div>
            <div class="result-cell">
                <span class="title">链接</span>
                <span class="value">{{videoData.url}}</span>
                <el-button class="copy-btn" @click.stop="_onCopyVideoUrl(videoData.url)">复制</el-button>
            </div>
            <video class="video-player" width="460" height="282" controls>
                <source :src="videoData.url" type="video/mp4"/>
            </video>
        </div>
        <div v-if="mediaType === 2" class="image-content">
            <div v-if="imageData && imageData.urls" style="width: 100%;display: flex;flex-wrap: wrap">
                <div class="image-item" v-for="(item, index) in imageData.urls" :key="index">
                    <img :src="item" referrerpolicy="no-referrer"/>
                </div>
            </div>
            <div v-else>
                <div class="content-wrapper">{{ this.mediaJson }}</div>
            </div>
        </div>
        <template #footer="">
            <el-button type="primary" @click="dismiss">确 定</el-button>
        </template>
    </el-dialog>
</template>

<script>
import BasicDialog from "@/views/base/BasicDialog";
import {QxPasteBoard} from "@/utils/QxPasteBoard";

export default {
    name: "RwmMediaResultDialog",
    mixins: [BasicDialog],
    data() {
        return {
            mediaJson: "",
            mediaObj: null,
            mediaType: 1,
            videoData: null,
            imageData: null
        }
    },
    methods: {
        _videoRatio() {
            if (this.videoData && this.videoData.videoWidth && this.videoData.videoHeight) {
                return this.videoData.videoWidth + "x" + this.videoData.videoHeight
            }
            return "unknown"
        },

        onDismiss() {
            this.mediaJson = ""
        },
        setupContent(text) {
            this.mediaJson = text;
            if (text) {
                this.mediaObj = JSON.parse(text);
                this.mediaType = this.mediaObj.mediaType;
                this.videoData = this.mediaObj.videoData;
                this.imageData = this.mediaObj.imageData;
            }
        },

        _onCopyVideoUrl(url) {
            QxPasteBoard.copy(url);
        }
    }
}
</script>

<style lang="scss" scoped>

:deep(el-dialog_body) {
    padding: 0;
}

.content-wrapper {
//width: 100%;
    min-height: 100px;
    max-height: 400px;
    background-color: #f5f5f5;
    color: #333333;
    padding: 8px;
    overflow-y: auto;
}

.video-content {
    width: 100%;
    flex: 1;
    display: flex;
    flex-direction: column;

    .result-cell {
        width: 100%;
        max-width: 610px;
        display: flex;
        align-items: center;
        background-color: #eeeeee;
        border-radius: 4px;
        padding: 8px;
        box-sizing: border-box;
        margin-bottom: 4px;
        font-size: 14px;

        .title {
            background-color: white;
            border-radius: 8px;
            padding: 3px 7px;
            box-sizing: border-box;
            flex-shrink: 0;
        }

        .value {
            flex: 1;
            margin-left: 8px;
            text-align: left;
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow-x: hidden;
        }

        .copy-btn {
            width: 24px;
            height: 24px;
            margin-left: 8px;
        }
    }

    .video-player {
        margin-top: 8px;
        background-color: black;
    }

}

.image-content {
    width: 100%;

    .image-item {
        width: 80px;
        height: 120px;
        margin-left: 10px;
        margin-bottom: 10px;

        img {
            width: 80px;
            height: 120px;
            object-fit: contain;
        }
    }
}

.dialog-footer {
    height: 44px;
    display: flex;
    align-items: center;
    justify-content: right;
//padding: 10px 10px;
}

</style>