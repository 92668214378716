<template>
  <el-drawer v-model="internalVisible" title="Style Editor" size="375px">
    <div class="drawer-main">
      <div class="drawer-content">
        <div class="content-inner">
          <el-form ref="styleForm" :model="styleForm" :rules="rules" label-width="72px">
            <el-form-item v-if="editing" lang="ID">
              <el-input v-model="styleForm.id" disabled/>
            </el-form-item>
            <el-form-item label="风格名称">
              <el-input v-model="styleForm.name" placeholder="输入风格中文名称"/>
            </el-form-item>
            <el-form-item label="风格取值">
              <el-input v-model="styleForm.value" placeholder="输入风格取值，例如auto"/>
            </el-form-item>
            <el-form-item label="训练模型">
              <el-input v-model="styleForm.trainModel" placeholder="输入训练模型，默认为wanxiang-v1"/>
            </el-form-item>
          </el-form>
        </div>
      </div>
      <div class="drawer-footer">
        <el-button class="action-btn" round @click="dismiss">取消</el-button>
        <el-button class="action-btn ml-20" type="primary" round @click="onCommitClick">{{ commitTitle }}</el-button>
      </div>
    </div>
  </el-drawer>
</template>

<script>
import BasicDialog from "@/views/base/BasicDialog";
import {DrawsApi} from "@/api/draws-api";

export default {
  name: "DrawStyleEditDrawer",
  mixins: [BasicDialog],
  data() {
    return {
      editing: false,
      styleForm: {
        id: null,
        name: null,
        value: null,
        trainModel: 'wanxiang-v1'
      },
      rules: {
        name: [
          { required: true, message: '风格名称不能为空', trigger: 'blur' }
        ],
        value: [
          { required: true, message: '风格取值不能为空', trigger: 'blur' }
        ],
        trainModel: [
          { required: true, message: '训练模型不能为空', trigger: 'blur' }
        ]
      }
    }
  },
  computed: {
    commitTitle() {
      return this.editing ? '修改' : '添加'
    }
  },
  methods: {
    showEditing(data) {
      if (data) {
        this.editing = true;
        this.styleForm.id = data.id;
        this.styleForm.name = data.name;
        this.styleForm.value = data.value;
        this.styleForm.trainModel = data.trainModel;
      }
      this.internalVisible = true;
    },
    onDismiss() {
      this.editing = false;
      this.styleForm.id = null;
      this.styleForm.name = null;
      this.styleForm.value = null;
    },
    onCommitClick() {
      this.$refs.styleForm.validate((valid) => {
        if (valid) {
          this.editing ? this.doEditStyle() : this.doAddNewStyle()
        }
      })
    },
    doAddNewStyle() {
      const params = {
        name: this.styleForm.name,
        value: this.styleForm.value,
        trainModel: this.styleForm.trainModel
      }
      DrawsApi.addStyle(params).then(_ => {
        this.$message.success('添加成功');
        this.$emit('success');
        this.dismiss();
      })
    },
    doEditStyle() {
      const params = {
        id: this.styleForm.id,
        name: this.styleForm.name,
        value: this.styleForm.value,
        trainModel: this.styleForm.trainModel
      }
      DrawsApi.updateStyle(params).then(_ => {
        this.$message.success('修改成功');
        this.$emit('success');
        this.dismiss();
      })
    }
  }
}
</script>

<style lang="scss" scoped>

.drawer-main {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;

  .drawer-content {
    flex: 1;
    width: 100%;
    overflow: auto;
    background-color: #f5f5f5;

    .content-inner {
      width: 100%;
      display: flex;
      flex-direction: column;
      box-sizing: border-box;
      padding: 15px;
    }
  }

  .drawer-footer {
    width: 100%;
    height: 64px;
    display: flex;
    align-items: center;
    box-sizing: border-box;
    padding: 0 20px;

    .action-btn {
      flex: 1;
      height: 40px;
    }
  }
}

</style>