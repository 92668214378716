<template>
    <div>
        <el-table v-loading="tableLoading" border :data="tableData">
            <el-table-column label="ID" prop="id" width="50px" align="center"/>
            <el-table-column label="名称" prop="name" width="100px" align="center"/>
            <!--<el-table-column label="别名" prop="alias" width="160px" align="center"/>-->
            <el-table-column label="属性" prop="value" width="160px" align="center"/>
            <el-table-column label="头像" prop="imagePath" align="center" width="80">
                <template #default="scope">
                    <el-image v-if="scope.row.avatar" fit="fill" :src="scope.row.avatar" style="width: 48px;height: 48px"/>
                </template>
            </el-table-column>
            <el-table-column label="排序" prop="sort" width="80px" align="center"/>
            <el-table-column label="操作" align="left" fixed="right">
                <template #default="scope">
                    <el-button size="small" @click="_onSetAvatar(scope.$index, scope.row)">设置图标</el-button>
                    <el-button size="small" @click="_onEdit(scope.$index, scope.row)">编辑</el-button>
                    <!--<el-button size="small" type="success" @click="onAddClick">添加</el-button>-->
                    <el-button size="small" type="danger" @click="_onDelete(scope.$index, scope.row)">删除</el-button>
                </template>
            </el-table-column>
        </el-table>

        <common-add-button title="添加角色" @click="_onAddClick"/>

        <voice-role-add-dialog v-model:visible="addDialogVisible" @success="_getAll"/>

        <voice-role-avatar-edit-dialog ref="avatarDialog" v-model:visible="avatarDialogVisible" @success="_getAll"/>

    </div>
</template>

<script>
import CommonAddButton from "@/views/common/CommonAddButton";
import VoiceRoleAddDialog from "@/views/dubbing/role/VoiceRoleAddDialog";
import {DubbingRoleApis} from "@/api/dubbing-role-apis";
import VoiceRoleAvatarEditDialog from "@/views/dubbing/role/VoiceRoleAvatarEditDialog";

export default {
    name: "VoiceRoleManager",
    components: {VoiceRoleAvatarEditDialog, VoiceRoleAddDialog, CommonAddButton},
    data() {
        return {
            tableLoading: false,
            tableData:[],
            addDialogVisible: false,
            avatarDialogVisible: false,
        }
    },

    mounted() {
        this._getAll();
    },

    methods: {

        _getAll() {
            DubbingRoleApis.getAll().then(res => {
                this.tableData = res.data;
            })
        },

        _onAddClick() {
            this.addDialogVisible = true;
        },

        _onSetAvatar(index, itemData) {
            this.$refs.avatarDialog.setRoleId(itemData.id);
            this.avatarDialogVisible = true;
        },

        _onEdit(index, itemData) {

        },

        _onDelete(index, itemData) {

        }
    }
}
</script>

<style scoped>

</style>