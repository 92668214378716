import request from "@/api/request";

export const AnchorAvatarApis = {

    /**
     * 添加主播头像
     *
     * @param form params={multipart-File, sex}
     * @returns {*}
     */
    addAnchorAvatarV2(form) {
        return request({
            url: '/admin/dubbing/avatar/v2/add',
            method: 'post',
            data: form,
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        })
    },

    /**
     * 更新主播头像
     * @param form params={multipart-File, avatarId}
     * @returns {*}
     */
    updateAvatarV2(form) {
        return request({
            url: '/admin/dubbing/avatar/v2/update',
            method: 'post',
            data: form,
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        })
    },

    updateSex(form) {
        return request({
            url: '/admin/dubbing/avatar/v2/update_sex',
            method: 'post',
            data: form,
        })
    }

}