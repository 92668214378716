import {ElMessageBox} from "element-plus";
import StringUtils from "@/utils/stringUtils";


export const QxAlertDialog = {

    success(title = null, message, positiveTitle, positiveHandler, negativeTitle = null, negativeHandler = null) {
        let hasCancel = StringUtils.isNotBlank(negativeTitle);
        let config = {
            type: 'success',
            confirmButtonText: positiveTitle,
        }
        if (hasCancel) {
            config.showCancelButton = true;
            config.cancelButtonText = negativeTitle;
        } else {
            config.showCancelButton = false;
        }

        ElMessageBox.confirm(message, title, config
        ).then(() => {
            // 立即查看
            if (positiveHandler) {
                positiveHandler();
            }
        }).catch(() => {
            // 留下来
            if (negativeHandler) {
                negativeHandler();
            }
        })
    },

    error(title = null, message, positiveTitle, positiveHandler, negativeTitle = null, negativeHandler = null) {
        this.alert("error", title, message, positiveTitle, positiveHandler, negativeTitle, negativeHandler);
    },

    warn(title = null, message, positiveTitle, positiveHandler, negativeTitle = null, negativeHandler = null) {
        this.alert("warning", title, message, positiveTitle, positiveHandler, negativeTitle, negativeHandler);
    },

    alert(type, title = null, message, positiveTitle, positiveHandler, negativeTitle = null, negativeHandler = null) {
        let hasCancel = StringUtils.isNotBlank(negativeTitle);
        let config = {
            type: type,
            confirmButtonText: positiveTitle,
        }
        if (hasCancel) {
            config.showCancelButton = true;
            config.cancelButtonText = negativeTitle;
        } else {
            config.showCancelButton = false;
        }

        ElMessageBox.confirm(message, title, config
        ).then(() => {
            // 立即查看
            if (positiveHandler) {
                positiveHandler();
            }
        }).catch(() => {
            // 留下来
            if (negativeHandler) {
                negativeHandler();
            }
        })
    },

}