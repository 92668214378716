<template>
    <el-dialog v-model="internalVisible" width="400px" title="编辑">
        <el-form :model="this.editForm" label-width="120px" label-position="right">
            <el-form-item label="会员等级">
                <el-input-number v-model="editForm.vipLevel" disabled/>
            </el-form-item>
            <el-form-item label="Ai等级">
                <el-input-number v-model="editForm.aiLevel" disabled/>
            </el-form-item>
            <el-form-item label="每天试听字数">
                <el-input-number v-model="editForm.auditionWordsInDay" min="1000"/>
            </el-form-item>
            <el-form-item label="每月试听字数">
                <el-input-number v-model="editForm.auditionWordsInMonth" min="10000"/>
            </el-form-item>
            <el-form-item label="每天合成次数">
                <el-input-number v-model="editForm.taskCountInDay" min="1"/>
            </el-form-item>
            <el-form-item label="每月合成字数">
                <el-input-number v-model="editForm.taskWordsInMonth" min="10000"/>
            </el-form-item>
        </el-form>

        <template #footer>
            <span class="dialog-footer">
                <el-button @click="dismiss">取消</el-button>
                <el-button type="primary" @click="_onSubmit">提交</el-button>
            </span>
        </template>
    </el-dialog>
</template>

<script>
import BasicDialog from "@/views/base/BasicDialog";
import {TtsApis} from "@/api/tts-apis";
import {ElMessage} from "element-plus";

export default {
    name: "TtsVipLimitEditDialog",
    mixins:[BasicDialog],
    data() {
        return {
            editForm: {
                vipLevel: 0,
                aiLevel: 0,
                auditionWordsInDay: 0,
                auditionWordsInMonth: 0,
                taskCountInDay: 0,
                taskWordsInMonth: 0,
                taskWordsInOnce: 0,
            }
        }
    },

    methods: {
        setup(itemData) {
            this.editForm.vipLevel = itemData.vipLevel;
            this.editForm.aiLevel = itemData.aiLevel;
            this.editForm.auditionWordsInDay = itemData.auditionWordsInDay;
            this.editForm.auditionWordsInMonth = itemData.auditionWordsInMonth;
            this.editForm.taskCountInDay = itemData.taskCountInDay;
            this.editForm.taskWordsInMonth = itemData.taskWordsInMonth;
            this.editForm.taskWordsInOnce = itemData.taskWordsInOnce;
        },

        _onSubmit() {
            let req = {
                vipLevel: this.editForm.vipLevel,
                aiLevel: this.editForm.aiLevel,
                auditionWordsInDay: this.editForm.auditionWordsInDay,
                auditionWordsInMonth: this.editForm.auditionWordsInMonth,
                taskCountInDay: this.editForm.taskCountInDay,
                taskWordsInMonth: this.editForm.taskWordsInMonth,
                taskWordsInOnce: this.editForm.taskWordsInOnce
            }
            TtsApis.updateVipLimitCell(req).then(res => {
                ElMessage.success("修改成功");
                this.dismiss();
            })

        }
    }
}
</script>

<style scoped>

</style>