<template>
    <el-popover ref="popover" width="370" trigger="click" @show="_onShow">
        <div class="form-container">
            <el-form v-model="sortForm" label-width="70px">
                <el-form-item label="排序">
                    <el-input-number v-model="sortForm.sort" step="1"/>
                </el-form-item>
            </el-form>
            <div class="form-footer">
                <el-button style="margin-left: auto;" @click.stop="_dismiss">取消</el-button>
                <el-button type="primary" style="margin-left: 15px;" @click.stop="_onCommit">确定</el-button>
            </div>
        </div>
        <template #reference>
            <span class="ui-cursor-pointer ui-color-blue">{{this.sort}}</span>
        </template>
    </el-popover>
</template>

<script>
import {AnchorApis} from "@/api/anchor";
import {ToastUtil} from "@/utils/toast-util";

export default {
    name: "AnchorSampleSortPop",
    props:['id',"sort"],
    data() {
        return {
            sortForm: {
                sampleId: null,
                sort: 0,
            }
        }
    },

    methods:  {

        _onShow() {
            this.sortForm.sampleId = this.id;
            this.sortForm.sort = this.sort;
        },

        _dismiss() {
            this.$refs.popover.hide();
        },

        _onCommit() {
            let req = {
                id: this.sortForm.sampleId,
                sort: this.sortForm.sort
            }
            AnchorApis.updateSampleSort(req).then(res => {
                ToastUtil.toast("设置成功");
                this._dismiss();
            })
        }
    }
}
</script>

<style lang="scss" scoped>

.form-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    .form-footer {
        width: 100%;
        display: flex;
        align-items: center;
    }
}

</style>