<template>
    <div class="sound-manager-body">
        <el-container>

            <el-header class="my-page-header">
                <el-row>
                    <common-page-title-label title="音效管理"/>
                    <common-add-button title="添加音效" @click="_onAddSoundClick"/>
                </el-row>
            </el-header>

            <!-- 列表 -->
            <el-main width="90%">

                <el-row :gutter="20" >
                    <el-col :span="4" class="sound-col-item"
                            v-for="(item, index) in soundList"
                            :key="item.name"
                            @click="_onItemEditClick(item, index)">
                        <div class="sound-item-wrapper">
                            <div class="sound-item-top">
                                <qx-circle-player :audio-path="item.path" :size="36"/>
                                <span style="margin-left: 10px">{{ item.name }}</span>
                            </div>
                            <span class="sound-item-style">
                                {{ item.style.name }}
                            </span>
                        </div>
                    </el-col>
                </el-row>

            </el-main>

            <!-- 分页插件 -->
            <el-footer>
                <el-pagination
                    :page-size="pageSize"
                    :page-count="total"
                    :current-page="currentPage"
                    layout="prev, pager, next"
                    :hide-on-single-page="true"
                    @current-change="_onPageChange">

                </el-pagination>
            </el-footer>

        </el-container>

        <sound-edit-dialog v-model:visible="this.addDialogVisible"
                           ref="editDialog"
                           :add-mode="true"
                           :style-list="this.styleList"/>

        <sound-edit-dialog v-model:visible="this.editDialogVisible"
                           ref="editDialog" :style-list="this.styleList"/>
    </div>
</template>

<script>
import CommonPageTitleLabel from "@/views/common/CommonPageTitleLabel";
import CommonAddButton from "@/views/common/CommonAddButton";
import {DubbingApis} from "@/api/dubbing";
import QxCirclePlayer from "@/components/QxCirclePlayer";
import SoundEditDialog from "@/views/dubbing/sound/SoundEditDialog";

export default {
    name: "SoundManagerPage",
    components: {SoundEditDialog, QxCirclePlayer, CommonAddButton, CommonPageTitleLabel},
    data() {
        return {
            tableLoading: false,
            currentPage: 1,
            pageSize: 30,
            total: 1,
            soundList: [],
            styleList: [],
            addDialogVisible: false,
            editDialogVisible: false,
        }
    },

    mounted() {
        this._getSoundStyleList();
        this._searchSound();
    },

    methods: {

        _getSoundStyleList() {
            DubbingApis.getSoundStyleList().then(res => {
                this.styleList = res.data;
            })
        },

        _searchSound() {
            let req = {
                page: this.currentPage,
                pageSize: 30,
            }
            DubbingApis.searchSound(req).then(res => {
                let pageData = res.data;
                this.total = pageData.total;
                this.soundList = pageData.list;
            })
        },

        _onAddSoundClick() {
            this.addDialogVisible = true;
        },

        _onPageChange(newPage) {
            this.currentPage = newPage;
            this._searchSound();
        },

        _onItemEditClick(itemData) {
            this.$refs.editDialog.setup(itemData);
            this.editDialogVisible = true;
        }
    }
}
</script>

<style lang="scss" scoped>

.sound-col-item {
    margin-bottom: 10px;
}

.sound-item-wrapper {
    width: 100%;
    padding: 10px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    background-color: #f5f5f5;
    border-radius: 7px;

    .sound-item-top {
        display: flex;
        align-items: center;

        font-size: 13px;
    }

    .sound-item-style {
        margin-top: 10px;
        font-size: 13px;
    }
}

</style>