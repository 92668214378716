import {Apis} from "@/api/Apis";

export const ToolsApis = {

    // ===========================================
    //      Asr
    // ===========================================

    searchAsrTask(userId, page, pageSize) {
        let req = {
            userId: userId,
            page: page,
            pageSize: pageSize
        }
        return Apis.post("/admin/tools/asr/task/search", req)
    },

    getAsrTaskResultText(resultId) {
        let req = {
            resultId: resultId
        }
        return Apis.get("/admin/tools/asr/task/result/text", req)
    },

    // ===========================================
    //      Rwm
    // ===========================================

    searchAllRecords(page, pageSize) {
        let params = {
            page: page,
            pageSize: pageSize
        }
        return Apis.get("/admin/tools/rwm/searchAll", params)
    },

    searchUserRecords(userId, page, pageSize) {
        let params = {
            userId: userId,
            page: page,
            pageSize: pageSize
        }
        return Apis.get("/admin/tools/rwm/searchAll", params)
    },
}