import request from "@/api/request";

const awaitWrap = (promise) => {
    return promise
        .then(res => [null, res])
        .catch(error => [error, null])
};

export function uploadAvatarAwait(formData) {
    return awaitWrap(
        uploadAvatar(formData)
    )
}

/**
 *  获取直传oss的policy配置
 * @param filename
 * @param sex 头像性别
 * @returns {any}
 */
export function getOssPolicy(filename, sex) {
    return request({
        url: "/admin/dubbing/avatar/ossPolicy",
        method: 'get',
        params: {
            filename: filename,
            sex: sex
        }
    })
}

export function uploadAvatar(formData) {
    return request({
        url: '/admin/dubbing/avatar/add',
        method: 'post',
        data: formData,
        headers: {
            'Content-Type': 'multipart/form-data'
        }
    })
}

/**
 * 删除头像
 * @param path
 * @returns {*}
 */
export function deleteAvatar(path) {
    return request({
        url: '/admin/dubbing/avatar/delete',
        method: 'post',
        params: {
            path: path
        }
    })
}