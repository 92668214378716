<template>
    <el-dialog width="400px" v-model="internalVisible" title="套餐编辑">
        <el-form :model="editForm" label-width="80px">
            <!--<el-form-item label="套餐ID">-->
            <!--    <el-input disabled>{{productData.id}}</el-input>-->
            <!--</el-form-item>-->
            <el-form-item label="套餐Code">
                <el-input disabled v-model="editForm.code"></el-input>
            </el-form-item>
            <el-form-item label="会员名称">
                <el-input v-model="editForm.name"/>
            </el-form-item>
            <el-form-item label="会员标题">
                <el-input v-model="editForm.title"/>
            </el-form-item>
            <el-form-item label="会员等级">
                <el-select v-model="editForm.level">
                    <el-option label="高级会员" :value="2"></el-option>
                    <el-option label="年度会员" :value="3"></el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="会员时长">
                <el-input type="number" v-model="editForm.count">
                    <template #append>
                        <el-select v-model="editForm.unit" placeholder="Select" style="width: 90px">
                            <el-option label="天" value="d" />
                            <el-option label="月" value="m" />
                        </el-select>
                    </template>
                </el-input>
            </el-form-item>

            <el-form-item label="原价">
                <el-input type="number" v-model="editForm.originPrice"/>
            </el-form-item>

            <el-form-item label="现价">
                <el-input type="number" v-model="editForm.realPrice"/>
            </el-form-item>

            <el-form-item label="内购价">
                <el-input type="number" v-model="editForm.iapPrice"/>
            </el-form-item>

            <el-form-item label="促销活动">
                <el-input v-model="editForm.promotion"/>
            </el-form-item>

            <el-form-item label="排序">
                <el-input-number v-model="editForm.sort"/>
            </el-form-item>

        </el-form>

        <template #footer>
                <span class="dialog-footer">
                    <el-button @click="dismiss">取消</el-button>
                    <el-button type="primary" @click="_onSubmit">确定</el-button>
                </span>
        </template>
    </el-dialog>
</template>

<script>
import BasicDialog from "@/views/base/BasicDialog";
import {TtsVipApis} from "@/api/tts-vip-apis";
import {ToastUtil} from "@/utils/toast-util";

export default {
    name: "TtsVipProductEditDialog",
    mixins: [BasicDialog],
    emits: ['change'],
    data() {
        return {
            editForm: {
                id:null,
                code: null,
                level: 0,
                name: null,
                title: null,
                count: 1,
                unit: 'd',
                originPrice: 29,
                realPrice: 28,
                iapPrice: 28,
                promotion: null,
                sort: 1,
            }
        }
    },
    methods: {
        init(product) {
            this.editForm.id = product.id;
            this.editForm.level = product.vipLevel;
            this.editForm.code = product.code;
            this.editForm.name = product.name;
            this.editForm.title = product.title;
            this.editForm.count = product.count;
            this.editForm.unit = product.unit;
            this.editForm.originPrice = product.originPrice;
            this.editForm.realPrice = product.realPrice;
            this.editForm.iapPrice = product.iapPrice;
            this.editForm.promotion = product.promotion;
            this.editForm.sort = product.sort;
        },
        _onSubmit() {
            let req = {
                id: this.editForm.id,
                level: this.editForm.level,
                // code: this.editForm.code,
                name: this.editForm.name,
                title: this.editForm.title,
                count: this.editForm.count,
                unit: this.editForm.unit,
                originPrice: this.editForm.originPrice,
                realPrice: this.editForm.realPrice,
                iapPrice: this.editForm.iapPrice,
                promotion: this.editForm.promotion,
                sort: this.editForm.sort,
            }
            console.log("update product: ", req);
            TtsVipApis.updateProduct(req).then(res => {
                ToastUtil.toast("修改成功");
                this.$emit("change");
                this.dismiss()
            })
        }
    }
}
</script>

<style scoped>

</style>