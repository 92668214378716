<!--suppress HtmlUnknownTag -->
<template>
    <div>
        <el-container>
            <!-- Header: 添加主播，搜索主播 -->
            <el-header class="my-page-header">
                <el-row>
                    <common-page-title-label title="主播管理"/>
                    <common-add-button title="添加主播" @click="onAddAnchor"/>
                    <common-add-button title="同步头像地址" @click="_syncAnchorAvatarPath"/>
                    <common-add-button title="同步模版Source" @click="_syncTemplateSource"/>
                    <common-add-button title="同步模版情绪值" @click="_syncTemplateStyleValue"/>
                </el-row>
            </el-header>

            <el-main style="height: 100%">
                <!-- 筛选 -->
                <el-row>
                    <el-select v-model="filterForm.source" @change="_onSourceChange">
                        <el-option v-for="(item,index) in sourceOptions"
                                   :key="index"
                                   :label="item.label"
                                   :value="item.value" />
                    </el-select>
                    <el-select v-model="filterForm.aiLevel" @change="_onAiLevelChange">
                        <el-option v-for="(item,index) in levelOptions"
                                   :key="index"
                                   :label="item.label"
                                   :value="item.value" />
                    </el-select>
                    <el-select v-model="filterForm.sex" @change="_onSexChange">
                        <el-option v-for="(item,index) in sexOptions"
                                   :key="index"
                                   :label="item.label"
                                   :value="item.value" />
                    </el-select>
                    <el-select v-model="filterForm.sort" @change="_onSortChange">
                        <el-option v-for="(item,index) in sortOptions"
                                   :key="index"
                                   :label="item.label"
                                   :value="item.value" />
                    </el-select>
                </el-row>

                <el-table v-loading="loading" border style="width: 100%; margin-top: 20px;"
                          :data="tableData">
                    <!-- ID, 头像，主播名，排序因子，AI等级，分类，风格，发音人，性别，语言，语速，语调，音量，-->
                    <el-table-column label="ID" prop="id" align="center" width="60"/>

                    <el-table-column label="头像" prop="avatar" align="center" width="70">
                        <template #default="scope">
                            <el-avatar :size="40" :src="scope.row.avatar"/>
                            <!--<span class="avatar-recommend">热门</span>-->
                        </template>
                    </el-table-column>

                    <el-table-column label="名称" prop="name" align="center" width="80"/>

                    <el-table-column label="排序-递减" prop="sort" align="center" width="100" style="cursor: pointer;">
                        <template #default="scope">
                            <qx-quick-edit-pop :text="scope.row.sort + ''" :row="scope.$index" input-type="number" @done-action="_onSortEdit"/>
                        </template>
                    </el-table-column>

                    <el-table-column label="热门/推荐" prop="recommend" align="center" width="100" style="cursor: pointer;">
                        <template #default="scope">
                            <anchor-recommend-edit-pop :row="scope.$index"
                                                       :anchor-id="scope.row.id"
                                                       :recommend="scope.row.recommend"
                                                       @update="_onRecommendUpdate"/>
                        </template>
                    </el-table-column>

                    <el-table-column label="简介" prop="description" align="center" width="180px"/>

                    <el-table-column label="AI等级" prop="aiLevel" align="center" width="60px">
                        <template #default="scope">
                            <span >Lv{{ scope.row.aiLevel }}</span>
                        </template>
                    </el-table-column>

                    <!--<el-table-column label="声音" prop="voice" align="center" width="160"/>-->

                    <el-table-column label="性别" prop="sex" align="center" width="60">
                        <template #default="scope">
                            <anchor-sex-label :sex="scope.row.sex"/>
                        </template>
                    </el-table-column>

                    <el-table-column label="语言" prop="languages" align="center" width="100">
                        <template #default="scope">
                            <span>{{ makeLanguageDesc(scope.row.languages) }}</span>
                        </template>
                    </el-table-column>

                    <el-table-column label="场景" prop="categories" align="center" width="150px">
                        <template #default="scope">
                            <!--<span>{{ makeCategoryDesc(scope.row.categories) }}</span>-->
                            <el-tag class="category-tag" v-for="item in scope.row.categories"
                                    :key="item.id">
                                {{item.name}}
                            </el-tag>
                        </template>
                    </el-table-column>

                    <el-table-column label="状态" prop="status" align="center" width="80px">
                        <template #default="scope">
                            <span v-if="scope.row.status === 0" class="status_label_normal">正常</span>
                            <span v-if="scope.row.status === 1" class="status_label_disabled">已禁用</span>
                            <span v-if="scope.row.status === 2" class="status_label_deleted">已删除</span>
                        </template>
                    </el-table-column>

                    <el-table-column label="操作">
                        <template #default="scope">

                            <el-button size="small"
                                       @click="onItemTemplateBtnClick(scope.$index, scope.row)">
                                模版
                            </el-button>

                            <el-button size="small"
                                       primary
                                       @click="onItemEditBtnClick(scope.$index, scope.row)">
                                编辑
                            </el-button>

                            <el-button v-if="scope.row.status === 1"
                                       size="small"
                                       type="success"
                                       @click="onItemResumeBtnClick(scope.$index, scope.row)">
                                恢复
                            </el-button>

                            <el-button v-if="scope.row.status === 0"
                                       size="small"
                                       type="danger"
                                       @click="onItemDisableBtnClick(scope.$index, scope.row)">
                                禁用
                            </el-button>

                            <el-button size="small"
                                       type="danger"
                                       @click="onItemDeleteBtnClick(scope.$index, scope.row)">
                                删除
                            </el-button>
                        </template>
                    </el-table-column>

                </el-table>
            </el-main>

            <!-- 分页插件 -->
            <el-footer>
                <el-pagination
                    :page-size="pageSize"
                    :page-count="totalPage"
                    :current-page="currentPage"
                    layout="prev, pager, next"
                    @current-change="_onPageChange">
                </el-pagination>
            </el-footer>
        </el-container>

        <!-- 添加/编辑主播 -->
        <!--<admin-anchor-edit-dialog :dialog-visible="showEditDialog" @dialog-dismiss="dismissEditDialog"/>-->

    </div>
</template>

<script>
import {AnchorApis} from "@/api/anchor";
import {ElMessage} from "element-plus";
import {DubbingApis} from "@/api/dubbing";
import AnchorSexLabel from "@/views/dubbing/components/AnchorSexLabel";
import CommonPageTitleLabel from "@/views/common/CommonPageTitleLabel";
import CommonAddButton from "@/views/common/CommonAddButton";
import {QxAlertDialog} from "@/components/QxAlertDialog";
import {FilterOptions} from "@/views/dubbing/anchor/FilterOptions";
import QxQuickEditPop from "@/components/QxQuickEditPop";
import AnchorRecommendEditPop from "@/views/dubbing/anchor/AnchorRecommendEditPop";

export default {
    name: "AnchorManagePage",
    components: {AnchorRecommendEditPop, QxQuickEditPop, CommonAddButton, CommonPageTitleLabel, AnchorSexLabel},
    data() {
        return {
            searchAnchorName: '',
            sourceOptions: FilterOptions.voiceSourceOptions,
            levelOptions: FilterOptions.aiLevelOptions,
            sexOptions: FilterOptions.sexOptions,
            sortOptions: [
                {
                    label: '添加时间',
                    value: 0
                }  ,
                {
                    label: '热门',
                    value: 1
                }
            ],
            filterForm: {
                source: 0, // 0 表示全部，1表示阿里云，2表示微软
                sex: 0, // 0-全部，1-男，2-女，3-童音
                aiLevel: 0, // 0-全部
                sort: 0, // 0-添加时间递增，1-sort递减
            },
            loading: false,
            currentPage: 1, // 当前页码
            pageSize: 10, // 每页显示条目数
            totalPage: 0, // 总页数
            tableData: [],
        }
    },
    mounted() {
        let page = this.$route.query.page;
        let source = this.$route.query.source;
        let sex = this.$route.query.sex;
        let aiLevel = this.$route.query.level;
        let sort = this.$route.query.sort;
        if (page) {
            this.currentPage = parseInt("" + page);
        }
        if (source) {
            this.filterForm.source = parseInt("" + source);
        }
        if (sex) {
            this.filterForm.sex = parseInt("" + sex);
        }
        if (aiLevel) {
            this.filterForm.aiLevel = parseInt("" + aiLevel);
        }
        if (sort) {
            this.filterForm.sort = parseInt("" + sort);
        }

        this.getTableData();
    },
    methods: {

        getTableData() {
            this.loading = true;
            let self = this;

            let searchReq = {
                page: this.currentPage,
                pageSize: this.pageSize,
                source: this.filterForm.source,
                sex: this.filterForm.sex,
                aiLevel: this.filterForm.aiLevel,
                sort: this.filterForm.sort
            }
            AnchorApis.searchAnchor(searchReq).then(res => {
                self.loading = false;
                let pageData = res.data;
                this.totalPage = pageData.total;
                self.tableData = pageData.list;
            }).catch(error => {
                self.loading = false;
                console.error(error)
            })
        },

        _onPageChange(page) {
            this.currentPage = page;
            this.getTableData();
            this._updateQueryParams();
        },

        _onSourceChange(val) {
            this.filterForm.source = val;
            this.getTableData();
            this._updateQueryParams();
        },

        _onSexChange(val) {
            this.filterForm.sex = val;
            this.getTableData();
            this._updateQueryParams();
        },

        _onSortChange(val) {
            this.filterForm.sort = val;
            this.getTableData();
            this._updateQueryParams();
        },

        _onAiLevelChange(val) {
            this.filterForm.aiLevel = val;
            this.getTableData();
            this._updateQueryParams();
        },

        _updateQueryParams() {
            // 修改query参数
            this.$router.replace({
                query: {
                    page: this.currentPage,
                    source: this.filterForm.source,
                    sex: this.filterForm.sex,
                    level: this.filterForm.aiLevel,
                    sort: this.filterForm.sort
                }
            })
        },

        // makeCategoryDesc(categories) {
        //     let desc = "";
        //     for (let i = 0; i < categories.length; i++) {
        //         let cateItem = categories[i];
        //         if (desc.length > 0) {
        //             desc += ",";
        //         }
        //         desc += cateItem.name
        //     }
        //     return desc
        // },

        makeLanguageDesc(languages) {
            let desc = "";
            for (let i = 0; i < languages.length; i++) {
                let item = languages[i];
                if (desc.length > 0) {
                    desc += ",";
                }
                desc += item.name
            }
            return desc
        },


        // ===========================================
        //      Listener
        // ===========================================

        /**
         * 点击按钮 -- 添加主播
         */
        onAddAnchor() {
            this.$router.push("/dubbing/anchor/add")
        },

        _syncTemplateSource() {
           AnchorApis.syncTemplateSource().then(() => {
               ElMessage.success("同步模版Source成功")
           })
        },

        _syncTemplateStyleValue() {
            AnchorApis.syncTemplateStyleValue().then(() => {
                ElMessage.success("同步模版StyleValue成功")
            })
        },

        _syncAnchorAvatarPath() {
            AnchorApis.syncAvatarPath().then(() => {
                ElMessage.success("同步头像地址成功")
            })
        },

        _onSortEdit(text, row) {
            let newSort = parseInt(text);
            let itemData = this.tableData[row];
            AnchorApis.updateAnchorSort(itemData.id, newSort).then(() => {
                this.tableData[row].sort = newSort;
            })
        },

        _onRecommendUpdate(row, recommend) {
            this.tableData[row].recommend = recommend;
        },

        /**
         * 点击操作按钮--编辑
         *
         * @param index
         * @param itemData
         */
        onItemEditBtnClick(index, itemData) {
            let arg = JSON.stringify(itemData);
            this.$router.push({
                name: "AnchorEditPage",
                params: {
                    anchor: arg
                }
            });
        },

        /**
         * 点击操作按钮--模版管理
         */
        onItemTemplateBtnClick(index, itemData) {
            // 页面传参，使用params传递参数，如果不在url中，则刷新后会丢失参数
            // https://blog.csdn.net/qq_41877050/article/details/122936868

            // 方式一：采用params传参，但是刷新后，参数会丢失，无法获取数据
            // let arg = JSON.stringify(itemData);
            // this.$router.push({
            //     name: 'AnchorTemplatePage',
            //     params: {
            //         anchor: arg
            //     }
            // })

            // 方式二：采用query传参
            this.$router.push({
                path: '/dubbing/anchor/template',
                query: {
                    id: itemData.id,
                    name: itemData.name
                }
            })
        },


        /**
         * 点击操作按钮--禁用
         *
         * @param index
         * @param itemData
         */
        onItemDisableBtnClick(index, itemData) {
            DubbingApis.disableAnchor(itemData.id).then( res => {
                if (res.code === 200) {
                    itemData.status = 1;
                    ElMessage.success("操作成功")
                } else {
                    ElMessage.error("操纵失败：" + res.message);
                }
            }).catch(error => {
                ElMessage.error("操纵失败：" + error.toString());
            })
        },

        /**
         * 点击操作按钮--恢复
         *
         * @param index
         * @param itemData
         */
        onItemResumeBtnClick(index, itemData) {
            DubbingApis.resumeAnchor(itemData.id).then( res => {
                if (res.code === 200) {
                    itemData.status = 0;
                    ElMessage.success("操作成功")
                } else {
                    ElMessage.error("操纵失败：" + res.message);
                }
            }).catch(error => {
                ElMessage.error("操纵失败：" + error.toString());
            })
        },

        onItemDeleteBtnClick(index, itemData) {
            QxAlertDialog.warn("删除主播", "危险操作，删除后无法恢复，请确认后再操作", "删除", () => {
                DubbingApis.deleteAnchor(itemData.id).then(res => {
                    if (res.code === 200) {
                        ElMessage.success("删除成功")
                    } else {
                        ElMessage.error("删除失败")
                    }
                })
            }, "取消", null)
        }

    }
}
</script>

<style scoped lang="scss">
//@import "src/styles/global";

.el-select {
    margin-left: 10px;
}

.avatar-recommend {
    position: absolute;
    font-size: 7px;
    background-color: $systemRed;
    color: white;
    width: 40px;
    height: 18px;
    border-top-left-radius: 9px;
    border-top-right-radius: 9px;
    border-bottom-right-radius: 9px;
}

.category-tag {
    margin: 5px;
    height: 25px;
    line-height: 25px;
}

.status_label_normal {
    //font-weight: bold;
    color: $systemBlue;
}

.status_label_disabled {
    //font-weight: bold;
    color: $systemRed;
}

.status_label_deleted {
    //font-weight: bold;
    color: $systemRed;
}

</style>