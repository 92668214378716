<template>
  <el-drawer v-model="internalVisible" size="400px" title="Cover Editor">
    <div v-loading="uploading" class="drawer-main">
      <div class="drawer-content">
        <div class="content-inner">
          <el-form :model="coverForm" label-width="72px">
            <el-form-item label="ID">
              <el-input v-model="coverForm.id" disabled/>
            </el-form-item>
            <el-form-item label="名称">
              <el-input v-model="coverForm.name" disabled/>
            </el-form-item>
            <el-form-item label="封面">
              <el-upload
                ref="uploader"
                action="#"
                accept="image/png,image/jpeg"
                class="uploader"
                drag
                :limit="1"
                :auto-upload="false"
                :show-file-list="false"
                :on-remove="_onRemove"
                :on-exceed="_onExceed"
                :on-change="_onFileChange"
                :http-request="onHttpRequest">
                <div v-if="selectedFileUrl" class="preview-wrap">
                  <img :src="selectedFileUrl" alt="">
                </div>
                <span v-else class="holder-text">点击选择图片或拖拽到此</span>
              </el-upload>
            </el-form-item>
          </el-form>
        </div>
      </div>
      <div class="drawer-footer">
        <el-button class="action-btn" round @click="dismiss">取消</el-button>
        <el-button class="action-btn ml-20" type="primary" round @click="onCommitClick">设置</el-button>
      </div>
    </div>
  </el-drawer>
</template>

<script>
import BasicDialog from "@/views/base/BasicDialog";
import {ElMessage, genFileId} from "element-plus";
import {DrawsApi} from "@/api/draws-api";

export default {
  name: "DrawCoverEditDrawer",
  mixins: [BasicDialog],
  props: {
    type: {
      type: String,
      default: 'model' // model, style
    }
  },
  data() {
    return {
      uploading: false,
      selectedFile: null,
      selectedFileUrl: null,
      coverForm: {
        id: null,
        name: null
      }
    }
  },
  methods: {
    showEditing(data){
      this.coverForm.id = data.id;
      this.coverForm.name = data.name;
      this.internalVisible = true;
    },
    onDismiss() {
      this.coverForm.id = null;
      this.coverForm.name = null;
      this.selectedFile = null;
      this.selectedFileUrl = null;
      this.$refs.uploader.clearFiles();
    },

    // ============================================
    // mark - uploader
    // ============================================
    _onExceed(files) {
      this.$refs.uploader.clearFiles();
      const file = files[0];
      file.uid = genFileId();
      this.$refs.uploader.handleStart(file);
    },

    _onRemove() {
      this.selectedFile = null;
      this.selectedFileUrl = null;
    },

    // 文件状态改变时的钩子，添加文件、上传成功和上传失败时都会被调用
    _onFileChange(file) {
      // console.log("onChange: " + JSON.stringify(file));
      this.selectedFile = file;
      // 获取上传图片的本地URL，用于上传前的本地预览
      let URL = null;
      if (window.createObjectURL !== undefined) {
        // basic
        URL = window.createObjectURL(file.raw);
      } else if (window.URL !== undefined) {
        // mozilla(firefox)
        URL = window.URL.createObjectURL(file.raw);
      } else if (window.webkitURL !== undefined) {
        // webkit or chrome
        URL = window.webkitURL.createObjectURL(file.raw);
      }
      this.selectedFileUrl = URL;
    },

    // avatar-uploader
    beforeUpload(file) {
      const isJPG = file.type === 'image/jpeg' || file.type === 'image/png';
      const isLt2M = file.size / 1024 / 1024 < 5;

      if (!isJPG) {
        ElMessage.error('Avatar picture must be JPG/PNG format!')
      }
      if (!isLt2M) {
        ElMessage.error('Avatar picture size can not exceed 2MB!')
      }
      return isJPG && isLt2M
    },

    onHttpRequest(param) {
      const file = param.file;
      this.uploading = true;
      DrawsApi.updateCover(file, this.coverForm.id, this.type).then(_ => {
        this.uploading = false;
        ElMessage.success("图标设置成功")
        this.dismiss();
        this.$emit('success');
      }).catch(() => {
        this.uploading = false;
      })
    },
    onCommitClick() {
      this.$refs.uploader.submit()
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@/styles/commonDrawer.scss";

.uploader {
  width: 200px;
  height: 200px;
  background-color: white;
  border-radius: 8px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;

  :deep(.el-upload-dragger) {
    padding: 0;
    min-height: 100px;
  }

  .preview-wrap {
    width: 200px;
    height: 200px;
    position: relative;

    img {
      width: 200px;
      height: 200px;
      object-fit: contain;
      border-radius: 8px;
      overflow: hidden;
    }
  }
}
</style>