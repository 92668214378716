import {createRouter, createWebHistory} from 'vue-router'
import Layout from "@/views/layout"

import LoginPage from "@/views/user/LoginPage";
import AdminVoicePage from "@/views/dubbing/voice/VoiceManagePage";
import TtsVipSetting from "@/views/setting/TtsVipSetting";
import AnchorManagePage from "@/views/dubbing/anchor/AnchorManagePage";
import AdminAvatarPage from "@/views/dubbing/avatar/AvatarManagePage";
import HomePage from "@/views/home/HomePage";
import UserListPage from "@/views/user/UserListPage";
import AdminManagePage from "@/views/user/AdminManagePage";
import LoginLogPage from "@/views/user/LoginLogPage";
import AdminAddPage from "@/views/user/AdminAddPage";
import PropertyManagerPage from "@/views/dubbing/tags/PropertyManagerPage";
import BgmManagerPage from "@/views/dubbing/bgm/BgmManagerPage";
import AnchorAddPage from "@/views/dubbing/anchor/AnchorAddPage";
import AnchorEditPage from "@/views/dubbing/anchor/AnchorEditPage";
import SubRouterLayout from "@/components/SubRouterLayout";
import AnchorTemplatePage from "@/views/dubbing/anchor/AnchorTemplatePage";
import AnchorTemplateAddPage from "@/views/dubbing/anchor/AnchorTemplateAddPage";
import AnchorTemplateEditPage from "@/views/dubbing/anchor/AnchorTemplateEditPage";
import About from "@/views/AboutView"
import TtsGuideSetting from "@/views/setting/TtsGuideSetting";
import SoundManagerPage from "@/views/dubbing/sound/SoundManagerPage";
import AppVersionManagerPage from "@/views/setting/AppVersionManagerPage";
import TtsTaskManagerPage from "@/views/tools/TtsTaskManagerPage";
import SmsLogPage from "@/views/sms/SmsLogPage";
import TtsVipMemberPage from "@/views/vip/TtsVipMemberPage";
import GptLogPage from "@/views/gpt/GptLogPage";
import GptLabelManagerPage from "@/views/gpt/GptLabelManagerPage";
import GptTemplateManagerPage from "@/views/gpt/GptTemplateManagerPage";
import TradeOrderManagerPage from "@/views/vip/TradeOrderManagerPage";
import AsrTaskManagerPage from "@/views/tools/AsrTaskManagerPage";
import CommonSwitcherPage from "@/views/common/CommonSwitcherPage";
import GptPresetPromptPage from "@/views/gpt/GptPresetPromptPage";
import AnchorSamplesPage from "@/views/dubbing/anchor/AnchorSamplesPage";
import BannersPage from "@/views/banner/BannersPage";
import TtsVipProductManagerPage from "@/views/vip/TtsVipProductManagerPage";
import RwmRecordsPage from "@/views/tools/RwmRecordsPage";
import ActivityBannerPage from "@/views/web/ActivityBannerPage";
import DrawStyleManager from "@/views/draws/DrawStyleManager.vue";
import DrawModelManager from "@/views/draws/DrawModelManager.vue";


export const routes = [
    {
        path: "/login",
        component: LoginPage,
        hidden: true
    },
    {
        path: "/about",
        component: About,
        hidden: true
    },
    // ======== Home
    {
        path: "/",
        redirect: "/home",
        component: Layout,
        meta: {title: "首页", icon: 'home'},
        children: [
            {
                path: "/home",
                component: HomePage,
                name: "Home",
                meta: {title: "新增用户", icon: 'chart'}
            }, {
                path: "/administrator",
                component: AdminManagePage,
                name: "AdminManagePage",
                meta: {title: "管理员", icon: 'user-admin'}
            }, {
                path: '/administrator/add',
                component: AdminAddPage,
                name: 'AdminAddPage',
                hidden: true,
                meta: {title: '添加管理员'}
            }
        ]
    },

    // ==== User Manager
    {
        path: "/user",
        name: "用户管理",
        component: Layout,
        redirect: '/user/list',
        meta: {title: "用户管理", icon: 'user'},
        children: [
            {
                path: "/user/list",
                component: UserListPage,
                name: "AdminUserListPage",
                meta: {title: "用户列表", icon: 'submenu-user'}
            },
            {
                path: '/user/login_logs_page',
                component: LoginLogPage,
                name: "LoginLogPage",
                meta: {title: "登录日志", icon: 'login-log'}
            },
            // {
            //     path: "/user/lease",
            //     component: LeaseAccountManager,
            //     name: "LeaseAccountManager",
            //     meta: {title: "租赁账户", icon: 'submenu-user'}
            // },
            // {
            //     path: "/user/taobao",
            //     component: TaoBaoAccountManager,
            //     name: "TaoBaoAccountManager",
            //     meta: {title: "淘宝用户", icon: 'submenu-user'}
            // }
        ]
    },
    // ======== Dubbing
    {
        path: "/dubbing",
        name: "Dubbing",
        component: Layout,
        redirect: '/dubbing/voice',
        meta: {title: "配音管理", icon: 'menu-dubbing'},
        children: [{
            path: "/dubbing/voice",
            component: AdminVoicePage,
            name: "AdminVoicePage",
            meta: {title: "声音管理", icon: 'submenu-microphone'}
        }, {
            path: "/dubbing/anchor",
            component: SubRouterLayout,
            redirect: '/dubbing/anchor/home',
            meta: {title: "主播管理", icon: 'menu-dubbing'},
            children: [{
                path: "/dubbing/anchor/home",
                component: AnchorManagePage,
                name: "AnchorManagePage",
                meta: {title: "主播管理"},
            }, {
                path: "/dubbing/anchor/add",
                component: AnchorAddPage,
                name: 'AnchorAddPage',
                hidden: true,
                meta: {title: "添加主播"}
            }, {
                path: "/dubbing/anchor/edit",
                component: AnchorEditPage,
                name: 'AnchorEditPage',
                meta: {title: "编辑主播"}
            }, {
                path: '/dubbing/anchor/templates',
                component: SubRouterLayout,
                // name: 'AnchorTemplatePage',
                redirect: '/dubbing/anchor/template',
                meta: {title: "风格模版"},
                children: [
                    {
                        path: '/dubbing/anchor/template',
                        name: 'AnchorTemplatePage',
                        component: AnchorTemplatePage,
                        // 缓存页面，从edit/add页面返回时，
                        meta: {title: "风格模版", keepAlive: true}
                    },
                    {
                        path: '/dubbing/anchor/template/add',
                        name: 'AnchorTemplateAddPage',
                        component: AnchorTemplateAddPage,
                        meta: {title: "添加模版"}
                    }, {
                        path: '/dubbing/anchor/template/edit',
                        name: 'AnchorTemplateEditPage',
                        component: AnchorTemplateEditPage,
                        meta: {title: "编辑模版"}
                    }
                ]
            }
            ]
        }, {
            path: "/dubbing/anchor/sample",
            component: AnchorSamplesPage,
            name: 'AnchorSamplesPage',
            meta: {title: "AI样音"}
        }, {
            path: "/dubbing/avatar",
            component: AdminAvatarPage,
            name: "AdminAvatarPage",
            meta: {title: "头像管理", icon: 'submenu-avatar'}
        }, {
            path: "/dubbing/property",
            component: PropertyManagerPage,
            name: "PropertyManagerPage",
            meta: {title: "标签管理", icon: 'brand'}
        }, {
            path: "/dubbing/bgm",
            component: BgmManagerPage,
            name: 'BgmManagerPage',
            meta: {title: "背景音乐", icon: 'bgm'}
        }, {
            path: "/dubbing/sound",
            component: SoundManagerPage,
            name: 'SoundManagerPage',
            meta: {title: "特效音乐", icon: 'bgm'}
        }
        ]
    },
    // ==== 会员管理
    {
        path: "/vip",
        name: "会员管理",
        component: Layout,
        redirect: '/vip/tts',
        meta: {title: "会员管理", icon: 'user'},
        children: [
            {
                path: "/vip/tts",
                component: TtsVipMemberPage,
                name: "TtsVipMemberPage",
                meta: {title: "配音会员", icon: 'submenu-user'}
            },
            {
                path: "/vip/tts/product",
                component: TtsVipProductManagerPage,
                name: "TtsVipProductManagerPage",
                meta: {title: "配音套餐管理", icon: 'submenu-user'}
            },
            {
                path: "/vip/tts/limit",
                component: TtsVipSetting,
                name: "TtsVipSetting",
                meta: {title: "配音限制设置"}
            },
            {
                path: "/trade/orders",
                component: TradeOrderManagerPage,
                name: "TradeOrderManagerPage",
                meta: {title: "交易记录", icon: 'submenu-user'}
            }
        ]
    },
    {
        path: "/task",
        name: "任务管理",
        component: Layout,
        redirect: "/task/ttsTask",
        meta: {title: "任务管理", icon: 'menu-task-manager'},
        children: [
            {
                path: "/task/ttsTask",
                component: TtsTaskManagerPage,
                name: "TtsTaskManagerPage",
                meta: {title: "配音任务"}
            },
            {
                path: "/gpt/logPage",
                component: GptLogPage,
                name: "GptLogPage",
                meta: {title: "Gpt日志"}
            },
            {
                path: "/task/asr",
                component: AsrTaskManagerPage,
                name: "AsrTaskManagerPage",
                meta: {title: "文案提取任务"}
            },
            {
                path: "/task/rwm",
                component: RwmRecordsPage,
                name: "RwmRecordsPage",
                meta: {title: "视频提取记录"}
            },
            {
                path: "/sms/logs",
                component: SmsLogPage,
                name: "SmsLogPage",
                meta: {title: "短信日志"}
            }
        ]
    },
    {
        path: "/draws",
        name: "Ai绘画管理",
        component: Layout,
        meta: {title: "Ai绘画管理", icon: 'menu-task-manager'},
        children: [
            {
                path: "/draws/styleManager",
                component: DrawStyleManager,
                name: "DrawStyleManager",
                meta: {title: "风格管理"}
            },
            {
                path: "/draws/modelManager",
                component: DrawModelManager,
                name: "DrawModelManager",
                meta: {title: "模版管理"}
            }
        ]
    },
    {
        path: "/gpt",
        name: "Gpt设置",
        component: Layout,
        redirect: "/gpt/common_switcher",
        meta: {title: "Gpt设置", icon: 'menu-setting'},
        children: [
            {
                path: "/gpt/label",
                component: GptLabelManagerPage,
                name: "GptLabelManagerPage",
                meta: {title: "Gpt标签样式管理"}
            },
            {
                path: "/gpt/template",
                component: GptTemplateManagerPage,
                name: "GptTemplateManagerPage",
                meta: {title: "Gpt模版管理"}
            },
            {
                path: "/gpt/preset_prompt",
                component: GptPresetPromptPage,
                name: "GptPresetPromptPage",
                meta: {title: "Gpt预置模版管理"}
            },
        ]
    },
    // ==== setting group
    {
        path: "/setting",
        name: "通用设置",
        component: Layout,
        redirect: "/setting/common_switcher",
        meta: {title: "常用设置", icon: 'menu-setting'},
        children: [
            {
                path: "/setting/common_switcher",
                component: CommonSwitcherPage,
                name: "CommonSwitcherPage",
                meta: {title: "常用开关设置"}
            },
            {
                path: "/setting/tts_guide",
                component: TtsGuideSetting,
                name: "TtsGuideSetting",
                meta: {title: "配音新手指引"}
            }
        ]
    },
    {
        path: "/app",
        name: "App设置",
        component: Layout,
        redirect: "/app/banner",
        meta: {title: "App端设置", icon: 'menu-setting'},
        children: [
            {
                path: "/app/version",
                component: AppVersionManagerPage,
                name: "AppVersionManagerPage",
                meta: {title: "App版本管理"}
            },
            {
                path: "/app/banner",
                component: BannersPage,
                name: "BannersPage",
                meta: {title: "Banner设置"}
            },
        ]
    },
    {
        path: "/web",
        name: "Web端设置",
        component: Layout,
        redirect: "/web/activity",
        meta: {title: "Web端设置", icon: 'menu-setting'},
        children: [
            {
                path: "/web/activityBanner",
                component: ActivityBannerPage,
                name: "ActivityBannerPage",
                meta: {title: "广告活动设置"}
            },
        ]
    },


];

const router = createRouter({
    // history: createWebHistory(process.env.BASE_URL),
    history: createWebHistory(),
    routes
})

export default router
