<template>
    <el-dialog v-model="internalVisible" width="30%" title="添加风格标签">
        <el-form ref="addingElForm" :model="addingForm" label-width="70px" :rules="addingRules">

            <el-form-item label="名称" prop="name">
                <el-input v-model="addingForm.name" placeholder="请输入风格名称"/>
            </el-form-item>

            <el-form-item label="别名" prop="alias">
                <el-input v-model="addingForm.alias" placeholder="请输入风格别名，仅支持大小写字母、数字、连接符-"/>
            </el-form-item>

            <el-form-item label="微软" prop="value">
                <el-input v-model="addingForm.value" placeholder="请输入微软风格的属性值，例如assistant, 其他平台为null"/>
            </el-form-item>

            <el-form-item label="阿里云" prop="valueAli">
                <el-input v-model="addingForm.valueAli" placeholder="请输入阿里云情绪的属性值，angry, 其他平台为null"/>
            </el-form-item>

            <el-form-item label="说明" prop="description">
                <el-input v-model="addingForm.description" placeholder="输入风格说明，可为空" type="textarea" :rows="2"/>
            </el-form-item>

            <el-form-item label="排序" prop="sort">
                <el-input-number v-model="addingForm.sort" :min="0" :max="100"/>
            </el-form-item>
        </el-form>

        <template #footer>
                <span>
                    <el-button primary @click="dismiss">取消</el-button>
                    <el-button type="primary" @click="_onAddingSubmit">提交</el-button>
                </span>
        </template>
    </el-dialog>
</template>

<script>
import BasicDialog from "@/views/base/BasicDialog";
import {DubbingApis} from "@/api/dubbing";
import {ElMessage} from "element-plus";

export default {
    name: "EmotionAddDialog",
    mixins: [BasicDialog],
    data() {
        return {
            addingForm: {
                name: '',
                alias: '',
                value: null,
                valueAli: null,
                description: null,
                sort: 0
            },
            addingRules: {
                name: [
                    {required: true, message: '风格名称不能为空', trigger: 'blur'},
                    {pattern: /^[\u4e00-\u9fa5-_]+$/, message: '风格名称仅支持中文、连接符-组合', trigger: 'blur'}
                ],
                alias: [
                    {required: true, message: '风格别名不能为空', trigger: 'blur'},
                    {pattern: /^[a-zA-Z0-9-_]{1,50}$/, message: '别名仅支持大小写字母、数字、连接符-组合', trigger: 'blur'}
                ]
            }
        }
    },

    methods: {
        _onAddingSubmit() {
            this.$refs.addingElForm.validate(valid => {
                if (valid) {
                    this._commitAdding();
                }
            })
        },

        _commitAdding() {
            let param = {
                name: this.addingForm.name,
                alias: this.addingForm.alias,
                value: this.addingForm.value,
                valueAli: this.addingForm.valueAli,
                description: this.addingForm.description,
                sort: this.addingForm.sort
            }
            DubbingApis.addStyle(param).then(res => {
                ElMessage.success("添加成功");
                this.dismiss();
            }).catch(error => {
                ElMessage.error("添加失败: " + error.message);
            })
        },
    }
}
</script>

<style scoped>

</style>