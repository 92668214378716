<template>
    <el-popover ref="popover" width="370" trigger="click" @show="_onShow">
        <div class="form-container">
            <el-form v-model="hotForm" label-width="70px">
                <el-form-item label="热门">
                    <el-input-number v-model="hotForm.hot" step="1"/>
                </el-form-item>
                <el-form-item label="角标">
                    <el-input v-model="hotForm.hotText" placeholder="自定义角标文案"/>
                </el-form-item>
            </el-form>
            <div class="form-footer">
                <el-button style="margin-left: auto;" @click.stop="_dismiss">取消</el-button>
                <el-button type="primary" style="margin-left: 15px;" @click.stop="_onCommit">确定</el-button>
            </div>
        </div>
        <template #reference>
            <span class="ui-cursor-pointer ui-color-blue">{{this.showHot}}</span>
        </template>
    </el-popover>
</template>

<script>
import {AnchorApis} from "@/api/anchor";
import {ToastUtil} from "@/utils/toast-util";

export default {
    name: "AnchorSampleHotPop",
    props: ["id", "hot", "hotText"],
    data() {
        return {
            hotForm: {
                sampleId: null,
                hot: 0,
                hotText: null,
            },
        }
    },
    computed: {
        showHot() {
            if (this.hotText) {
                return this.hotText;
            }
            return this.hot;
        }
    },

    methods: {

        // init(data) {
        //     this.id = data.id;
        //     this.hot = data.hot;
        //     this.hotText = data.hotText;
        // },

        _onShow() {
            this.hotForm.sampleId = this.id;
            this.hotForm.hot = this.hot;
            this.hotForm.hotText = this.hotText;
        },

        _dismiss() {
            this.$refs.popover.hide();
        },

        _onCommit() {
            let req = {
                id: this.hotForm.sampleId,
                hot: this.hotForm.hot,
                hotText: this.hotForm.hotText
            }
            AnchorApis.updateSampleHot(req).then(res => {
                ToastUtil.toast("修改成功");
                this._dismiss();
            })
        }
    }
}
</script>

<style lang="scss" scoped>

.form-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    .form-footer {
        width: 100%;
        display: flex;
        align-items: center;
    }
}

</style>