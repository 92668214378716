export const FilterOptions = {
    voiceSourceOptions: [
        {
            label: '全部',
            value: 0
        },
        {
            label: '阿里云',
            value: 1
        },
        {
            label: '微软',
            value: 2
        }
    ],
    sexOptions: [
        {
            label: '全部',
            value: 0
        },
        {
            label: '男',
            value: 1
        },
        {
            label: '女',
            value: 2
        },
        {
            label: '童声',
            value: 3
        }
    ],
    aiLevelOptions: [
        {
            label: '全部',
            value: 0
        },
        {
            label: '普通AI',
            value: 1
        },
        {
            label: '高级AI',
            value: 2
        },
        {
            label: '情绪AI',
            value: 3
        }
    ],
    sampleRateOptions: [
        {
            label: "8k",
            value: 8000
        },
        {
            label: "16k",
            value: 16000
        },
        {
            label: "24k",
            value: 24000
        },
        {
            label: "48k",
            value: 48000
        }
    ],
}