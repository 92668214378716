<template>
    <el-container style="height: 100%;">
        <el-header>
            <el-row>
                <common-page-title-label title="视频提取记录"/>
                <el-input v-model="searchUserId" style="width: 180px;" placeholder="输入用户ID"/>
                <el-button type="primary" class="ml-16" @click="_onSearchClick">搜索</el-button>
                <el-button type="primary" @click="_onSearchClearClick">全部</el-button>
            </el-row>
        </el-header>
        <el-main style="width: 100%; flex: 1">
            <el-table :data="records" border>
                <el-table-column label="ID" prop="id" width="80"/>
                <el-table-column label="User" prop="userId" width="180"/>
                <el-table-column label="Text" prop="shareUrl" width="220"/>
                <el-table-column label="Url" prop="simpleUrl" width="160"/>
                <el-table-column label="平台" prop="platformName" width="120"/>
                <el-table-column label="结果" prop="resultStatusText" width="120"/>
                <el-table-column label="客户端" prop="clientName" width="120"/>
                <el-table-column label="封面" width="180">
                    <template #default="scope">
                        <!--<iframe style="width: 180px;height: 180px">-->
                            <img style="width: 120px; height: 120px;object-fit: contain;" :src="_parseCover(scope.row.resultMediaJson)" referrerpolicy="no-referrer"/>
                        <!--</iframe>-->
                    </template>
                </el-table-column>
                <el-table-column label="Media" width="120">
                    <template #default="scope">
                        <el-button type="primary" @click="_showMediaResult(scope.row.resultMediaJson)">查看结果</el-button>
                    </template>
                </el-table-column>
                <el-table-column label="IP" prop="requestIp" width="120"/>
                <el-table-column label="UA" prop="userAgent"/>

            </el-table>
        </el-main>
        <!-- 分页插件 -->
        <el-footer>
            <el-pagination
                :current-page="page"
                :page-size="pageSize"
                :page-count="totalPage"
                layout="prev, pager, next"
                @current-change="_onPageChange">
            </el-pagination>
        </el-footer>
    </el-container>

    <rwm-media-result-dialog ref="mediaDialog" v-model:visible="mediaDialogVisible"/>
</template>

<script>
import CommonPageTitleLabel from "@/views/common/CommonPageTitleLabel";
import {ElMessage} from "element-plus";
import {ToolsApis} from "@/api/tools-apis";
import RwmMediaResultDialog from "@/views/tools/RwmMediaResultDialog";
export default {
    name: "RwmRecordsPage",
    components: {RwmMediaResultDialog, CommonPageTitleLabel},
    data() {
        return {
            page: 1,
            pageSize: 30,
            totalPage: 1,
            records: [],
            searchMode: false,
            searchUserId: null,
            mediaDialogVisible: false,
        }
    },

    mounted() {
        this._searchAllRecords();
    },

    methods: {

        _onPageChange(newPage) {
            this.page = newPage;
            if (this.searchMode && this.searchUserId) {
                this._searchUserRecords();
            } else {
                this._searchAllRecords();
            }
        },

        _searchAllRecords() {
            ToolsApis.searchAllRecords(this.page, this.pageSize).then(res => {
                let pageData = res.data;
                this.totalPage = pageData.total;
                this.records = pageData.list;
            })
        },

        _parseCover(mediaJson) {
            if (!mediaJson) {
                return null;
            }
            let media = JSON.parse(mediaJson)
            return media.cover;
        },

        _showMediaResult(mediaJson) {
            this.$refs.mediaDialog.setupContent(mediaJson);
            this.mediaDialogVisible = true;
        },

        // ===========================================
        //      Search
        // ===========================================

        _onSearchClick() {
            if (!this.searchUserId) {
                ElMessage.error("请输入userId")
                return;
            }
            this.searchMode = true;
            this.page = 1;
            this._searchUserRecords();
        },

        _onSearchClearClick() {
            this.searchMode = false;
            this.searchUserId = null;
            this.page = 1;
            this._searchAllRecords();
        },

        _searchUserRecords() {
            ToolsApis.searchUserRecords(this.searchUserId, this.page, this.pageSize).then(res => {
                let pageData = res.data;
                this.totalPage = pageData.total;
                this.records = pageData.list;
            })
        }
    }
}
</script>

<style scoped>

</style>