import request from "@/api/request";
import {Apis} from "@/api/Apis";


export const DubbingApis = {

    // ===========================================
    //      发音人
    // ===========================================

    getVoiceList(page, pageSize) {
        return request({
            url: '/admin/dubbing/voice/list',
            method: 'get',
            params: {
                page: page,
                pageSize: pageSize
            }
        })
    },

    /**
     * 获取全部发音人
     * @param source 声音平台
     * @returns {*}
     */
    getAllVoiceList(source) {
        return request({
            url: '/admin/dubbing/voice/all',
            method: 'get',
            params: {
                source: source
            }
        })
    },

    /**
     * 获取发音人选项列表
     *
     * @returns {*}
     */
    getVoiceOptionList(source) {
        return request({
            url: '/admin/dubbing/voice/optionList',
            method: 'get',
            params: {
                source: source
            }
        })
    },

    /**
     * 添加新发音人
     *
     * @param data
     * @returns {*}
     */
    addVoice(data) {
        return request({
            url: '/admin/dubbing/voice/add',
            method: 'post',
            data
        })
    },

    /**
     * 编辑发音人
     *
     * @param data
     * @returns {*}
     */
    editVoice(data) {
        return request({
            url: '/admin/dubbing/voice/edit',
            method: 'post',
            data
        })
    },

    /**
     * 修改发音人 风格列表 ，仅限微软平台
     * @param data
     * @returns {*}
     */
    editVoiceStyles(data) {
        return request({
            url: '/admin/dubbing/voice/styles/update',
            method: 'post',
            data
        })
    },

    editVoiceRoles(data) {
        return request({
            url: '/admin/dubbing/voice/updateRoles',
            method: 'post',
            data
        })
    },


    // ===========================================
    //      主播
    // ===========================================

    /**
     * 文案试听
     *
     * @param data
     */
    requestAudition(data) {
        return Apis.post('/admin/tts/audition', data);
    },

    /**
     * 添加主播
     * @param data
     */
    addAnchor(data) {
        return request({
            url: "/admin/dubbing/anchor/add",
            method: "post",
            data: data
        })
    },

    /**
     * 修改主播
     * @param data
     * @returns {*}
     */
    updateAnchor(data) {
        return request({
            url: '/admin/dubbing/anchor/update',
            method: 'post',
            data
        })
    },

    /**
     * 禁用主播
     *
     * @param anchorId
     * @returns {*}
     */
    disableAnchor(anchorId) {
        return request({
            url: "/admin/dubbing/anchor/disable",
            method: "post",
            params: {
                anchorId: anchorId
            }
        })
    },

    /**
     * 恢复主播
     *
     * @param anchorId
     * @returns {*}
     */
    resumeAnchor(anchorId) {
        return request({
            url: "/admin/dubbing/anchor/resume",
            method: "post",
            params: {
                anchorId: anchorId
            }
        })
    },

    /**
     * 删除主播
     * @param anchorId
     * @returns {*}
     */
    deleteAnchor(anchorId) {
        return request({
            url: '/admin/dubbing/anchor/delete',
            method: 'post',
            params: {
                anchorId: anchorId
            }
        })
    },



    // ===========================================
    //      主播 - 风格/模版
    // ===========================================

    /**
     * 获取主播的风格模版
     *
     * @param anchorId
     * @returns {*}
     */
    getTemplatesByAnchorId(anchorId) {
        return request({
            url: '/admin/dubbing/anchor/templates',
            method: 'get',
            params: {
                anchorId: anchorId
            }
        })
    },

    /**
     * 获取模版详细信息
     *
     * @param id
     * @returns {*}
     */
    getTemplateInfo(id) {
        return request({
            url: '/admin/dubbing/template/info',
            method: 'get',
            params: {
                id: id
            }
        })
    },

    /**
     * 添加主播模版
     *
     * @param data
     * @returns {*}
     */
    addAnchorTemplate(data) {
        return request({
            url: '/admin/dubbing/template/add',
            method: 'post',
            data
        })
    },

    /**
     * 编辑主播模版
     *
     * @param data
     * @returns {*}
     */
    updateTemplate(data) {
        return request({
            url: '/admin/dubbing/template/edit',
            method: 'post',
            data
        })
    },

    /**
     * 删除模版
     * @param templateId 模版id
     */
    deleteTemplate(templateId) {
        return request({
            url: '/admin/dubbing/template/delete',
            method: 'post',
            params: {
                templateId: templateId
            }
        })
    },

    // ===========================================
    //      配音 场景/领域 管理
    // ===========================================

    /**
     * 获取主播场景列表
     * @returns {*}
     */
    getCategoryList() {
        return request({
            url: '/admin/dubbing/category/list',
            method: 'get'
        })
    },

    /**
     * 删除场景列表缓存
     * @returns {*}
     */
    deleteCategoryCache() {
        return request({
            url: '/admin/dubbing/category/deleteCache',
            method: 'get'
        })
    },

    /**
     * 添加分类
     * @param data
     * @returns {*}
     */
    addCategory(data) {
        return request({
            url: '/admin/dubbing/category/add',
            method: 'post',
            data
        })
    },

    /**
     * 修改场景/领域标签
     * @param data
     */
    updateCategory(data) {
        return request({
            url: '/admin/dubbing/category/update',
            method: 'post',
            data
        })
    },

    /**
     * 删除 场景/领域 标签
     * @param data
     * @returns {*}
     */
    deleteCategory(data) {
        return request({
            url: '/admin/dubbing/category/delete',
            method: 'post',
            data
        })
    },

    // ===========================================
    //      风格标签管理 Style
    // ===========================================

    /**
     * 查询声音风格列表
     * @returns {*}
     */
    getStyleList() {
        return request({
            url: '/admin/dubbing/style/list',
            method: 'get'
        })
    },

    /**
     * 查询声音风格选项列表
     * @returns {*}
     */
    getStyleOptionList() {
        return request({
            url: '/admin/dubbing/style/options',
            method: 'get'
        })
    },

    /**
     * 添加风格标签
     * @param data
     * @returns {*}
     */
    addStyle(data) {
        return request({
            url: '/admin/dubbing/style/add',
            method: 'post',
            data
        })
    },

    /**
     * 更新声音风格
     * @param data
     * @returns {*}
     */
    updateStyle(data) {
        return request({
            url: '/admin/dubbing/style/update',
            method: 'post',
            data
        })
    },

    /**
     * 设置风格的图标
     * @param formData
     * @returns {*}
     */
    updateStyleImage(formData) {
        return request({
            url: '/admin/dubbing/style/image/set',
            method: 'post',
            data: formData,
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        })
    },

    /**
     * 删除风格标签
     * @param styleId
     * @returns {*}
     */
    deleteStyle(styleId) {
        return request({
            url: '/admin/dubbing/style/delete',
            method: 'post',
            params: {
                styleId: styleId
            }
        })
    },

    // ===========================================
    //      语种
    // ===========================================

    /**
     * 获取语种列表
     *
     * @returns {*}
     */
    getLanguageList() {
        return request({
            url: '/admin/dubbing/language/list',
            method: 'get'
        })
    },

    /**
     * 添加语种
     * @param data
     * @returns {*}
     */
    addLanguage(data) {
        return request({
            url: '/admin/dubbing/language/add',
            method: 'post',
            data
        })
    },

    // ===========================================
    //      背景音乐管理
    // ===========================================

    /**
     * 获取背景音乐列表
     *
     * @param page
     * @param pageSize
     * @returns {*}
     */
    getBgmList(page, pageSize) {
        return request({
            url: '/admin/dubbing/bgm/list',
            method: 'get',
            params: {
                page: page,
                pageSize: pageSize
            }
        })
    },

    /**
     * 获取背景音乐上传的oss配置
     *
     * @param data
     * @returns {*}
     */
    getBgmUploadPolicy(data) {
        return request({
            url: '/admin/dubbing/bgm/policy',
            method: 'post',
            data
        })
    },

    /**
     * 上传背景音乐文件
     * @param form
     */
    uploadBgm(form) {
        return Apis.uploadWithForm("/admin/dubbing/bgm/upload", form)
    },

    /**
     * 替换背景音乐文件
     * @param form
     */
    replaceBgm(form) {
        return Apis.uploadWithForm("/admin/dubbing/bgm/replace", form)
    },

    /**
     * 更新背景音乐
     * @param data
     * @returns {*}
     */
    updateBgm(data) {
        return request({
            url: '/admin/dubbing/bgm/update',
            method: 'post',
            data
        })
    },

    /**
     * 禁用背景音乐
     * @param bgmId
     * @returns {*}
     */
    disableBgm(bgmId) {
        return request({
            url: '/admin/dubbing/bgm/disable',
            method: 'get',
            params: {
                bgmId: bgmId
            }
        })
    },

    /**
     * 删除背景音乐
     * @param bgmId
     * @returns {*}
     */
    deleteBgm(bgmId) {
        return request({
            url: '/admin/dubbing/bgm/delete',
            method: 'get',
            params: {
                bgmId: bgmId
            }
        })
    },

    /**
     * 删除背景音乐
     * @param bgmId
     * @returns {*}
     */
    resumeBgm(bgmId) {
        return request({
            url: '/admin/dubbing/bgm/resume',
            method: 'get',
            params: {
                bgmId: bgmId
            }
        })
    },

    /**
     * 背景音乐试听
     *
     * @param data
     */
    auditionBgm(data) {
        return Apis.post('/admin/dubbing/bgm/test', data);
    },

    // ===========================================
    //      音效
    // ===========================================

    getSoundStyleList() {
        return Apis.get("/admin/dubbing/sound/styleList");
    },

    searchSound(req) {
        return Apis.post("/admin/dubbing/sound/search", req);
    },

    addSound(file, req) {
        let jsonStr = JSON.stringify(req);
        let form = {
            file: file,
            params: jsonStr
        }
        return Apis.uploadWithForm("/admin/dubbing/sound/add", form)
    },

    /**
     * 更新音效文件和属性
     * @param file
     * @param req
     * @returns {*}
     */
    updateSoundAll(file, req) {
        let jsonStr = JSON.stringify(req);
        let form = {
            file: file,
            params: jsonStr
        }
        return Apis.uploadWithForm("/admin/dubbing/sound/update", form)
    },

    /**
     * 修改音效属性
     * @param req
     */
    updateSoundInfo(req) {
        return Apis.post("/admin/dubbing/sound/updateInfo", req)
    }

}

