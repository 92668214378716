import {Apis} from "@/api/Apis";

export const WebActivityApis = {

    getAllActivities() {
        return Apis.get("/admin/web/activity/list")
    },

    addActivity(req) {
        return Apis.post("/admin/web/activity/add", req);
    },

    updateActivity(req) {
        return Apis.post("/admin/web/activity/update", req)
    },

    setActivityImage(file, activityId) {
        let form = {
            file: file,
            id: activityId
        }
        return Apis.uploadWithForm("/admin/web/activity/updateImage", form);
    },

    disableActivity(activityId) {
        let params = {
            id: activityId
        }
        return Apis.get("/admin/web/activity/disable", params);
    },

    resumeActivity(activityId) {
        let params = {
            id: activityId
        }
        return Apis.get("/admin/web/activity/resume", params);
    },

}