<template>
    <el-container>
        <el-header class="my-page-header">
            <el-button type="primary" @click="_onRefreshClick">刷新</el-button>
            <el-button type="primary" @click="_onAddClick">添加</el-button>
        </el-header>

        <el-main style="width: 100%;margin-top: 10px;">
            <el-table :data="labelList" border >
                <el-table-column label="ID" width="80" prop="id"/>
                <el-table-column label="文字颜色" width="160" prop="textColor"/>
                <el-table-column label="背景颜色" width="160" prop="bgColor"/>
                <el-table-column label="预览" width="200" prop="taskTitle">
                    <template #default="scope">
                        <span style="padding: 10px;text-align: center;" :style="{color: scope.row.textColor, backgroundColor: scope.row.bgColor}">T</span>
                    </template>
                </el-table-column>
                <el-table-column label="操作">
                    <template #default="scope">
                        <el-button size="small" type="primary" @click="_onEditClick(scope.row)">编辑</el-button>
                    </template>
                </el-table-column>
            </el-table>
        </el-main>

        <gpt-label-add-dialog v-model:visible="this.addDialogVisible" @success="_onAddSuccess"/>

        <gpt-label-edit-dialog ref="editDialog" v-model:visible="this.editDialogVisible" @success="_onEditSuccess"/>
    </el-container>
</template>

<script>
import GptLabelAddDialog from "@/views/gpt/GptLabelAddDialog";
import {GptApis} from "@/api/gpt-apis";
import GptLabelEditDialog from "@/views/gpt/GptLabelEditDialog";
export default {
    name: "GptLabelManagerPage",
    components: {GptLabelEditDialog, GptLabelAddDialog},
    data() {
        return {
            labelList: [],
            labelItem: {
                id: 0,
                textColor: "",
                bgColor: ""
            },
            addDialogVisible: false,
            editDialogVisible: false,
        }
    },
    mounted() {
        this._loadLabels();
    },
    methods: {

        _loadLabels() {
            GptApis.getAllLabelType().then(res => {
                this.labelList = res.data;
            })
        },

        _onRefreshClick() {
            this._loadLabels();
        },

        _onAddClick() {
            this.addDialogVisible = true;
        },

        _onAddSuccess() {

        },

        _onEditClick(itemData) {
            this.$refs.editDialog.init(itemData);
            this.editDialogVisible = true;
        },
        _onEditSuccess() {
            this._loadLabels();
        }
    }
}
</script>

<style scoped>

</style>