<template>
    <el-dialog width="350px" title="添加管理员" v-model="internalVisible">
        <el-form ref="registerForm" :model="this.form" :rules="rules" label-position="top">
            <el-form-item label="用户名" prop="username">
                <el-input v-model="form.username" placeholder="输入用户名"/>
            </el-form-item>
            <el-form-item label="密码" prop="password">
                <el-input v-model="form.password" placeholder="输入密码"/>
            </el-form-item>
            <el-form-item label="昵称">
                <el-input v-model="form.nickname" placeholder="输入昵称，可为空"/>
            </el-form-item>
        </el-form>

        <template #footer>
            <el-button type="primary" @click="_onSubmit">注册</el-button>
            <el-button @click="dismiss">取消</el-button>
        </template>
    </el-dialog>
</template>

<script>
import BasicDialog from "@/views/base/BasicDialog";
import {UserApis} from "@/api/user-apis";
import {ElMessage} from "element-plus";

export default {
    name: "AdminRegisterDialog",
    mixins: [BasicDialog],
    emits: ['success'],
    data() {
        return {
            form: {
                username: null,
                password: null,
                nickname: null
            },
            rules: {
                username: [
                    {required: true, message: '用户名不能为空', trigger: 'blur'},
                    {min: 4, max: 20, message: '必须由4～20位大小写字母、数字、特殊字符_@组成，且不能以特殊字符开头', trigger: 'change'}
                ],
                password: [
                    {required: true, message: '密码不能为空', trigger: 'blur'},
                    {min: 6, max: 16, message: '必须由6～16位大小写字母、数字、特殊符_@!组成', trigger: 'change'}
                ]
            }
        }
    },

    methods: {

        onDismiss() {
            this.form.username = null;
            this.form.password = null;
            this.form.nickname = null;
        },

        _onSubmit() {
            this.$refs.registerForm.validate((validated) => {
                if (validated) {
                    this._doRegister();
                }
            })
        },

        _doRegister() {
            UserApis.addAdministrator(this.form).then(res => {
                ElMessage.success("添加成功");
                this._onRegisterSuccess();
            })
        },

        _onRegisterSuccess() {
            this.dismiss();
            this.$emit('success');
        }
    }
}
</script>

<style scoped>

</style>