<template>
    <el-dialog v-model="internalVisible" width="360px"
               title="设置图标"
               :before-close="beforeClose"
               @closed="reset">

        <el-form ref="avatarForm" :model="avatarForm" label-width="80px">

            <el-form-item label="模版ID:">
                <el-input v-model="this.avatarForm.templateId" disabled/>
            </el-form-item>

            <el-form-item label="选择头像:" prop="avatar">
                <el-upload ref="uploader" class="anchor-avatar-uploader"
                           action="#" drag
                           accept="image/png,image/jpeg"
                           :limit="1"
                           :auto-upload="false"
                           :show-file-list="false"
                           :on-exceed="_onExceed"
                           :on-remove="_onRemove"
                           :on-change="_onFileChange"
                           :before-upload="beforeUpload"
                           :http-request="onUpload">
                    <div class="anchor-avatar-uploader-holder">
                        <img v-if="selectedAvatarFileUrl"
                             :src="selectedAvatarFileUrl"
                             class="avatar-img">
                        <span class="holder-text">点击选择头像或拖拽到此</span>
                    </div>
                </el-upload>
            </el-form-item>

        </el-form>

        <template #footer>
                <span class="dialog-footer">
                    <el-button type="error" @click="_onRemoveIconClick">删除图标</el-button>
                    <el-button @click="onCancelClick">取消</el-button>
                    <el-button type="primary" @click="onSubmitClick">确定</el-button>
                </span>
        </template>
    </el-dialog>
</template>

<script>
import BasicDialog from "@/views/base/BasicDialog";
import {ElMessage, genFileId} from "element-plus";
import {Plus} from "@element-plus/icons-vue";
import {GptApis} from "@/api/gpt-apis";

export default {
    name: "GptTemplateIconDialog",
    components: {
        Plus
    },
    mixins:[BasicDialog],
    emits: ["result"],
    data() {
        return {
            selectedAvatarFileUrl: null, // 当前选择的头像文件链接，用于预览
            selectedAvatarFile: null, // 当前选择的头像文件，用于上传,
            avatarForm: {
                templateId: 0
            }
        }
    },
    methods: {

        init(templateId, iconPath) {
            this.avatarForm.templateId = templateId;
            this.selectedAvatarFileUrl = iconPath;
        },

        reset() {
            // 清空已选择的文件
            this.$refs.uploader.clearFiles();
            // 清除表单
            this.$refs['avatarForm'].resetFields();
            //清除表单内所有规则检测提示
            this.$refs['avatarForm'].clearValidate();

            let obj1 = this.$data;
            let obj2 = this.$options.data.call(this);
            for (let attrName in obj1) {
                if (attrName !== 'rules') {
                    // noinspection JSUnfilteredForInLoop
                    obj1[attrName] = obj2[attrName]
                }
            }
        },

        beforeClose() {
            this.dismiss();
        },
        onCancelClick() {
            this.dismiss();
        },

        onSubmitClick() {
            this.$refs.uploader.submit();
        },

        notifyAddSuccess(data) {
            this.$emit('result', data);
            this.dismiss();
        },

        _onRemoveIconClick() {
            GptApis.removeTemplateIcon(this.avatarForm.templateId).then(res => {
                ElMessage.success("已删除图片图标");
                this.dismiss();
            })
        },

        // ===========================================
        //      file change
        // ===========================================

        _onExceed(files) {
            this.$refs.uploader.clearFiles();
            const file = files[0];
            file.uid = genFileId();
            this.$refs.uploader.handleStart(file);
        },

        _onRemove() {
            this.selectedAvatarFile = null;
            this.selectedAvatarFileUrl = null;
        },

        // 文件状态改变时的钩子，添加文件、上传成功和上传失败时都会被调用
        _onFileChange(file) {
            // console.log("onChange: " + JSON.stringify(file));
            this.selectedAvatarFile = file;
            // 获取上传图片的本地URL，用于上传前的本地预览
            let URL = null;
            if (window.createObjectURL !== undefined) {
                // basic
                URL = window.createObjectURL(file.raw);
            } else if (window.URL !== undefined) {
                // mozilla(firefox)
                URL = window.URL.createObjectURL(file.raw);
            } else if (window.webkitURL !== undefined) {
                // webkit or chrome
                URL = window.webkitURL.createObjectURL(file.raw);
            }
            this.selectedAvatarFileUrl = URL;
        },

        // avatar-uploader
        beforeUpload(file) {
            if (file === null && this.selectedAvatarFileUrl != null) {
                return true;
            }
            // console.log("beforeUpload file: " + JSON.stringify(file));
            const isJPG = file.type === 'image/jpeg' || file.type === 'image/png';
            const isLt2M = file.size / 1024 / 1024 < 2;

            if (!isJPG) {
                ElMessage.error('Avatar picture must be JPG/PNG format!')
            }
            if (!isLt2M) {
                ElMessage.error('Avatar picture size can not exceed 2MB!')
            }
            return isJPG && isLt2M
        },

        onUpload(param) {
            this._updateAvatar(param);
        },

        // ===========================================
        //      upload
        // ===========================================

        _updateAvatar(param) {
            let file = param.file;

            let form = new FormData();
            form.append("file", file);
            form.append("templateId", this.avatarForm.templateId);

            GptApis.updateTemplateIcon(form).then(res => {
                this.notifyAddSuccess();
            })
        },

    }
}
</script>

<style lang="scss" scoped>

.anchor-avatar-uploader {
    width: 100%;
    //height: 90px;
    //border: 1px dashed #d9d9d9;
    //border-radius: 10px;
    cursor: pointer;
    overflow: hidden;
    line-height: 0;

    :deep(.el-upload-dragger) {
        padding: 0;
    }

}

.anchor-avatar-uploader-holder {
    display: flex;
    align-items: center;
    width: 100%;
    height: 90px;
    justify-content: center;

    .avatar-img {
        width: 64px;
        height: 64px;
        border: 1px dashed #d9d9d9;
        border-radius: 32px;
    }

    .holder-text {
        margin-left: 10px;
        font-size: 12px;
    }
}

</style>