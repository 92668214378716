<template>
  <div class="page-body">
    <div class="page-main-card">
      <div>
        <el-button type="primary" @click="onAddModelClick">添加风格模型</el-button>
      </div>
      <el-table border stripe :data="modelList" style="margin-top: 15px">
        <el-table-column label="ID" prop="id" width="60" align="center"></el-table-column>
        <el-table-column label="名称" prop="name" width="120" align="center"></el-table-column>
        <el-table-column label="描述" prop="description" width="120" align="center"></el-table-column>
        <el-table-column label="风格" prop="styleValue" width="120" align="center"></el-table-column>
        <el-table-column label="训练模型" prop="trainModel" width="120" align="center"></el-table-column>
        <el-table-column label="提示词前缀" prop="promptPrefix" width="120" align="center"></el-table-column>
        <el-table-column label="封面" prop="cover" width="120" align="center">
          <template #default="scope">
            <img v-if="scope.row.cover" style="width: 80px;height: 80px" :src="scope.row.cover" alt="cover">
            <span v-else>{{ scope.row.cover }}</span>
          </template>
        </el-table-column>
        <el-table-column label="修改时间" prop="updateTime" width="100" align="center"></el-table-column>
        <el-table-column label="创建时间" prop="createTime" width="100" align="center"></el-table-column>
        <el-table-column label="操作" align="center">
          <template #default="scope">
            <el-button text type="primary" @click="onItemEditClick(scope.row)">编辑</el-button>
            <el-button text type="primary" @click="onItemCoverEditClick(scope.row)">封面</el-button>
            <el-button v-if="scope.row.status === 0" text type="danger" @click="onItemDeleteClick(scope.row)">下架</el-button>
            <el-button v-else text type="success" @click="onItemResumeClick(scope.row)">恢复</el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <draw-model-edit-drawer
      ref="editDrawer"
      v-model:visible="editDialogVisible"
      @success="onEditSuccess">
    </draw-model-edit-drawer>
    <draw-cover-edit-drawer
      ref="coverEditDrawer"
      v-model:visible="coverDialogVisible"
      @success="onEditSuccess">
    </draw-cover-edit-drawer>
  </div>
</template>

<script>
import {DrawsApi} from "@/api/draws-api";
import DrawModelEditDrawer from "@/views/draws/DrawModelEditDrawer.vue";
import DrawCoverEditDrawer from "@/views/draws/DrawCoverEditDrawer.vue";

export default {
  name: "DrawModelManager",
  components: {DrawCoverEditDrawer, DrawModelEditDrawer},
  data() {
    return {
      modelList: [],
      editDialogVisible: false,
      coverDialogVisible: false
    }
  },
  mounted() {
    this.getModelList();
  },
  methods: {
    getModelList() {
      DrawsApi.getModelList().then(res => {
        this.modelList = res.data;
      })
    },
    onAddModelClick() {
      this.editDialogVisible = true;
    },
    onItemEditClick(row) {
      this.$refs.editDrawer.showEditing(row);
    },
    onItemCoverEditClick(row) {
      this.$refs.coverEditDrawer.showEditing(row);
    },
    onItemDeleteClick(row) {
      this.$confirm('确定要下架该风格吗？', '温馨提示', {
        type: 'warning',
        confirmButtonText: '下架'
      }).then(async () => {
        DrawsApi.deleteModel(row.id).then(res => {
          this.getModelList()
          this.$message.success('已下架')
        })
      })
    },
    onItemResumeClick(row) {
      DrawsApi.resumeModel(row.id).then(res => {
        this.getModelList()
        this.$message.success('已恢复')
      })
    },
    onEditSuccess() {
      this.getModelList();
    }
  }
}
</script>

<style lang="scss" scoped>

.page-body {
  width: 100%;
  height: 100%;
  background-color: $systemBackground;
  box-sizing: border-box;
  padding: 15px;

  .page-main-card {
    width: 100%;
    //height: 100%;
    background-color: white;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    padding: 10px;
    overflow: auto;
  }
}

</style>