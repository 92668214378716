<template>
    <el-upload class="audio-uploader"
               ref="uploader" drag action="#"
               :accept="this.accept"
               :limit="1"
               :auto-upload="false"
               :show-file-list="false"
               :on-exceed="_onExceed"
               :on-remove="_onRemove"
               :on-change="_onUploadFileChange"
               :beforeUpload="_beforeUpload"
               :http-request="_uploadHandler">

        <div v-if="this.filename" class="audio-selected-file__wrapper">
            <div class="cell-item">
                <span class="cell-item__label">文件名称:</span>
                <span class="cell-item__value">{{ this.filename }}</span>
            </div>
            <div class="cell-item">
                <span class="cell-item__label">音频时长:</span>
                <span class="cell-item__value">{{ this.durationDesc }}</span>
            </div>
        </div>

        <div v-else-if="this.soundUrl" class="audio-selected-file__wrapper">
            <div class="cell-item">
                <span class="cell-item__label">已有音频文件：点击或者拖拽进行修改</span>
            </div>
        </div>

        <div v-else class="audio-uploader-placeholder">
            <span>{{ this.placeholderText }}</span>
        </div>

    </el-upload>
</template>

<script>
import {AudioUtils} from "@/utils/audio-util";
import {DateUtils} from "@/utils/DateUtils";
import {ElMessage, genFileId} from "element-plus";

export default {
    name: "AudioUploader",
    props: {
        accept: {
            type: String,
            default: ".mp3,.wav"
        },
        placeholderText: {
            type: String,
            default: "点击上传音频文件或拖拽到此区域"
        },
        /**
         * 最大时长限制：单位秒
         */
        maxDuration: {
            type: Number,
            default: 1200
        },
        /**
         * 最大文件大小：单位M
         */
        maxSize: {
            type: Number,
            default: 20
        },
        /**
         * 音频文件链接
         */
        soundUrl: {
            type: String,
            default: null
        }
    },
    emits: ['fileChange', 'upload-handler'],
    data() {
        return {
            selectedFile: null,
            filename: null,
            duration: 0,
            durationDesc: null,
        }
    },
    methods: {

        getSelectedFile() {
            return this.selectedFile
        },

        submit() {
            this.$refs.uploader.submit();
        },

        _onUploadFileChange(file) {
            this.selectedFile = file;
            this.filename = file.name;
            AudioUtils.parseAudioDuration(file, (duration) => {
                this.duration = duration;
                this.durationDesc = DateUtils.formatDuration(duration);
            })
        },

        _onExceed(files) {
            this.$refs.uploader.clearFiles();
            const file = files[0];
            file.uid = genFileId()
            this.$refs.uploader.handleStart(file);
        },

        _onRemove() {
            this.selectedFile = null;
            this.filename = null;
            this.duration = 0;
            this.durationDesc = null;
        },

        _beforeUpload(file) {
            // 30分钟的音频可以在10秒内完成识别
            // 音频格式：支持AAC、MP3、OPUS、WAV格式编码的音频。
            // 使用限制：支持100 MB以内且时长不超过2小时的音频文件的识别，时长超过2小时的文件请使用录音文件识别普通版。
            if (this.duration > this.maxDuration) {
                ElMessage.error(`音频时长不超过${this.maxDuration}秒`)
                return false;
            }
            const sizeEnable = file.size / 1024 / 1024 < this.maxSize;
            if (!sizeEnable) {
                ElMessage.error(`文件大小不超过${this.maxSize}M`)
            }
            return sizeEnable
        },

        _uploadHandler(param) {
            let file = param.file;
            this.$emit('upload-handler', file, this.duration);
        },
    }
}
</script>

<style lang="scss" scoped>

.audio-uploader {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;

    :deep(.el-upload) {
        width: 100%;
    }

    :deep(.el-upload-dragger) {
        padding: 0;
        width: 100%;
        height: 80px;
    }
}

.audio-selected-file__wrapper {
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;

    .cell-item {
        display: flex;
        align-items: center;
        font-size: 13px;
        margin-bottom: 10px;
    }

    .cell-item--column {
        display: flex;
        flex-direction: column;
        align-items: center;
        font-size: 13px;
        margin-bottom: 10px;
    }

    .cell-item__label {
    }

    .cell-item__value {
        font-weight: bold;
        margin-left: 10px;
    }
}

.audio-uploader-placeholder {
    font-size: 12px;
}

</style>