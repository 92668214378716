import request from "@/api/request";
import {Apis} from "@/api/Apis";

const MODULE_PATH = "/admin/user";
export const UserApis = {

    /**
     * 添加管理员账户
     * @param data
     */
    addAdministrator(data) {
        return Apis.post(MODULE_PATH + "/addAdmin", data);
    },

    /**
     * 获取管理员列表
     */
    getAdministratorList() {
        return Apis.get(MODULE_PATH + "/adminList");
    },

    /**
     * 获取近7天新增用户数
     */
    getNewUsersCount() {
        return Apis.get("/admin/user/newUsersCount")
    },

    listUser(page, pageSize) {
        let params = {
            page: page,
            pageSize: pageSize
        }
        return Apis.get("/admin/user/list", params);
    },

    searchUser(type, content) {
        let body = {
            type: type,
            content: content,
            page: 1,
            pageSize: 30
        }
        return Apis.post("/admin/user/search", body);
    },

    resetPassword(username, password) {
        let body = {
            username: username,
            password: password
        }
        return Apis.post("/admin/user/resetPassword", body);
    }
}

export function login(data) {
    return request({
        url: '/admin/user/login',
        method: 'post',
        data
    })
}

export function signOut() {
    return request({
        url: '/admin/user/signOut',
        method: 'post',
    })
}

export function addAdministrator(data) {
    return request({
        url: '/admin/user/add',
        method: 'post',
        data
    })
}

/**
 * 查询管理员账号
 */
export function getAdministratorList() {
    return request({
        url: '/admin/user/adminList',
        method: 'get'
    })
}

/**
 * 查询所有用户
 * @param page 页码
 * @param pageSize 记录数
 * @returns {*}
 */
export function getUserList(page, pageSize) {
    return request({
        url: '/admin/user/list',
        method: 'get',
        params: {
            page: page,
            pageSize: pageSize
        }
    })
}

/**
 * 修改密码
 * @param data
 * @returns {*}
 */
export function modifyPassword(data) {
    return request({
        url: '/admin/user/modifyPassword',
        method: 'post',
        data
    })
}

/**
 * 恢复账号
 * @param userId
 * @returns {*}
 */
export function resumeUser(userId) {
    return request({
        url: '/admin/user/resume',
        method: 'post',
        params: {
            userId: userId
        }
    })
}

/**
 * 禁用账号
 * @param userId
 * @returns {*}
 */
export function disableUser(userId) {
    return request({
        url: '/admin/user/disable',
        method: 'post',
        params: {
            userId: userId
        }
    })
}

/**
 * 注销账号
 * @param userId
 * @returns {*}
 */
export function unregisterUser(userId) {
    return request({
        url: '/admin/user/unregister',
        method: 'post',
        params: {
            userId: userId
        }
    })
}

/**
 * 删除账号
 * @param userId
 * @returns {*}
 */
export function deleteUser(userId) {
    return request({
        url: '/admin/user/delete',
        method: 'post',
        params: {
            userId: userId
        }
    })
}

/**
 * 查询登录日志{userId, startTime, endTime, page, pageSize}
 * @param data
 * @returns {*}
 */
export function getLoginLogs(data) {
    return request({
        url: '/admin/user/loginLogs',
        method: 'post',
        data
    })
}