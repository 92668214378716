import axios, {AxiosError} from "axios";
import Store from "@/store"
import {ElMessage} from "element-plus";
import {AccountManager} from "@/data/AccountManager";

// https://juejin.cn/post/6844903891872514056

const service = axios.create({
    // baseURL: "http://localhost:8080",
    baseURL: process.env.VUE_APP_API_HOST,
    // timeout: 20 * 1000,
    // withCredentials: true, // send cookies when cross-domain requests
    // headers: {
    //     'Content-Type': 'application/x-www-form-urlencoded',
    // }
});

// 默认 post 请求，使用 application/json 形式
service.defaults.headers.post['Content-Type'] = 'application/json';

// 请求拦截
service.interceptors.request.use(config => {
    // 在这可以添加一些请求头的逻辑,如配置token
    if (Store.getters.token) {
        config.headers['Authorization'] = `Bearer ${Store.getters.token}`
    }
    return config
}, error => {
    // do something with request error
    console.log(error);// for debug
    return Promise.reject(error)
});

// 响应拦截
service.interceptors.response.use(response => {
    // 处理服务器返回的数据 response.data = {code, message, data}
    if (response.data.code === 200) {
        // 直接返回服务端的返回数据
        return response.data;
    }
    // 登录过期、未登录等处理
    if (response.data.code === 400) {
        AccountManager.setLoginOut();
    }
    // 如果服务端返回的code非200，则表示接口调用失败
    let error = {
        code: response.data.code,
        message: response.data.message,
    }
    const errMsg = error.message;
    let isHandled = false;
    setTimeout(() => {
        if (!isHandled) {
            ElMessage.error(errMsg);
        }
    })
    error.setHandled = function (handled = true) {
        isHandled = handled;
    }
    return Promise.reject(error);

}, error => {
    let res = {
        code: 0,
        message: ''
    }
    if (error instanceof AxiosError) {
        res.code = error.code
        res.message = error.message;
    } else if (error && error.response) {
        res.code = error.response.status;
        res.message = error.response.statusText;
    } else {
        res.code = 206;
        res.message = "网络请求超时";
    }

    if (res.code === 400) {
        AccountManager.setLoginOut();
    }
    const errMsg = res.message;
    let isHandled = false;
    setTimeout(() => {
        if (!isHandled) {
            ElMessage.error(errMsg);
        }
    })
    error.setHandled = function (handled = true) {
        isHandled = handled;
    }
    return Promise.reject(res);
});

export default service