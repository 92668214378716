<template>
    <el-container>
        <el-header class="my-page-header">
            <el-row>
                <common-page-title-label title="App版本管理"/>
                <!--<el-radio-group v-model="selectedClientId" @change="_loadAllVersion">-->
                <!--    <el-radio-button v-for="item in appList" :key="item.clientId" :label="item.clientId">-->
                <!--        {{ item.name + "-" + item.clientName }}-->
                <!--    </el-radio-button>-->
                <!--</el-radio-group>-->
                <el-radio-group v-model="selectedChannelIndex" @change="_loadAllVersion">
                    <el-radio-button v-for="(item,index) in channelList" :key="item.clientId" :label="index">
                        {{ item.channel }}
                    </el-radio-button>
                </el-radio-group>
                <common-add-button class="ml-16" title="添加新版本" @click="_onAddVersion"/>
                <el-button type="primary" @click="_loadAllVersion">刷新</el-button>
            </el-row>

            <el-main>
                <el-table v-loading="loading" :data="tableData" border cell-class-name="table-cell-nowrap">
                    <el-table-column label="ID" prop="id" width="80" align="center"/>
                    <el-table-column label="名称" prop="name" width="80" align="center"/>
                    <el-table-column label="渠道" prop="channel" width="100" align="center"/>
                    <el-table-column label="包名" prop="appId" width="180" align="center"/>
                    <el-table-column label="审核中" prop="review" width="80" align="center">
                        <template #default="scope">
                            <!--{{_reviewName(scope.row.review)}}-->
                            <el-switch :value="scope.row.review"
                                       active-color="#13ce66"
                                       @change="_onItemReviewChange(scope.row, $event)"/>
                        </template>
                    </el-table-column>
                    <el-table-column label="版本名" prop="version" width="100" align="center"/>
                    <el-table-column label="版本号" prop="build" width="100" align="center"/>
                    <!--<el-table-column label="最低版本" prop="requireVersion" width="100" align="center"/>-->
                    <!--<el-table-column label="最低版本号" prop="requireBuild" width="100" align="center"/>-->
                    <el-table-column label="更新内容" prop="details" width="200" align="center"/>
                    <el-table-column label="静默更新" prop="silent" width="100" align="center">
                        <template #default="scope">
                            <!--{{_reviewName(scope.row.review)}}-->
                            <el-switch :value="scope.row.silent"
                                       active-color="#13ce66"
                                       @change="_onItemSilentChange(scope.row, $event)"/>
                        </template>
                    </el-table-column>
                    <el-table-column label="文件大小" prop="fileSize" width="100" align="center"/>
                    <el-table-column label="文件地址" prop="filePath" fixed="right"/>
                    <el-table-column label="操作" fixed="right">
                        <template #default="scope">
                            <el-button size="small" primary @click="_onItemEditClick(scope.$index, scope.row)">
                                编辑
                            </el-button>
                            <el-button v-if="scope.row.clientId === 2" size="small" primary @click="_onItemFileEditClick(scope.$index, scope.row)">
                                修改Apk文件
                            </el-button>
                            <el-button size="small" type="danger" @click="_onItemDeleteClick(scope.$index, scope.row)">
                                删除
                            </el-button>
                        </template>
                    </el-table-column>
                </el-table>
            </el-main>

            <!-- 分页插件 -->
            <el-footer>
                <el-pagination
                    :page-size="20"
                    :page-count="totalPage"
                    :current-page="page"
                    layout="prev, pager, next"
                    @current-change="_onPageChange">
                </el-pagination>
            </el-footer>

        </el-header>

        <app-version-add-dialog ref="addDialog" v-model:visible="addDialogVisible"/>
        <app-version-edit-dialog ref="editDialog" v-model:visible="editDialogVisible" @success="_loadAllVersion"/>
        <app-version-file-edit-dialog ref="fileDialog" v-model:visible="fileDialogVisible" @success="_loadAllVersion"/>
    </el-container>
</template>

<script>
import CommonPageTitleLabel from "@/views/common/CommonPageTitleLabel";
import CommonAddButton from "@/views/common/CommonAddButton";
import {AppVersionApis} from "@/api/app-version-apis";
import AppVersionAddDialog from "@/views/setting/AppVersionAddDialog";
import {ElMessage} from "element-plus";
import AppVersionEditDialog from "@/views/setting/AppVersionEditDialog";
import {ToastUtil} from "@/utils/toast-util";
import {QxAlertDialog} from "@/components/QxAlertDialog";
import AppVersionFileEditDialog from "@/views/setting/AppVersionFileEditDialog";


const Client = {
    android: 2,
    ios: 3
}

const AppIds = {
    android: "com.qxvoice.qingxiu",
    ios: "com.qxvoice.AppMain"
}

const AppChannelList = [
    {
        appId: AppIds.ios,
        clientId: Client.ios,
        channel: "AppStore",
        name: "App Store",
    },
    {
        appId: AppIds.android,
        clientId: Client.android,
        channel: "default",
    },
    {
        appId: AppIds.android,
        clientId: Client.android,
        channel: "huawei",
    },
    {
        appId: AppIds.android,
        clientId: Client.android,
        channel: "xiaomi",
    },
    {
        appId: AppIds.android,
        clientId: Client.android,
        channel: "vivo",
    },
    {
        appId: AppIds.android,
        clientId: Client.android,
        channel: "oppo",
    },
    {
        appId: AppIds.android,
        clientId: Client.android,
        channel: "douyin",
    },
    {
        appId: AppIds.android,
        clientId: Client.android,
        channel: "baidu",
    },
    {
        appId: AppIds.android,
        clientId: Client.android,
        channel: "yyb",
    },
    {
        appId: AppIds.android,
        clientId: Client.android,
        channel: "google",
    },
]

export default {
    name: "AppVersionManagerPage",
    components: {
        AppVersionFileEditDialog,
        AppVersionEditDialog, AppVersionAddDialog, CommonAddButton, CommonPageTitleLabel},
    data() {
        return {
            channelList: AppChannelList,
            selectedChannelIndex: 0,
            loading: false,
            page: 1,
            totalPage: 1,
            tableData: [],
            addDialogVisible: false,
            editDialogVisible: false,
            fileDialogVisible: false,
        }
    },
    mounted() {
        this._loadAllVersion();
    },
    methods: {

        _onPageChange(newPage) {
            this.page = newPage;
            this._loadAllVersion();
        },

        _loadAllVersion() {
            let selectedChannel = this.channelList[this.selectedChannelIndex];
            let appId = selectedChannel.appId;
            let clientId = selectedChannel.clientId;
            let channel = selectedChannel.channel;
            this.loading = true;
            AppVersionApis.searchVersions(this.page, clientId, appId, channel).then(res => {
                let pageData = res.data;
                this.totalPage = pageData.total;
                this.tableData = pageData.list;
                this.loading = false;
            }).catch(_ => {
                this.loading = false;
            })
        },

        _onItemReviewChange(itemData, newReview) {
            let versionId = itemData.id;
            AppVersionApis.updateReview(versionId, newReview).then(_ => {
                // 修改成功
                itemData.review = newReview;
                if (newReview) {
                    ElMessage.success("已开启审核");
                } else {
                    ElMessage.success("已关闭审核");
                }
            }).catch(_ => {
                // 修改失败
                itemData.review = !newReview;
            })
        },

        _onItemSilentChange(itemData, newSilent) {
            let versionId = itemData.id;
            AppVersionApis.updateSilent(versionId, newSilent).then(_ => {
                // 修改成功
                ToastUtil.toast("修改成功");
                this._loadAllVersion();
            }).catch(_ => {

            })
        },

        // ===========================================
        //      Add New Version
        // ===========================================

        _onAddVersion() {
            let selectedChannel = this.channelList[this.selectedChannelIndex];
            this.$refs.addDialog.init(selectedChannel);
            this.addDialogVisible = true;
        },

        // ===========================================
        //      Edit
        // ===========================================

        _onItemEditClick(index, itemData) {
            this.$refs.editDialog.init(itemData);
            this.editDialogVisible = true;
        },

        _onItemFileEditClick(index, itemData) {
            this.$refs.fileDialog.init(itemData);
            this.fileDialogVisible = true;
        },

        _onItemDeleteClick(index, itemData) {
            QxAlertDialog.warn("删除提示", "删除后无法恢复，是否继续", "删除", () => {
                this._doDelete(itemData.id);
            }, "取消")
        },

        _doDelete(versionId) {
            AppVersionApis.deleteVersion(versionId).then(res => {
                ToastUtil.toast("已删除")
                this._loadAllVersion();
            })
        }

    }
}
</script>

<style lang="scss">

.table-cell-nowrap {
    .cell {
        white-space: nowrap;
    }
}

</style>