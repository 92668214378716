<template>
    <span v-if="level === 1">普通AI</span>
    <span v-if="level === 2" class="level-advance">高级AI</span>
    <span v-if="level === 3" class="level-advance">情绪AI</span>
</template>

<script>
export default {
    name: "AiLevelLabel",
    props: {
        level: {
            type: Number,
            default: 1
        }
    }
}
</script>

<style scoped lang="scss">
@import "src/styles/colors.scss";

.level-advance {
    color: $systemRed;
}

</style>