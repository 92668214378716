<!-- 修改密码，需要传入参数：userId, username -->
<template>

    <el-dialog custom-class="card-dialog" v-model="dialogVisible" width="25%" title="修改密码" :before-close="dismiss" @close="dismiss">
        <el-form ref="updateForm" v-model="updateForm" label-width="80px" label-position="top">
            <el-form-item label="用户名: ">
                <el-input v-model="updateForm.username" disabled/>
            </el-form-item>

            <el-form-item label="旧密码: ">
                <el-input v-model="updateForm.oldPassword" type="password" show-password placeholder="请输入旧密码"/>
            </el-form-item>

            <el-form-item label="新密码: ">
                <el-input v-model="updateForm.newPassword" type="password" show-password placeholder="请输入新密码(6-16位数字字母组合)"/>
            </el-form-item>
        </el-form>

        <template #footer>
            <span class="dialog-footer">
                <el-button type="danger" @click="onSubmit">确定</el-button>
                <el-button style="margin-left: 20px" @click="dismiss">取消</el-button>
            </span>
        </template>
    </el-dialog>

</template>

<script>
import StringUtils from "@/utils/stringUtils";
import bus from "@/utils/bus";
import {ElMessage} from "element-plus";
import {modifyPassword} from "@/api/user-apis";

export default {
    name: "ModifyPasswordDialog",
    props: {
        visible: {
            type: Boolean,
            default: false
        }
    },
    computed: {
        dialogVisible() {
            return this.visible
        }
    },
    emits: ['dismiss'],
    data() {
        return {
            userData: null,
            updateForm: {
                username: '',
                oldPassword: '',
                newPassword: '',
            }
        }
    },
    mounted() {
        bus.on('userData', (data) => {
            console.log(data)
            this.userData = data;
            this.updateForm.username = data.username;
        })
    },
    methods: {
        dismiss() {
            this.$emit('dismiss')
        },
        reset() {
            this.$refs['updateForm'].resetFields();
        },

        onSubmit() {
            if (this.userData == null) {
                ElMessage.warning("userData为null")
                return
            }
            if (StringUtils.isBlank(this.updateForm.username)) {
                ElMessage.warning("username为空")
                return
            }
            if (StringUtils.isBlank(this.updateForm.oldPassword)) {
                ElMessage.warning("旧密码不能为空")
                return
            }
            if (StringUtils.isBlank(this.updateForm.newPassword)) {
                ElMessage.warning("新密码不能为空")
                return
            }
            // if (this.updateForm.oldPassword === this.updateForm.newPassword) {
            //     ElMessage.warning("新密码与旧密码相同")
            // }

            modifyPassword(this.updateForm).then(res => {
                if (res.code === 200) {
                    ElMessage.success("修改成功")
                    this.dismiss();
                } else {
                    ElMessage.warning("修改失败：" + res.msg);
                }
            }).catch(error => {
                ElMessage.error("请求失败: " + error.toString());
            })
        }
    }
}
</script>

<style>

.card-dialog {
    border-radius: 5px;
}

</style>