<template>
    <div>
        <el-container>
            <el-header class="my-page-header">
                <common-page-title-label title="背景音乐"/>
                <common-add-button title="添加背景音乐" @click="uploadDialogVisible = true"/>
            </el-header>

            <el-main>
                <el-table v-model="tableLoading" border :data="tableData">

                    <el-table-column label="ID" prop="id" width="64px" align="center"/>

                    <el-table-column label="名称" prop="name" width="180px" align="center"/>

                    <el-table-column label="时长" prop="duration" width="64px" align="center">
                        <template #default="scope">
                            <span>{{_makeDurationDesc(scope.row.duration)}}</span>
                        </template>
                    </el-table-column>

                    <el-table-column label="使用次数" prop="count" width="100px" align="center"/>

                    <el-table-column label="状态" prop="status" width="80px" align="center">
                        <template #default="scope">
                            <span>{{_statusStr(scope.row.status)}}</span>
                        </template>
                    </el-table-column>

                    <el-table-column label="音频地址" width="360px" prop="audioPath">
                        <template #default="scope">
                            <audio :src="scope.row.filepath" controls preload="none" style="height: 40px"/>
                        </template>
                    </el-table-column>

                    <el-table-column label="操作" align="left">
                        <template #default="scope" >
                            <el-button @click="onAuditionClick(scope.row)">试听</el-button>
                            <el-button @click="onReplaceClick(scope.row)">更新</el-button>
                            <el-button @click="onEditClick(scope.$index, scope.row)">编辑</el-button>
                            <el-button v-if="scope.row.status !== 0" type="success" @click="onResumeClick(scope.$index, scope.row)">恢复</el-button>
                            <el-button v-if="scope.row.status === 0" type="warning" @click="onDisableClick(scope.$index, scope.row)">禁用</el-button>
                            <el-button v-if="scope.row.status === 0" type="danger" @click="onDeleteClick(scope.$index, scope.row)">删除</el-button>
                        </template>
                    </el-table-column>

                </el-table>
            </el-main>

            <!-- 分页插件 -->
            <el-footer>
                <el-pagination
                    :page-size="pageSize"
                    :pager-count="11"
                    :total="total"
                    :current-page="page"
                    layout="prev, pager, next"
                    @current-change="onPageChange"/>
            </el-footer>
        </el-container>

        <bgm-upload-dialog :visible="uploadDialogVisible" @dismiss="uploadDialogVisible = false"/>

        <!-- 编辑弹框 -->
        <el-dialog width="25%" title="编辑" v-model="editingDialogVisible" @close="dismissEditing">
            <el-form ref="editingElForm" :model="editingForm" label-width="80px">
                <el-form-item label="名称:" prop="name">
                    <el-input v-model="editingForm.name"/>
                </el-form-item>

                <el-form-item label="使用次数:">
                    <el-input v-model="editingForm.count" type="number"/>
                </el-form-item>
            </el-form>

            <template #footer>
                <el-button @click="dismissEditing">取消</el-button>
                <el-button type="primary" @click="onEditSubmit">提交</el-button>
            </template>
        </el-dialog>

        <bgm-audition-dialog ref="auditionDialog" v-model:visible="auditionDialogVisible"/>
        <bgm-replace-dialog ref="replaceDialog" v-model:visible="replaceDialogVisible"/>

    </div>
</template>

<script>
import BgmUploadDialog from "@/views/dubbing/bgm/BgmUploadDialog";
import {DubbingApis} from "@/api/dubbing";
import {ElMessage, ElMessageBox} from "element-plus";
import CommonPageTitleLabel from "@/views/common/CommonPageTitleLabel";
import CommonAddButton from "@/views/common/CommonAddButton";
import {DateUtils} from "@/utils/DateUtils";
import BgmAuditionDialog from "@/views/dubbing/bgm/BgmAuditionDialog";
import BgmReplaceDialog from "@/views/dubbing/bgm/BgmReplaceDialog";
export default {
    name: "BgmManagerPage",
    components: {BgmReplaceDialog, BgmAuditionDialog, CommonAddButton, CommonPageTitleLabel, BgmUploadDialog},
    data() {
        return {
            tableLoading: false,
            tableData: [],
            total: 0,
            page: 1,
            pageSize: 20,
            uploadDialogVisible: false,
            // editing
            editingDialogVisible: false,
            editingForm: {
                id: 0,
                name: '',
                count: null
            },
            editingRules: {
                name: [
                    {required: true, message: "名称不能为空", trigger: 'blur'},
                    {pattern: /^[A-Za-z0-9\u4e00-\u9fa5]+$/, message: "名称只能由汉字、字母、数字组成，且长度不超过50", trigger: 'blur'}
                ]
            },
            auditionDialogVisible: false,
            replaceDialogVisible: false,
        }
    },
    mounted() {
        this.loaTableData();
    },
    methods: {

        _statusStr(status) {
            if (status === 1) {
                return "已删除"
            } else if (status === 2) {
                return "已禁用"
            } else {
                return "正常"
            }
        },

        loaTableData() {
            this.tableLoading = true;
            DubbingApis.getBgmList(this.page, this.pageSize).then(res => {
                this.tableLoading = false;
                if (res.code === 200) {
                    this.total = res.data.total;
                    this.tableData = res.data.list;
                } else {
                    ElMessage.error("获取背景音乐失败：" + res.message);
                }
            }).catch(error => {
                this.tableLoading = false;
                ElMessage.error("获取背景音乐失败：" + error.toString());
            })

        },

        onPageChange(newPage) {
            this.page = newPage;
            this.loaTableData();
        },

        _makeDurationDesc(duration) {
            return DateUtils.formatDuration(duration);
        },

        // ===========================================
        //      Edit
        // ===========================================

        dismissEditing() {
            this.editingDialogVisible = false;
            this.$refs.editingElForm.resetFields();
            this.$refs.editingElForm.clearValidate();

        },

        onEditClick(index, itemData) {
            this.editingForm.id = itemData.id;
            this.editingForm.name = itemData.name;
            this.editingForm.count = itemData.count;
            this.editingDialogVisible = true;
        },

        onEditSubmit() {
            let param = {
                id: this.editingForm.id,
                name: this.editingForm.name,
                count: this.editingForm.count
            }
            DubbingApis.updateBgm(param).then(res => {
                if (res.code === 200) {
                    ElMessage.success("更新成功");
                    this.loaTableData();
                } else {
                    ElMessage.error("更新失败: " + res.message);
                }

            }).catch(error => {
                ElMessage.error("更新失败：" + error.toString());
            })
        },

        onResumeClick(index, itemData) {
            let bgmId = itemData.id;
            DubbingApis.resumeBgm(bgmId).then(res => {
                if (res.code === 200) {
                    ElMessage.success("恢复成功")
                    this.loaTableData();
                } else {
                    ElMessage.error("恢复失败: " + res.message);
                }
            }).catch(error => {
                ElMessage.error("恢复失败：" + error.toString());
            })
        },

        // ===========================================
        //      Disable
        // ===========================================

        onDisableClick(index, itemData) {
            ElMessageBox.confirm("请确认后再执行此操作", "温馨提示", {
                confirmButtonText: '禁用',
                cancelButtonText: '取消',
                type: 'error'
            }).then(() => {
                this.handleDisable(index, itemData)
            })
        },

        handleDisable(index, itemData) {
            let bgmId = itemData.id;

            DubbingApis.disableBgm(bgmId).then(res => {
                if (res.code === 200) {
                    ElMessage.success("禁用成功")
                    this.loaTableData();
                } else {
                    ElMessage.error("禁用失败: " + res.message);
                }
            }).catch(error => {
                ElMessage.error("禁用失败：" + error.toString());
            })
        },

        // ===========================================
        //      Delete
        // ===========================================

        onDeleteClick(index, itemData) {
            ElMessageBox.confirm("删除后无法恢复，请确认后再执行此操作", "温馨提示", {
                confirmButtonText: '删除',
                cancelButtonText: '取消',
                type: 'error'
            }).then(() => {
                this.handleDelete(index, itemData)
            })
        },

        handleDelete(index, itemData) {
            let bgmId = itemData.id;

            DubbingApis.deleteBgm(bgmId).then(res => {
                if (res.code === 200) {
                    ElMessage.success("删除成功")
                    this.loaTableData();
                } else {
                    ElMessage.error("删除失败: " + res.message);
                }
            }).catch(error => {
                ElMessage.error("删除失败：" + error.toString());
            })
        },

        // ===========================================
        //      试听
        // ===========================================

        onAuditionClick(itemData) {
            let bgmId = itemData.id;
            let bgmName = itemData.name;

            const dialog = this.$refs.auditionDialog;
            dialog.init(bgmId, bgmName);
           this.auditionDialogVisible = true;
        },

        onReplaceClick(itemData) {
            let bgmId = itemData.id;
            let bgmName = itemData.name;

            const dialog = this.$refs.replaceDialog;
            dialog.init(bgmId, bgmName);
            this.replaceDialogVisible = true;
        }

    }
}
</script>

<style scoped>

</style>