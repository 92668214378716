<template>
    <el-container>
        <el-header class="my-page-header">
            <el-row>
                <common-page-title-label title="短信群发日志"/>
                <el-button type="success" class="ml-12" @click="sendDialogVisible = true">群发短信</el-button>
            </el-row>
        </el-header>

        <el-main style="height: 100%;">
            <el-table
                v-loading="tableLoading"
                style="width: 100%;" border
                :data="smsLogList">

                <el-table-column label="ID" prop="id" width="80" align="center"/>
                <el-table-column label="发送时间" prop="createTime" width="180" align="center"/>
                <el-table-column label="手机号" prop="phone" width="120" align="center"/>
                <!--<el-table-column label="模版ID" prop="templateId" width="180" align="center"/>-->
                <el-table-column label="模版名称" prop="templateName" width="140" align="center"/>
                <el-table-column label="发送结果" prop="statusText" width="120" align="center"/>
                <el-table-column label="回执id" prop="bizId" width="180" align="center"/>
                <el-table-column label="短信内容" prop="content" align="center"/>
                <!--<el-table-column label="操作">-->
                <!--    <template #default="scope">-->
                <!--        <el-button v-if="scope.row.status === 0" size="small" type="danger"-->
                <!--                   @click="onItemDeleteClick(scope.$index, scope.row)">-->
                <!--            删除-->
                <!--        </el-button>-->
                <!--    </template>-->
                <!--</el-table-column>-->
            </el-table>

            <el-pagination layout="prev,pager,next"
                           :current-page="this.page"
                           :page-size="this.pageSize"
                           :page-count="this.totalPage" />
        </el-main>

        <sms-send-dialog v-model:visible="sendDialogVisible"/>
    </el-container>
</template>

<script>
import CommonPageTitleLabel from "@/views/common/CommonPageTitleLabel";
import SmsSendDialog from "@/views/sms/SmsSendDialog";
import {SmsApis} from "@/api/SmsApis";
export default {
    name: "SmsLogPage",
    components: {SmsSendDialog, CommonPageTitleLabel},
    data() {
        return {
            logItem: {
                id: 0,
                phone: '',
                templateId: 0,
                templateName: ''
            },
            smsLogList: [],
            tableLoading: false,
            sendDialogVisible: false,
            page: 1,
            pageSize: 20,
            totalPage: 1
        }
    },
    mounted() {
        this._getSmsLogList();
    },
    methods: {
        _onPageChange(newPage) {
            this.page = newPage;
            this._getSmsLogList();
        },
        _getSmsLogList() {
            this.tableLoading = true;
            SmsApis.getSmsLogList(this.page, this.pageSize).then(res => {
                this.tableLoading = false;
                let pageData = res.data;
                this.totalPage = pageData.total;
                this.smsLogList = pageData.list;
            }).catch(_ => {
                this.tableLoading = false;
            })
        }
    }
}
</script>

<style lang="scss" scoped>

</style>