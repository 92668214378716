<template>
    <el-drawer v-model="internalVisible" title="设置AI样音" :destroy-on-close="true">
        <el-form ref="form" :model="sampleForm" label-width="90px" label-position="right">
            <el-form-item label="发音人">
                <el-input disabled v-model="sampleForm.voice"/>
            </el-form-item>
            <el-form-item label="风格">
                <el-input disabled v-model="sampleForm.templateName"/>
            </el-form-item>
            <el-form-item label="样音名称">
                <el-input v-model="sampleForm.name" placeholder="输入样音名称"/>
            </el-form-item>
            <el-form-item label="样音简介">
                <el-input v-model="sampleForm.comment" placeholder="输入样音简介"/>
            </el-form-item>
            <el-form-item label="场景">
                <el-select v-model="sampleForm.sceneId" value-key="id" @change="_onSceneSelected">
                    <el-option v-for="item in sceneList"
                               :label="item.name"
                               :key="item.id"
                               :value="item.id"/>
                </el-select>
            </el-form-item>
        </el-form>

        <div class="drawer-footer">
            <el-button class="cancel-btn">取消</el-button>
            <el-button type="primary" @click="_onCommitClick">确定</el-button>
        </div>
    </el-drawer>
</template>

<script>

// 将模版设置为AI样音
import BasicDialog from "@/views/base/BasicDialog";
import {AnchorApis} from "@/api/anchor";
import {ToastUtil} from "@/utils/toast-util";

export default {
    name: "AnchorSampleSetDrawer",
    mixins: [BasicDialog],
    data() {
        return {
            sceneList: [],
            sampleForm: {
                anchorId: 0,
                voice: null,
                templateId: 0,
                templateName: null,
                name: null,
                comment: null,
                sceneId: 2,
                sceneName: null,
            }
        }
    },
    mounted() {
        this._loadSceneList();
    },
    methods: {
        _loadSceneList() {
            AnchorApis.getSceneList().then(res => {
                this.sceneList = res.data;
            })
        },
        init(data) {
            console.log(data);
            this.sampleForm.anchorId = data.anchorId;
            this.sampleForm.voice = data.voice;
            this.sampleForm.templateId = data.id;
            this.sampleForm.templateName = data.title;
        },

        _onSceneSelected(sceneId) {
            for (let i = 0; i < this.sceneList.length; i++) {
                let item = this.sceneList[i];
                if (sceneId === item.id) {
                    this.sampleForm.sceneName = item.name;
                }
            }
        },

        _onCommitClick() {
            let req = {
                name: this.sampleForm.name,
                comment: this.sampleForm.comment,
                anchorId: this.sampleForm.anchorId,
                templateId: this.sampleForm.templateId,
                sceneId: this.sampleForm.sceneId,
            }
            AnchorApis.addAnchorSample(req).then(res => {
                ToastUtil.toast("设置成功")
                this.dismiss();
            })
        }
    }
}
</script>

<style lang="scss" scoped>

.drawer-footer {
    width: 100%;
    display: flex;
    align-items: center;

    .cancel-btn {
        margin-left: auto;
    }
}

</style>