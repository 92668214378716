<!-- AdministratorListPage 管理员账号列表 -->
<template>
    <el-container>
        <el-header class="my-page-header">
            <el-row>
                <common-page-title-label title="管理员列表"/>
                <common-add-button title="添加管理员" @click="onAddAdmin"/>
            </el-row>
        </el-header>

        <el-main >
            <el-table v-loading="tableLoading" border :data="tableData">
                <!-- ID, userId, username, nickname, gender, createTime, lastLoginTime -->
                <!--<el-table-column label="ID" prop="id" width="80" align="center"/>-->
                <!--<el-table-column label="用户id" prop="userId" width="180" align="center"/>-->
                <el-table-column label="用户名" prop="username" width="180" align="center"/>
                <el-table-column label="昵称" prop="nickname" width="180" align="center"/>
                <el-table-column label="用户状态" prop="statusName" width="100" align="center">
                    <template #default="scope">
                        <span v-if="scope.row.status === 0">{{ scope.row.statusName }}</span>
                        <span v-if="scope.row.status === 1" style="color: #ff3b30">{{ scope.row.statusName }}</span>
                        <span v-if="scope.row.status === 2" style="color: #ff3b30">{{ scope.row.statusName }}</span>
                    </template>
                </el-table-column>
                <!--<el-table-column label="注册时间" prop="createTime" width="180" align="center"/>-->
                <el-table-column label="上次登录" prop="lastLoginTime" width="180" align="center"/>
                <el-table-column label="IP地址" prop="lastLoginIp" width="180" align="center"/>
                <el-table-column label="操作" fixed="right" width="250px">
                    <template #default="scope">
                        <div v-if="scope.row.status === 0">
                            <el-button size="small" primary
                                       @click="onModifyPwdAction(scope.$index, scope.row)">
                                修改密码
                            </el-button>
                            <el-button size="small" type="warning"
                                       @click="onDisableUser(scope.$index, scope.row)">
                                禁用
                            </el-button>
                            <el-button size="small" type="warning"
                                       @click="onUnregisterUser(scope.$index, scope.row)">
                                注销
                            </el-button>
                        </div>
                        <div v-else>
                            <el-button size="small" type="success"
                                       @click="onResumeUser(scope.$index, scope.row)">
                                恢复
                            </el-button>
                        </div>
                    </template>
                </el-table-column>
            </el-table>
        </el-main>

        <admin-register-dialog v-model:visible="registerDialogVisible"
                               @success="getTableData"/>

        <!-- 修改密码对话框 -->
        <modify-password-dialog :visible="modifyPwdDialogVisible"
                                @dismiss="modifyPwdDialogVisible = false"/>
    </el-container>
</template>

<script>
import {disableUser, resumeUser, unregisterUser} from "@/api/user-apis";
import {ElMessage} from "element-plus";
import ModifyPasswordDialog from "@/views/user/ModifyPasswordDialog";
import bus from "@/utils/bus";
import CommonPageTitleLabel from "@/views/common/CommonPageTitleLabel";
import CommonAddButton from "@/views/common/CommonAddButton";
import AdminRegisterDialog from "@/views/user/AdminRegisterDialog";
import {UserApis} from "@/api/user-apis";

export default {
    name: "AdminManagePage",
    components: {AdminRegisterDialog, CommonAddButton, CommonPageTitleLabel, ModifyPasswordDialog},
    data() {
        return {
            tableLoading: false,
            tableData: [],
            registerDialogVisible: false,
            modifyPwdDialogVisible: false,
        }
    },
    mounted() {
        this.getTableData(this.currentPage);
    },
    methods: {

        setTableLoading(loading) {
            this.tableLoading = loading;
        },

        tableRowClassName({row, rowIndex}) {
            let status = row.status;
            if (status !== 0) {
                return "warning-row"
            }
            return ''
        },

        onAddAdmin() {
            this.registerDialogVisible = true;
            // this.$router.push('/administrator/add')
        },

        getTableData() {
            this.setTableLoading(true);
            UserApis.getAdministratorList().then(res => {
                this.setTableLoading(false);
                this.tableData = res.data;
            }).catch(() => {
                this.setTableLoading(false)
            })
        },

        onModifyPwdAction(index, cellData) {
            let userData = {
                userId: cellData.userId,
                username: cellData.username,
            }
            bus.emit('userData', userData);
            this.modifyPwdDialogVisible = true;
        },

        onDisableUser(index, cellData) {
            if (cellData.status === 1) {
                ElMessage.warning("当前用户已被禁用，不要重复操作")
                return
            }
            if (cellData.status === 2) {
                ElMessage.warning("当前用户已被删除，不要重复操作")
                return
            }
            disableUser(cellData.userId).then(res => {
                if (res.code === 200) {
                    cellData.status = res.data.status;
                    cellData.statusName = res.data.statusName;
                    ElMessage.success("操作成功");
                } else {
                    ElMessage.error("操作失败: " + res.message);
                }
            }).catch(error => {
                ElMessage.error("网络异常: " + error.toString());
            })
        },

        onUnregisterUser(index, cellData) {
            if (cellData.status === 2) {
                ElMessage.warning("当前用户已被删除，不要重复操作")
                return
            }
            unregisterUser(cellData.userId).then(res => {
                if (res.code === 200) {
                    cellData.status = res.data.status;
                    cellData.statusName = res.data.statusName;
                    ElMessage.success("操作成功");
                } else {
                    ElMessage.error("操作失败: " + res.message);
                }
            }).catch(error => {
                ElMessage.error("网络异常: " + error.toString());
            })
        },

        onResumeUser(index, cellData) {
            resumeUser(cellData.userId).then(res => {
                if (res.code === 200) {
                    cellData.status = res.data.status;
                    cellData.statusName = res.data.statusName;
                    ElMessage.success("操作成功");
                } else {
                    ElMessage.error("操作失败: " + res.message);
                }
            }).catch(error => {
                ElMessage.error("网络异常: " + error.toString());
            })
        }
    }
}
</script>

<!--<style>-->

<!--.el-table .warning-row {-->
<!--    &#45;&#45;el-table-tr-bg-color: #ffcc0040;-->
<!--}-->

<!--</style>-->

<style lang="scss" scoped>

//.el-header {
//    --el-header-padding: 0px;
//}
//
//.el-main {
//    --el-main-padding: 0px;
//}

//.page-header {
//    color: $colorAccent;
//}

</style>