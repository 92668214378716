<template>
    <el-dialog v-model="internalVisible" width="30%" title="添加风格标签">
        <el-form ref="addingElForm" :model="addingForm" label-width="70px" :rules="addingRules">

            <el-form-item label="名称" prop="name">
                <el-input v-model="addingForm.name" placeholder="请输入风格名称"/>
            </el-form-item>

            <el-form-item label="属性" prop="value">
                <el-input v-model="addingForm.value" placeholder="请输入微软角色的属性值，例如assistant, 其他平台为null"/>
            </el-form-item>

            <el-form-item label="排序" prop="sort">
                <el-input-number v-model="addingForm.sort" :min="0" :max="100"/>
            </el-form-item>
        </el-form>

        <template #footer>
                <span>
                    <el-button primary @click="dismiss">取消</el-button>
                    <el-button type="primary" @click="_onAddingSubmit">提交</el-button>
                </span>
        </template>
    </el-dialog>
</template>

<script>
import BasicDialog from "@/views/base/BasicDialog";
import {ElMessage} from "element-plus";
import {DubbingRoleApis} from "@/api/dubbing-role-apis";

export default {
    name: "VoiceRoleAddDialog",
    mixins: [BasicDialog],
    emits: ['success'],
    data() {
        return {
            addingForm: {
                name: '',
                value: null,
                sort: 0
            },
            addingRules: {
                name: [
                    {required: true, message: '风格名称不能为空', trigger: 'blur'},
                    {pattern: /^[\u4e00-\u9fa5-_]+$/, message: '风格名称仅支持中文、连接符-组合', trigger: 'blur'}
                ],
                value: [
                    {required: true, message: '风格别名不能为空', trigger: 'blur'},
                    {pattern: /^[a-zA-Z0-9-_]{1,50}$/, message: '属性仅支持大小写字母、数字、连接符-组合', trigger: 'blur'}
                ]
            }
        }
    },

    methods: {

        _reset() {
            this.addingForm.name = null;
            this.addingForm.value = null;
            this.addingForm.sort = 0;
        },

        _onAddingSubmit() {
            this.$refs.addingElForm.validate(valid => {
                if (valid) {
                    this._commitAdding();
                }
            })
        },

        _commitAdding() {
            let param = {
                name: this.addingForm.name,
                value: this.addingForm.value,
                sort: this.addingForm.sort
            }
            DubbingRoleApis.addRole(param).then(res => {
                ElMessage.success(res.message);
                this.dismiss();
                this._reset();
                this.$emit('success');
            })
        },
    }
}
</script>

<style scoped>

</style>