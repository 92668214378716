<template>
    <div>
        <el-header>
            <el-page-header content="添加模版" @back="onBack"/>
        </el-header>

        <el-form ref="templateElForm" :model="templateForm" :rules="templateRules" label-width="100px"
                 style="width: 60%; margin-left: 10px">
            <el-form-item label="主播:">
                <el-input v-model="templateForm.anchorName" disabled/>
            </el-form-item>

            <el-form-item label="模版名称:" prop="title">
                <el-input v-model="templateForm.title" placeholder="请输入风格模版名称"/>
            </el-form-item>

            <el-form-item label="主播风格:" prop="styleId">
                <el-select v-model="templateForm.styleId" placeholder="请选择主播风格">
                    <el-option v-for="item in styleOptionList" :label="item.name" :value="item.id" :key="item.id"/>
                </el-select>
            </el-form-item>

            <el-form-item label="采样率" prop="sampleRate">
                <el-select v-model="templateForm.sampleRate" placeholder="选择采样率">
                    <el-option v-for="item in sampleRateOptions"
                               :key="item.value" :value="item.value"
                               :label="item.label"/>
                </el-select>
            </el-form-item>

            <el-form-item label="语调:">
                <el-slider class="slider" v-model="templateForm.pitchRate" :min="-500" :max="500" show-input/>
            </el-form-item>

            <el-form-item label="语速:">
                <el-slider class="slider" v-model="templateForm.speechRate" :min="0.5" :max="2.0" :step="0.01"
                           :marks="speedLevelMarks" show-input/>
            </el-form-item>

            <el-form-item label="音量:">
                <el-slider class="slider" v-model="templateForm.volume" :min="0" :max="100" show-input/>
            </el-form-item>

            <el-form-item label="模版文案：" prop="content">
                <el-input ref="contentInput"
                          v-model="templateForm.content"
                          :rows="7"
                          type="textarea"
                          maxlength="500"
                          show-word-limit
                          placeholder="请输入主播试听内容"
                          @blur="onInputBlur"/>
            </el-form-item>

            <el-form-item>
                <el-button round type="primary" @click="onInsertHalt">插入停顿</el-button>
                <el-button round type="primary" @click="onInsertPhoneme">指定发音</el-button>
            </el-form-item>

            <el-form-item label="点击试听：">
                <audition-player ref="auditionPlayer"
                                 :audio-data="auditionData"
                                 @play="onAudition"/>
            </el-form-item>

        </el-form>

        <div style="width: 50%;text-align: right">
            <span>
                <el-button type="primary" round @click="onAddClick">添加</el-button>
                <el-button round>取消</el-button>
            </span>
        </div>

        <!-- 插入停顿对话框 -->
        <halt-picker-dialog :dialog-visible="haltPickerVisible"
                            @dismiss="haltPickerVisible = false"
                            @result="onHaltResult"/>

        <!-- 指定发音对话框 -->
        <phoneme-input-dialog :visible="phonemeDialogVisible"
                              @dismiss="phonemeDialogVisible = false"
                              @result="onPhonemeResult"/>
    </div>
</template>

<!-- 添加模版 -->
<script>
import AuditionPlayer from "@/views/dubbing/anchor/AuditionPlayer";
import PhonemeInputDialog from "@/views/dubbing/components/PhonemeInputDialog";
import HaltPickerDialog from "@/views/dubbing/components/HaltPickerDialog";
import AvatarPickerDialog from "@/views/dubbing/anchor/AvatarPickerDialog";
import {Loading, Plus, VideoPause, VideoPlay} from "@element-plus/icons-vue";
import {ElMessage, ElMessageBox} from "element-plus";
import StringUtils from "@/utils/stringUtils";
import {DubbingApis} from "@/api/dubbing";
import bus from "@/utils/bus";
import voiceOptions from "@/views/dubbing/VoiceConstants";
import {SsmlUtils} from "@/utils/SsmlUtils";
import {AnchorApis} from "@/api/anchor";

export default {
    name: "AnchorTemplateAddPage",
    components: {
        AuditionPlayer,
        PhonemeInputDialog,
        HaltPickerDialog,
        AvatarPickerDialog,
        Plus, VideoPlay, VideoPause, Loading,
    },
    data() {
        return {
            // anchorId: null,
            styleOptionList: [], // 风格列表
            sampleRateOptions: voiceOptions.sampleRateOptions,
            speedLevelMarks: {0.5: '0.5x', 1: '1x', 1.5: '1.5x', 2.0: '2x'},  // 语速marks

            haltPickerVisible: false, // 插入停顿对话框可见性
            phonemeDialogVisible: false, // 指定发音对话框可见性
            inputSelectionStart: null, // 文案输入框当前选中范围 开始位置
            inputSelectionEnd: null, // 文案输入框当前选中范围 结束位置

            auditionData: null,
            lastAuditionData: '', // 记录当前试听请求参数

            templateForm: {
                anchorId: null, // 主播id
                anchorName: null, // 主播名称
                styleId: 1, // 风格/情绪id
                title: '', // 模版名称
                pitchRate: 0, // 语调
                speechRate: 1.0, // 语速
                volume: 100, // 音量
                sampleRate: 16000,
                content: '', // 文案
            },
            templateRules: {
                title: [
                    {required: true, message: "模版名称不能为空", trigger: 'blur'},
                    {pattern: /^[a-zA-Z0-9\u4e00-\u9fa5-_]{2,4}$/, message: "标题仅支持2～4位汉字、大小写字母、数字、-、_组成", trigger: 'blur'}
                ],
                styleId: [
                    {required: true, message: "请选择一中风格", trigger: 'blur'}
                ],
                content: [
                    {required: true, message: '试听文案不能为空', trigger: 'blur'}
                ]
            }
        }
    },
    created() {
        if (this.$route.query.id) {
            this.templateForm.anchorId = Number.parseInt(this.$route.query.id + "");
        }
        // if (this.$route.query.name) {
        //     this.templateForm.anchorName = this.$route.query.name;
        // }
    },

    mounted() {
        this.loadStyleList();
        this.loadAnchorSimpleInfo();
    },

    activated() {

    },

    deactivated() {

    },

    methods: {

        onBack() {
            this.$router.back();
        },

        loadStyleList() {
            DubbingApis.getStyleList().then(res => {
              if (res.code === 200) {
                  this.styleOptionList = res.data;
              }
          })
        },

        loadAnchorSimpleInfo() {
            if (this.templateForm.anchorId) {
                AnchorApis.getAnchorSimpleInfo(this.templateForm.anchorId).then(res => {
                    if (res.code === 200) {
                        let info = res.data;
                        this.sampleRateOptions = voiceOptions.makeSampleRateOptions(info.sampleRate);
                        this.templateForm.sampleRate = info.sampleRate;
                        this.templateForm.anchorName = info.name;
                    }
                })
            }
        },

        // ===========================================
        //      插入停顿 指定发音
        // ===========================================

        onInputBlur(event) {
            let input = event.srcElement;
            this.inputSelectionStart = input.selectionStart;
            this.inputSelectionEnd = input.selectionEnd;
        },

        onInsertHalt() {
            this.haltPickerVisible = true;
        },

        onHaltResult(halt) {
            const startPos = this.inputSelectionStart;
            const endPos = this.inputSelectionEnd;
            // console.log("selection: start = " + startPos + ", end = " + endPos);
            if (startPos === null || endPos === undefined) {
                ElMessage.waring("请将光标移动至待插入位置")
                return
            }
            let content = this.templateForm.content;
            let haltTag = SsmlUtils.makeBreakTag(halt);
            this.templateForm.content = content.slice(0, startPos) + haltTag + content.slice(endPos);
        },

        onInsertPhoneme() {
            const startPos = this.inputSelectionStart;
            const endPos = this.inputSelectionEnd;
            // console.log("selection: start = " + startPos + ", end = " + endPos);
            if (startPos === null || endPos === undefined || (endPos - startPos) !== 1) {
                // ElMessage.waring("请选择")
                alert("请选择一个需要设置读音的中文汉字")
                return
            }

            let content = this.templateForm.content;
            let selectedWord = content.slice(startPos, endPos)
            // console.log("selectedWord: " + selectedWord)
            bus.emit('selectedWord', selectedWord)
            this.phonemeDialogVisible = true;
        },

        onPhonemeResult(phoneme) {
            const startPos = this.inputSelectionStart;
            const endPos = this.inputSelectionEnd;
            // console.log("selection: start = " + startPos + ", end = " + endPos);
            if (startPos === null || endPos === undefined) {
                ElMessage.waring("请将光标移动至待插入位置")
                return
            }
            let content = this.templateForm.content;
            let selectedWord = content.slice(startPos, endPos)
            let ssmlTag = SsmlUtils.makePhonemeTag(selectedWord, phoneme);
            // <!行hang2!>
            this.templateForm.content = content.slice(0, startPos) + ssmlTag + content.slice(endPos);
        },


        // ===========================================
        //      试听
        // ===========================================

        onAudition(callback) {
            callback(true)
            let ap = this.$refs['auditionPlayer']
            if (ap.isPlaying) {
                ap.stopPlay()
                return
            }
            if (StringUtils.isBlank(this.templateForm.anchorId)) {
                ElMessage.error("主播id为空");
                return
            }

            let content = this.templateForm.content;
            if (StringUtils.isBlank(content)) {
                ElMessage.error("请输入试听文案");
                return;
            }

            let auditionParam = {
                anchorId: this.templateForm.anchorId,
                styleId: this.templateForm.styleId,
                pitchRate: this.templateForm.pitchRate,
                speechRate: this.templateForm.speechRate,
                volume: this.templateForm.volume,
                sampleRate: this.templateForm.sampleRate,
                content: this.templateForm.content
            }

            let currentAuditionData = JSON.stringify(auditionParam);
            // 如果试听参数与上次相同，且当前试听链接不为空，则直接播放; 减少向服务器请求次数
            if (this.auditionData !== null && this.lastAuditionData === currentAuditionData) {
                ap.startPlay();
                return
            }

            ap.showLoading();
            DubbingApis.requestAudition(auditionParam).then(res => {
                let model = res.data;
                if (model.status === 200) {
                    this.auditionData = model.audioData;
                    this.lastAuditionData = currentAuditionData;
                    this.delayToStartPlay();
                } else {
                    ap.hideLoading();
                    ElMessage.error("试听失败: " + res.message);
                }
            }).catch(error => {
                ap.hideLoading();
            })
        },

        delayToStartPlay() {
            setTimeout(() => {
                this.$refs.auditionPlayer.startPlay()
            }, 1)
        },

        // ===========================================
        //      添加主播
        // ===========================================

        onAddClick() {
            this.$refs.templateElForm.validate(valid => {
                if (valid) {
                    // 添加模版
                    this.requestAddTemplate();
                }
            })
        },

        requestAddTemplate() {
            let req = {
                anchorId: this.templateForm.anchorId,
                title: this.templateForm.title,
                styleId: this.templateForm.styleId,
                volume: this.templateForm.volume,
                speechRate: this.templateForm.speechRate,
                pitchRate: this.templateForm.pitchRate,
                sampleRate: this.templateForm.sampleRate,
                content: this.templateForm.content
            }
            DubbingApis.addAnchorTemplate(req).then(res => {
                if (res.code === 200) {
                    console.log("添加主播成功");
                    // ElMessage.success("添加主播成功");
                    ElMessageBox.confirm("添加主播成功", "提示", {
                        confirmButtonText: "继续添加",
                        cancelButtonText: "退出",
                        type: 'success'
                    }).then(() => {
                        // 继续添加
                        this.reset();
                    }).catch(() => {
                        // 退出
                        this.onBack();
                    })
                } else {
                    ElMessage.error("添加主播失败: " + res.message);
                }
            }).catch(error => {
                ElMessage.error("添加主播失败: " + error.toString());
            })
        },

    }
}
</script>

<style scoped>

</style>