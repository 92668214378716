<template>
    <el-container style="height: 100%">

        <!-- 侧边栏 -->
        <el-aside width="200px" height="100vh">
            <admin-side-bar/>
        </el-aside>

        <el-container>

            <!-- el-header默认高度 60px -->
            <el-header style="height: 60px; background-color: white; padding: 0">
                <admin-nav-bar style="height: 50px"/>
            </el-header>

            <el-main class="content" height="100%">
                <router-view />
            </el-main>

        </el-container>
    </el-container>
</template>

<script>
    import AdminSideBar from "@/views/layout/components/AdminSideBar";
    import { mapState } from 'vuex'
    import AdminNavBar from "@/views/layout/components/AdminNavBar";

    export default {
        // eslint-disable-next-line vue/multi-word-component-names
        name: 'Layout',
        components: {
            AdminNavBar,
            AdminSideBar
        },
        computed: {
            ...mapState({
                sidebar: state => state.app.sidebar,
                device: state => state.app.device,
                showSettings: state => state.settings.showSettings,
                needTagsView: state => state.settings.tagsView,
                fixedHeader: state => state.settings.fixedHeader
            }),
            classObj() {
                return {
                    hideSidebar: !this.sidebar.opened,
                    openSidebar: this.sidebar.opened,
                    withoutAnimation: this.sidebar.withoutAnimation,
                    mobile: this.device === 'mobile'
                }
            }
        },
        methods: {
            handleClickOutside() {
                this.$store.dispatch('app/closeSideBar', { withoutAnimation: false })
            }
        }
    }
</script>

<style lang="scss" scoped>

    .content {
        --el-main-padding: 0px;
    }

</style>
