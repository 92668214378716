<!--suppress HtmlUnknownTag -->
<template>
    <div>
        <el-container>
            <el-header class="my-page-header">
                <el-row>
                    <common-page-title-label title="发音人管理"/>
                    <el-radio-group v-model="selectedVoiceSource" @change="loadAllVoice">
                        <el-radio-button v-for="item in voiceSourceList" :key="item.value" :label="item.value">
                            {{ item.label }}
                        </el-radio-button>
                    </el-radio-group>
                    <common-add-button title="添加声音" @click="onAddVoiceClick" style="margin-left: 50px"/>
                    <!--<el-input v-model="searchContent" style="width: 160px;margin-left: 30px" placeholder="请输入渠道名称" clearable/>-->
                    <!--<el-button :icon="Search" style="margin-left: 5px" @click="submitSearch()"/>-->
                </el-row>
            </el-header>

            <!-- 发音人列表 -->
            <el-main style="height: 100%">
                <!-- 只要在 el-table 元素中定义了 height 属性，即可实现固定表头的表格，而不需要额外的代码。 -->
                <el-table v-loading="loading" :data="tableData" style="width: 100%;" border>
                    <el-table-column label="ID" prop="id" width="80" align="center"/>
                    <el-table-column label="名称" prop="name" width="100" align="center"/>
                    <el-table-column label="参数值" prop="voice" width="160" align="center"/>
                    <el-table-column label="AI等级" prop="aiLevel" width="80" align="center">
                        <template #default="scope">
                            <ai-level-label :level="scope.row.aiLevel"/>
                        </template>
                    </el-table-column>
                    <el-table-column label="性别" prop="sex" width="80" align="center">
                        <template #default="scope">
                            <anchor-sex-label :sex="scope.row.sex"/>
                        </template>
                    </el-table-column>
                    <el-table-column label="类型" prop="type" width="120" align="center"/>
                    <el-table-column label="采样率" prop="sampleRate" width="80" align="center"/>
                    <!-- 阿里云 字段 -->
                    <el-table-column v-if="selectedVoiceSource === 1" label="适用场景" prop="scene" width="160" align="center"/>
                    <el-table-column v-if="selectedVoiceSource === 1" label="支持语言" prop="language" width="200" align="center"/>
                    <el-table-column v-if="selectedVoiceSource === 1" label="声音品质" prop="quality" width="80" align="center"/>
                    <!-- 微软字段 -->
                    <el-table-column v-if="selectedVoiceSource === 2" label="支持风格" prop="styles" align="center">
                        <template #default="scope">
                            <!--<span>{{ makeCategoryDesc(scope.row.categories) }}</span>-->
                            <el-tag class="gl-table-tag-item"
                                    v-for="item in scope.row.styles"
                                    :key="item.id">
                                {{item.name}}
                            </el-tag>
                        </template>
                    </el-table-column>
                    <el-table-column v-if="selectedVoiceSource === 2" label="角色列表" prop="roles" align="center">
                        <template #default="scope">
                            <!--<span>{{ makeCategoryDesc(scope.row.categories) }}</span>-->
                            <el-tag class="gl-table-tag-item"
                                    v-for="item in scope.row.roles"
                                    :key="item.id">
                                {{item.name}}
                            </el-tag>
                        </template>
                    </el-table-column>
                    <el-table-column label="操作" fixed="right">
                        <template #default="scope">
                            <!-- 设置风格：仅限微软平台 -->
                            <el-button v-if="scope.row.source === 2" size="small" type="success" @click="onItemStyleEditClick(scope.$index, scope.row)">
                                设置风格
                            </el-button>
                            <el-button v-if="scope.row.source === 2" size="small" type="success" @click="_onSetRoleClick(scope.$index, scope.row)">
                                设置角色
                            </el-button>
                            <el-button size="small" primary @click="onItemEditClick(scope.$index, scope.row)">
                                编辑
                            </el-button>
                            <el-button size="small" type="danger" @click="onItemDeleteClick(scope.$index, scope.row)">
                                删除
                            </el-button>
                        </template>
                    </el-table-column>
                </el-table>

            </el-main>

            <!-- 分页插件 -->
            <!--<el-footer>-->
            <!--    <el-pagination-->
            <!--        :page-size="pageSize"-->
            <!--        :pager-count="11"-->
            <!--        :total="total"-->
            <!--        :current-page="currentPage"-->
            <!--        layout="prev, pager, next"-->
            <!--        @current-change="handleCurrentPageChange">-->
            <!--    </el-pagination>-->
            <!--</el-footer>-->
        </el-container>


        <!-- 添加发音人弹框 -->
        <voice-add-dialog :visible="addDialogVisible" @dismiss="addDialogVisible = false"/>
        <!-- 编辑发音人  -->
        <voice-edit-dialog :visible="editDialogVisible" @dismiss="editDialogVisible = false"/>
        <!-- 设置风格支持列表 -->
        <voice-style-edit-dialog :visible="styleEditDialogVisible" @dismiss="styleEditDialogVisible = false"/>
        <voice-role-edit-dialog ref="roleDialog" v-model:visible="this.roleEditDialogVisible"/>

    </div>
</template>

<script>

import AdminVoiceEditDialog from "@/views/dubbing/voice/VoiceEditDialog";
import VoiceEditDialog from "@/views/dubbing/voice/VoiceEditDialog";
import bus from "@/utils/bus";
import {DubbingApis} from "@/api/dubbing";
import CommonPageTitleLabel from "@/views/common/CommonPageTitleLabel";
import CommonAddButton from "@/views/common/CommonAddButton";
import VoiceAddDialog from "@/views/dubbing/voice/VoiceAddDialog";
import AiLevelLabel from "@/views/dubbing/components/AiLevelLabel";
import AnchorSexLabel from "@/views/dubbing/components/AnchorSexLabel";
import {ElMessage} from "element-plus";
import VoiceStyleEditDialog from "@/views/dubbing/voice/VoiceStyleEditDialog";
import VoiceRoleEditDialog from "@/views/dubbing/voice/VoiceRoleEditDialog";

export default {
    name: "AdminVoicePage",
    components: {
        VoiceRoleEditDialog,
        VoiceStyleEditDialog,
        VoiceEditDialog,
        AnchorSexLabel,
        AiLevelLabel, VoiceAddDialog, CommonAddButton, CommonPageTitleLabel, AdminVoiceEditDialog},
    data() {
        return {
            voiceSourceList: [
                {
                    label: '阿里云',
                    value: 1
                }, {
                    label: '微软',
                    value: 2
                }
            ], // 声音来源列表
            selectedVoiceSource: 1, // 当前选择的声音来源，默认为1，阿里云
            loading: false,
            // pageSize: 10, // 每页item数量
            // currentPage: 1, // 当前页
            // total: 0,
            tableData: [],
            searchContent: '',
            addDialogVisible: false,
            editDialogVisible: false,
            styleEditDialogVisible: false,
            roleEditDialogVisible: false,
        }
    },
    mounted() {
        this.loadAllVoice();
    },
    methods: {

        loadAllVoice() {
          this.loading = true;
            DubbingApis.getAllVoiceList(this.selectedVoiceSource).then(res => {
              this.loading = false;
              if (res.code === 200) {
                  this.tableData = res.data;
              } else {
                  ElMessage.error("无法获取发音人列表：" + res.message);
              }
          }).catch(error => {
              this.loading = false;
              ElMessage.error("无法获取发音人列表：" + error.toString());
          })
        },

        onAddVoiceClick() {
            this.addDialogVisible = true;
        },

        onItemEditClick(index, cellData) {
            bus.emit('editVoiceData', cellData);
            this.editDialogVisible = true
        },

        onItemStyleEditClick(index, cellData) {
            bus.emit('editVoiceData', cellData);
            this.styleEditDialogVisible = true
        },

        _onSetRoleClick(index, cellData) {
            this.$refs.roleDialog.setupRole(cellData);
            this.roleEditDialogVisible = true;
        },

        onItemDeleteClick(index, itemData) {

        }
    }
}
</script>

<style lang="scss" scoped>

.el-pagination {
    text-align: center;
}

</style>