<template>
    <el-container>
        <el-header class="my-page-header">
            <el-row>
                <el-input v-model="searchUserId" style="width: 180px;" placeholder="输入用户ID"/>
                <el-button type="primary" class="ml-16" @click="_onSearchClick">搜索</el-button>
                <el-button type="primary" @click="_onSearchClearClick">清空</el-button>
                <el-button type="primary" @click="_onSyncDraftVersion">同步草稿Version字段</el-button>
            </el-row>
        </el-header>

        <el-main style="width: 100%;margin-top: 10px;">
            <el-table :data="taskList" border :row-class-name="_rowClassName">
                <el-table-column label="ID" width="80" prop="taskId"/>
                <el-table-column label="日期" width="160" prop="createTime"/>
                <el-table-column label="用户ID" width="180" prop="userId"/>
                <el-table-column label="任务名称" width="200" prop="taskTitle"/>
                <el-table-column label="字数" width="80" prop="contentLength"/>
                <el-table-column label="模版ID" width="80" prop="templateId"/>
                <!--<el-table-column label="音色" width="120" prop="voice"/>-->
                <el-table-column label="任务状态" width="100" prop="taskStatusText"/>
                <el-table-column label="音频地址" prop="audioPath">
                    <template #default="scope">
                        <audio :src="scope.row.audioPath" controls preload="none" style="height: 40px"/>
                    </template>
                </el-table-column>
                <el-table-column label="来源" width="80" prop="clientName"/>
                <el-table-column label="操作">
                    <template #default="scope">
                        <el-button size="small" type="primary" @click="_onCheckContent(scope.row)">文案</el-button>
                        <el-button v-if="scope.row.taskStatus !== 1" size="small" type="primary" @click="_onRetryTask(scope.row)">重试</el-button>
                    </template>
                </el-table-column>
            </el-table>
        </el-main>
        <!-- 分页插件 -->
        <el-footer>
            <el-pagination
                :current-page="page"
                :page-size="pageSize"
                :page-count="totalPage"
                layout="prev, pager, next"
                @current-change="_onPageChange">
            </el-pagination>
        </el-footer>

        <tts-task-content-dialog ref="contentDialog" v-model:visible="this.contentDialogVisible"/>
    </el-container>
</template>

<script>
import {TtsApis} from "@/api/tts-apis";
import TtsTaskContentDialog from "@/views/tools/TtsTaskContentDialog";
import {ElMessage} from "element-plus";
import StringUtils from "@/utils/stringUtils";

export default {
    name: "TtsTaskManagerPage",
    components: {TtsTaskContentDialog},
    data() {
        return {
            taskItem: {
                userId: "",
                taskId: 0,
                taskTitle: "",
                contentLength: 0,
                templateId: 0,
                voice: "",
                taskStatus: 0,
                taskStatusText: "",
                audioPath: "",
            },
            page: 1,
            pageSize: 30,
            totalPage: 1,
            taskList: [],
            contentDialogVisible: false,
            searchMode: false,
            searchUserId: null,
        }
    },
    mounted() {
        this._loadTaskList();
    },
    methods: {
        _rowClassName({row, index}) {
            let status = row.taskStatus;
            if (status === 0) {
                return 'warning-row'
            } else if (status === 1) {
                return 'success-row'
            } else {
                return 'error-row'
            }
        },
        _onPageChange(newPage) {
            if (this.searchMode && this.searchUserId) {
                this.page = newPage;
                this._searchUserTask();

            } else {
                this.page = newPage;
                this._loadTaskList();
            }
        },

        _loadTaskList() {
            TtsApis.getTaskList(this.page, this.pageSize).then(res => {
                let pageData = res.data;
                this.totalPage = pageData.total;
                this.taskList = pageData.list;
            })
        },

        _onCheckContent(itemData) {
            let taskId = itemData.taskId;
            TtsApis.getTaskContent(taskId).then(res => {
                let text = res.data;
                this.$refs.contentDialog.setupContent(text);
                this.contentDialogVisible = true;
            })
        },

        _onRetryTask(itemData) {
            if (itemData.taskStatus === 1) {
                return;
            }
            let taskId = itemData.taskId;
            TtsApis.retryTask(taskId).then((_) => {
                ElMessage.success("已重新执行任务")
            })
        },

        // ===========================================
        //      Search
        // ===========================================
        _onSearchClick() {
            if (StringUtils.isBlank(this.searchUserId)) {
                ElMessage.warning("输入用户id");
                return;
            }
            this.searchMode = true;
            this.page = 1;
            this._searchUserTask();
        },

        _searchUserTask() {
            TtsApis.getUserTaskList(this.searchUserId, this.page, this.pageSize).then(res => {
                let pageData = res.data;
                this.totalPage = pageData.total;
                this.taskList = pageData.list;
            })
        },

        _onSearchClearClick() {
            this.searchMode = false;
            this.searchUserId = null;
        },
        _onSyncDraftVersion() {
            TtsApis.syncWorkVersion().then(res => {
                ElMessage.success("同步成功")
            })
        }
    }
}
</script>

<style scoped>

</style>