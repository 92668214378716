<template>
    <div>
        <el-table v-loading="tableLoading" border :data="tableData">
            <el-table-column label="ID" prop="id" width="50px" align="center"/>
            <el-table-column label="名称" prop="name" width="100px" align="center"/>
            <!--<el-table-column label="别名" prop="alias" width="160px" align="center"/>-->
            <el-table-column label="微软" prop="value" width="160px" align="center"/>
            <el-table-column label="阿里" prop="valueAli" width="160px" align="center"/>
            <el-table-column label="头像" prop="imagePath" align="center" width="80">
                <template #default="scope">
                    <el-image v-if="scope.row.imagePath" fit="fill" :src="scope.row.imagePath" style="width: 48px;height: 48px"/>
                </template>
            </el-table-column>
            <el-table-column label="说明" prop="description" width="300px" align="center"/>
            <el-table-column label="排序" prop="sort" width="80px" align="center"/>
            <el-table-column label="操作" align="left">
                <template #default="scope">
                    <el-button size="small" @click="onItemSetImageClick(scope.$index, scope.row)">设置图标</el-button>
                    <el-button size="small" @click="onItemEditClick(scope.$index, scope.row)">编辑</el-button>
                    <!--<el-button size="small" type="success" @click="onAddClick">添加</el-button>-->
                    <el-button size="small" type="danger" @click="onDeleteClick(scope.$index, scope.row)">删除</el-button>
                </template>
            </el-table-column>
        </el-table>

        <common-add-button title="添加风格" @click="onAddClick" style="margin-top: 20px"/>

        <!-- 设置图标 -->
        <emotion-icon-edit-dialog ref="iconSettingDialog" v-model:visible="iconDialogVisible"/>

        <!-- 编辑框 -->
        <emotion-edit-dialog ref="editDialog" v-model:visible="editingDialogVisible"/>

        <!-- 添加框 -->
        <emotion-add-dialog v-model:visible="addingDialogVisible"/>
    </div>
</template>

<!-- 声音风格管理页面 -->
<script>
import {DubbingApis} from "@/api/dubbing";
import {ElMessage, ElMessageBox} from "element-plus";
import CommonAddButton from "@/views/common/CommonAddButton";
import {Plus} from "@element-plus/icons-vue";
import EmotionEditDialog from "@/views/dubbing/style/EmotionEditDialog";
import EmotionAddDialog from "@/views/dubbing/style/EmotionAddDialog";
import EmotionIconEditDialog from "@/views/dubbing/style/EmotionIconEditDialog";

export default {
    name: "StyleTabPage",
    components: {
        EmotionIconEditDialog, EmotionAddDialog, EmotionEditDialog, CommonAddButton, Plus},
    data() {
        return {
            tableLoading: false,
            tableData: [],

            loadingService: null,

            // 设置头像dialog
            iconDialogVisible: false,
            // editing dialog
            editingDialogVisible: false,
            // adding dialog
            addingDialogVisible: false,
        }
    },

    mounted() {
        this.loadTableData()
    },

    methods: {
        loadTableData() {
            this.tableLoading = true;
            DubbingApis.getStyleList().then(res => {
                this.tableLoading = false;
                if (res.code === 200) {
                    this.tableData = res.data;
                } else {
                    ElMessage.error("查询失败2: " + res.message);
                }
            }).catch(error => {
                this.tableLoading = false;
                ElMessage.error("查询失败3: " + error.toString());
            })
        },


        // ===========================================
        //      设置图标
        // ===========================================

        onItemSetImageClick(index, itemData) {
            this.iconDialogVisible = true;
            this.$refs.iconSettingDialog.setStyleId(itemData.id);
        },

        // ===========================================
        //      Editing
        // ===========================================

        onItemEditClick(index, itemData) {
            this.$refs.editDialog.setup(itemData);
            this.editingDialogVisible = true;
        },

        // ===========================================
        //      Add
        // ===========================================

        onAddClick() {
            this.addingDialogVisible = true;
        },

        // ===========================================
        //      Delete
        // ===========================================

        onDeleteClick(index, itemData) {
            ElMessageBox.confirm("删除后无法恢复，且相关记录都会被删除，请确认后再继续执行", "危险提示", {
                confirmButtonText: '删除',
                cancelButtonText: '取消',
                type: 'error'
            }).then(() => {
                this.commitDelete(itemData.id);
            })
        },

        commitDelete(styleId) {
            DubbingApis.deleteStyle(styleId).then(res => {
                if (res.code === 200) {
                    ElMessage.success("删除成功");
                    this.loadTableData();
                } else {
                    ElMessage.error("删除失败：" + res.message)
                }
            })
        }
    }
}
</script>

<style lang="scss" scoped>

.plus-icon {
    font-size: 20px;
    color: #8c939d;
    width: 90px;
    height: 90px;
    text-align: center;
}
</style>