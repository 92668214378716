<template>
    <el-container>
        <el-header>
            <el-row>
                <common-page-title-label title="广告活动管理"/>
                <common-add-button title="添加活动" @click="_onAddActivityClick"/>
                <el-button type="primary" @click="_loadAllActivities">刷新</el-button>
            </el-row>
        </el-header>

        <el-main>
            <el-table :data="activityList" border>
                <el-table-column label="ID" prop="id" width="60"/>
                <el-table-column label="名称" prop="name" width="120"/>
                <el-table-column label="图片" width="200">
                    <template #default="scope">
                        <img :src="scope.row.imagePath" style="max-width: 180px;object-fit: contain;">
                    </template>
                </el-table-column>
                <el-table-column label="跳转" prop="link" width="120"/>
                <el-table-column label="开始" prop="beginDate" width="160">
                    <template #default="scope">
                        <div>{{_formatTimeMillis(scope.row.beginTimeMillis)}}</div>
                    </template>
                </el-table-column>
                <el-table-column label="结束" prop="endDate" width="160">
                    <template #default="scope">
                        <div>{{_formatTimeMillis(scope.row.endTimeMillis)}}</div>
                    </template>
                </el-table-column>
                <el-table-column label="状态" width="100">
                    <template #default="scope">
                        <div>{{scope.row.status === 0 ? "正常" : "下架"}}</div>
                    </template>
                </el-table-column>
                <el-table-column label="操作" fixed="right">
                    <template #default="scope">
                        <el-row>
                            <el-button type="primary" size="mini" @click="_onEditImageClick(scope.row)">设置图片</el-button>
                            <el-button type="primary" size="mini" @click="_onEditClick(scope.row)">编辑</el-button>
                            <el-button v-if="scope.row.status === 0" type="danger" size="mini" @click="_onDisableClick(scope.row)">禁用</el-button>
                            <el-button v-else type="success" size="mini" @click="_onResumeClick(scope.row)">恢复</el-button>
                        </el-row>
                    </template>
                </el-table-column>
            </el-table>
        </el-main>

        <activity-banner-dialog-add v-model:visible="addDialogVisible" @success="_loadAllActivities"/>
        <activity-banner-dialog-edit ref="editDialog" v-model:visible="editDialogVisible" @success="_loadAllActivities"/>
        <activity-banner-dialog-set-image ref="imageEditDialog" v-model:visible="editImageDialogVisible" @success="_loadAllActivities"/>
    </el-container>
</template>

<script>
import CommonPageTitleLabel from "@/views/common/CommonPageTitleLabel";
import CommonAddButton from "@/views/common/CommonAddButton";
import ActivityBannerDialogAdd from "@/views/web/ActivityBannerDialogAdd";
import {WebActivityApis} from "@/api/web-activity-apis";
import {DateUtils} from "@/utils/DateUtils";
import ActivityBannerDialogEdit from "@/views/web/ActivityBannerDialogEdit";
import ActivityBannerDialogSetImage from "@/views/web/ActivityBannerDialogSetImage";
import {ToastUtil} from "@/utils/toast-util";
export default {
    name: "ActivityBannerPage",
    components: {
        ActivityBannerDialogSetImage,
        ActivityBannerDialogEdit, ActivityBannerDialogAdd, CommonAddButton, CommonPageTitleLabel},
    data() {
        return {
            positionArray: [
                {
                    id: 1,
                    name: "页面顶部"
                }
            ],
            activityList: [],
            addDialogVisible: false,
            editDialogVisible: false,
            editImageDialogVisible: false,
        }
    },

    mounted() {
        this._loadAllActivities()
    },
    methods: {

        _formatTimeMillis(timeMillis) {
            return DateUtils.formatTimeMillis(timeMillis, "yyyy-mm-dd HH:MM:SS")
        },

        _loadAllActivities() {
            WebActivityApis.getAllActivities().then(res => {
                this.activityList = res.data;
            })
        },

        _onAddActivityClick() {
            this.addDialogVisible = true;
        },

        _onEditImageClick(itemData) {
            this.$refs.imageEditDialog.init(itemData);
            this.editImageDialogVisible = true;
        },

        _onEditClick(itemData) {
            this.$refs.editDialog.init(itemData);
            this.editDialogVisible = true;
        },

        _onDisableClick(itemData) {
            WebActivityApis.disableActivity(itemData.id).then(res => {
                ToastUtil.toast("已禁用")
                this._loadAllActivities();
            })
        },

        _onResumeClick(itemData) {
            WebActivityApis.resumeActivity(itemData.id).then(res => {
                ToastUtil.toast("已恢复");
                this._loadAllActivities();
            })
        }
    }
}
</script>

<style lang="scss" scoped>

</style>