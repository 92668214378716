<template>
    <!-- eslint-disable-next-line vue/no-mutating-props -->
    <el-dialog width="26%" v-model="dialogVisible"
               title="插入停顿"
               :before-close="beforeClose"
               @closed="reset" custom-class="cardDialog">
        <!--<el-input v-model="halt" placeholder="请输入停顿时间(毫秒，1秒=1000毫秒)">-->
        <!--    <template #append>.com</template>-->
        <!--</el-input>-->
        <el-row type="flex" align="middle" justify="center">
            <el-input-number v-model="halt" :step="100" :min="200" :max="10000" :precision="0"/>
            <span style="margin-left: 20px">ms(毫秒)</span>
        </el-row>

        <template #footer>
                <span class="dialog-footer">
                    <el-button @click="onCancel">取消</el-button>
                    <el-button type="primary" @click="onSubmitClick">确定</el-button>
                </span>
        </template>
    </el-dialog>
</template>

<script>

export default {
    name: "HaltPickerDialog",
    props: {
        dialogVisible: {
            type: Boolean,
            default: false
        }
    },
    // 定义 回调事件
    emits: ['result', 'dismiss'],
    data() {
        return {
            halt: 200, // 停顿时间 (ms)
        }
    },
    methods: {
        dismiss() {
            this.$emit('dismiss');
        },
        onCancel() {
            this.dismiss();
        },
        beforeClose() {
            this.dismiss();
        },
        reset() {

        },

        onSubmitClick() {
            this.$emit('result', this.halt);
            this.dismiss();
        }

    }
}
</script>

<style>
.cardDialog {
    border-radius: 10px;
}
</style>

<style scoped>

</style>