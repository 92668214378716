<template>
    <el-dialog v-model="internalVisible" width="400px" title="添加标签样式">
        <el-form ref="sendForm" :model="this.labelForm" style="width: 100%;" label-width="90px" label-position="right">
            <el-form-item label="文字颜色">
                <el-color-picker v-model="this.labelForm.textColor" color-format="hex"></el-color-picker>
            </el-form-item>
            <el-form-item label="背景颜色">
                <el-color-picker v-model="this.labelForm.bgColor" color-format="hex"></el-color-picker>
            </el-form-item>
        </el-form>

        <template #footer>
            <span style="display: flex;" v-loading="commitLoading">
                <div style="flex: 1"/>
                <el-button @click="dismiss">取消</el-button>
                <el-button type="primary" @click="_onCommit">提交</el-button>
            </span>
        </template>

    </el-dialog>
</template>

<script>
import BasicDialog from "@/views/base/BasicDialog";
import {GptApis} from "@/api/gpt-apis";
import {ElMessage} from "element-plus";

export default {
    name: "GptLabelAddDialog",
    mixins: [BasicDialog],
    emits: ["success"],
    props: {
        editing: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            labelForm: {
                textColor: "#14cfa6",
                bgColor: "#000000"
            },
            commitLoading: false,
        }
    },
    methods: {

        _onCommit() {
            let req = {
                textColor: this.labelForm.textColor,
                bgColor: this.labelForm.bgColor
            };
            GptApis.addLabelType(req).then(res => {
                ElMessage.success("添加成功");
                this.dismiss();
                this.$emit("success");
            })
        }
    }
}
</script>

<style scoped>

</style>