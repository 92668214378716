<template>
    <el-button type="success">
        <svg-icon icon-class="ui-plus"/>
        <span style="margin-left: 7px">
            {{title}}
        </span>
    </el-button>
</template>

<script>
import SvgIcon from "@/components/SvgIcon";
export default {
    name: "CommonAddButton",
    components: {SvgIcon},
    props: {
        title: {
            type: String,
            default: '添加'
        }
    }
}
</script>

<style scoped>

</style>