<template>
    <el-container>
        <el-header class="my-page-header">
            <el-row>
                <el-input v-model="searchUserId" style="width: 180px;" placeholder="输入用户ID"/>
                <el-button type="primary" class="ml-16" @click="_onSearchClick">搜索</el-button>
                <el-button type="primary" @click="_onSearchClearClick">清空</el-button>
            </el-row>
        </el-header>

        <el-main style="width: 100%;margin-top: 10px;">
            <el-table :data="logList" border >
                <el-table-column label="ID" width="80" prop="id"/>
                <el-table-column label="日期" width="160" prop="createTime"/>
                <el-table-column label="用户ID" width="180" prop="userId"/>
                <el-table-column label="Api" width="200" prop="api"/>
                <el-table-column label="Q-Tokens" width="80" prop="questionTokens"/>
                <el-table-column label="A-Tokens" width="80" prop="answerTokens"/>
                <el-table-column label="Client" width="80" prop="clientName"/>
                <!--<el-table-column label="UserAgent" width="100" prop="userAgent"/>-->
                <el-table-column label="操作">
                    <template #default="scope">
                        <el-button size="small" type="primary"
                                   @click="_onCheckQuestion(scope.row)">查看提问</el-button>
                        <el-button size="small" type="primary"
                                   @click="_onCheckAnswer(scope.row)">查看回答</el-button>
                    </template>
                </el-table-column>
            </el-table>
        </el-main>
        <!-- 分页插件 -->
        <el-footer>
            <el-pagination
                :current-page="page"
                :page-size="pageSize"
                :page-count="totalPage"
                layout="prev, pager, next"
                @current-change="_onPageChange">
            </el-pagination>
        </el-footer>

        <tts-task-content-dialog ref="contentDialog" v-model:visible="this.contentDialogVisible"/>
    </el-container>
</template>

<script>
import TtsTaskContentDialog from "@/views/tools/TtsTaskContentDialog";
import {ElMessage} from "element-plus";
import StringUtils from "@/utils/stringUtils";
import {GptApis} from "@/api/gpt-apis";

export default {
    name: "GptLogPage",
    components: {TtsTaskContentDialog},
    data() {
        return {
            logItem: {
                id: 0,
                createTime: "",
                userId: "",
                question: "",
                answer: "",
                clientName: "",
                userAgent: "",
                api: "",
            },
            page: 1,
            pageSize: 30,
            totalPage: 1,
            logList: [],
            contentDialogVisible: false,
            searchMode: false,
            searchUserId: null,
        }
    },
    mounted() {
        this._loadTaskList();
    },
    methods: {
        _onPageChange(newPage) {
            if (this.searchMode && this.searchUserId) {
                this.page = newPage;
                this._searchConversationLog();

            } else {
                this.page = newPage;
                this._loadTaskList();
            }
        },

        _loadTaskList() {
            let req = {
                page: this.page,
                pageSize: this.pageSize
            }
            GptApis.searchLogList(req).then(res => {
                let pageData = res.data;
                this.totalPage = pageData.total;
                this.logList = pageData.list;
            })
        },

        _onCheckQuestion(itemData) {
            this._showText(itemData.question);
        },

        _onCheckAnswer(itemData) {
            this._showText(itemData.answer)
        },

        _showText(text) {
            this.$refs.contentDialog.setupContent(text);
            this.contentDialogVisible = true;
        },

        // ===========================================
        //      Search
        // ===========================================

        _onSearchClick() {
            if (StringUtils.isBlank(this.searchUserId)) {
                ElMessage.warning("输入用户id");
                return;
            }
            this.searchMode = true;
            this.page = 1;
            this._searchConversationLog();
        },

        _searchConversationLog() {
            let req = {
                userId: this.searchUserId,
                page: this.page,
                pageSize: this.pageSize
            }
            GptApis.searchLogList(req).then(res => {
                let pageData = res.data;
                this.totalPage = pageData.total;
                this.logList = pageData.list;
            })
        },

        _onSearchClearClick() {
            this.searchMode = false;
            this.searchUserId = null;
            this._searchConversationLog();
        }
    }
}
</script>

<style scoped>

</style>