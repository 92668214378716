import {Apis} from "@/api/Apis";

const CONTENT_PATH = "/admin/dubbing/role"

export const DubbingRoleApis = {

    getAll() {
        return Apis.get2(CONTENT_PATH, "/all");
    },

    addRole(req) {
        return Apis.post2(CONTENT_PATH, "/add", req);
    },

    updateAvatar(file, roleId) {
        let form = {
            file: file,
            roleId: roleId
        }
        return Apis.uploadWithForm2(CONTENT_PATH, "/updateAvatar", form);
    }
}