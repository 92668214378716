<template>
    <el-dialog v-model="internalVisible" width="560px" title="发布版本" @closed="_clearUploader">
        <el-form ref="addForm" :model="addForm" label-width="90px" label-position="right" :rules="uploadRules">
            <el-form-item label="客户端">
                <el-select v-model="addForm.clientId" disabled>
                    <el-option v-for="item in appList"
                               :key="item.clientId"
                               :value="item.clientId"
                               :label="item.clientName">
                    </el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="App名称">
                <el-input v-model="addForm.appName" placeholder="输入App名称" disabled/>
            </el-form-item>
            <el-form-item label="App包名">
                <el-input v-model="addForm.appId" disabled/>
            </el-form-item>
            <el-form-item label="渠道名">
                <el-input v-model="addForm.channel"/>
            </el-form-item>
            <el-form-item label="App版本">
                <el-input v-model="addForm.version" placeholder="App版本，如1.2.3"/>
            </el-form-item>
            <el-form-item label="App版本号">
                <el-input-number v-model="addForm.build" placeholder="App版本号，如99"/>
            </el-form-item>
            <el-form-item label="最低版本">
                <el-input v-model="addForm.requireVersion" placeholder="最低版本，如1.2.3"/>
            </el-form-item>
            <el-form-item label="最低版本号">
                <el-input-number v-model="addForm.requireBuild" placeholder="最低版本号，如99"/>
            </el-form-item>
            <el-form-item label="静默更新">
                <el-switch
                    v-model="addForm.silent"
                    active-color="#13ce66">
                </el-switch>
            </el-form-item>
            <el-form-item label="审核中">
                <el-switch
                    v-model="addForm.review"
                    active-color="#13ce66">
                </el-switch>
            </el-form-item>
            <el-form-item label="更新内容">
                <el-input type="textarea" :rows="5" v-model="addForm.details" placeholder="输入更新内容"/>
            </el-form-item>

            <el-form-item label="更新链接">
                <el-input v-model="addForm.filepath" placeholder="更新链接"/>
            </el-form-item>

            <el-form-item v-if="this.isAndroid" label="选择文件:">
                <el-upload ref="uploader"
                           accept=".apk"
                           action="#" drag
                           :limit="1"
                           :auto-upload="false"
                           :on-change="_onChange"
                           :on-remove="_onRemove"
                           :before-upload="_beforeUpload"
                           :http-request="_onUploadToOss">

                    <div class="apk-uploader-holder">
                        <span v-if="selectedFilename" class="f12">
                            {{ selectedFilename }}
                        </span>
                        <span v-else class="ml-12 f10">点击选择头像或拖拽到此</span>
                    </div>

                    <span class="ml-16 f9">仅支持上传apk格式，且不超过150M</span>
                </el-upload>
            </el-form-item>
            <el-form-item label="上传进度">
                <el-progress class="upload-progress" :percentage="uploadPercent"/>
            </el-form-item>
        </el-form>

        <template #footer>
            <div class="dialog-footer" v-loading="uploading">
                <!--<el-progress v-if="uploading" class="upload-progress" :percentage="uploadPercent"/>-->
                <span style="flex: 1"/>
                <el-button @click="dismiss">取消</el-button>
                <el-button type="primary" @click="_onCommit">提交</el-button>
            </div>
        </template>
    </el-dialog>
</template>

<script>
import BasicDialog from "@/views/base/BasicDialog";
import {ElMessage} from "element-plus";
import {AppVersionApis} from "@/api/app-version-apis";
import {OssService} from "@/api/oss-service";
import {AppVersionConstants} from "@/views/setting/AppVersionConstants";

export default {
    name: "AppVersionAddDialog",
    mixins: [BasicDialog],
    computed: {
        isApple() {
            return this.addForm.clientId === 3;
        },
        isAndroid() {
            return this.addForm.clientId === 2;
        },
        // currentApp() {
        //     for (const index in this.appList) {
        //         let item = this.appList[index];
        //         if (item.clientId === this.currentClientId) {
        //             return item;
        //         }
        //     }
        //     return null;
        // },
    },
    data() {
        return {
            appList: AppVersionConstants.AppList,
            addForm: {
                clientId: 2,
                channel: "default",
                appName: "青岫",
                appId: "",
                build: 1,
                version: "1.0.0",
                requireBuild: 1,
                requireVersion: "1.0.0",
                details: "",
                silent: true,
                review: false,
                filepath: null,
                fileSize: 0
            },
            selectedFilename: null,
            uploadRules: {
                details: [
                    {required: true, message: "更新内容不能为空", trigger: 'blur'},
                ]
            },
            uploading: false,
            uploadPercent: 0,
        }
    },

    mounted() {

    },

    methods: {

        init(channelData) {
            this.addForm.clientId = channelData.clientId;
            this.addForm.appId = channelData.appId;
            this.addForm.channel = channelData.channel;
            if (this.isApple) {
                this.addForm.filepath = "https://apps.apple.com/cn/app/id6444070251";
            } else {
                this.addForm.filepath = "";
            }
        },

        onShow() {
            // 清空已选择的文件
            this.selectedFilename = null;
            this.$nextTick(() => {
                this.$refs.uploader.clearFiles();
            })
        },

        onDismiss() {

        },

        _onCommit() {
            if (this.isApple) {
                this._commitNewVersion(this.addForm.filepath)
            } else {
                this.$refs.uploader.submit()
            }
        },

        // ===========================================
        //      Upload
        // ===========================================

        _clearUploader() {
            // 清除表单
            this.$refs.addForm.resetFields();
            //清除表单内所有规则检测提示
            this.$refs.addForm.clearValidate();
        },

        _onChange(file) {
            if (file) {
                this.selectedFilename = file.name;
                this.addForm.fileSize = file.size;
            } else {
                this.selectedFilename = null;
                this.addForm.fileSize = 0
            }
        },

        _onRemove(file) {
            this.selectedFilename = null;
            this.addForm.fileSize = 0
        },

        _beforeUpload(file) {
            // 限制上传大小为200M
            const sizeLimit = file.size / 1024 / 1024 < 200;
            if (!sizeLimit) {
                ElMessage.error('Apk文件大小不能超过 200MB!')
            }
            return sizeLimit
        },

        _onUploadToOss(options) {
            let file = options.file;
            let filename = file.name;
            AppVersionApis.getApkUploadPolicy(filename).then(res => {
                let policyData = res.data;
                this._handleUploadToOss(file, policyData);
            })
        },

        _handleUploadToOss(file, policy) {
            let objectKey = policy.objectKey;
            // this._commitNewVersion(objectKey);
            // console.log("handle object key")
            let self = this;
            this.uploading = true;
            OssService.uploadFile(file, policy, function (percent) {
                self.uploadPercent = percent;
            }, function (result) {
                self.uploading = false;
                self._commitNewVersion(objectKey);
            }, function (errCode, errMsg) {
                self.uploading = false;
                return false;
            })
        },

        _commitNewVersion(objectKey) {
            let req = {
                clientId: this.addForm.clientId,
                channel: this.addForm.channel,
                appName: this.addForm.appName,
                appId: this.addForm.appId,
                build: this.addForm.build,
                version: this.addForm.version,
                details: this.addForm.details,
                requireBuild: this.addForm.requireBuild,
                requireVersion: this.addForm.requireVersion,
                silent: this.addForm.silent,
                review: this.addForm.review,
                filepath: objectKey,
                filesize: this.addForm.fileSize
            }
            AppVersionApis.addVersion(req).then(_ => {
                this.dismiss()
                ElMessage.success("发布成功");
            })
        }
    }
}
</script>

<style lang="scss" scoped>

.apk-uploader-holder {
    display: flex;
    align-items: center;
    width: 100%;
    height: 70px;
    justify-content: center;
}

.dialog-footer {
    display: flex;

    .upload-progress {
        width: 250px;
    }
}

</style>