<template>
    <div>
        <div ref="lineChart" class="line-chart"></div>
    </div>
</template>

<script>
import * as echarts from 'echarts'
import {DateUtils} from "@/utils/DateUtils";
import {UserApis} from "@/api/user-apis";

export default {
    name: "HomePage",

    data() {
        return {
            lastDays: [],
            sumList: [0,0,0,0,0,0,0],
            webList: [0,0,0,0,0,0,0],
            appleList: [0,0,0,0,0,0,0],
            androidList: [0,0,0,0,0,0,0],
        }
    },

    mounted() {
        // 计算近7日日期，包括今天
        let days = [];
        for (let i = 0; i < 7; i++) {
            let date = DateUtils.dayBefore(i);
            let dateStr = DateUtils.format(date, 'mm-dd')
            days.unshift(dateStr);
        }
        this.lastDays = days;
        this.lineChart = echarts.init(this.$refs.lineChart);

        // 初始化
        this._updateLineChart();

        this._loadNewUsersCount();
    },

    methods: {
        _loadNewUsersCount() {
            UserApis.getNewUsersCount().then(res => {
                let data = res.data;
                this.sumList = data.sumList;
                this.webList = data.webList;
                this.appleList = data.appleList;
                this.androidList = data.androidList;
                this._updateLineChart();
            })
        },

        _updateLineChart() {
            this.lineChart.setOption({
                title: {
                    text: "新增用户"
                },
                // 提示框
                tooltip: {
                    trigger: 'axis'
                },
                // 图例
                legend: {
                    icon: 'circle',
                    left: 'center',
                    top: 0,
                    data: ['总人数', 'web', 'iOS', '安卓']
                },
                grid: {
                    left: '3%',
                    right: '3%',
                    bottom: '3%',
                    containLabel: true
                },
                xAxis: {
                    type: 'category',
                    boundaryGap: false,
                    data: this.lastDays
                },
                yAxis: {
                    type: 'value'
                },
                series: [
                    {
                        name: '总人数',
                        type: 'line',
                        // smooth: true, // 平滑曲线显示
                        data: this.sumList
                    },
                    {
                        name: 'web',
                        type: 'line',
                        // smooth: true,
                        data: this.webList
                    },
                    {
                        name: '安卓',
                        type: 'line',
                        stack: '总量',
                        // smooth: true,
                        data: this.androidList
                    },
                    {
                        name: 'iOS',
                        type: 'line',
                        // smooth: true,
                        data: this.appleList
                    }
                ]
            }, true);
        }
    }
}
</script>

<style lang="scss" scoped>

.line-chart {
    width: 800px;
    height: 400px;
    margin-left: 20px;
    margin-top: 20px;
}

</style>