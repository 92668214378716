
export const SsmlUtils = {

    /**
     * 构造停顿时长标签
     * @param millis 时长-毫秒
     * @returns {string} [#200ms#]
     */
    makeBreakTag(millis) {
        return `[#${millis}ms#]`
    },

    /**
     * 构造多音字标签
     * @param word 汉字
     * @param pingyin 拼音
     * @returns {string} [#行=hang2#]
     */
    makePhonemeTag(word, pingyin) {
        return `[#${word}=${pingyin}#]`
    },

    /**
     * 构造别名标签
     * @param words 原内容
     * @param alias 别名
     * @returns {string} [@WTO=世界贸易组织#]
     */
    makeAliasTag(words, alias) {
        return `[@${words}=${alias}#]`
    },

}