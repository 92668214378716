<template>
    <!-- 缓存的页面，缓存$route.meta.keepAlive为true的组件 -->
    <!--<keep-alive>-->
    <!--    <router-view v-if="$route.meta.keepAlive"></router-view>-->
    <!--</keep-alive>-->
    <!--&lt;!&ndash;<router-view v-if="$route.meta.keepAlive" v-slot="{ Component }">&ndash;&gt;-->
    <!--&lt;!&ndash;    <keep-alive>&ndash;&gt;-->
    <!--&lt;!&ndash;        <component :is="Component" />&ndash;&gt;-->
    <!--&lt;!&ndash;    </keep-alive>&ndash;&gt;-->
    <!--&lt;!&ndash;</router-view>&ndash;&gt;-->
    <!--&lt;!&ndash; 不缓存的页面 &ndash;&gt;-->
    <!--<router-view v-if="!$route.meta.keepAlive"></router-view>-->

    <router-view v-slot="{ Component }">
        <keep-alive>
            <component :is="Component"
                       v-if="$route.meta.keepAlive"
                       :key="$route.path" />
        </keep-alive>
        <component :is="Component"
                   v-if="!$route.meta.keepAlive" />
    </router-view>
</template>

<script>
export default {
    name: "SubRouterLayout"
}
</script>

<style scoped>

</style>