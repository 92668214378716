<template>
    <el-popover width="300" ref="popover" trigger="click" @show="_onShow">
        <!--<el-radio-group style="width: 300px" v-model="internalRecommend" @change="$forceUpdate()">-->
        <el-radio v-model="internalRecommend" :label="0">无</el-radio>
        <el-radio v-model="internalRecommend" :label="1">热门</el-radio>
        <el-radio v-model="internalRecommend" :label="2">推荐</el-radio>
        <el-radio v-model="internalRecommend" :label="3">最新</el-radio>
        <!--</el-radio-group>-->
        <div class="action-wrapper">
            <el-button size="small" @click="_dismiss">取消</el-button>
            <el-button type="primary" size="small" @click.stop="_onCommit">确定</el-button>
        </div>
        <template #reference>
            <span class="ui-cursor-pointer ui-color-blue">{{ this._recommendText() }}</span>
        </template>
    </el-popover>
</template>

<script>
import {ElMessage} from "element-plus";
import {AnchorApis} from "@/api/anchor";

export default {
    name: "AnchorRecommendEditPop",
    props: {
        row: {
            type: Number,
            default: 0
        },
        anchorId: {
            type: Number,
            default: 0
        },
        recommend: {
            type: Number,
            default: 0
        }
    },
    emits: ['update'],
    data() {
        return {
            internalRecommend: 0
        }
    },
    methods: {
        _recommendText() {
            if (this.recommend === 0) {
                return "无"
            } else if (this.recommend === 1) {
                return "热门"
            } else if (this.recommend === 2) {
                return "推荐"
            } else {
                return "其他"
            }
        },
        _onShow() {
            this.internalRecommend = this.recommend;
        },

        _dismiss() {
            this.$refs.popover.hide();
        },

        _onCommit() {
            let self = this;
            AnchorApis.updateAnchorRecommend(this.anchorId, this.internalRecommend).then(() => {
                // self.recommend = self.internalRecommend;
                ElMessage.success("修改成功");
                self._dismiss();
                this.$emit('update', this.row, this.internalRecommend);
            })
        }
    }
}
</script>

<style lang="scss" scoped>

.action-wrapper {
    width: 100%;
    margin-top: 7px;
    text-align: right;
}

</style>