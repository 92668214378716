<template>
    <!-- eslint-disable-next-line vue/no-mutating-props -->
    <el-dialog width="20%" v-model="dialogVisible" :title="dialogTitle" :before-close="beforeClose" @closed="reset">
        <el-form ref="avatarForm" :model="avatarForm" label-width="80">

            <el-form-item label="声音性别" prop="sex">
                <el-select v-model="value" placeholder="选择声音性别" style="width: 240px">
                    <el-option v-for="item in sexOptions"
                               :key="item.value"
                               :label="item.label"
                               :value="item.value"/>
                </el-select>
            </el-form-item>

            <el-form-item label="选择头像:" prop="avatar">
                <el-upload ref="uploader" class="avatar-uploader"
                           action="#" drag
                           accept="image/png,image/jpeg"
                           :limit="1"
                           :auto-upload="false"
                           :show-file-list="true"
                           :on-exceed="_onExceed"
                           :on-remove="_onRemove"
                           :on-change="_onFileChange"
                           :before-upload="beforeUpload"
                           :http-request="onUploadToOSS">
                    <!--suppress HtmlUnknownTarget -->
                    <img v-if="selectedAvatarFile" :src="selectedAvatarFileUrl" class="avatar-img" alt="头像">
                    <span>点击选择头像或拖拽到此</span>
                    <!--<el-icon v-else class="plus-icon">-->
                    <!--    <plus/>-->
                    <!--</el-icon>-->
                </el-upload>
            </el-form-item>

        </el-form>

        <template #footer>
                <span class="dialog-footer">
                    <el-button @click="onCancelClick">取消</el-button>
                    <el-button type="primary" @click="onSubmitClick">确定</el-button>
                </span>
        </template>
    </el-dialog>
</template>

<script>
import voiceOptions from "@/views/dubbing/VoiceConstants";
import {ElMessage, genFileId} from "element-plus";
import {Plus} from "@element-plus/icons-vue";
import {getOssPolicy, uploadAvatar} from "@/api/avatar";
import axios from "axios";

export default {
    name: "AdminAvatarEditDialog",
    components: {
        Plus
    },
    props: {
        dialogVisible: {
            type: Boolean,
            default: false
        }
    },
    // 定义抛出事件名
    emits: ["dialog-dismiss", "onResult"],
    computed: {
        isShowing() {
            return this.dialogVisible
        }
    },
    data() {
        return {
            dialogTitle: "添加头像",
            sexOptions: voiceOptions.sexOptions,
            selectedAvatarFileUrl: null, // 当前选择的头像文件链接，用于预览
            selectedAvatarFile: null, // 当前选择的头像文件，用于上传,
            value: 1,
            avatarForm: {
                sex: 1
            }
        }
    },
    methods: {

        reset() {
            // 清空已选择的文件
            this.$refs.uploader.clearFiles();
            // 清除表单
            this.$refs['avatarForm'].resetFields();
            //清除表单内所有规则检测提示
            this.$refs['avatarForm'].clearValidate();

            let obj1 = this.$data;
            let obj2 = this.$options.data.call(this);
            for (let attrName in obj1) {
                if (attrName !== 'rules') {
                    // noinspection JSUnfilteredForInLoop
                    obj1[attrName] = obj2[attrName]
                }
            }

        },
        dismiss() {
            // 关闭状态需要父组件控制
            this.$emit('dialog-dismiss');
        },
        beforeClose() {
            this.dismiss();
        },
        onCancelClick() {
            this.dismiss();
        },

        onSubmitClick() {
            console.log("onSubmitClick");
            this.$refs.uploader.submit()
        },

        notifyAddSuccess(data) {
            this.$emit('onResult', data);
            this.dismiss();
        },

        // ===========================================
        //      Uploader
        // ===========================================

        _onExceed(files) {
            this.$refs.uploader.clearFiles();
            const file = files[0];
            file.uid = genFileId();
            this.$refs.uploader.handleStart(file);
        },

        _onRemove() {
            this.selectedAvatarFile = null;
            this.selectedAvatarFileUrl = null;
        },

        // 文件状态改变时的钩子，添加文件、上传成功和上传失败时都会被调用
        _onFileChange(file) {
            // console.log("onChange: " + JSON.stringify(file));
            this.selectedAvatarFile = file;
            // 获取上传图片的本地URL，用于上传前的本地预览
            let URL = null;
            if (window.createObjectURL !== undefined) {
                // basic
                URL = window.createObjectURL(file.raw);
            } else if (window.URL !== undefined) {
                // mozilla(firefox)
                URL = window.URL.createObjectURL(file.raw);
            } else if (window.webkitURL !== undefined) {
                // webkit or chrome
                URL = window.webkitURL.createObjectURL(file.raw);
            }
            this.selectedAvatarFileUrl = URL;
        },

        // avatar-uploader
        beforeUpload(file) {
            // console.log("beforeUpload file: " + JSON.stringify(file));
            const isJPG = file.type === 'image/jpeg' || file.type === 'image/png';
            const isLt2M = file.size / 1024 / 1024 < 5;

            if (!isJPG) {
                ElMessage.error('Avatar picture must be JPG/PNG format!')
            }
            if (!isLt2M) {
                ElMessage.error('Avatar picture size can not exceed 5MB!')
            }
            return isJPG && isLt2M
        },

        // 上传方式1：上传图片至本地服务器
        onAvatarUpload(param) {
            let file = param.file;
            let form = new FormData();
            form.append("file", file);
            form.append("sex", this.avatarForm.sex);

            uploadAvatar(form).then(res => {
                if (res.code !== 200) {
                    console.log("头像上传失败");
                    ElMessage.error("头像上传失败: " + res.message);
                    return
                }
                ElMessage.success("头像上传成功：");

                this.dismiss();

            }).catch(error => {
                console.log("头像上传失败: " + error.toString());
                ElMessage.error("头像上传失败: " + error.toString());
            });
        },

        // 上传方式2：上传图片至oss
        onUploadToOSS(param) {
            let file = param.file;
            let oldFilename = file.name;
            let sex = this.avatarForm.sex;

            getOssPolicy(oldFilename, sex).then(res => {
                if (res.code !== 200) {
                    console.log("获取oss-policy失败");
                    ElMessage.error("获取oss-policy失败: " + res.message);
                    return
                }
                // 获取oss-policy成功
                let ossPolicy = res.data;
                // console.log(ossPolicy)
                this.uploadToOss(file, ossPolicy)
            })
                .catch(error => {
                    console.log("获取oss-policy失败")
                    ElMessage.error("获取oss-policy失败: " + error.toString());
                })
        },

        uploadToOss(file, ossPolicy) {
            let filename = ossPolicy.filename; // 使用服务端生成的文件名
            let form = new FormData();
            //注意formData里append添加的键的大小写
            form.append('key', ossPolicy.dir + filename); //存储在oss的文件路径
            form.append('OSSAccessKeyId', ossPolicy.accessKeyId); //accessKeyId
            form.append('policy', ossPolicy.policy); //policy
            form.append('Signature', ossPolicy.signature); //签名
            form.append('callback', ossPolicy.callback); //回调
            form.append('success_action_status', 200); //成功后返回的操作码
            // 自定义参数：需要注意必须以x:开头，且只能使用小写字母和下划线_
            // form.append('x:token', Store.getters.token);
            // form.append('x:gender', this.avatarForm.sex);
            //如果是base64文件，那么直接把base64字符串转成blob对象进行上传就可以了
            form.append("file", file);
            axios.post(ossPolicy.host, form, {
                'Content-Type': 'multipart/form-data'
            }).then(response => {
                /**
                 * {status:200, statusText: '成功', data: {code: 200, msg: '成功', data: {...}}}
                 */
                if (response.status === 200 && response.data.code === 200) {
                    ElMessage.success("头像上传成功");
                    let avatarVo = response.data.data;
                    this.notifyAddSuccess(avatarVo);
                } else {
                    ElMessage.error("头像上传失败: " + response.statusText);
                }
            }).catch(error => {
                ElMessage.error("头像上传失败: " + error.toString());
            })
        }

    }
}
</script>

<style scoped>

.avatar-uploader {
    width: 90px;
    border: 1px dashed #d9d9d9;
    border-radius: 10px;
    cursor: pointer;
    overflow: hidden;
    line-height: 0;

}

</style>