<template>
   <el-container>
       <el-header class="my-page-header">
           <el-row>
               <common-page-title-label title="Banner轮播图"></common-page-title-label>
               <common-add-button title="添加Banner" @click="_onAddClick"/>
           </el-row>
       </el-header>

       <el-main>
           <el-row style="margin-bottom: 15px;">
               <el-radio-group v-model="clientId" @change="_onTabChange">
                   <el-radio-button :label="1">web</el-radio-button>
                   <el-radio-button :label="2">app</el-radio-button>
               </el-radio-group>
           </el-row>
           <el-table :data="bannerList" border>
               <el-table-column label="ID" prop="id" align="center" width="60"></el-table-column>
               <el-table-column label="Client" prop="clientName" align="center" width="100"/>
               <el-table-column label="排序" prop="sort" align="center" width="80"/>
               <el-table-column label="描述" prop="comment" align="center" width="160"/>
               <el-table-column label="链接" prop="link" align="center" width="160"/>
               <el-table-column label="预览图" prop="imagePath" align="center" width="240">
                   <template #default="scope">
                       <img :src="scope.row.imagePath" style="width: 220px;height: 69px;"/>
                   </template>
               </el-table-column>
               <el-table-column label="状态" prop="status" width="70">
                   <template #default="scope">
                       <span>{{this._mkStatusText(scope.row.status)}}</span>
                   </template>
               </el-table-column>
               <el-table-column label="操作" fixed="right">
                   <template #default="scope">
                       <el-row>
                           <el-button type="primary" size="mini" @click="_onEditImageClick(scope.row)">设置图片</el-button>
                           <el-button type="primary" size="mini" @click="_onEditClick(scope.row)">编辑</el-button>
                           <el-button v-if="scope.row.status === 0" type="danger" size="mini" @click="_onForbidClick(scope.row)">禁用</el-button>
                           <el-button v-else type="success" size="mini" @click="_onResumeClick(scope.row)">恢复</el-button>
                       </el-row>
                   </template>
               </el-table-column>
           </el-table>
       </el-main>

       <banner-add-dialog v-model:visible="addDialogVisible"/>
       <banner-add-dialog ref="editDialog" v-model:visible="editDialogVisible" :editing="true"/>
       <banner-image-upload-dialog ref="editImageDialog" v-model:visible="editImageDialogVisible"/>
   </el-container>
</template>

<script>
import CommonPageTitleLabel from "@/views/common/CommonPageTitleLabel";
import BannerAddDialog from "@/views/banner/BannerAddDialog";
import CommonAddButton from "@/views/common/CommonAddButton";
import {BannerApis} from "@/api/banner-apis";
import BannerImageUploadDialog from "@/views/banner/BannerImageUploadDialog";
import {ToastUtil} from "@/utils/toast-util";
export default {
    name: "BannersPage",
    components: {BannerImageUploadDialog, CommonAddButton, BannerAddDialog, CommonPageTitleLabel},
    data() {
        return {
            clientId: 1,
            bannerList: [],
            addDialogVisible: false,
            editDialogVisible: false,
            editImageDialogVisible: false
        }
    },
    mounted() {
        this._loadBanners();
    },
    methods: {

        _mkStatusText(status) {
            if (status === 0) {
                return "正常"
            } else if (status === 1) {
                return "已删除"
            } else if (status === 2) {
                return "已禁用"
            }
            return "未知"
        },

        _onAddClick() {
            this.addDialogVisible = true;
        },

        _onTabChange(newClientId) {
            this._loadBanners();
        },

        _loadBanners() {
            BannerApis.getAllBanners(this.clientId).then(res => {
                this.bannerList = res.data;
            })
        },

        _onEditClick(item) {
            this.$refs.editDialog.init(item);
            this.editDialogVisible = true;
        },

        _onEditImageClick(item) {
            this.$refs.editImageDialog.init(item);
            this.editImageDialogVisible = true;
        },

        _onForbidClick(item) {
            BannerApis.forbidBanner(item.id).then(res => {
                ToastUtil.toast("已禁用");
            })
        },

        _onResumeClick(item) {
            BannerApis.resumeBanner(item.id).then(res => {
                ToastUtil.toast("已恢复")
            })
        }
    }
}
</script>

<style lang="scss" scoped>

</style>