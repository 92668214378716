<template>
  <el-drawer v-model="internalVisible" title="ModelEditor" size="400px">
    <div class="drawer-main">
      <div class="drawer-content">
        <div class="content-inner">
          <el-form ref="modelForm" :model="modelForm" label-width="88px">
            <el-form-item label="风格">
              <el-select v-model="modelForm.styleId" placeholder="选择风格">
                <el-option
                  v-for="(styleItem, index) in styleList"
                  :key="index"
                  :label="styleItem.name"
                  :value="styleItem.id"/>
              </el-select>
            </el-form-item>
            <el-form-item label="名称">
              <el-input v-model="modelForm.name" placeholder="输入模版名称"></el-input>
            </el-form-item>
            <el-form-item label="描述">
              <el-input v-model="modelForm.description" placeholder="输入模型简要描述"></el-input>
            </el-form-item>
            <el-form-item label="提示词前缀">
              <el-input v-model="modelForm.promptPrefix" placeholder="输入提示词前缀"></el-input>
            </el-form-item>
          </el-form>
        </div>
      </div>
      <div class="drawer-footer">
        <el-button class="action-btn" round @click="dismiss">取消</el-button>
        <el-button class="action-btn ml-20" type="primary" round @click="onCommitClick">{{ commitTitle }}</el-button>
      </div>
    </div>
  </el-drawer>
</template>

<script>
import BasicDialog from "@/views/base/BasicDialog";
import {DrawsApi} from "@/api/draws-api";

export default {
  name: "DrawModelEditDrawer",
  mixins: [BasicDialog],
  data() {
    return {
      editing: false,
      styleList: [],
      modelForm: {
        id: null,
        name: null,
        description: null,
        styleId: null,
        promptPrefix: null,
      },
      rules: {
        name: [
          { required: true, message: '名称不能为空', trigger: 'blur' }
        ],
        styleId: [
          { required: true, message: '风格不能为空', trigger: 'blur' }
        ]
      }
    }
  },
  computed: {
    commitTitle() {
      return this.editing ? 'Edit' : 'Add'
    }
  },
  mounted() {
    this.getStyleList();
  },
  methods: {
    getStyleList() {
      DrawsApi.getStyleList().then(res => {
        this.styleList = res.data;
      })
    },
    showEditing(data) {
      this.editing = true;
      this.modelForm.id = data.id;
      this.modelForm.name = data.name;
      this.modelForm.description = data.description;
      this.modelForm.styleId = data.styleId;
      this.modelForm.promptPrefix = data.promptPrefix;
      this.internalVisible = true;
    },
    onCommitClick() {
      this.$refs.modelForm.validate((valid) => {
        if (valid) {
          this.editing ? this.doEditStyle() : this.doAddNewStyle()
        }
      })
    },
    doAddNewStyle() {
      const params = {
        styleId: this.modelForm.styleId,
        name: this.modelForm.name,
        description: this.modelForm.description,
        promptPrefix: this.modelForm.promptPrefix
      }
      DrawsApi.addModel(params).then(_ => {
        this.$message.success('添加成功');
        this.$emit('success');
        this.dismiss();
      })
    },
    doEditStyle() {
      const params = {
        id: this.modelForm.id,
        styleId: this.modelForm.styleId,
        name: this.modelForm.name,
        description: this.modelForm.description,
        promptPrefix: this.modelForm.promptPrefix
      }
      DrawsApi.updateModel(params).then(_ => {
        this.$message.success('修改成功');
        this.$emit('success');
        this.dismiss();
      })
    }
  }
}
</script>

<style lang="scss" scoped>

.drawer-main {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;

  .drawer-content {
    flex: 1;
    width: 100%;
    overflow: auto;
    background-color: #f5f5f5;

    .content-inner {
      width: 100%;
      display: flex;
      flex-direction: column;
      box-sizing: border-box;
      padding: 15px;
    }
  }

  .drawer-footer {
    width: 100%;
    height: 64px;
    display: flex;
    align-items: center;
    box-sizing: border-box;
    padding: 0 20px;

    .action-btn {
      flex: 1;
      height: 40px;
    }
  }
}

</style>