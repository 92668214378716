<template>
    <!-- 编辑发音人弹框 -->
    <!-- name, voice, type, scene, language, quality,  -->
    <el-dialog width="40%" v-model="isVisible" title="编辑发音人" @closed="reset">
        <el-form ref="voiceFormEl" :model="voiceForm" :rules="rules" label-width="100px">

            <el-form-item label="声音名称" prop="name">
                <el-input v-model="voiceForm.name" disabled/>
            </el-form-item>

            <el-form-item label="支持风格:" prop="styles">
                <el-checkbox-group v-model="voiceForm.styles">
                    <el-checkbox v-for="item in styleOptionList" :key="item.id" :label="item.id">
                        {{ item.value }}
                    </el-checkbox>
                </el-checkbox-group>
            </el-form-item>

        </el-form>

        <template #footer>
                <span class="dialog-footer">
                    <el-button @click="dismiss">取消</el-button>
                    <el-button type="primary" @click="onSubmitClick">确定</el-button>
                </span>
        </template>
    </el-dialog>
</template>

<!-- 微软发音人 支持风格 编辑 -->
<script>
import bus from "@/utils/bus";
import {DubbingApis} from "@/api/dubbing";
import {ElMessage} from "element-plus";

export default {
    name: "VoiceStyleEditDialog",
    props: {
        visible: {
            type: Boolean,
            default: false
        }
    },
    // 定义抛出事件名
    emits: ["dismiss", 'visible'],
    computed: {
        isVisible: {
            get() {
                return this.visible;
            },
            set(v) {
                this.$emit('visible', v);
            }
        }
    },
    data() {
        return {
            styleOptionList: [], // 可选择风格列表
            voiceForm: {
                id: null,
                name: null,
                styles:[], // 已选择的风格
            },
            rules: {
                styles: [
                    {type: 'array', required: true, message: '至少选择一个分类', trigger: 'change'}
                ],
            }
        }
    },
    mounted() {
        this.getStyleOptions();

        let self = this;
        bus.on('editVoiceData', (data) => {
            self.voiceForm.id = data.id;
            self.voiceForm.name = data.name;
            // 风格
            if (data.styles !== null) {
                for (let i = 0; i < data.styles.length; i++) {
                    let item = data.styles[i];
                    this.voiceForm.styles.push(item.id)
                }
            }
        });
    },
    methods: {
        reset() {
            this.$refs.voiceFormEl.resetFields();
            this.$refs.voiceFormEl.clearValidate();
        },
        dismiss() {
            this.$emit('dismiss');
            this.reset()
        },

        getStyleOptions() {
            DubbingApis.getStyleOptionList().then(res => {
                if (res.code === 200) {
                    this.styleOptionList = res.data;
                } else {
                    console.log("获取风格选项列表失败：" + res.message);
                }
            })
        },

        onSubmitClick() {
            // $refs 获取组件
            this.$refs.voiceFormEl.validate( (valid) => {
                if (valid) {
                    this.modifyStyles()
                }
            })
        },
        modifyStyles() {
            let req = {
                voiceId: this.voiceForm.id,
                styleIds: this.voiceForm.styles
            }
            DubbingApis.editVoiceStyles(req).then(res => {
                if (res.code === 200) {
                    ElMessage.success("设置成功");
                    this.dismiss();
                } else {
                    ElMessage.error("设置失败：" + res.message);
                }
            }).catch(error => {
                ElMessage.error("设置失败：" + error.toString());
            })
        }
    }
}
</script>

<style scoped>

</style>